import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { Cookies } from 'react-cookie' 
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/admin.css'
import {Col, Row, FormControl, FormGroup, Radio, Tabs,Tab,Table,Checkbox} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,ADMIN_EDIT_USER} from '../../constants/actionTypes'
import ModalDeleteUser from './ModalDeleteUser'
//import translations from '../common/captionhome.json';
import $ from 'jquery';
import Datetime from "react-datetime";


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: ADMIN_EDIT_USER, data })  
	},
});

class UserInfo extends Component{ 	
	constructor(){
		super()
		this.state = {
			language:'1005',
			userList:[],
			bankList:[],
			timezoneList:[],
			avatar:'https://www.coopbet.com/media/no_avatar.png',
			search:'',
			countryList:[],
			roleList:[],
			UserNameContains:'',
			EmailContains:'',
			SinceDate:'',
			ShowonlySuspendedUsers:0,
			senior_tips:1,isOpenModal:false,user_id:'',deleteType:'',
			userInfo : null,
			tipster_name: '',
		}
		
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		this.getUsers=this.getUsers.bind(this);
		this.getTimezone();
		this.getCountry();
		
	}
	
	//---
	getTimezone(){
		agent.auth.timezone().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({timezoneList:datas.list})
		}
	  })	
	}
	//---
	getCountry(){
		agent.auth.country().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({countryList:datas.list})
		}
	  })	
	}
	getUsers(userid){
		$(".imgload").show();
		//const user_id=this.state.user_id;
		const user_id=userid;
		agent.admin.profiledetail({user_id:user_id}).then((datas) => {
			//console.log(datas)
			if(datas!=null && datas.result=='good'){
				this.setState({userList:[datas.UserInfo],userInfo:datas.UserInfo,bankList:[datas.UserBankRolls]});
				
			}
			$(".imgload").hide();
	  })	
	}
	
	
	ChangeField(field,ev){
		this.setState({[field]:ev.target.value})
	}
	handleSuspendedUsers(ev){
		//console.log(ev.target.checked)
		//this.setState({ShowonlySuspendedUsers:ev.target.checked})
	}
	
	componentWillMount(){		
		let user_id=this.props.match.params.id;
	//	console.log("user_id:"+user_id);
		this.props.onLoad('adminedituser');
		this.getUsers(user_id);
	//	this.getRoleList();	
		//this.getSeniorTips();	
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({language:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	
	render(){   
		const roleList=this.state.roleList;
		const userList=this.state.userList;
		const bankList=this.state.bankList;
		let userInfo = this.state.userInfo;
		const timezoneList=this.state.timezoneList;
		const countryList=this.state.countryList;
		let urlname=this.state.tipster_name.split(' ').join('');
		let days=['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
		let months=[{'key':'1','value':'Jan'},{'key':'2','value':'Feb'},{'key':'3','value':'Mar'},
				   {'key':'4','value':'Apr'},{'key':'5','value':'May'},{'key':'6','value':'Jun'},
				   {'key':'7','value':'Jul'},{'key':'8','value':'Aug'},{'key':'9','value':'Sep'},
				   {'key':'10','value':'Oct'},{'key':'11','value':'Nov'},{'key':'12','value':'Dec'}]
		let years=this.state.years

		
		let joinsdate=[{'value':'','title':'All'},{'value':'LAST_DAY','title':'Last day'},
			{'value':'LAST_WEEK','title':'Last week'},{'value':'LAST_MONTH','title':'Last month'},
			{'value':'LAST_YEAR','title':'Last year'}]

		//console.log(bankList);
    return (
			<div id="body">   
				<div>
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">USER MANAGEMENT</span>
								</h1>
							</div>
							
							<div className="portlet-input input-inline input-small div-search-user">
								<div><a className="running" href="./createaduser">Create ad user</a></div>
							</div>		
						</div>
					</div>
					<div className="tipster-list">
						
						<br/>
						<div className="container">
						<div className="col-md-12" style={{'textAlign':'center'}}>
							<img className="imgload" src="https://www.coopbet.com/media/SpinnerLoading.gif" style={{'display':'','position':'absolute'}}/>
						</div>
						<div className="items userlistheader">Users</div>
						{
							(userList!='') ? (userList.map((data,index)=>{
								const summary=data.SummaryBet
								return (
										<div className="items" key={index}>
											
											<div className="name item" style={{'width':'10%'}}>
												<span>&nbsp;</span>
											</div>

											<figure className="item" style={{'width':'75px'}}>
													<img src={(data.imageurl!='' && data.imageurl!=null)?data.imageurl:this.state.avatar} width="70" height="70"/>
											</figure>
											<div className="name item">
												<h3 className="">
												{data.name}
												</h3>
												<span className="lbl">{data.tipster_title}</span><br/>
												<span className="ctn">{data.sport_tips}</span><br/>
												<span>{data.tipster_name}</span>
											</div>
											
											<div className="profit item">
												<ul>
													<li>
														<b>Profit: </b>
														<span>{summary.profit}</span>
													</li>
													<li>
														<b>Yield: </b>
															<span>
																{summary.profitpercentage}
															</span>
													</li>
													<li>
														<b>Win rate: </b>
														<span>{summary.IswonPercentage}</span>
													</li>
													<li>
														<b>Number of Tips: </b>
														<span>{summary.NumberOfTips}</span>
													</li>
												</ul>
											</div>
											<div className="profit item">
												<ul>
													<li>
														<b>Email: </b>
														<span>{data.email}</span>
													</li>
													<li>
														<b>Last Visit: </b>
															<span>
																{data.last_visit}
															</span>
													</li>
													<li>
														<b>Locked: </b>
														<span>{data.Locked}</span>
													</li>
												</ul>
											</div>
												
						<div className="register page">
							<div className="regis-ctn">
								<Col md={12} className='items-1'>
									
									<Col md={1}></Col>
									
								</Col>
								
								<Col md={12} className='items-2'>
									<Col md={6}>
										<Row className="form-group " style={{'marginBottom':'3px'}}>
											<Col md={5} className="pdr0">
												<span className="lbl">Time Zone:</span>
											</Col>
											<Col md={7}>
											<FormControl componentClass="select" placeholder="select" value={data.time_zone}>
													
													{
														(timezoneList)?timezoneList.map(data1=>{
															return (<option value={data1.id} key={data1.id}> {data1.desc} </option>)
														}):''
													}
												</FormControl>	
											</Col>
										</Row>										
									</Col>
									<Col md={6}>
										<Row className="form-group ">
											<Col md={5} className="pdr0">
													<span className="lbl">Country:</span>
											</Col>
											<Col md={7}>
											<FormControl componentClass="select" placeholder="select" value={data.country_id}>
													{
														(countryList)?countryList.map(data1=>{
															return(<option value={data1.id} key={data1.id}> {data1.title} </option>)
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
									</Col>
									
									<Col md={6}>
										<Row className="form-group " style={{'marginBottom':'3px'}}>
											<Col md={5} className="pdr0">
								<span className="lbl">Birthday:</span>
											</Col>
											<Col md={7}>
												<input id="Name_Tip" name="Name_Tip" type="text" placeholder="Tipster Name" className="form-control" readOnly  value={data.birthday}/>
											</Col>
										</Row>										
									</Col>
									<Col md={6}>
										<Row className="form-group ">
											<Col md={5} className="pdr0">
													<span className="lbl">Mobile:</span>
											</Col>
											<Col md={7}>
												
												<input  name="display_order" type="text" placeholder="Display order" readOnly className="form-control" value={data.phone_number}/>
											</Col>
										</Row>
									</Col>
									<Col md={6}>
										<Row className="form-group " style={{'marginBottom':'3px'}}>
											<Col md={5} className="pdr0">
												<span className="lbl">Url Username:</span>
											</Col>
											<Col md={7}>
												<input id="Name_Tip" name="Name_Tip" type="text" placeholder="Tipster Name" className="form-control" readOnly value={data.url_username}/>
											</Col>
										</Row>										
									</Col>
									<Col md={6}>
										<Row className="form-group ">
											<Col md={5} className="pdr0">
													<span className="lbl">Date verify:</span>
											</Col>
											<Col md={7}>
												<input id="display_order" name="display_order" type="text" placeholder="Display order" readOnly className="form-control" value={data.validated_time}/>
											</Col>
										</Row>
									</Col>

									
								</Col>
								
								<Col md={12} className='items-3'>
								
									<Col md={12}>
										<Row className="form-group" style={{'marginBottom':'3px'}}>
											<Col md={5} className="pdr0">
												<span className="lbl">Account:</span>
											</Col>
											<Col md={7}>
												
											</Col>
										</Row>										
									</Col>									
									
									{
										(bankList!='') ? (bankList.map((data,index)=>{
											const bankrolls=data.BankRolls;
											const bitcoinRolls=data.BitcoinRolls;
											const paypalRolls=data.PaypalRolls;
											
										//	console.log(bankrolls);
											return (
												
												<Col md={12}>
												<Row>
													<Col md={2}></Col>
													<Col md={8} className="right-br">
														<Tabs defaultActiveKey="key1" id="tab-bankroll">
														<Tab eventKey="key1" title="ngân hàng">
															<Row className="mg0">
																<Table className="tbl-bank-rol" >
																	<thead>
																		<tr>
																			<td colSpan="1" style={{'width':'20%'}}>Bank Name</td>
																			<td colSpan="1" style={{'width':'27%'}}>Account Name</td>
																			<td colSpan="1" style={{'width':'27%'}}>Account No</td>
																			<td colSpan="1" style={{'width':'16%'}}>Date</td>	
																			<td colSpan="1" style={{'width':'10%'}}>Default</td>
																		</tr>
																	</thead>
																	<tbody>
																	{
																		(bankrolls!='')?bankrolls.map((data,idx)=>{
																			return(
																				<tr key={idx}>
																					<td>{data.bank_name}</td>
																					<td>{data.bank_user}</td>
																					<td>{data.bank_no}</td>
																					<td>{data.create_date}</td>
																					<td>
																						<Radio name="gt" inline checked={(data.is_default==1)?true:false}  >
																							
																						</Radio>{' '}
																						
																					</td>
																				</tr>
																			)
																		}):<tr><td colSpan="5">No data.</td></tr>
																	}
																	</tbody>
																</Table>
															</Row>
															
															
														</Tab>
														
														<Tab eventKey="key2" title="Bitcoin">
														<Row>
															<Table className="tbl-bank-rol" >
																<thead>
																	<tr>
																		<td colSpan="1" style={{'width':'64%'}}>Bitcoin address</td>
																		
																		<td colSpan="1" style={{'width':'16%'}}>Date</td>	
																		<td colSpan="1" style={{'width':'10%'}}>Default</td>
																	</tr>
																</thead>
																<tbody>
																{
																	(bitcoinRolls!='')?bitcoinRolls.map((data,idx)=>{
																		return(
																			<tr key={idx}>
																				<td>{data.bank_no}</td>
																				<td>{data.create_date}</td>
																				<td>
																					<Radio name="gt" inline checked={(data.is_default==1)?true:false}  disabled={false}>
																					
																					</Radio>{' '}
																				</td>
																			</tr>
																		)
																	}):<tr><td colSpan="3">No data.</td></tr>
																}
																</tbody>
															</Table>
														</Row>
														
												
												</Tab>
												<Tab eventKey="key3" title="Paypal">
														<Row>
															<Table className="tbl-bank-rol" >
																<thead>
																	<tr>
																		<td colSpan="1" style={{'width':'64%'}}>Paypal address</td>
																		
																		<td colSpan="1" style={{'width':'16%'}}>Date</td>	
																		<td colSpan="1" style={{'width':'10%'}}>Default</td>
																	</tr>
																</thead>
																<tbody>
																{
																	(paypalRolls!='')?paypalRolls.map((data,idx)=>{
																		return(
																			<tr key={idx}>
																				<td>{data.bank_no}</td>
																				<td>{data.create_date}</td>
																				<td>
																					<Radio name="gt" inline checked={(data.is_default==1)?true:false} disabled={false}>
																					
																					</Radio>{' '}
																				</td>
																			</tr>
																		)
																	}):<tr><td colSpan="3">No data.</td></tr>
																}
																</tbody>
															</Table>
														</Row>
														
													
												</Tab>
											</Tabs>
														
										</Col>
													<Col md={2}></Col>
									</Row>								
								</Col>	
												)
										})) : 
										
										<div className="items norecord">No records found.</div>
									}
								<Col md={12} className='items-3'>
								
									<Col md={12}>
										<Row className="form-group" style={{'marginBottom':'3px'}}>
											<Col md={2} className="pdr0">
												<span className="lbl">Payment Default</span>
											</Col>
											<Col md={2}>
												<Radio name="Bank_bk" checked={(data.payment_default==1)?true:false}><span className="lbl" style={{'fontWeight': '500','font-size': '14px'}}>Bank Account </span></Radio>
											</Col>
											<Col md={2}>
												<Radio name="Bitcoin_ac" checked={(data.payment_default==2)?true:false}><span className="lbl" style={{'fontWeight': '500','font-size': '14px'}}>Bitcoin </span></Radio>
											</Col>
											<Col md={2}>
												<Radio name="Paypal_ac" checked={(data.payment_default==3)?true:false}><span className="lbl" style={{'fontWeight': '500','font-size': '14px'}}>Paypal </span></Radio>
											</Col>
										</Row>										
									</Col>		
								</Col>	
							</Col>
							
							<Col md={12} className="msg-error" id="msg-error"></Col>
							<Col md={12} className="msg-succesfull" id="msg-succesfull" style={{'color':'green'}}></Col>
							</div>
						</div>

					</div>

										
								)
							})) : 
							
							<div className="items norecord">No records found.</div>
			
						}
							
						</div>
					</div>
				</div>
				<ModalDeleteUser isOpen={this.state.isOpenModal} close={this.close} user_id={this.state.user_id} deleteType={this.state.deleteType} 
								 getUsers={this.getUsers}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)