import React from 'react';
import { Provider } from 'react-redux';
//import { localeReducer } from 'react-localize-redux';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import store from './store';
//import { TOKEN_NAME } from './constants/actionTypes'
import { Cookies } from 'react-cookie'
import {TOKEN_NAME} from './constants/actionTypes'

import Home from './components/Home'
import Tipmarket from './components/Tipmakert'
import AdDetail from './components/Tipmakert/adDetail'
import NewInfo from './components/Tipmakert/newInfo'
import TodayTips from './components/TodayTips'
import TipsterList from './components/TipsterList'
import Addtips from './components/TipsterList/addtips'
import AddtipFilter from './components/TipsterList/addtipFilter'
import Jackpot from './components/Jackpot'
import Result from './components/Result'
import App from './components/App'
import Login from './components/Acount/login'
import Register from './components/Acount/register'
import Mysetting from './components/Acount/mysetting'
import ForgotPass from './components/Acount/forgotPass'
import ResetPass from './components/Acount/resetPass'
import Userlist from './components/Admin/userlist'
import Matchs from './components/Admin/matchs'
import SelectCompetition from './components/TipsterList/selectCompetition';
import ListBets from './components/TipsterList/listBets';
import AllFriends from './components/Home/AllFriends';
import MyFriends from './components/Home/MyFriends';
import Profile from './components/Proflie';
import Running from './components/Running/running';
import Upload from './components/Upload';
import Ads from './components/Admin/ads';
import EditAd from './components/Ad/editAd';
import AdUser from './components/Ad';
import Footer from './components/Footer'
import PageNotFound from './components/PageNotFound'
import CreateAdUser from './components/Admin/createaduser';
import AdminEditUser from './components/Admin/adminedituser';
import MatchInfo from './components/Admin/matchinfo';
import MatchNew from './components/Admin/matchnew';
import AllNotification from './components/common/AllNotification';
import Active from './components/common/Active';
import Contact from './components/Acount/contact';
import Bankroll from './components/Acount/banroll';
import Tipters from './components/Acount/tipsters';
import TipstersContest from './components/Acount/tipstersContest';
import BuyTipster from './components/Acount/buyTipster';
import TipsManagement from './components/Acount/tipsManagement';
import UserInfo from './components/Admin/userInfo';
import BuyTips from './components/Acount/buyTips'
import InvoiceBuyTip from './components/Acount/invoiceBuyTip'
import InvoiceTipInfo from './components/Acount/invoiceTipInfo'
import ManagermentTips from './components/Admin/managermentTips'
import ExchangeTips from './components/Acount/exchangeTips'
import Header from './components/Header';
import PageUnavailable from './components/PageUnavailable';

const Router = () =>{ 
	//const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)
	const token = window.localStorage.getItem(TOKEN_NAME)
	let usertype='';
	let role_id='';
	if(token!='' && token!='null' && token!=null){
		let tok=JSON.parse(token)
		usertype=tok.UserInfo.usertype
		role_id=tok.UserInfo.role_id
		//console.log(role_id)
	 }
	
	//console.log(usertype,role_id)
	return (            
        <div>
			<Route component={App} />
		    <Switch>
				<Route exact path='/' render={() => (
					
					<Redirect to="/home" />
					
				)} />
                <Route path='/home' render={() => (
					<div>
						<Header/>
						<Home /> 
					</div>
                )} />
				<Route path='/bookmakers' render={() => (
					<div>
						<Header/>
                        <Tipmarket /> 
					</div>
                )} />
				<Route path='/todaytips' render={() => (
					<div>
						<Header/>
                        <TodayTips /> 
					</div>
                )} />
				<Route path='/tipsterlist' render={() => (
					<div>
						<Header/>
						<TipsterList /> 
					</div>
                )} />
				<Route path='/jackpot' render={() => (
					<div>
						<Header/>
						<Jackpot /> 
					</div>
                )} />
				<Route path='/results' render={() => (
					<div>
						<Header/>
						<Result /> 
					</div>
                )} />
				<Route path='/login' render={() => (
					(role_id!='')?(
						<Redirect to="/home" />
					):
					<div>
						<Header/>
						<Login /> 
					</div>
                )} />	
                <Route path='/register' render={() => (
					(role_id!='')?(
						<Redirect to="/home" />
					):
					<div>
						<Header/>
						<Register /> 
					</div>
                )} />
				<Route path='/mysetting' render={() => (
					<div>
						<Header/>
						<Mysetting /> 
					</div>
                )} />
				<Route path='/bankroll' render={() => (
					<div>
						<Header/>
						<Bankroll /> 
					</div>
                )} />
				<Route path='/tipters' render={() => (
					<div>
						<Header/>
						<Tipters /> 
					</div>
                )} />
				<Route path='/tipters-contest' render={() => (
					<div>
						<Header/>
						<TipstersContest /> 
					</div>
                )} />
				<Route path='/buy-tipter' render={() => (
					<div>
						<Header/>
						<BuyTipster /> 
					</div>
                )} />
				<Route path='/management-tips' render={() => (
					<div>
						<Header/>
						<TipsManagement /> 
					</div>
                )} />
				<Route path='/forgot-password' render={() => (
					(role_id!='')?(
						<Redirect to="/home" />
					):
					<div>
						<Header/>
						<ForgotPass /> 
					</div>
                )} />
				<Route path='/reset-password' render={() => (
					<div>
						<Header/>
						<ResetPass /> 
					</div>
                )} />
				<Route path='/userlist' render={() => (
					(usertype=='admin')?(
						<div>
							<Header/>
							<Userlist /> 
						</div>):(
                        <Redirect to="/not-found" />
                      )
                )} />
				<Route path='/matchs' render={() => (
					(usertype=='admin')?(
						<div>
							<Header/>
							<Matchs /> 
						</div>):(
                        <Redirect to="/not-found" />
                      )
                )} />
				<Route path='/addtips' render={() => (
					<div>
						<Header/>
						<Addtips /> 
					</div>
                )} />
				
				
				<Route path='/select-competition' render={() => (
					<div>
						<Header/>
						<SelectCompetition /> 
					</div>
                )} />
				<Route path='/list-all-bets' render={() => (
					<div>
						<Header/>
						<ListBets /> 
					</div>
                )} />
				<Route path='/friends-request' render={() => (
					<div>
						<Header/>
						<AllFriends /> 
					</div>
                )} />
				<Route path='/my-friends' render={() => (
					<div>
						<Header/>
						<MyFriends /> 
					</div>
                )} />
				<Route path='/uploads' render={() => (
					(usertype=='admin')?(
						<div>
							<Header/>
							<Upload /> 
						</div>):(
							<Redirect to="/not-found" />
                      )
                )} />
				<Route path='/ads' render={() => (
					(usertype=='admin')?(
						<div>
							<Header/>
							<Ads /> 
						</div>):(
							<Redirect to="/not-found" />
                      )
                )} />
				
				<Route path='/ad-user' render={() => (
				(usertype=='admin' || role_id==3)?(
						<div>
							<Header/>
							<AdUser /> 
						</div>
					):(
							<Redirect to="/not-found" />
                      )
                )} />
				<Route path='/ad-list' render={() => (
					<div>
						<Header/>
						<AdDetail /> 
					</div>
                )} />
			
				<Route path='/notifications' render={() => (
					<div>
						<Header/>
						<AllNotification /> 
					</div>
                )} />
				<Route path='/createaduser' render={() => (
					(usertype=='admin')?(
						<div>
							<Header/>
							<CreateAdUser /> 
						</div>
					):(
							<Redirect to="/not-found" />
                      )
                )} />
				<Route path='/matchinfo' render={() => (
					(usertype=='admin')?(
						<div>
							<Header/>
							<MatchInfo /> 
						</div>
					):(
							<Redirect to="/not-found" />
                      )
                )} />
				<Route path='/matchnew' render={() => (
					(usertype=='admin')?(
						<div>
							<Header/>
							<MatchNew /> 
						</div>
					):(
							<Redirect to="/not-found" />
                      )
                )} />
				<Route path='/tips' render={() => (
					(usertype=='admin')?(
						<div>
							<Header/>
							<ManagermentTips /> 
						</div>
					):(
							<Redirect to="/not-found" />
                      )
                )} />
				<Route path='/tips-exchange' render={() => (
					<div>
						<Header/>
						<ExchangeTips /> 
					</div>
                )} />
				<Route path='/not-found' render={() => (
					<div>
						<PageNotFound/>
					</div>
                )} />
				<Route path='/un-available' render={() => (
					<div>
						<PageUnavailable/>
					</div>
                )} />
				<Route path='/contact' render={() => (
					<div>
						<Contact/>
					</div>
                )} />
				
				<Route path='/new-info/:id/' component={NewInfo} />
				<Route path='/user-info/:id/' component={UserInfo} />
				<Route path='/running/:id/' component={Running} />
				<Route path='/active/:id/' component={Active} />
				<Route path='/activeaccount/:id/' component={Active} />
				<Route path='/topic/:id/' component={Running} />
				<Route path='/add-tips/:leagueids/:filterdate' component={AddtipFilter} />
				<Route path='/buy-tips/:id/' component={BuyTips} />
				<Route path='/invoice-tips/:id/' component={InvoiceBuyTip} />
				<Route path='/invoice-tips-info/:id/' component={InvoiceTipInfo} />
					{
					//<Route path='/createaduser' component={CreateAdUser} />
				   //<Route path='/matchinfo' component={MatchInfo} />
					//<Route path='/matchnew' component={MatchNew} />
					}
				<Route path='/adminedituser/:id/' component={(usertype=='admin')?AdminEditUser:PageNotFound} />
				<Route path='/edit-ad/:id/' component={(usertype=='admin' || role_id==3)?EditAd:EditAd} />
				
				<Route path='/:user/' component={Profile} />
				
				
		    </Switch>	
			 
			{
				//<Route component={Footer} />
				//<Route path='*' component={PageNotFound} />
			}
			
        </div>
	);
}
//basename='/coopbet'
const Root = () => {
  return (
        <Provider store={store}>
	      <BrowserRouter basename='/'> 
		    <Router />  
	      </BrowserRouter>
      </Provider>
  );
}


ReactDOM.render(<Root />, document.getElementById('root'));