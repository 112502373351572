import React from 'react';
import { Link,withRouter, history} from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row} from 'react-bootstrap'
import '../../styles/App.css'
import $ from 'jquery';
import {ACTIVE_LOADER} from '../../constants/actionTypes'
//import Header from '../Header';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({    
	onLoad: (data) => {
		dispatch({ type: 'ACTIVE_LOADER', data})  
	},
});

class Active extends React.Component {
	constructor(){
		super()
		this.state = {
			recoverycode:''
		}
		//---
		this.activeaccount=(recoverycode)=>{
			agent.auth.activeaccount({recoverycode:recoverycode}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					$(".sucess").show();
					$(".error").hide();
				}else{
					$(".error").html(data.message);
					$(".sucess").hide();
					$(".error").show();
				}
			})
		}
		this.handleWindowSizeChange = (recoverycode) => {
			 // this.setState({ width: window.innerWidth });
			 let width=window.innerWidth;
			 if(width<=1024){
				window.location='https://m.coopbet.com/activeaccount/'+recoverycode
			 }else{
				//window.location='https://coopbet.com/home' 
				//window.location='http://localhost:3000/home' 
			 }
			// console.log(width)
		 
		}
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		
	}	

	componentWillMount() {
		//---
		this.props.onLoad('active');
		let recoverycode=this.props.match.params.id
		if(recoverycode!='' && recoverycode!=null){
			this.activeaccount(recoverycode)
		}
		//----
		//console.log(this.props.match.params.id)
		window.addEventListener('resize', this.handleWindowSizeChange(recoverycode));
		
	}	
	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	componentWillReceiveProps(nextProps) {
		
	}
	componentDidMount() {
		
	}
	
	
	render() {
		
		//---------
			return (
					<div className="body" >
						<div className="container">
							<Row style={{'marginTop':'120px'}}>
								<Col md={12} className="error" style={{'color':'red','display':'none'}}></Col>
								<Col md={12} className="sucess" style={{'display':'none'}}>Your account is active and is ready to <a href="/login" style={{'color':'#337ab7'}}>login</a>.</Col>
							</Row>
						</div>
					</div>
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Active)