import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import ReactDOM from 'react-dom';
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import '../../styles/matchs.css'
import {Col, Row, FormControl, FormGroup, Radio, Checkbox, Table} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,ADD_MATCH,MATCH_ID} from '../../constants/actionTypes'
import NumberFormat from 'react-number-format'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: ADD_MATCH })  
	},
});



 


class MatchInfo extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
            matchInfo:[],
            avatar:'https://www.coopbet.com/media/no_avatar.png',
            search:'',
            typeMatch: '',
			offset:0,limit:20,loading:false,loadedAll:false,scrollCus:false,
			isOpenModal:false,msgaprove:'',
            
            sport_tips:2,
            league_id:1139,
            away_team_id: 2464,
            away_team: 'Bayern Munchen',
            home_team_id: 2465,
            home_team:'Borussia Dortmund',
            
            startDate: new Date(),filterdate:'',
            KickHour : '19',
            KickMinute:'00',
            
            sporttipList:[],
            leagueList :[],
            teamList :[],
            odd_1x2_id:0,
            odd_handicap_id:0,
            odd_overunder_id:0,
            odd_oddeven_id:0,
            odd_monney_id:0,
            
            odd_monney_home:0,
            odd_monney_away:0,
            
            odd_1x2_home:0,
            odd_1x2_away:0,
            odd_1x2_draw:0,
            
            odd_handicap_home:0,
            odd_handicap_away:0,
            odd_handicap_home_draw:0,
            odd_handicap_away_draw:0.00,
            
            odd_overunder_home: 0,
            odd_overunder_away: 0,
            odd_overunder_home_draw: 0,
            odd_overunder_away_draw: 0.00,
            
			odd_oddeven_away: 0,
            odd_oddeven_draw: 0.00,
            odd_oddeven_home: 0,
            curr_match_id:1,
            first_odd_header:"Money Line",
            hidematch:"Hide Match",
            is_not_used:0,

		}
        this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.doKeySearch=e=>{
            if (e.key == 'Enter') {
                
            }
            
        }
        
        this.updateMatch=this.updateMatch.bind(this) ;
        this.hideMatch=this.hideMatch.bind(this) ;
        this.onScrollCus = () => {
			
			const messgeContainer = document.getElementById("match-list")
			const loadedAll = this.state.loadedAll

			//if( !loadedAll && messgeContainer.scrollTop === (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
			if( !loadedAll && parseInt(messgeContainer.scrollTop) >= (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
				//let offset = parseInt(this.state.offset) + this.state.limit
				let pageno = parseInt(this.state.offset) + this.state.limit
				const newState = Object.assign({}, this.state, { offset: pageno, scrollCus: true })
				this.setState(newState, () => {
					
				})				
			}
		}
		
	}                                       
  
    
	
	handleChange(date) {
       //console.log(date); 
		let day=date.getDate();
		let month=date.getMonth()+1;
		let year=date.getFullYear();
		let KickDate=year+"-"+month+"-"+day
		//console.log(KickDate);
		this.setState({
		  startDate: date,filterdate:KickDate
		},()=>{
			
		});
	}
	componentWillMount(){		
        
      //  console.log(window.localStorage.getItem( MATCH_ID));
        this.setState({curr_match_id:window.localStorage.getItem( MATCH_ID)})
        this.getmatch(window.localStorage.getItem( MATCH_ID));
        this.getSporttip();
        
        
		this.props.onLoad('matchs');	
        
		const cookies = new Cookies()
		const token = cookies.get(TOKEN_NAME)
		if(token){			
			agent.setToken(token)
			this.props.onLoad( token)       
		}
		let language=(cookies.get('language')!=='' && cookies.get('language') !==null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		let date = new Date();
		let dateFormat=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth())+"-"+parseInt(date.getDate())
		this.setState({'lguages':language,filterdate:dateFormat},()=>{
			
		})
		//----
		
		
		
		//console.log(dateFormat)
		//----
	}
	ChangeField(field,ev){
        this.setState({[field]:ev.target.value,offset: 0})
    }
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	handleChangeText(){
	 
	}	
	
    addZero(i) {
        if (i < 10) {
            i = "0" + i;
          }
          return i;
        }
    //------
    roundMinute(i) {
         var module = i%5;
         if (module==0) return i;
         
         return this.addZero(i - i%5);
         
        }
    //------
    getmatch(match_id) {
        let state=this.state;
        let params = {match_id:match_id};
        //alert(state.league_id);
        agent.upload.getmatchinfo(params).then((datas) => {
            if(datas.result=='good'){
                //console.log(datas.match_info[0].str_start_time);
                let starttime = new Date(datas.match_info[0].str_start_time);
                var hours = this.addZero(starttime.getHours());
                //console.log("current:" + hours);
                var minute = this.roundMinute(starttime.getMinutes());
                //console.log("minute:" + minute);
                var dateFormat=parseInt(starttime.getFullYear())+'-'+parseInt(starttime.getMonth())+"-"+parseInt(starttime.getDate())
                if (datas.match_info[0].not_used==1) this.setState({hidematch:"Show Match",is_not_used:datas.match_info[0].not_used});
                else this.setState({hidematch:"Hide Match",is_not_used:datas.match_info[0].not_used});
                //this.handleChange(new Date(datas.list[0].start_time));
                this.setState({matchInfo:datas.match_info[0],sport_tips:datas.match_info[0].sportid,league_id:datas.match_info[0].league_id,league_id:datas.match_info[0].league_id,home_team_id:datas.match_info[0].home_teamid,away_team_id:datas.match_info[0].away_teamid,startDate:new Date(datas.match_info[0].str_start_time), KickHour:hours,KickMinute:minute});
                //console.log(datas.odds);
                if (datas.match_info[0]["sportid"]==2) {
                    this.setState({first_odd_header:"1x2"});
                } else this.setState({first_odd_header:"Money Line"});
                    
                     for (var i=0; i < datas.odds.length; i++) {
                         if (datas.odds[i]["is_main_odd"]==1) {
                             if (datas.odds[i]["odd_type"]==0 || datas.odds[i]["odd_type"]==22 || datas.odds[i]["odd_type"]==14) {
                                this.setState({odd_1x2_home:datas.odds[i].home,odd_1x2_draw:datas.odds[i].draw,odd_1x2_away:datas.odds[i].away,odd_1x2_id:datas.odds[i].id});
                             }
                             else if (datas.odds[i]["odd_type"]==1 || datas.odds[i]["odd_type"]==21 || datas.odds[i]["odd_type"]==11) {
                                 console.log(datas.odds[i].draw);
                                 if (datas.odds[i].draw>0) 
                                    this.setState({odd_handicap_home:datas.odds[i].home,odd_handicap_away:datas.odds[i].away,odd_handicap_away_draw:datas.odds[i].draw,odd_handicap_id:datas.odds[i].id});
                                 else 
                                    this.setState({odd_handicap_home:datas.odds[i].home,odd_handicap_away:datas.odds[i].away,odd_handicap_home_draw:datas.odds[i].draw,odd_handicap_id:datas.odds[i].id});
                             } else if (datas.odds[i]["odd_type"]==2 || datas.odds[i]["odd_type"]==23 || datas.odds[i]["odd_type"]==12) {
                                 if (datas.odds[i].draw>0) 
                                    this.setState({odd_overunder_home:datas.odds[i].home,odd_overunder_away:datas.odds[i].away,odd_overunder_away_draw:datas.odds[i].draw,odd_overunder_id:datas.odds[i].id});
                                 else 
                                    this.setState({odd_overunder_home:datas.odds[i].home,odd_overunder_away:datas.odds[i].away,odd_overunder_home_draw:datas.odds[i].draw,odd_overunder_id:datas.odds[i].id});
                             } else if (datas.odds[i]["odd_type"]==4 || datas.odds[i]["odd_type"]==24 || datas.odds[i]["odd_type"]==13) {
                                this.setState({odd_oddeven_home:datas.odds[i].home,odd_oddeven_away:datas.odds[i].away,odd_oddeven_draw:datas.odds[i].draw,odd_oddeven_id:datas.odds[i].id});
                             }
                                
                         }
                    }
                
                      
                //odd_handicap_id,
                //this.handleChange(starttime);
            //    console.log(datas.odds);
                //this.getSporttip();
                this.getTeams(datas.match_info[0].league_id,datas.match_info[0].home_teamid,datas.match_info[0].away_teamid);
                this.getLeagues({sportID:datas.match_info[0].sportid});
                //alert(this.state.KickHour);
            }
      })    
      
    }
    
    //---
    selectAwayTeam(ev){
        this.setState({away_team_id:ev.target.value})
      
        const teamList=this.state.teamList;
        var selected_team =  teamList.filter(function(team) {
            return team.id == ev.target.value;
        });
        //console.log(selected_team[0].team_name);
        this.setState({away_team:selected_team[0].team_name});
    }
    
    //---
    setHomeTeam(hometeamid){
        this.setState({home_team_id:hometeamid})
        const teamList=this.state.teamList;
        //console.log(teamList);
        var selected_team =  teamList.filter(function(team) {
            return team.id == hometeamid;
        });
        //console.log("home team: "+selected_team);
        if (selected_team.length>0)
            this.setState({home_team:selected_team[0].team_name});
        else this.setState({home_team:''});
       //this.getTeams({sportID:ev.target.value});
    }
    //---
    setAwayTeam(away_team_id){
        this.setState({away_team_id:away_team_id})
        const teamList=this.state.teamList;
        var selected_team =  teamList.filter(function(team) {
            return team.id == away_team_id;
        });
        if (selected_team.length>0)
            this.setState({away_team:selected_team[0].team_name});
        else this.setState({away_team:''});
        //this.setState({away_team:selected_team[0].team_name});
       //this.getTeams({sportID:ev.target.value});
    }
    
     //---
    selectHomeTeam(ev){
        this.setState({home_team_id:ev.target.value})
        const teamList=this.state.teamList;
        var selected_team =  teamList.filter(function(team) {
            return team.id == ev.target.value;
        });
        //console.log(selected_team[0].team_name);
        this.setState({home_team:selected_team[0].team_name});
       //this.getTeams({sportID:ev.target.value});
    }
    
    selectLeague(ev) {
        this.setState({league_id:ev.target.value});
        this.getTeams(ev.target.value);
    }
     //---
    selectSportTips(ev){
        this.setState({sport_tips:ev.target.value})
        //alert(ev.target.value);
        this.getLeagues({sportID:ev.target.value});
        
    }
    
    selectHour(feild,ev){
        this.setState({[feild]:ev.target.value})
    }
    
    selectMinute(feild,ev){
        this.setState({[feild]:ev.target.value})
    } 
    getSporttip(){
        agent.auth.sporttip().then((datas) => {
            if(datas.result=='good'){
                this.setState({sporttipList:datas.list})
            }
      })    
    }
    
    getLeagues(sportID){
        //alert(sportID);
        agent.upload.getleaguelist(sportID).then((datas) => {
            if(datas.result=='good'){
               // console.log(datas.list);
                this.setState({leagueList:datas.list});
                //this.getTeams(datas.list[0]['id']);
            }
      })    
    }
    
    getTeams(league_id,home_teamid,away_teamid){
        //alert(sportID);
        let state=this.state;
        let params = {league_id:league_id,home_teamid:home_teamid,away_teamid:away_teamid,sport_id:state.sport_tips};
        //alert(state.league_id);
        agent.upload.getteamlist(params).then((datas) => {
            if(datas.result=='good'){
               // console.log(datas.list);
                this.setState({teamList:datas.list})
                this.setHomeTeam(home_teamid);  
                this.setAwayTeam(away_teamid);    
            }
      })    
    }
    
    hideMatch() {
        
      //  alert("hide");
        
        let state=this.state;
        var donot_used = state.is_not_used==1?0:1;
        let params = {league_id:state.league_id,
                match_id:state.curr_match_id,not_used:donot_used};
            
        agent.upload.hidematch(params).then((datas) => {
            
            //console.log(datas);
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Hide sucessfully.")
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
                this.getmatch(state.curr_match_id);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })  
      
    }
    updateMatch() {
       // alert("saveMatch");
        let state=this.state;
        //var kick_Date = state.startDate.format('YYYY-MM-DD');
        var kick_Date = state.startDate;
		kick_Date=parseInt(kick_Date.getFullYear())+'-'+parseInt(kick_Date.getMonth()+1)+"-"+parseInt(kick_Date.getDate())
        //console.log(kick_Date); 
        
        let params = {league_id:state.league_id,home_team_id:state.home_team_id,away_team_id:state.away_team_id,KickDate:kick_Date,KickHour:state.KickHour,KickMinute:state.KickMinute,
                odd_1x2_home:state.odd_1x2_home,
                odd_1x2_away:state.odd_1x2_away,
                odd_1x2_draw:state.odd_1x2_draw,
                odd_handicap_home:state.odd_handicap_home,
                odd_handicap_away:state.odd_handicap_away,
                odd_handicap_home_draw:state.odd_handicap_home_draw,
                odd_handicap_away_draw:state.odd_handicap_away_draw,
                odd_overunder_home: state.odd_overunder_home,
                odd_overunder_away: state.odd_overunder_away,
                odd_overunder_home_draw: state.odd_overunder_home_draw,
                odd_overunder_away_draw: state.odd_overunder_away_draw,odd_oddeven_away: state.odd_oddeven_away,
                odd_oddeven_draw: state.odd_oddeven_draw,
                odd_oddeven_home: state.odd_oddeven_home,
                match_id:state.curr_match_id,
                odd_oddeven_id:state.odd_oddeven_id,odd_overunder_id:state.odd_overunder_id,
                odd_handicap_id:state.odd_handicap_id,odd_1x2_id:state.odd_1x2_id};
            
        agent.upload.updatematch(params).then((datas) => {
            
            //console.log(datas);
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Update sucessfully.")
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })  
      
      
    } 
	render(){   
		const matchInfo=this.state.matchInfo;
        const sporttipList=this.state.sporttipList;
        const leagueList=this.state.leagueList;
        const teamList=this.state.teamList;
		const loading=this.state.loading;
        
       // console.log("teamList"+teamList);
        let hous=['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
        let minutes=['00','05','10','15','20','25','30','35','40','45','50','55']
		//console.log(matchInfo)
        return (
			<div id="body">   
				<div>
					<div className="heder-page" style={{'padding':'10px 0'}}>
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">MATCH INFO</span>
								</h1>
                                
							</div>
                        </div>
                    </div>    
				</div>
                
                <div>
                    <Col md={12} className='items-3'>
                        
                        <Col md={5}>
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">Sport Tips:</span>
                                </Col>
                                <Col md={5}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.sport_tips} onChange={this.selectSportTips.bind(this)}>
                                        
                                        {
                                            (sporttipList)?sporttipList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.title} </option> )
                                            }):''
                                        }
                                    </FormControl>    
                                </Col>
                            </Row>
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">League:</span>
                                </Col>
                                <Col md={5}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.league_id} onChange={this.selectLeague.bind(this)}>
                                        
                                        {
                                            (leagueList)?leagueList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.league_name} </option> )
                                            }):''
                                        }
                                    </FormControl>    
                                </Col>
                            </Row>
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">Kick Of Time:</span>
                                </Col>
                                <Col md={3}>
                                    <div className="kickofftime" id="daterange">
                                        <DatePicker className="input-date"
                                            selected={this.state.startDate}
                                           // startDate = {this.state.startDate}
                                            onChange={this.handleChange.bind(this)}                                        
                                        />
                                    </div>
                                </Col>
                                <Col md={1} className="form-group colleft" style={{'padding':'0','width':'50px'}}>
                                        <FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} selected={this.state.KickHour} value={this.state.KickHour} onChange={this.selectHour.bind(this,'KickHour')}>
                                        {
                                            hous.map((data,i)=>{
                                                return(<option value={data} key={i}>{data}</option>)
                                            })
                                        }
                                        </FormControl>        
                                </Col>
                                <Col md={1} className="form-group colleft" style={{'padding':'0','width':'50px'}}>
                                        <FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} value={this.state.KickMinute} onChange={this.selectMinute.bind(this,'KickMinute')}>
                                        {
                                            minutes.map((data,i)=>{
                                                return(<option value={data} key={i}>{data}</option>)
                                            })
                                        }
                                        </FormControl>        
                                </Col>    
                                                        
                            </Row>
                            
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">Home:</span>
                                </Col>
                                <Col md={5}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.home_team_id} onChange={this.selectHomeTeam.bind(this)}>
                                        
                                        {
                                            (teamList)?teamList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.team_name} </option> )
                                            }):''
                                        }
                                    </FormControl>    
                                </Col>
                            </Row>
                            
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">Away:</span>
                                </Col>
                                <Col md={5}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.away_team_id} onChange={this.selectAwayTeam.bind(this)}>
                                        
                                        {
                                            (teamList)?teamList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.team_name} </option> )
                                            }):''
                                        }
                                    </FormControl>    
                                </Col>
                            </Row>
                        </Col>
                        
                        
                        <Col md={7}>
                            <div className="sh-snap-menu">
                                <Table className="oddmove" >
                                    <thead>
                                        <tr>
                                           
                                            <td colSpan="1"  style={{'width':'180px','textAlign': 'center','paddingLeft': '0px'}}>Match</td>
                                            <td style={{'width':'100px','textAlign': 'center','paddingRight': '20px'}}>{this.state.first_odd_header}</td>
                                            
                                            <td colSpan="2" style={{'width':'150px','textAlign': 'center','paddingRight': '20px'}}>Asian Handicap</td>
                                            
                                            <td colSpan="2" style={{'width':'100px','textAlign': 'center','paddingRight': '0px'}}>Over/Under</td>
                                            <td colSpan="2" style={{'width':'80px','textAlign': 'center','paddingLeft': '0px'}}>Odd/Even</td>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="boder_none odd-row ">
                                            <td colSpan="1">
                                                <div style={{'paddingTop':'10px'}}>{this.state.home_team}</div>
                                                <div style={{'paddingTop':'20px'}}>{this.state.away_team}</div>
                                                <div style={{'paddingTop':'20px'}}>Draw</div>
                                            </td>
                                            
                                             <td colSpan="1">
                                                <div className="divw65"><input id="odd_1x2_home" name="odd_1x2_home" type="text" value={this.state.odd_1x2_home} placeholder="odd 1x2 home" className="form-control" onChange={this.ChangeField.bind(this,'odd_1x2_home')}/></div>
                                                <div className="divw65"><input id="odd_1x2_away" name="odd_1x2_away" type="text" value={this.state.odd_1x2_away} placeholder="odd 1x2 away" className="form-control" onChange={this.ChangeField.bind(this,'odd_1x2_away')}/></div>
                                                <div className="divw65"><input id="odd_1x2_draw" name="odd_1x2_draw" type="text" value={this.state.odd_1x2_draw} placeholder="odd 1x2 draw" className="form-control" onChange={this.ChangeField.bind(this,'odd_1x2_draw')}/></div>
                                            </td>
                                           
                                            <td style={{'width':'60px','paddingRight':'0px'}}>
                                                <div className="divw65"><input id="odd_handicap_home_draw" name="odd_handicap_home_draw" type="text" value={this.state.odd_handicap_home_draw} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_handicap_home_draw')}/></div>
                                                <div className="divw65"><input id="odd_handicap_away_draw" name="odd_handicap_away_draw" type="text" value={this.state.odd_handicap_away_draw} placeholder="odd handicap away draw" className="form-control" onChange={this.ChangeField.bind(this,'odd_handicap_away_draw')}/></div>
                                                <div>&nbsp;</div>
                                                
                                                
                                            </td>
                                            
                                            <td colSpan="1" style={{'paddingLeft':'0px'}}>
                                                <div className="divw65"><input id="odd_handicap_home" name="odd_handicap_home" type="text" value={this.state.odd_handicap_home} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_handicap_home')}/></div>
                                                <div className="divw65"><input id="odd_handicap_away" name="odd_handicap_away" type="text" value={this.state.odd_handicap_away} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_handicap_away')}/></div>
                                                <div className="divw65">&nbsp;</div>
                                            </td>
                                            
                                            <td style={{'paddingRight':'0px','width':'40px'}}>
                                                
                                                <div className="divw65" style={{'paddingTop':'5px'}}><input id="odd_overunder_home_draw" name="odd_overunder_home_draw" type="text" value={this.state.odd_overunder_home_draw} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_overunder_home_draw')}/></div>
                                                <div className="divw65" style={{'paddingTop':'5px','display':'none'}}><input id="odd_overunder_away_draw" name="odd_overunder_away_draw" type="text" value={this.state.odd_overunder_away_draw} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_overunder_away_draw')}/></div>
                                                <div>&nbsp;</div>
                                            </td>
                                            <td colSpan="1" style={{'paddingLeft':'0px','width':'40px'}}>
                                                <div className="divw65"><input id="odd_overunder_home" name="odd_overunder_home" type="text" value={this.state.odd_overunder_home} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_overunder_home')}/></div>
                                                <div className="divw65"><input id="odd_overunder_away" name="odd_overunder_away" type="text" value={this.state.odd_overunder_away} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_overunder_away')}/></div>
                                                <div className="divw65">&nbsp;</div>
                                            </td>
                                            
                                            <td colSpan="2" style={{'paddingLeft': '25px'}}>
                                                <div className="divw65"><input id="odd_oddeven_home" name="odd_oddeven_home" type="text" value={this.state.odd_oddeven_home} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_oddeven_home')}/></div>
                                                <div className="divw65"><input id="odd_oddeven_away" name="odd_oddeven_away" type="text" value={this.state.odd_oddeven_away} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_oddeven_away')}/></div>
                                                <div className="divw65">&nbsp;</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    
                                </Table>
                            </div>
                            <div>
                                <Col md={12} style={{'paddingLeft':'0px'}}>
                                    <a className="addmatch" style={{'margin':'0px'}} onClick={this.updateMatch} >  Save Match  </a> &nbsp; <a className="addmatch" style={{'margin':'0px'}} onClick={this.hideMatch} > {this.state.hidematch} </a>
                                </Col> 
                                <Col md={12} className="msg-error" id="msg-error"></Col>
                                <Col md={12} className="msg-succesfull" id="msg-succesfull" style={{'paddingLeft':'0px','color':'green'}}></Col>  
                            </div>
                        </Col>
                    </Col>
                    
                </div>
                
                
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgaprove}/>
			</div>
        );
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(MatchInfo)