import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox, Form, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format'
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Link } from 'react-router-dom'
import agent from '../../agent'
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import '../../styles/modal.css'; 
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
 TOKEN_NAME, CHANGE_TAB,LOAD_AD_DATA
  
}from '../../constants/actionTypes'; 
import UploadLogo from '../common/UploadLogo'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({    
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	//onloadDataAd: (data) => 
		//dispatch({ type: LOAD_AD_DATA, data }),
});

class ModalEditAd extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,userList:[],userid:'',language:'',
			old_avatar: 'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png',
			avatar: 'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png',
			ad_title:'',ad_url:'',startDate:moment(),endDate:moment(),ad_startdate:'',ad_enddate:'',ad_id:'',
			ad_description:'',suspended:'',isOpenModalconfirm:false,adList:[],startdateH:'',enddateH:''
			
        }
		this.close=()=>{
			this.setState({isOpenModalconfirm:false,isOpen:false})
		}
		this.hideModal = ev => {	
		  $(".lblMessage").text('');
		  this.setState({
			isOpen: false,isOpen:false
		  });
		 this.props.close();
			$(".lblMessage").text('');
		  //this.props.refeshorder();
		};
		//=====
		this.handleGetFileUpload = (imageURL) => { 
			console.log(imageURL)
			const avatar = (imageURL!='') ? imageURL : this.state.old_avatar				
			const newState = Object.assign({}, this.state,{avatar:avatar})
			this.setState(newState)	
		}
		//=====
		this.editAd=this.editAd.bind(this)
		//this.deleteAd=this.deleteAd.bind(this)
		this.getadList=this.getadList.bind(this)
		
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,ad_id:nextProps.ad_id,adList:nextProps.adList});
			let info=nextProps.info;
			if(info!=''){
				let ad_description=info.ad_description
				let ad_enddate=info.ad_enddate
				let ad_user_id=info.ad_user_id
				let ad_logo_url=info.ad_logo_url
				let ad_startdate=info.ad_startdate
				let ad_title=info.ad_title
				let ad_url=info.ad_url
				let ad_lang_display_name=info.ad_lang_display_name
				let suspended=info.suspended
				this.setState({avatar:ad_logo_url ,ad_title:ad_title,ad_url:ad_url,
							   ad_startdate:ad_startdate,ad_enddate:ad_enddate,ad_description:ad_description,
							   userid:ad_user_id,language:ad_lang_display_name,startDate:moment(ad_startdate),
							   endDate:moment(ad_enddate),suspended:info.suspended})
				$(".seelct-ad-user").val(ad_user_id)
				let startdateH=$('.seelct-ad-user  option:selected').attr("startdate")
				let enddateH=$('.seelct-ad-user  option:selected').attr("enddate")
				this.setState({startdateH:startdateH,enddateH:enddateH})
			}
				
			//this.getAdInfo(nextProps.ad_id)
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		//const idb=nextState.valueBuild;
		//console.log(nextState)
		return nextState;
		
	}
	componentDidMount() {
		
	}
	componentWillMount() {
		this.getAdUserList()
		
		//let date=new Date()
		//let newdate=date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate()
		//this.setState({ad_startdate:newdate,ad_enddate:newdate})
		let thiss=this
		
		
	}
	getAdUserList(){
		agent.ads.adUserList().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({userList:datas.list})
		}else{
			this.setState({userList:[]})
		}
	  })	
	}
	handleChangeField(field,ev){
		//console.log(field,ev.target.value)
		if(field=='userid'){
			let language=$('.seelct-ad-user  option:selected').attr("datalang")
			let startdateH=$('.seelct-ad-user  option:selected').attr("startdate")
			let enddateH=$('.seelct-ad-user  option:selected').attr("enddate")
			this.setState({[field]:ev.target.value,language:language,startdateH:startdateH,enddateH:enddateH})
		}else{
			this.setState({[field]:ev.target.value})
		}
	 }	
	 handleChange(field,date) {
		let day=date.date();
		let month=date.month()+1;
		let year=date.year();
		let newdate=year+"-"+month+"-"+day
		//console.log(date)	
		if(field=='ad_startdate'){
			this.setState({
			  startDate: date,ad_startdate:newdate
			});
		}
		if(field=='ad_enddate'){
			this.setState({
			  endDate: date,ad_enddate:newdate
			});
		}
		
	}
	editAd(){
		let ad_user_id=this.state.userid
		let ad_logo_url=this.state.avatar
		let ad_url=this.state.ad_url
		let ad_title=this.state.ad_title
		let ad_description=this.state.ad_description
		let ad_startdate=this.state.ad_startdate
		let ad_enddate=this.state.ad_enddate
		let ad_id=this.state.ad_id
		let suspended=this.state.suspended
		//console.log(ad_user_id,ad_logo_url,ad_url,ad_title,ad_description,ad_startdate,ad_enddate)
		agent.ads.adUserEdit({ad_id:ad_id,ad_user_id:ad_user_id,ad_logo_url:ad_logo_url,
							ad_url:ad_url,ad_title:ad_title,ad_description:ad_description,
							ad_startdate:ad_startdate,ad_enddate:ad_enddate,suspended:suspended}).then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				this.hideModal();
				this.props.getadList();
			}else{
				$(".eror-saved").html(datas.message)
				setTimeout(function() {
				  $(".eror-saved").html('')
				}, 7000)
			}
		  })	
	}
	getAdInfo(ad_id){
		agent.ads.adInfo({ad_id:ad_id}).then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				let info=datas.info;
				let ad_description=info.ad_description
				let ad_enddate=info.ad_enddate
				let ad_user_id=info.ad_user_id
				let ad_logo_url=info.ad_logo_url
				let ad_startdate=info.ad_startdate
				let ad_title=info.ad_title
				let ad_url=info.ad_url
				let ad_lang_display_name=info.ad_lang_display_name
				let suspended=info.suspended
				this.setState({avatar:ad_logo_url ,ad_title:ad_title,ad_url:ad_url,
							   ad_startdate:ad_startdate,ad_enddate:ad_enddate,ad_description:ad_description,
							   userid:ad_user_id,language:ad_lang_display_name,startDate:moment(ad_startdate),
							   endDate:moment(ad_enddate),suspended:info.suspended})
				$(".seelct-ad-user").val(ad_user_id)
			}else{
				
			}
		  })	
	}
	
	getadList(adList){
		//this.props.getadList()
		this.props.updateAdList(adList)
	}
  render() {
	   let userList=this.state.userList
	   //console.log(userList)
        return (
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="modal-ad"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title "><span className="title-stage">COOPBET Edits AD</span></span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body>
					<Row>		
						<Col md={12} >
							<Form className="form-ad">
								<FormGroup>
									<ControlLabel className="label-sale"> Ad User </ControlLabel>	
									<FormControl componentClass="select" className="seelct-ad-user" placeholder="select" onChange={this.handleChangeField.bind(this,'userid')} disabled={true}>
										<option value='' datalang=''>Select-ad-user </option>	
										{
											(userList!='')?userList.map((data,idx)=>{
												return(
													<option value={data.ad_user_id} startdate={data.adhiring_startdate} enddate={data.adhiring_enddate} datalang={data.lang_display_name} key={idx}>{data.first_name+" "+data.last_name} ({data.tipster_name})</option>
												)
											}):''
										}
									
									</FormControl>	
									{
										(this.state.language!='')?<div className="language-ad">
											<span>Language: {this.state.language}</span>
											<span className="padlhr">Hiring Duration: {this.state.startdateH}->{this.state.enddateH}</span>
										</div>:''
									}
								</FormGroup>
								
								<FormGroup style={{'display':'none'}}>
									<ControlLabel className="label-sale">Language </ControlLabel>
									<input className="form-control" onChange={this.handleChangeField.bind(this,'language')} value={this.state.language}/>
								</FormGroup>
								<FormGroup id="img-logo">
									<ControlLabel className="label-sale">Logo </ControlLabel>
									<Row>
										<Col md={6}>
											<div className="avatar-container">
												<figure className="mgt0">>
													<img className="avatar1" src={this.state.avatar} alt="" />
												</figure>
											</div>	
										</Col>
									</Row>
									<Row>
										<Col md={12}>
											<div className="ctnavatar-container" style={{'padding':'0'}} >												
												<UploadLogo text='Choose Avatar'  
													multiFiles={false} resetFile={this.state.resetFile}
													updateFiles={this.handleGetFileUpload} />																								
											</div>
										</Col>	
									</Row>
								</FormGroup>
								<FormGroup>
									<ControlLabel className="label-sale">Ad Title </ControlLabel>
									<input className="form-control" onChange={this.handleChangeField.bind(this,'ad_title')} value={this.state.ad_title}/>
								</FormGroup>
								<FormGroup>
									<ControlLabel className="label-sale">Ad URL</ControlLabel>
									<input className="form-control" onChange={this.handleChangeField.bind(this,'ad_url')} value={this.state.ad_url}/>
								</FormGroup>
								<FormGroup>
									<ControlLabel className="label-sale">Ad Description</ControlLabel>
									<textarea
										className="form-control input-sale"
										id="txt-desc"
										rows="3"
										value={this.state.ad_description} onChange={this.handleChangeField.bind(this,'ad_description')}
									/>
								</FormGroup>
								<FormGroup>
									<Row>
										<Col md={6}>
											<ControlLabel className="label-sale">Start Date</ControlLabel>
										</Col>
										<Col md={6}>
											<ControlLabel className="label-sale">End Date</ControlLabel>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<DatePicker className="input-date"
												selected={this.state.startDate}
												onChange={this.handleChange.bind(this,'ad_startdate')}										
											/>
										</Col>
										<Col md={6}>
											<DatePicker className="input-date"
												selected={this.state.endDate}
												onChange={this.handleChange.bind(this,'ad_enddate')}										
											/>
										</Col>
									</Row>
									
								</FormGroup>
								
							</Form>				
						</Col>	
					</Row>
			  </Modal.Body>
			  <Modal.Footer>
					<Row className="rct textr" style={{'padding':'0px 15px'}}>
						<span className="eror-saved" style={{'float':'left','color':'red'}}></span>
					</Row>
					<Row className="rct textr" style={{'padding':'0px 15px'}}>
						<Link to="#" className="btn-back" onClick={this.hideModal}>Cancel</Link>
						<Button onClick={this.editAd} bsStyle="primary" id="btn-start"> Save</Button>
					</Row>
					
			  </Modal.Footer>
				
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalEditAd); 
//export default ModalComfirmOrder