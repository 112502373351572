import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import {TIPMAKET_PAGE_LOADER,LANGUAGE_KEY,CHANGE_TAB,TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'
import $ from 'jquery';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: TIPMAKET_PAGE_LOADER })  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Tipmakert extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',List:[]
		}
		this.checksuspend=()=>{
			agent.auth.checksuspend().then(data => {
				//console.log(data)
				if(data!=null && data.result === 'good' && data.suspended==1){
					window.location='/un-available' 
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		
  }
		//---
	componentWillMount(){		
		this.props.onLoad('tipmarket')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({lguages:language})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//console.log(login)
		if(login!='' && login!='null' && login!=null){
			window.addEventListener('click', this.checksuspend);
		}
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			this.getbookMaker(nextProps.languageKey)
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	   //console.log(this.state.lguages)
	   this.getbookMaker(this.state.lguages)
    }

    componentWillUnmount() {
       window.removeEventListener('click', this.checksuspend);
    }

	getbookMaker(lang_id){
		agent.ads.bookMaker({lang_id:lang_id}).then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({List:datas.list})
		}else{
			this.setState({List:[]})
		}
	  })	
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		this.props.onTabClick("ad-list")
	}
	render(){   
		//console.log(this.state.lguages)
		let list=this.state.List
        return (
			<div id="body">   
				<div className="heder-page">
					<div className="container">
						<div className="item lbl-1">
							<h1>
								<span className="txt">SportBook News</span>
							</h1>
						</div>									
					</div>
				</div>
				<div className="tipster-list">
					<div className="container">
						<Row>
							<Col md={12}>
							{
								(list!='')?list.map((it,idx)=>{
									let urlUser=(it.aduser_url!='' && it.aduser_url!=null && it.aduser_url!='null')?it.aduser_url:''
									return(
										<Col md={6} className="brth-box" key={idx}>
											<Row className="row-ad">
												<Col md={3}>
													<img src={it.aduser_logo_url} className="logo-ad"/>
												</Col>
												<Col md={9} className="txtcenter">
													<h3 className="caption-ad">{it.first_name}</h3>
													<a href={it.aduser_url} className="lnk-note-ad">{(urlUser.length<=60)?urlUser:urlUser.substr(0,60)+'...'} </a>
												</Col>
											</Row>
											<Row style={{'margin':'0'}}>
												<Col md={12} className="desc-ad heig50">{(it.aduser_introduction.length<=160)?it.aduser_introduction:it.aduser_introduction.substr(0,160)+'...'}</Col>
												<Col md={12} style={{'padding':'0px'}}>
													<Col md={4} className="creat-by-ad"><span style={{'display':'none'}}>Create/Update: {it.CreatUpdateDate}</span></Col>
													<Col md={8} className="note-to" >
														<span style={{'display':'none'}}>100% up to $200</span>
														<a href="" onClick={this.goAdView.bind(this)} dataid={it.user_id} title={it.first_name}>view more</a>
													</Col>
												</Col>
											</Row>
										</Col>
									)
								}):'No data.'
							}
								
							</Col>
						</Row>
					</div>
				</div>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tipmakert)