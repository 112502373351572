import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button,Tabs,Tab,Table,Radio} from 'react-bootstrap'
import {TIPMAKET_PAGE_LOADER,LANGUAGE_KEY,CHANGE_TAB,TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import ModalErorr from '../common/ModalErorr'


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: TIPMAKET_PAGE_LOADER })  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Tipmakert extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',idUser:'',UserInfo:{},BankRolls:[],bank_name:3,
			bank_user:'',bank_no:'',Banklist:[],BitcoinRolls:[],PaypalRolls:[],
			bank_no:'',bank_no2:'',bank_no3:'',isOpenModal:false,msg:''
		}
		this.addbankroll=this.addbankroll.bind(this)
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
  }
		//---
	componentWillMount(){		
		//this.props.onLoad('tipmarket')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({lguages:language})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//console.log(login)
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id},()=>{
				//this.getbankrolls(info.id)
			})
			this.getbankrolls(info.id)
		}
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	  //document.getElementById("text1").innerText =''
    }

    componentWillUnmount() {
       
    }
	getbankrolls(userid){
		agent.auth.getbankrolls({userid:userid}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let UserBankRolls=data.UserBankRolls
				this.setState({UserInfo:data.UserInfo,BankRolls:UserBankRolls.BankRolls,
							   Banklist:data.Banklist,BitcoinRolls:UserBankRolls.BitcoinRolls,
							   PaypalRolls:UserBankRolls.PaypalRolls})
			}
		})
	}
	addbankroll(bank_type,e){
		let idUser=this.state.idUser
		let bank_no=(bank_type==1)?this.state.bank_no:(bank_type==2)?this.state.bank_no2:this.state.bank_no3
		let bank_user=this.state.bank_user
		let bank_id=(bank_type==1)?this.state.bank_name:(bank_type==2)?2:1
		//console.log(idUser,bank_type,bank_no)
		//userid,bank_user,bank_no,bank_id,bank_type
		agent.auth.addbankroll({userid:idUser,bank_user:bank_user,bank_no:bank_no,bank_id:bank_id,bank_type:bank_type}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.getbankrolls(idUser)
				this.setState({bank_no:'',bank_no2:'',bank_no3:'',bank_user:'',})
				
			}else{
				this.setState({msg:data.message,isOpenModal:true})
			}
		})
	}
	setDefaultBankRoll(userbankid,userid){
		agent.auth.setDefaultBankRoll({userid:userid,userbankid:userbankid}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.getbankrolls(userid)
			}else{
				this.setState({msg:data.message,isOpenModal:true})
			}
		})
	}
	handleChange(userbankid,userid,e){
		//console.log(userbankid,userid)
		this.setDefaultBankRoll(userbankid,userid)
	}
	handleChangeField(feild,e){
		this.setState({[feild]:e.target.value})
	}
	render(){   
		//console.log(this.state.PaypalRolls)
		let PaypalRolls=this.state.PaypalRolls
		let BitcoinRolls=this.state.BitcoinRolls
		let Banklist=this.state.Banklist
		let BankRolls=this.state.BankRolls
		let UserInfo=this.state.UserInfo
		let id_user=this.state.idUser
		var url_username=UserInfo.url_username
		let prName='';
		let url='';
		if(url_username!='' && url_username!='null' && url_username!=null){
			prName=url_username.split("/");
			url=prName[prName.length-1];
		}
		
        return (
			<div id="body">   
				<div className="heder-page">
					<div className="container">
						<div className="item lbl-1">
							<h1 id="text1">
								<span className="txt">Bankroll</span>
							</h1>
						</div>									
					</div>
				</div>
				<div className="tipster-list">
					<div className="container">
						<Row>
							<Col md={3} className="lef-br">
								<h3 className="headerbr">
								<Link to={(id_user!='' && id_user!=null)?"/"+url:"/login"} className="userprofile">{UserInfo.tipster_name}</Link>
								</h3>
								<div className="content" id="contentbr">
									<div className="ctn">
										<figure>
												<Link to={(id_user!='' && id_user!=null)?"/"+url:"/login"} className="userprofile"><img src={(UserInfo.imageurl!='' && UserInfo.imageurl!=null && UserInfo.imageurl!='null')?UserInfo.imageurl:'https://www.coopbet.com/media/no_avatar.png'} width="70" height="70"/></Link>
										</figure>
										<div className="tipster">
											<Link to={(id_user!='' && id_user!=null)?"/"+url:"/login"} className="usertip"><span className="lbl">{UserInfo.tipster_title}</span></Link>
											<span className="ctn">{UserInfo.FollowNumbers}</span>
										</div>
										
										
									</div>
									
								</div>
								
							</Col>
							<Col md={9} className="right-br">
								<Tabs defaultActiveKey="key1" id="tab-bankroll">
								  <Tab eventKey="key1" title="ngân hàng">
									<Row className="mg0">
										<Table className="tbl-bank-rol" >
											<thead>
												<tr>
													<td colSpan="1" style={{'width':'20%'}}>ngân hàng</td>
													<td colSpan="1" style={{'width':'27%'}}>tên tài khoản</td>
													<td colSpan="1" style={{'width':'27%'}}>số tài khoản</td>
													<td colSpan="1" style={{'width':'16%'}}>ngày</td>	
													<td colSpan="1" style={{'width':'10%'}}>mặc định</td>
												</tr>
											</thead>
											<tbody>
											{
												(BankRolls!='')?BankRolls.map((data,idx)=>{
													return(
														<tr key={idx}>
															<td>{data.bank_name}</td>
															<td>{data.bank_user}</td>
															<td>{data.bank_no}</td>
															<td>{data.create_date}</td>
															<td>
																  <Radio name="gt" inline checked={(data.is_default==1)?true:false} onChange={this.handleChange.bind(this,data.userbankid,data.userid)} >
																	
																  </Radio>{' '}
																  
															</td>
														</tr>
													)
												}):<tr><td colSpan="5">No data.</td></tr>
											}
											</tbody>
										</Table>
									</Row>
									<Row className="title-br">thêm vào tài khoản</Row>
									<Row style={{'marginTop':'10px'}}>
										<Table className="tbl-bank-rol ad-bank" >
											<thead>
												<tr>
													<td colSpan="1" style={{'width':'20%'}}>ngân hàng</td>
													<td colSpan="1" style={{'width':'40%'}}>tên tài khoản</td>
													<td colSpan="1" style={{'width':'45%'}}>số tài khoản</td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<FormControl componentClass="select" value={this.state.bank_name} onChange={this.handleChangeField.bind(this,'bank_name')}>
															{
																Banklist.map((data,i)=>{
																	return (
																		<option value={data.bank_id} key={i}>{data.bank_name}</option>
																	)
																})
															}
														</FormControl>	
													</td>
													<td>
														<FormControl className="name-bank" type="text" value={this.state.bank_user} onChange={this.handleChangeField.bind(this,'bank_user')}/>
													</td>
													<td>
														<FormControl className="number-bank" type="text" value={this.state.bank_no} onChange={this.handleChangeField.bind(this,'bank_no')}/>
													</td>
												</tr>
											</tbody>
										</Table>
									</Row>
									<Row className="txt-rg">
										<input type="submit" className="bnt-ad-bank" value="THÊM TÀI KHOẢN" onClick={this.addbankroll.bind(this,1)} />
									</Row>
								  </Tab>
								  <Tab eventKey="key2" title="Bitcoin">
										<Row>
											<Table className="tbl-bank-rol" >
												<thead>
													<tr>
														<td colSpan="1" style={{'width':'64%'}}>Bitcoin address</td>
														
														<td colSpan="1" style={{'width':'16%'}}>ngày</td>	
														<td colSpan="1" style={{'width':'10%'}}>mặc định</td>
													</tr>
												</thead>
												<tbody>
												{
													(BitcoinRolls!='')?BitcoinRolls.map((data,idx)=>{
														return(
															<tr key={idx}>
																<td>{data.bank_no}</td>
																<td>{data.create_date}</td>
																<td>
																	<Radio name="gt" inline checked={(data.is_default==1)?true:false} onChange={this.handleChange.bind(this,data.userbankid,data.userid)} disabled={false}>
																	
																	</Radio>{' '}
																</td>
															</tr>
														)
													}):<tr><td colSpan="3">No data.</td></tr>
												}
												</tbody>
											</Table>
										</Row>
										<Row className="title-br">thêm vào tài khoản</Row>
										<Row style={{'marginTop':'10px'}}>
										<Table className="tbl-bank-rol ad-bank" style={{'width':'50%'}}>
											<thead>
												<tr>
													<td colSpan="1">Bitcoin address</td>
												</tr>
											</thead>
											<tbody>
												<tr>													
													<td>
														<FormControl className="number-bank" type="text" value={this.state.bank_no3} onChange={this.handleChangeField.bind(this,'bank_no3')}/>
													</td>
												</tr>
											</tbody>
										</Table>
									</Row>
									<Row className="">
										<input type="submit" className="bnt-ad-bank" value="THÊM TÀI KHOẢN" onClick={this.addbankroll.bind(this,3)}/>
									</Row>
								  </Tab>
								  <Tab eventKey="key3" title="Paypal">
										<Row>
											<Table className="tbl-bank-rol" >
												<thead>
													<tr>
														<td colSpan="1" style={{'width':'64%'}}>Paypal address</td>
														
														<td colSpan="1" style={{'width':'16%'}}>ngày</td>	
														<td colSpan="1" style={{'width':'10%'}}>mặc định</td>
													</tr>
												</thead>
												<tbody>
												{
													(PaypalRolls!='')?PaypalRolls.map((data,idx)=>{
														return(
															<tr key={idx}>
																<td>{data.bank_no}</td>
																<td>{data.create_date}</td>
																<td>
																	<Radio name="gt" inline checked={(data.is_default==1)?true:false} onChange={this.handleChange.bind(this,data.userbankid,data.userid)} disabled={false}>
																	
																	</Radio>{' '}
																</td>
															</tr>
														)
													}):<tr><td colSpan="3">No data.</td></tr>
												}
												</tbody>
											</Table>
										</Row>
										<Row className="title-br">thêm vào tài khoản</Row>
										<Row style={{'marginTop':'10px'}}>
										<Table className="tbl-bank-rol ad-bank" style={{'width':'50%'}}>
											<thead>
												<tr>
													<td colSpan="1">Paypal address</td>
												</tr>
											</thead>
											<tbody>
												<tr>													
													<td>
														<FormControl className="number-bank" type="text" value={this.state.bank_no2} onChange={this.handleChangeField.bind(this,'bank_no2')}/>
													</td>
												</tr>
											</tbody>
										</Table>
									</Row>
									<Row className="">
										<input type="submit" className="bnt-ad-bank" value="THÊM TÀI KHOẢN" onClick={this.addbankroll.bind(this,2)}/>
									</Row>
								  </Tab>
								</Tabs>
								<Row style={{'marginTop':'15px'}}>
									<Col md={12}>Note:</Col>
									<Col md={12} style={{'padding':'5px 60px'}}>
										<div>- Vui lòng kiểm tra chính xác thông tin trước khi nhấn nút thêm tài khoản</div>
										<div>- Tài khoản mặt định dùng cho việc bạn nhận tiền từ bán tips</div>
										<div>- Tài khoản chỉ thêm chứ không chỉnh sửa được</div>
										<div>- Được thêm tối đa 5 tài khoản bank, bitcoin, paypal</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tipmakert)