import React, {Component} from 'react'
import findDOMNode from 'react-dom'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import '../../styles/sticker.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import TextTruncate from 'react-text-truncate';
//import ReactTooltip from 'react-tooltip'
import {CHANGE_TAB,TOKEN_NAME, REDIRECT, RUNNING_LOADER,LANGUAGE_KEY,RUNNING_HOME_PICK} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import menu from '../common/menu.json';
import ModalErorr from '../common/ModalErorr'
import Comment from './Comment'
//import MyFriends from './MyFriends'
//import ListBets from './ListBet'
import ModalLikes from '../common/ModalLikes'
import Pusher from 'pusher-js';

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey,
		home_pick_data:state.common.home_pick_data,
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'RUNNING_LOADER', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});



 
/*var pusher = new Pusher('ac53b48bde4e5e9c5a17', {
	cluster: 'ap1',
	forceTLS: true
});*/
var pusher = new Pusher('d75bec97873cadcd8a43', {
	cluster: 'ap1',
	forceTLS: true
});

/*var pickcode = '1548778711';
var channelcode = 'coopbet-pick-' + pickcode;
var event = 'comments-changed';
var channel = pusher.subscribe(channelcode);
channel.bind(event, function(data) {
	alert(JSON.stringify(data));
});
*/	  	

class HomeList extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',			
			homebetList:[],
			member_name:'',id_user:'',
			avatar:'https://www.coopbet.com/media/no_avatar.png',loginInfo:'',
			isOpenModal:false,msgLogin:'',imageurl:'',profileInfo:{},user_id:'',isOpenModalLike:false,
			likeList:[],parent_id:'',mth:''
		}
		//---
		this.handleClickTab = menu => e => {
			e.preventDefault()
			//const token = window.localStorage.getItem(TOKEN_NAME); 
			this.props.onTabClick(menu)
			
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,isOpenModalLike:false})
		}
		this.callModalError=(message)=>{
			this.setState({isOpenModal:true,msgLogin:message})
		}
		this.updateHomeLis=(PickCode,PickComment)=>{
			const homebetList=this.state.homebetList.map(dt=>{
				if(dt.PickCode==PickCode){
					dt.PickComment=PickComment
					dt.edit_decrpt=1
				}
				return dt;
			});
			this.setState({homebetList:homebetList})
		}
		//---
		this.emptyPickComment=this.emptyPickComment.bind(this)
	}
	
	componentWillMount(){
		const cookies = new Cookies()
		let dates=new Date();
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language,mth:dates.getMonth()+1})
		//-----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({member_name:login.UserInfo.member_name,id_user:login.UserInfo.id,imageurl:login.UserInfo.imageurl})
		}
		//console.log(login)
		//---
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps){
			let loginInfo=nextProps.loginInfo
			if(loginInfo!='' && loginInfo!='null' && loginInfo!=null){
				 let login=JSON.parse(loginInfo)
				 this.setState({member_name:login.UserInfo.member_name,id_user:login.UserInfo.id})
			}
			this.setState({homebetList:nextProps.home_pick_data, profileInfo:nextProps.profileInfo,
							loginInfo:nextProps.loginInfo,user_id:nextProps.user_id
						});
		}
		if(nextProps.parent_id!='' && nextProps.parent_id!=null){
			this.setState({parent_id:nextProps.parent_id})
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	togglelikebet(bet_id,ev){
		//console.log(bet_id)
		let thiss=ev.target.closest('.like');
		$(thiss).attr('id',"like-pr")
		agent.home.togglelikebet({bet_id:bet_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				//this.props.loadlist();
				var LikedNum=data.LikedNum
				var LikedUserIDList=data.LikedUserIDList
				var LikedUserList=data.LikedUserList
				let homebetList=this.state.homebetList;
				homebetList=(homebetList!='')?
					homebetList.map(dt=>{
						let BetList=dt.BetList;
						if(BetList!=''){
							BetList.map(it=>{
								if(it.bet_id==bet_id){
									it.LikedNum=LikedNum
									it.LikedUserIDList=LikedUserIDList
									it.LikedUserList=LikedUserList
								}
								return it;
							})
						}
						return data;
					}):''
				
				this.setState({isOpenModal:false,msgLogin:''})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
			}
		})
		
	}
	createrequest(ev){
		
		let thiss=ev.target.closest('.add-friend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				/*const homebetList=this.state.homebetList.map(item=>{
					if(item.UserInfo.id==to_user_id){
						item.UserInfo.FriendStatusWithLoginUser='Pending'
					}
					return item;
				})
				this.setState({isOpenModal:false,msgLogin:'',homebetList:homebetList})*/
				let profileInfo=this.state.profileInfo
				profileInfo.FriendStatusWithLoginUser='Pending'
				this.setState({isOpenModal:false,msgLogin:'',profileInfo:profileInfo})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
				$(thiss).find('.loading').hide();
			}
		})
	}
	createFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.createFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				let profileInfo=this.state.profileInfo
				if(profileInfo.id==id){
					profileInfo.LoginUserFollowStatus="Following"
					profileInfo.FollowNumbers=data.FollowNumbers
				}
				
				this.setState({profileInfo:profileInfo})
			}
		})
	}
	removeFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.removeFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				let profileInfo=this.state.profileInfo
				if(profileInfo.id==id){
					profileInfo.LoginUserFollowStatus=""
					profileInfo.FollowNumbers=data.FollowNumbers
				}
					
				
				this.setState({profileInfo:profileInfo})
			}
		})
	}
	tooltip(it){
		//console.log(it)
		return(
			<Tooltip id="tooltip">
			{
				it.map((data,id)=>{
					return(
						<div key={id}>{data.tipster_name}</div>
					)
				})
			}
				
			</Tooltip>
		)
	}
	getLikeList(idpet){
		let login_user_id=this.state.id_user
		let bet_id=idpet
		agent.home.likeList({bet_id:bet_id,login_user_id:login_user_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({likeList:data.list})
			}
		})
	}
	oPenShowLike(numlike,idpet){
		this.setState({isOpenModalLike:true,numlike:numlike,idpet:idpet})
		this.getLikeList(idpet)
	}
	emptyPickComment(PickCode,ev){
		agent.admin.emptyPickComment({PickCode:PickCode}).then(data => {
			if(data.result=="good"){
				let homebetList=this.state.homebetList.map(dt=>{
					if(dt!=null && dt.PickCode==PickCode){
						dt.PickComment='';
					}
					return dt;
				})
				this.setState({homebetList:homebetList})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
			}
		})
	}
	render(){   
		
		//---homebetList
		const homebetList=this.state.homebetList;
		const profileInfo=this.state.profileInfo;
		//---
		const member_name=this.state.member_name;
		const id_user=this.state.id_user;
		//---
		let i=0;let ii=0;
		//console.log(homebetList)
		//console.log(profileInfo)
		//let BetList=data.BetList;
		var url_username=profileInfo.url_username
		let prName='';
		let url='';
		if(url_username!='' && url_username!='null' && url_username!=null){
			prName=url_username.split("/");
			url=prName[prName.length-1];
		}
        return (
			<div className="items" style={{'marginTop':'10px'}}>

				<div className="row">
					<div className="col-md-12">
					</div>
				</div>
				{
					
					(profileInfo!='')?					
						
							<div>
								<div className="row">
									<div className="col-md-3 left">
										<h3 className="header">
										<Link to={"/"+url} className="userprofile">{profileInfo.tipster_name}</Link>
										</h3>
										<div className="content">
											<div className="ctn">
												<figure>
														<Link to="" className="userprofile"><img src={(profileInfo.imageurl!='' && profileInfo.imageurl!=null && profileInfo.imageurl!='null')?profileInfo.imageurl:this.state.avatar} width="70" height="70"/></Link>
												</figure>
												<div className="tipster">
													<Link to={"/"} className="usertip"><span className="lbl">{profileInfo.tipster_title}</span></Link>
													<span className="ctn">{profileInfo.FollowNumbers}</span>
												</div>
												{
												(profileInfo.role_id==3 && profileInfo.banneradurl!='')?
												<div className="sport-tip" style={{"marginBottom":"0px","backgroundColor":"#fff","padding":"0px"}}>
													<div className="div-baner">
														<a href={profileInfo.aduser_url} target="_blank"><img className="img-baner" src={profileInfo.banneradurl} /></a>
													</div>
												</div>:
												<div>
													<div className="sport-tip">
														{
															(profileInfo.sporttips_title=='SOCCER')?
															<i className="far fa-futbol icon-sport fnt26px" aria-hidden="true"></i>:
															(profileInfo.sporttips_title=='BASKETBALL')?
															<i className="fas fa-basketball-ball icon-sport fnt26px" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport fnt26px" aria-hidden="true"></i>
														}
														<span>{profileInfo.sporttips_title}</span>
													</div>
													<div className="profit">
														<div id="triangle-top-left" title={"Month: "+this.state.mth}>
															<div className="triangle-text">
															{this.state.mth}								
															</div>
														</div>
													{
														(profileInfo.MonthStatistic!='' && profileInfo.MonthStatistic!=null)?
														<ul className="not-sticker-mth">
															<li>
																<b>{localizeKey(translations)('Profit', this.state.lguages)}: </b>
																<span>{profileInfo.MonthStatistic.profit}</span>
															</li>
															<li>
																<b>{localizeKey(translations)('Yield', this.state.lguages)}: </b>
																	<span>
																		{profileInfo.MonthStatistic.profitpercentage}
																	</span>
															</li>
															<li>
																<b>{localizeKey(translations)('Wrate', this.state.lguages)}: </b>
																<span>{profileInfo.MonthStatistic.IswonPercentage}</span>
															</li>
															<li>
																<b>{localizeKey(translations)('NOtips', this.state.lguages)}: </b>
																<span>{profileInfo.MonthStatistic.NumberOfTips}</span>
															</li>
														</ul>:''
													}
													</div>	
												</div>
												}	
												
											</div>
											
											{
												(profileInfo.id!='' && id_user!='' && id_user!=profileInfo.id)?
												<div className="footer" style={{'display':(profileInfo.role_id==3 && profileInfo.banneradurl!='')?'none':''}}>
												{
													(profileInfo.FriendStatusWithLoginUser=="Confirmed")?
													<a className="add-friended">
														Friend
													</a>:
													
													<a className="add-friend" data-id={profileInfo.id} onClick={(member_name!='')?this.createrequest.bind(this):this.handleClickTab('login')}>
														{(profileInfo.FriendStatusWithLoginUser=="Pending")?localizeKey(translations)('requestSend', this.state.lguages):(profileInfo.FriendStatusWithLoginUser=="PendingYou")?localizeKey(menu)('waitingconfirm', this.state.lguages):localizeKey(translations)('AF', this.state.lguages)}
														<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
													</a>
													
												}
												{
													(profileInfo.FriendStatusWithLoginUser=="Confirmed")?'':
													<a className="following" className={(profileInfo.LoginUserFollowStatus!='')?'following flwg':'following'} data-id={profileInfo.id} onClick={(member_name!='')?((profileInfo.LoginUserFollowStatus!='')?this.removeFollow.bind(this):this.createFollow.bind(this)):this.handleClickTab('login')}>
														{(profileInfo.LoginUserFollowStatus!='')?localizeKey(translations)('followings', this.state.lguages):localizeKey(translations)('Follow', this.state.lguages)}
													</a>
												}	
												</div>:''
												
											}
										</div>
									</div>
									<div className="col-md-9">
									{
										//-------- Time line
									}
										<Row id="profile-time-line" className="mg0 right">
										{
											(homebetList!='' && homebetList!='undefined' && homebetList!=null && homebetList!='null')?
											homebetList.map((data,indexNum)=>{
												let BetList=data.BetList;
												var runLst=data.PickCodeComingUsers;
												i+=1;
												return (
													<div key={indexNum} className="item-time-line">
														<Row className="mg-20">
															<Col md={12}>
																<div className="header">
																	
																	<div className="w-0" style={{'width':'85px'}}><span>{localizeKey(translations)('title1', this.state.lguages)}</span></div>
																	<div className="w-1" style={{'width':'115px'}}><span>{localizeKey(translations)('title2', this.state.lguages)}</span></div>
																	<div className="w-2"><span>{localizeKey(translations)('title3', this.state.lguages)}</span></div>
																	<div className="w-3" style={{'width':'160px'}}><span>{localizeKey(translations)('title4', this.state.lguages)}</span></div>
																	<div className="w-1"><span>{localizeKey(translations)('title5', this.state.lguages)}</span></div>
																	
																	<div className="w-1"><span>{localizeKey(translations)('title6', this.state.lguages)}</span></div>
																	<div className="w-1" style={{'width':'90px'}}><span>{localizeKey(translations)('title7', this.state.lguages)}</span></div>
																	<div className="w-1" style={{'display':'none'}}><span>{localizeKey(translations)('title8', this.state.lguages)}</span></div>
																</div>
																<div className="ctn">
																	{
																		(BetList.length>0)?
																		BetList.map(item=>{
																			ii=ii+1;
																			var likeLst=item.LikedUserList;
																			var pesons='';
																			var status='';
																			for( let i=0;i<likeLst.length;i++){
																				if(i==likeLst.length-1){
																					pesons+=likeLst[i].tipster_name;
																				}else{
																					pesons+=likeLst[i].tipster_name+', ';
																				}
																				if(likeLst[i].user_id==id_user){
																					status="liked"
																				}
																				
																			}
																			return (
																				<div className="sitem" key={ii}>													
																					<div className="w-0" style={{'width':'85px'}}>
																						<img src={item.sporttip_imageurl} style={{display:'none'}}/>
																					{
																						(item.sportid==1)?<i className="fas fa-basketball-ball icon-sport fnt26px" aria-hidden="true"></i>:(item.sportid==2)?<i className="far fa-futbol icon-sport fnt26px" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport fnt26px" aria-hidden="true"></i>
																					}
																					</div>
																					<div className="w-1" style={{'width':'115px'}}>
																						<span>
																						{item.start_date} {item.start_time}
																						</span>
																					</div>
																					<div className="w-2">
																						<span>
																							[{item.league_name}]<br/>
																							{item.hometeam_name} - {item.awayteam_name}
																						</span>
																					</div>
																					<div className="w-3" style={{'width':'160px'}}>
																							<span className="bet-type">{item.tip_desc
																							}</span>
																					</div>
																					<div className="w-1"><span>{parseInt(item.amount)}/10</span></div>
																					
																					<div className="w-1" style={{'padding':'inherit'}}>
																						{
																							(item.home_goal!=null && item.home_goal!='null')?
																							<span>{item.home_goal} - {item.away_goal}</span>:
																							<span> ? </span>
																						}
																							
																					</div>
																					<div className="w-1" style={{'padding':'inherit','width':'90px'}}>
																						{
																							(item.bet_status=='WIN')?
																							<span className="status won" title="Won">
																								{localizeKey(translations)('Win', this.state.lguages)}
																							</span>:(item.bet_status=='DRAW')?
																							<span className="status draw" title="Draw">
																								{localizeKey(translations)('Draw', this.state.lguages)}
																							</span>:(item.bet_status=='WAIT')?
																							<span className="status pending" title="Pending">
																								{localizeKey(translations)('wait', this.state.lguages)}
																							</span>:(item.bet_status=='LOST')?
																							<span className="status lost" title="Lost">
																								{localizeKey(translations)('lost', this.state.lguages)}
																							</span>:''
																						}
																						
																					</div>
																					<div className="w-1 like" style={{'display':'none'}}>
																						{
																							(item.LikedNum>0 && status=='liked')?
																							<span>
																								<a className="like-bet like-bet-id active" data-id={item.bet_id} title="Unlike" onClick={(member_name!='')?this.togglelikebet.bind(this,item.bet_id):this.handleClickTab('login')}></a>
																								<a onClick={this.oPenShowLike.bind(this,item.LikedNum,item.bet_id)}>
																									<OverlayTrigger placement="bottom" overlay={this.tooltip(likeLst)}>
																									  <span>{item.LikedNum}</span>
																									</OverlayTrigger>
																								</a>
																							</span>:
																							<span>
																								<a className="like-bet" data-id={item.bet_id} title="Like" onClick={(member_name!='')?this.togglelikebet.bind(this,item.bet_id):this.handleClickTab('login')}></a>
																								<a onClick={this.oPenShowLike.bind(this,item.LikedNum,item.bet_id)}>
																								{
																									(item.LikedNum>0)?
																									<OverlayTrigger placement="bottom" overlay={this.tooltip(likeLst)}>
																									  <span>{item.LikedNum}</span>
																									</OverlayTrigger>: <span>{item.LikedNum}</span>
																								}
																									
																								</a>
																							</span>
																						}
																					</div>
																					{
																						(item.comment!='')?
																						<div className="comment">
																					
																							<div className="content" style={{'height':'54px'}}>
																								<span>
																									<TextTruncate
																										line={3}
																										truncateText="…"
																										text={item.comment}
																										textTruncateChild={<a href="#">Xem thêm</a>}
																									/>
																								</span>
																							</div>
																							<a className="action" style={{'display':'none'}}>Xem thêm</a>
																						</div>:''
																					}
																					
																				</div>
																			)
																		}):''
																	}
																		
																		
																	</div>
																</Col>
															</Row>
															{
																//---comment
															}
															
															{
																/*(data.PickComment!='' && data.PickComment!=null)?
																<Row className="mg0">
																	<Col md={12} className="pd10_0">
																		<Col md={1} className="pl0">
																			<img src={(profileInfo.imageurl!='' && profileInfo.imageurl!=null)?profileInfo.imageurl:this.state.avatar} width="35" height="35" className="img-icon"/>
																		</Col>
																		<Col md={11}>
																			<Row><h4 className="name-comment">{profileInfo.tipster_name}</h4></Row>
																			<Row><div className="date-comment">{data.PickDatetimeSTR}</div></Row>
																			<Row><div className="content-comment">{data.PickComment}</div></Row>
																			
																		</Col>
																	</Col>
																</Row>:''*/
															}
															<Row className="mg0" style={{'borderTop':(data.PickComment!='' && data.PickComment!=null)?'':'0px'}}>
																<Comment 
																	imageurl={this.state.imageurl} 
																	TotalComments={data.TotalComments} 
																	CommentList={data.CommentList} 
																	PickCode={data.PickCode}
																	member_name={member_name}
																	numi={i} id_user={this.state.id_user}
																	PickLikedNum={data.PickLikedNum}
																	PickLikedUserList={data.PickLikedUserList}
																	PickLikedUserIDList={data.PickLikedUserIDList}
																	PickCodeRunning={data.PickCodeRunning}
																	PickCodeComingNumber={data.PickCodeComingNumber}
																	PickDatetimeSTR={data.PickDatetimeSTR}
																	edit_decrpt={data.edit_decrpt}
																	runLst={runLst}
																	PickComment={data.PickComment}
																	avartaturl={profileInfo.imageurl}
																	tipsterName={profileInfo.tipster_name}
																	parent_id_cm={this.state.parent_id}
																	user_id_login={id_user}
																	user_id={profileInfo.id}
																	callModalError={this.callModalError}
																	emptyPickComment={this.emptyPickComment}
																	updateHomeLis={this.updateHomeLis}
																/>
															</Row>
															
															{
																//end
															}
													</div>
												)
											}):''
										}
											
										</Row>
									
									</div>
								</div>
							</div>:''
						
					
				}
				
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
				<ModalLikes isOpen={this.state.isOpenModalLike} close={this.close} numlike={this.state.numlike}
					idpet={this.state.idpet} id_user={this.state.id_user} likeList={this.state.likeList}
				/>
			</div>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(HomeList)