import React, {Component} from 'react'
import { connect } from 'react-redux'
//import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Checkbox} from 'react-bootstrap'
import {AD_LONG_DESCPT} from '../../constants/actionTypes'
import agent from '../../agent'
//import languages from '../../languages'
//import translations from '../common/captionhome.json';
//import { Cookies } from 'react-cookie'
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import Header from '../../components/Header';
import Avatar from 'react-avatar';
import ModalErorr from '../common/ModalErorr'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey,
		ad_long_description: state.common.ad_long_description
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'RUNNING_LOADER', data})  
	},
	
});



 


class NewInfo extends Component{ 	
	constructor(){
		super()
		this.state = {
			isLoading:false,seller_id:'',PaymentMethods:[],PriceTips:'',
			PriceTips_VND:'',buyer_info:{},from_date:'',seller_info:{},
			to_date:'',seller_userbankid:'',isOpenModal:false,accept:0,stk:'',
			copied:false,numCoppy:''
		}
		//---
		this.buyTip=()=>{
			this.setState({isLoading:true})
			let date=new Date();
			let mt=date.getMonth()+1;
			let year=date.getFullYear();
			let month=mt+"/"+year;
			let seller_id=this.state.seller_id
			let seller_userbankid=this.state.seller_userbankid
			let PriceTips=this.state.PriceTips
			agent.home.createOrder({PriceTips:PriceTips,month:month,
										   seller_id:seller_id,seller_userbankid:seller_userbankid}).then(data => {
				if(data.result=="good"){					
					this.setState({isLoading:false})
					window.location="/invoice-tips/"+data.order_info.order_id
				}else{
					this.setState({msg:data.message,isOpenModal:true,isLoading:false})
				}
			})
		}
		this.getOrderPrepare=(id)=>{
			let date=new Date();
			let mt=date.getMonth()+1;
			let year=date.getFullYear();
			let month=mt+"/"+year;
			agent.profile.getOrderPrepare({seller_id:id,month:month}).then(data => {
				if(data.result=="good"){					
					this.setState({PaymentMethods:data.PaymentMethods,
								   PriceTips:data.PriceTips,PriceTips_VND:data.PriceTips_VND,
								   buyer_info:data.buyer_info,from_date:data.from_date,
								   seller_info:data.seller_info,to_date:data.to_date})
				}else{
					this.setState({msg:data.message,isOpenModal:true,isLoading:false})
				}
			})
		}
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		
  }
		//---
	componentWillMount(){
		this.props.onLoad('running');
		let id=this.props.match.params.id
		this.getOrderPrepare(id)
		this.setState({seller_id:id})
		//console.log(id)
		
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	componentDidMount() {
      
    }
	
    componentWillUnmount() {
       
    }
	numberFormat(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	}
	handleChangeField(field,e){
		//console.log(e.target.value)
		if(e.target.value!==''){
			//console.log(e.target,$("#selec-tt option:selected").attr("bank_no"))
			let bank_name=$("#selec-tt option:selected").attr("bank_name")
			let name=(bank_name==='Bitcoin')?'btc':(bank_name==='Paypal')?'paypal':'stk'
			let stk=name+": "+$("#selec-tt option:selected").attr("bank_no");
			let numCoppy=$("#selec-tt option:selected").attr("bank_no");
			this.setState({[field]:e.target.value,stk:stk,numCoppy:numCoppy})
		}else{
			this.setState({stk:''})
		}
	}
	handleCheck(ev){
		let check=(ev.target.checked===true)?1:0;
		this.setState({accept:check})
	}
	render(){   
		let PaymentMethods=this.state.PaymentMethods;

        return (
			<div>
				<Header/>
				<div id="body">   
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">BUY TIPS</span>
								</h1>
							</div>	
							
						</div>
					</div>
					<div className="tipster-list-buy">
						<div className="container">
							<Row className="mg0">
								<Col md={12} id="buy-tip-ct" className="">
									<Col md={1} className="pd0 img-buy-tip">
										<figure>
											<a>
												{
													(this.state.seller_info.imageurl!=null && this.state.seller_info.imageurl!='')?<img src={this.state.seller_info.imageurl}/>:
													<Avatar size="75" round={true} className="avatar-title" color={this.generateColor()}
															name={this.state.seller_info.tipster_name}/> 
												}
											</a>
										</figure>
									</Col>
									<Col md={7} className="left-ct-buy">
										<h4 className="name-tips">{this.state.seller_info.tipster_name}</h4>
										<div className="lh22"><b>Lợi nhuận:</b> {this.state.seller_info.profit}</div>
										<div className="lh22"><b>Lợi nhuận %:</b> {this.state.seller_info.profitpercentage}</div>
									</Col>
									<Col md={4} className="fl-r">
										<h4 className="txt-cst">{this.state.PriceTips}$</h4>
										<h4 className="txt-cst pr-txt-r">{this.numberFormat(this.state.PriceTips_VND)} VNĐ</h4>
										<div className="name-buyer">Buyer: {this.state.buyer_info.first_name+" "+this.state.buyer_info.last_name}</div>
									</Col>
								</Col>
								<Col md={12} className="txt-time-see-tip">Thời gian xem tisp từ: {this.state.from_date} đến {this.state.to_date}.</Col>
								<Col md={12} className="mg2o">
									<Col md={3} className="pd0 pttt">Chọn phương thức thanh toán:</Col>
									<Col md={4} className="pd0">
										<FormControl componentClass="select" placeholder="select" id="selec-tt" onChange={this.handleChangeField.bind(this,'seller_userbankid')}>
											<option value=""> Method transfer </option>
											{
											(PaymentMethods!='')?PaymentMethods.map((it,idx)=>{
												return(
											<option value={it.userbankid} key={idx} bank_no={it.bank_no} bank_name={it.bank_name}> {it.bank_desc} </option>
												)
											}):''
											}
											
										</FormControl>
										{
											(this.state.stk!=='')?
											<div  className="copi-stk">
												{this.state.stk}
												<CopyToClipboard text={this.state.numCoppy}
												  onCopy={() => this.setState({copied: true})}>
												  <i className="far fa-copy copied" title={(this.state.copied===true)?'Copied':'Copy'}></i>
												</CopyToClipboard>
											</div>:''
										}
										
									</Col>
									<Col md={5} className="pd0 tx-r">
										<Button
											id="btn-save-buy-tip"
											bsStyle="primary"
											disabled={(this.state.isLoading===true || this.state.accept <1)?true:false}
											onClick={!this.state.isLoading ? this.buyTip : null}>
											{this.state.isLoading ? <span style={{'fontSize':'14px'}}><i className='fa fa-spinner fa-spin '></i> BUYING</span> :<span>BUY </span>}
										</Button>
									</Col>
								</Col>
								<Col md={12}>
									<Checkbox  onChange={this.handleCheck.bind(this)}>
									 <b> Tôi chấp nhận các quy định của coopbet:</b>
									  <div>- coopbet không chịu bất cứ trách nhiệm nào trong giao dịch này</div>
									  <div>- coopbet không giải quyết các tranh chấp và khiếu nại liên quan</div>
									</Checkbox>
									
								</Col>
							</Row>
						</div>
					</div>
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInfo)