import React from 'react';
//import { Link } from 'react-router-dom'
import { Link,withRouter, history} from 'react-router-dom'
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import { connect } from 'react-redux'
import agent from '../agent'
import { Cookies } from 'react-cookie'
import {TOKEN_NAME,LANGUAGE_KEY,CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME} from '../constants/actionTypes'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import header from './common/header.json'
import '../styles/App.css'

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
		only_friend: state.common.only_friend,
	})      
);

const mapDispatchToProps = dispatch => ({  
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	setMember: (data)=>{ 
		dispatch({ type: MEBER_NAME, data })
	},
});

class Header extends React.Component {
	constructor(){
		super()
		this.state = {
			showModalSave: false,
			messages: '',
			classMessage: '',
			language:'1005',
			locales:[],
		}
		//---
		
		
    }
	//---------------------------
	 
	componentWillReceiveProps(nextProps) {
		if(nextProps.languageKey){
			this.setState({language:nextProps.languageKey});
		}
	}	

	componentWillMount() {
		//-----------------------
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({language:language})
		
	}	
	render() {
		const token = this.props.token
		const tab = this.props.tab
		const locales = this.state.locales?this.state.locales:'';
		
		
		//---------
			return (
				
						<header>
							<div className="container">
								<div className="header-1">
									<span className="t-1">{localizeKey(header)('text1', this.state.language)}</span>
									<span className="t-2">{localizeKey(header)('text2', this.state.language)}</span>
								</div>
								<div className="header-2">
									<div className="we">
										<span className="lbl">{localizeKey(header)('text3', this.state.language)}</span>
										<span className="ctn">{localizeKey(header)('text4', this.state.language)}</span>
									 
										<span className="ctn">{localizeKey(header)('text5', this.state.language)}</span>
									</div>
									<div className="you">
										<span className="lbl">{localizeKey(header)('text6', this.state.language)}</span>
										<span className="ctn">{localizeKey(header)('text7', this.state.language)}</span>
									</div>
								</div>
							</div>
						</header>
			)			
		//}
		return null
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));