import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox } from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Link } from 'react-router-dom'
import agent from '../../agent'


import '../../styles/modal.css'; 
import '../../styles/style-modal.css'; 
import '../../styles/App.css'
import '../../styles/Friends.css'
import ImageUploader from 'react-images-upload';
import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  //BUILDING_LOADED,
  
}from '../../constants/actionTypes'; 

const mapStateToProps = state => (
    Object.assign({}, state, {
	// customer: state.buildings.customer,
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class UploadInvoice extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,listBank:[],buyer_bank_type_id:1,order_id:'',pictures:[],
			fileurl:''
			
			
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};
		
		this.onDrop=this.onDrop.bind(this)
		//---
		this.getBankTypes=()=>{
			agent.home.getBankTypes({}).then(data => {
				if(data.result=="good"){					
					this.setState({listBank:data.list})
				}else{
				}
			})
		}
		this.uploadProofofpayment=()=>{
			let order_id=this.state.order_id
			let picture=this.state.pictures
			agent.auth.uploadProofofpayment({order_id:order_id,picture:picture}).then(data => {
				if(data.result=="good"){					
					this.setState({fileurl:data.fileurl})
				}else{
				}
			})
		}
		this.orderProofofpayment=()=>{
			let order_id=this.state.order_id
			let buyer_bank_type_id=this.state.buyer_bank_type_id
			let proof_of_payment_url=this.state.fileurl
			agent.home.orderProofofpayment({order_id:order_id,proof_of_payment_url:proof_of_payment_url,
											buyer_bank_type_id:buyer_bank_type_id}).then(data => {
				if(data.result=="good"){					
					this.props.close();
					window.location="/buy-tipter"
				}else{
					this.props.viewError(data.message)
				}
			})
		}
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,order_id:nextProps.order_id
			},()=>{
				if(nextProps.isOpen===true){
					this.getBankTypes();
				}
			});
			
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		
	}
	componentDidMount() {
	}
	componentWillMount() {
		//this.getBankTypes();
	}
	onDrop(picture) {
		//console.log(picture[0])
        this.setState({			
           // pictures: picture
            pictures: picture[0]
        },()=>{
			this.uploadProofofpayment();
		});
		
		//agent.user.uploadMedias({medias :picture[0]}).then((datas) => {			
		//	console.log(datas)
		//})
    }
	handleChangeField(feild,e){
		this.setState({[feild]:e.target.value})
	}
  render() {
	    let listBank=this.state.listBank
        return (
			<Modal
     		  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="modal-uploadInvoice"
			  className="w3-animate-zoom"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title">
						
							Gửi bằng chứng thanh toán
						</span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body className="">
					<div className="">
						<Row className="mg0">
							<Col md={12}>
								<label className="lb14">Trả qua</label>
								<FormControl componentClass="select" placeholder="select" id="selec-tt" onChange={this.handleChangeField.bind(this,'buyer_bank_type_id')}>
									
									{
										(listBank!=='')?listBank.map((dt,idx)=>{
											return(
											<option value={dt.id} key={idx}> {dt.bank_type_name} </option>
											)
										}):''
									}
									
								</FormControl>	
							</Col>
						</Row>
						<Row className="mg0" style={{'marginTop':'10px'}}>								
							<Col md={12}>
								<label className="lb14">Tải lên bằng chứng</label>
								 <ImageUploader
									withIcon={true}
									buttonText='chọn file'
									onChange={this.onDrop}
									//imgExtension={['.jpg', '.gif', '.png', '.gif']}
									withPreview={true}
									//maxFileSize={5242880}
								/>
								<div>
									<i className="fas fa-exclamation-circle ic-exa"></i>
									<span>Hình ảnh cần phải rõ nét. Sử dụng ảnh chụp màn hình nếu bạn có thể.</span>
								</div>
							</Col>
							
						</Row>
					</div>
			  </Modal.Body>
			  <Modal.Footer style={{'display':''}}>
					<Col md={12}> 						
						<button type="button" className="btn btn-closed" onClick={this.props.close}>Đóng</button>
						<button type="button" className="btn btn-send" onClick={this.orderProofofpayment.bind(this)}>Gửi</button>
					</Col>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadInvoice); 