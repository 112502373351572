import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import '../../styles/App.css'
import '../../styles/Comment.css'
import $ from 'jquery';
import {RESET_DASHBOARD, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME,UDATE_PICK_COMENT} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import ReplyList from './ReplyList'
import Lqhome from '../common/home.json'
import {localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie'
import TextareaAutosize from 'react-textarea-autosize';
import ModalEditComment from './ModalEditComment';
import ReactHtmlParser from 'react-html-parser';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({               
	onResetDashboard: (data) => {
		dispatch({ type: RESET_DASHBOARD, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},
	reload_pick_comment: (data) => {
		dispatch({ type: UDATE_PICK_COMENT, data})  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class CommentLayout extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			language:'1005',
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://www.coopbet.com/media/avartar_cm.jpg',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			TotalComments:'',CommentList:[],PickCode:'',for_pick_code:'',parent_id:'',member_name:'',
			numCM:'',backoffset:0,numi:'',ViewAllComments:0,user_id:'',user_id_login:'',isOpenModalEditCm:false,
			txt_comment:'',comment_id:''
		}
		//---
		this.close=()=>{
			this.setState({isOpenModalEditCm:false})
		}
		//----
		this.listComment=this.listComment.bind(this)
		this.viewCM=this.viewCM.bind(this)
		this.listCommentAfterRely=this.listCommentAfterRely.bind(this)
		this.updateTotalRely=this.updateTotalRely.bind(this)
		//----
		this.handleClickTab = menu => e => {
			e.preventDefault()
			this.props.onTabClick(menu)
			
		}
		//----
		this.updateTotalCM=(TotalComments)=>{
			this.setState({TotalComments:TotalComments})
		}
		//----
		this.showModalEditCM=(comment_id,for_pick_code,comment)=>{
			this.setState({isOpenModalEditCm:true,comment_id:comment_id,
						   txt_comment:comment,for_pick_code:for_pick_code,txt_title:'Edit Comment'})
		}
		this.updateCommentList=(comment_id,comment)=>{
			let CommentList=this.state.CommentList.map(dt=>{
				if(dt.comment_id==comment_id){
					dt.comment=comment
					dt.edit_cm=1
				}
				return dt;
			})
			this.setState({CommentList:CommentList})
		}
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({language:nextProps.languageKey});
		}
		
		let url=this.state.url
		let TotalComments=this.state.TotalComments
		let CommentList=this.state.CommentList
		let PickCode=this.state.PickCode
		let member_name=this.state.member_name
		let numCM=this.state.TotalComments
		let numi=this.state.numi
		let ViewAllComments=this.state.ViewAllComments
		let user_id=this.state.user_id
		let user_id_login=this.state.user_id_login
		if(nextProps.imageurl!='' && nextProps.imageurl!=null){
			url=nextProps.imageurl
		}
		if(nextProps.TotalComments!='' && nextProps.TotalComments!=null){
			TotalComments=nextProps.TotalComments
			numCM=nextProps.TotalComments
		}
		if(nextProps.CommentList!='' && nextProps.CommentList!=null){
			CommentList=nextProps.CommentList.map(dt=>{
				dt.show_rely=0
				return dt;
			})
		}
		if(nextProps.PickCode!='' && nextProps.PickCode!=null){
			PickCode=nextProps.PickCode
		}
		if(nextProps.member_name!='' && nextProps.member_name!=null){
			member_name=nextProps.member_name
		}
		if(nextProps.numi!='' && nextProps.numi!=null){
			numi=nextProps.numi
		}
		if(nextProps.ViewAllComments!='' && nextProps.ViewAllComments!=null){
			ViewAllComments=nextProps.ViewAllComments
		}
		if(nextProps.user_id!='' && nextProps.user_id!=null){
			user_id=nextProps.user_id
		}
		if(nextProps.user_id_login!='' && nextProps.user_id_login!=null){
			user_id_login=nextProps.user_id_login
		}
		this.setState({url:url,TotalComments:TotalComments,CommentList:CommentList,PickCode:PickCode,
						member_name:member_name,numCM:numCM,numi:numi,ViewAllComments:ViewAllComments,
						user_id:user_id,user_id_login:user_id_login})
		
	}

	componentWillMount() {
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		//---
		//console.log(this.props.user_id)
		const url=(this.props.imageurl!='' && this.props.imageurl!=null && this.props.imageurl!='null')?this.props.imageurl:this.state.url
		const TotalComments=this.props.TotalComments;
		const CommentList=this.props.CommentList;
		const PickCode=this.props.PickCode;
		const member_name=this.props.member_name;
		const numCM=this.props.TotalComments;
		const numi=this.props.numi;
		const ViewAllComments=this.props.ViewAllComments;
		const user_id=this.props.user_id;
		const user_id_login=this.props.user_id_login;
		//----
		let Comments=CommentList.map(dt=>{
				if(dt!=null){
					dt.show_rely=0
					return dt;
				}
			})
		this.setState({url:url,TotalComments:TotalComments,CommentList:Comments,PickCode:PickCode,
						member_name:member_name,numCM:numCM,numi:numi,ViewAllComments:ViewAllComments,
						user_id:user_id,user_id_login:user_id_login,language:language})
						
		
		
	}	
	componentDidMount() {
		
	}
	doKeycomment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".bg-green-haze").addClass("active")
		if(e.key=='Enter'){
			
		}
		
		//
	}
	changeCOmment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".ot-btn").show()
	}
	hideComment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".ot-btn").hide()
		$(thiss).find(".textarea-cm").val('')
		$(thiss).find(".bg-green-haze").removeClass("active")
	}
	handleRely(e){
		let thiss=e.target.closest(".ct-cmt")
		$(thiss).find(".t-reply").show();
	}
	hideRyply(e){
		let thiss=e.target.closest(".t-reply")
		$(thiss).hide();
	}
	hideRyplyChild(e){
		let thiss=e.target.closest(".t-reply-child")
		$(thiss).hide();
	}
	handleRelyChild(e){
		let thiss=e.target.closest(".ct-cm-reply")
		$(thiss).find(".t-reply-child").show();
	}
	hideCM(for_pick_code,parent_id,e){
		//let thiss=e.target.closest(".reply-List");
		//$(thiss).find(".view-cm").hide();
		//$(thiss).find(".hide-cm").show();
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt!=null && dt.comment_id==parent_id){
				dt.show_rely=1
			}
			return dt;
		})
		this.setState({CommentList:CommentList,for_pick_code:for_pick_code,parent_id:parent_id})
	}
	viewCM(comment_id,e){
		//let thiss=e.target.closest(".reply-List");
		//$(thiss).find(".view-cm").show();
		//$(thiss).find(".hide-cm").hide();
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt!=null && dt.comment_id==comment_id){
				dt.show_rely=0
			}
			return dt;
		})
		this.setState({CommentList:CommentList})
	}
	createComment(parent_id,e){
		let thiss=e.target.closest(".cm-dad")
		let comment=$(thiss).find(".textarea-cm").val();
		let for_pick_code=this.state.PickCode
		let user_id=this.state.user_id
		//---
		$(thiss).find('.do_cm').hide()
		$(thiss).find('.ot-btn').hide()
		$(thiss).find('.loading_dad').show()
		//---
		agent.comment.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id,lang_id:this.state.language}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.listComment(for_pick_code,0)
				$(thiss).find('.do_cm').show()			
				
				$(thiss).find(".textarea-cm").val('');
				window.location="/topic/"+user_id+"_"+for_pick_code;
			}else{
				this.props.callModalError(data.message)
				$(thiss).find('.do_cm').show()	
			}
			$(thiss).find('.loading_dad').hide()
		})
	}
	createCommentChild(parent_id,for_pick_code,e){
		let thiss=e.target.closest(".cm-child")
		let thisss=e.target.closest(".ct-cmt")
		let comment=$(thiss).find(".textarea-cm").val();
		let user_id=this.state.user_id
		//---
		$(thiss).find('.do_cm').hide()
		$(thiss).find('.ot-btn').hide()
		$(thiss).find('.loading_dad').show()
		//---
		agent.comment.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id,lang_id:this.state.language}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				//this.listComment(for_pick_code,0)
				this.listCommentAfterRely(for_pick_code,parent_id)
				$(thiss).find('.do_cm').show()
				//$(thiss).find('.loading_dad').hide()
				$(thiss).find(".textarea-cm").val('');
				//this.props.reload_pick_comment({for_pick_code:for_pick_code,parent_id:parent_id})
				//this.setState({for_pick_code:for_pick_code,parent_id:parent_id})
				/*let ev=this
				setTimeout(function(){
					//$(thisss).find('.view-cm').hide();
					//$(thisss).find('.hide-cm').show();
					//console.log(parent_id,ev.state.CommentList)
					
					ev.setState({for_pick_code:for_pick_code,parent_id:parent_id})
				},500)	*/
				window.location="/topic/"+user_id+"_"+for_pick_code+"_"+parent_id;
			}else{
				this.props.callModalError(data.message);
				$(thiss).find('.do_cm').show()
				$(thisss).find(".t-reply").hide()
				$(thiss).find(".textarea-cm").val('');
			}
			$(thiss).find('.loading_dad').hide()
		})
	}
	listCommentAfterRely(for_pick_code,comment_id){
		let limit=(this.state.backoffset+10);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:0,limit:limit,offset:0}).then(data => {
			
			if(data.result=="good"){
				let CommentList=data.CommentList.map(dt=>{
						if(dt!=null && dt.comment_id==comment_id){
							dt.show_rely=1
						}
						return dt;
					})
				this.setState({CommentList:CommentList,TotalComments:data.TotalComments,numCM:data.TotalComments})
			}
		})
	}
	listComment(for_pick_code,parent_id){
		let backoffset=parseInt(this.state.backoffset);//new
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:parent_id,limit:10,offset:backoffset}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({CommentList:data.CommentList,TotalComments:data.TotalComments,numCM:data.TotalComments,backoffset:backoffset})
			}
		})
	}
	togglelike(comment_id,ev){
		agent.comment.togglelike({comment_id:comment_id,lang_id:this.state.language}).then(data => {
			console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item!=null && item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsLiked=data.IsLiked
						item.IsDisliked=data.IsDisliked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	toggledislike(comment_id,ev){
		agent.comment.toggledislike({comment_id:comment_id,lang_id:this.state.language}).then(data => {
			console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item!=null && item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsDisliked=data.IsDisliked
						item.IsLiked=data.IsLiked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	viewComment(for_pick_code){
		let backoffset=parseInt(this.state.backoffset+10);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:0,limit:10,offset:backoffset}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let cm_list=this.state.CommentList.concat(data.CommentList)
				//let cm_list=data.CommentList
				let Comments=cm_list.map(dt=>{
					if(dt!=null){
						dt.show_rely=0
						return dt;
					}
				})
				this.setState({CommentList:Comments,TotalComments:data.TotalComments,numCM:data.TotalComments,backoffset:backoffset})
			}
		})
	}
	docm(for_pick_code,ev){
		if(for_pick_code!=''){
			let thiss=ev.target.closest("#cmt-lt")
			//console.log(thiss)
			let ot=$(thiss).attr("option")
			if(ot==0){
				$(thiss).find(".sh_hd_cm").hide();
				$(thiss).attr("option",1)
			}else{
				$(thiss).find(".sh_hd_cm").show();
				$(thiss).attr("option",0)
				this.listComment(for_pick_code,0)
			}
		}else{
			window.location="/login"
		}
		
	}
	onDotToll(comment_id,e){
		//console.log(userId)
		let CommentList=this.state.CommentList.map(data=>{
			if(data!=null && comment_id==data.comment_id){
				data.show_dot=1;
			}else if(data!=null && comment_id!=data.comment_id){
				data.show_dot=0;
			}
			return data;
		})
		this.setState({CommentList:CommentList})
	}
	leaveDotTool(e){
		let CommentList=this.state.CommentList.map(data=>{
			if(data!=null){
				data.show_dot=0;
				return data;
			}
		})
		this.setState({CommentList:CommentList})
	}
	deleteInOwnTopic(comment_id,for_pick_code,e){
		agent.comment.deleteInOwnTopic({for_pick_code:for_pick_code,comment_id:comment_id,lang_id:this.state.language}).then(data => {
			//console.log(this.state.CommentList)
			if(data.result=="good"){
				/*let CommentList=this.state.CommentList.map(dt=>{
					if(dt!=null && dt.comment_id!=comment_id){
						return dt;
					}
				})*/
				this.listCommentAfterRely(for_pick_code,0)
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	deleteCm(comment_id,for_pick_code,e){
		agent.admin.deleteCm({for_pick_code:for_pick_code,comment_id:comment_id,lang_id:this.state.language}).then(data => {
			//console.log(this.state.CommentList)
			if(data.result=="good"){
				
				this.listCommentAfterRely(for_pick_code,0)
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	updateTotalRely(comment_id,e){
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt!=null && dt.comment_id==comment_id){
				dt.total_replies=dt.total_replies-1
			}
			return dt;
		})
		this.setState({CommentList:CommentList})
	}
	blockUser(blocked_user_id,for_pick_code,e){
		agent.running.blockUser({blocked_user_id:blocked_user_id,lang_id:this.state.language}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				/*let CommentList=this.state.CommentList.map(dt=>{
					if(dt!=null && dt.comment_id!=comment_id){
						return dt;
					}
				})*/
				this.listCommentAfterRely(for_pick_code,0)
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	render() {
		let url=this.state.url
		let img_default=this.state.img_default
		let url_like=this.state.url_like
		let url_liked=this.state.url_liked
		let url_dislike=this.state.url_dislike
		let url_disliked=this.state.url_disliked
		let TotalComments=this.state.TotalComments
		let CommentList=this.state.CommentList
		let member_name=this.state.member_name
		let numCM=this.state.numCM
		let PickCode=this.state.PickCode
		let numi=this.state.numi
		let ViewAllComments=this.state.ViewAllComments
		const numCmt=(TotalComments-CommentList.length)
		let user_id=this.state.user_id
		let user_id_login=this.state.user_id_login
		//---------
		//console.log(numCM,numCmt)
			return (
				<Row id="cmt-lt">
					<Col md={12}>
						<div className="count-txt-cm" option="1" onClick={(user_id_login!='' && user_id_login!=null)?this.docm.bind(this,PickCode):this.docm.bind(this,'')}>{TotalComments} {localizeKey(Lqhome)('comments', this.state.language)}</div>
						<a className="count-txt-cm" href={(member_name!='')?"/topic/"+user_id+"_"+PickCode:'/login'} style={{'display':'none'}}> 
							{TotalComments} Comments
						</a>
					</Col>
					{
						//---
					}
					<Row className="mg0 sh_hd_cm" style={{'display':(numi==1)?'':'none'}}>
					{
						(member_name!='' && member_name!='null' && member_name!=null)?
						<Col md={12} className="pd10_0 cm-dad">
							<Col md={1} className="">
								<img src={url} width="35" height="35" className="img-icon-sm"/>
							</Col>
							<Col md={11} className="pdL0 mgt10 mgbt10">
								<div className="do_cm">
									
									<TextareaAutosize className="textarea-cm pd0"
										placeholder={localizeKey(Lqhome)('add_a_comment', this.state.language)} 
										maxLength={10000}
										onKeyPress={this.doKeycomment.bind(this)} 
										onClick={this.changeCOmment.bind(this)}
									/>
								</div>
								<img className="loading_dad" src="https://www.coopbet.com/media/loading.gif" width="33"/>
							</Col>
							<Col md={12} className="t-right ot-btn">
								<a className="bg-grey-steel" onClick={this.hideComment.bind(this)}>cancel</a>
								<a className="bg-green-haze" onClick={this.createComment.bind(this,0)}>{localizeKey(Lqhome)('comments', this.state.language)}</a>
							</Col>
						</Col>:''
					}
					
					
					
					{
						//---comment List
					}
					<Row className="mg0">
					{
						(CommentList!='')?CommentList.map((data,index)=>{
							if(data!=null){
								var url_username=data.url_username
								let prName='';
								let url_profile='';
								if(url_username!='' && url_username!='null' && url_username!=null){
									prName=url_username.split("/");
									url_profile=prName[prName.length-1];
								}
								var html = data.comment;
								html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
								var div = document.createElement("div");
								div.innerHTML = html;
								var textmessge = html;
								//console.log(div)
								return(
									<Col md={12} className="pd10_0" key={index}>
										<Col md={1} className="">
											<Link to={(user_id_login!='' && user_id_login!=null)?"/"+url_profile:"/login"} className="">
												<img src={(data.imageurl!='' && data.imageurl!=null && data.imageurl!='null')?data.imageurl:img_default} width="35" height="35" className="img-icon-sm"/>
											</Link>
										</Col>
										<Col md={11} className="pdL0 ct-cmt">
											<Row className="mg0 dot-hv" onMouseEnter={this.onDotToll.bind(this,data.comment_id)} onMouseLeave={this.leaveDotTool.bind(this)}>
												<h4 className="name-cmt" >
													<Link to={(user_id_login!='' && user_id_login!=null)?"/"+url_profile:"/login"} className="">{(data.blocked==true)?<i className="fa fa-ban" style={{'color':'red','paddingRight':'3px'}}></i>:''}{data.tipster_name} </Link>
													<span className="time-cmt">{data.str_comment_time}</span>
													{(data.edit_cm==1)?<span className="txt-cm-small"> (edited)</span>:''}
													{
														((user_id==user_id_login || user_id_login==data.user_id) && data.show_dot==1)?
														<DropdownMenu triggerType='text' trigger='...'  className="tool-of-user">
															<Row className="mg0 noti-box">	
																{
																	(user_id_login==data.user_id && data.show_dot==1)?
																	<Col md={12}  className="lhi0"><a className="text-cmt-tl" onClick={this.showModalEditCM.bind(this,data.comment_id,data.for_pick_code,data.comment)}><i className="fa fa-pencil"></i> edit comment</a></Col>:''
																}
																<Col md={12} className="lhi0"><a className="text-cmt-tl" onClick={this.deleteInOwnTopic.bind(this,data.comment_id,data.for_pick_code)}><i className="fa fa-trash-o"></i> delete comment</a></Col>	
																{
																	(user_id_login!=data.user_id && data.user_id!=5 && data.blocked!=true)?
																	<Col md={12} style={{'marginTop':'7px'}} className="lhi0">
																		<a className="text-cmt-tl" onClick={this.blockUser.bind(this,data.user_id,data.for_pick_code)}><i className="fa fa-minus-circle"></i> block user</a>
																	</Col>	:''
																}
																
															</Row>
														</DropdownMenu>	:(user_id_login==5 && user_id_login!=data.user_id && data.show_dot==1)?
														<DropdownMenu triggerType='text' trigger='...'  className="tool-of-user">
															<Row className="mg0 noti-box">														
																<Col md={12}><a className="text-cmt-tl" onClick={this.deleteCm.bind(this,data.comment_id,data.for_pick_code)}><i className="fa fa-trash-o"></i> delete comment</a></Col>																																
															</Row>
														</DropdownMenu>:''
														
													}
													
												</h4>
											</Row>
											<Row className="mg0">
											{//data.comment
												ReactHtmlParser(textmessge)
												//<span dangerouslySetInnerHTML={{ __html: textmessge }} />												
											}
												
											</Row>
											<Row className="mg0">
												<div className="r-like-reply">
													<img src={(data.IsLiked==true)?url_liked:url_like} 
														width="35" height="35" className="img-icon-like"
														onClick={(member_name!='' && member_name!=null && member_name!='null')?this.togglelike.bind(this,data.comment_id):this.handleClickTab('login')}
													/>
													<span className="count-like">{(data.LikedNum>0)?data.LikedNum:''}</span>
													<img src={(data.IsDisliked==true)?url_disliked:url_dislike}
														width="35" height="35" className="img-icon-dislike"
														onClick={(member_name!='' && member_name!=null && member_name!='null')?this.toggledislike.bind(this,data.comment_id):this.handleClickTab('login')}
													/>
													{
														(member_name!='' &&  member_name!='null' && member_name!=null)?
														<a className="reply-cmt" onClick={this.handleRely.bind(this)}>{localizeKey(Lqhome)('reply', this.state.language)}</a>:''
													}
													
												</div>
											</Row>
											<Row className="mg0 t-reply cm-child">
												<Col md={12} className="pd10_0">
													<Col md={1} className="pdL0 w-5p">
														<img src={url} width="24" height="24" className="img-icon-sm24"/>
													</Col>
													<Col md={11} className="pd0 mgt10 w-95p">
														<div className="do_cm">
															
															<TextareaAutosize className="textarea-cm pd0"
																	placeholder="Add a public reply..." rows={1}
																	maxLength={10000}
																	onKeyPress={this.doKeycomment.bind(this)} 
																	onClick={this.changeCOmment.bind(this)}
																/>
														</div>
														<img className="loading_dad" src="https://www.coopbet.com/media/loading.gif" width="33"/>
													</Col>
													<Col md={12} className="t-right ot-btn" style={{'display':'block','marginTop':'10px','padding':'0px'}}>
														<a className="bg-grey-steel" onClick={this.hideRyply.bind(this)}>cancel</a>
														<a className="bg-green-haze" onClick={this.createCommentChild.bind(this,data.comment_id,data.for_pick_code)}>{localizeKey(Lqhome)('reply', this.state.language)}</a>
														
														
													</Col>
												</Col>
											</Row>
											{
												//---Reply list
											}
											{
												(data.total_replies>0)?
												<Row className="mg0 reply-List">
												{
													(data.show_rely==1 && (data.comment_id==this.state.parent_id))?
													<div>
														<h4 className="name-cmt hide-cm" onClick={this.viewCM.bind(this,data.comment_id)}>
															<span>{localizeKey(Lqhome)('hideReplies', this.state.language)}</span>
															<i className="fa fa-chevron-up"></i>
														</h4>
														<Col md={12} className="pd0 hide-cm">
															<ReplyList 
																imageurl={url} 
																for_pick_code={this.state.for_pick_code} 
																//for_pick_code={data.for_pick_code} 
																parent_id={this.state.parent_id}
																//parent_id={data.comment_id}
																listComment={this.listComment}
																member_name={this.state.member_name}
																updateTotalCM={this.updateTotalCM}
																user_id_login={user_id_login}
																user_id={this.state.user_id}
																updateTotalRely={this.updateTotalRely}
																callModalError={this.props.callModalError}
															/>
														</Col>
													</div>:
													<h4 className="name-cmt view-cm" onClick={(member_name!='' && member_name!=null && member_name!='null')?this.hideCM.bind(this,data.for_pick_code,data.comment_id):this.handleClickTab('login')}>
														<span>{localizeKey(Lqhome)('view_replies', this.state.language)} ({data.total_replies})</span>
														<i className="fa fa-chevron-down"></i>
													</h4>
													
												}
													
													
													
												</Row>:''
											}
											
											{
												//---end
											}
										</Col>
										
									</Col>
								)
								
							}
						}):''
					}
					</Row>
					{
						(ViewAllComments<1 && numCM>10 && CommentList!='' && numCmt>0)?
						//(TotalComments>10 && CommentList!='' && (TotalComments-parseInt(CommentList.length))>0)?
							<Row className="mg0">
								<Col md={12}>
									<a className="view-more" onClick={(member_name!='' && member_name!=null)?this.viewComment.bind(this,PickCode):this.handleClickTab('login')}>{localizeKey(Lqhome)('viewmore', this.state.language)}</a>
								</Col> 
							</Row>:''
					}	
					</Row>
					{
							//---end comment
					}
					<ModalEditComment isOpen={this.state.isOpenModalEditCm} close={this.close}
						txt_comment={this.state.txt_comment} PickCode={this.state.for_pick_code}
						txt_title={this.state.txt_title} callModalError={this.props.callModalError}
						updateCommentList={this.updateCommentList} comment_id={this.state.comment_id}/>
				</Row>
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentLayout)
