import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME,PROFILE_LOADER,USERID_NF,USER_TIMELINE,USER_STATISTIC} from '../../constants/actionTypes'
import agent from '../../agent'
//import languages from '../../languages'
//import translations from '../common/captionhome.json';
import HomeList from './homeList.js';
import HeaderProfile from '../HeaderProfile'
//import PageNotFound from '../PageNotFound'
const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey,
		user_statistic: state.common.user_statistic,
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'PROFILE_LOADER', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale)),
	setUserFr: (data)=>{ 
		dispatch({ type: USERID_NF, data })
	},
	setUserTimeLine: (data)=>{ 
		dispatch({ type: USER_TIMELINE, data })
	},
	setUserStatistic: (data)=>{ 
		dispatch({ type: USER_STATISTIC, data })
	}
});



 


class Profile extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},
			homebetList:[],pathname:'',userInfo:{},user_id:'',full_url_username:'',isActive:'',offset:0,
			limit:30,listTimeLine:[],isLoading:false
		}
		//===
		this.getrandomad=(lang_id)=>{
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//=========
		this.gethomebetlist=()=>{
			agent.home.gethomebetlist().then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({homebetList:data.list})
				}
			})
		}
		//====
		this.gethomebetlistNew=()=>{
			agent.home.gethomebetlistNew().then(data => {
				//console.log(data)
				if(data.result === 'good'){
					//this.setState({homebetList:data.list})
					this.props.setUserFr(data.list)
				}
			})
		}
		this.loadMore=()=>{
			let height=$("#all-list-home").height();
			let offset = parseInt(this.state.offset) + this.state.limit
			this.setState({isLoading:true,offset},()=>{
				this.timeline();
				setTimeout(function(){
					$('html,body').animate({
								scrollTop: height
							}, 15);
					
				},8000);
			})
			
		}
		//====
		this.statisticMonth=this.statisticMonth.bind(this)
		this.statisticYear=this.statisticYear.bind(this)
		this.statisticQuater=this.statisticQuater.bind(this)
		this.getProfileInfo=this.getProfileInfo.bind(this)
		this.friendlistNew=this.friendlistNew.bind(this)
		this.timeline=this.timeline.bind(this)
		this.statistic=this.statistic.bind(this)
		//====
		
	}
	getProfileInfo(){
		//let pathname=this.state.pathname;
		//pathname=pathname.split(".");
		//let id=pathname[pathname.length-1];
		let full_url_username=this.state.full_url_username
		//console.log(full_url_username)
		
		agent.profile.profileInfo({full_url_username:full_url_username}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({userInfo:data.data})
			}else{
				window.location='/not-found'
			}
		})
	}
	friendlistNew(){
		//let user_id=this.state.user_id
		let full_url_username=this.state.full_url_username
		agent.profile.friends({full_url_username:full_url_username}).then((datas) => {
			//console.log(datas)
			if(datas!=null && datas.result=='good'){
				//this.props.setUserFr(datas.list)
				this.props.setUserFr(datas)
			}
			
		  })	
	}
	timeline(){
		//let user_id=this.state.user_id
		let limit=this.state.limit
		let offset=this.state.offset
		let full_url_username=this.state.full_url_username
		agent.profile.timeline({full_url_username:full_url_username,limit:limit,offset:offset}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				const list = this.state.listTimeLine.concat(datas.list);
				this.setState({listTimeLine:list,isLoading:false})
				this.props.setUserTimeLine(list)
			}
			
		  })	
	}
	statistic(){
		let user_id=this.state.user_id
		let full_url_username=this.state.full_url_username
		agent.profile.statistic({full_url_username:full_url_username}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.props.setUserStatistic(datas)
			}
			
		  })	
	}
	statisticMonth(month){
		let user_id=this.state.user_id
		let full_url_username=this.state.full_url_username
		agent.profile.statistic_month({user_id:user_id,month:month,full_url_username,full_url_username}).then((datas) => {
			//console.log(datas)
			var data=this.props.user_statistic
			//console.log(this.props.user_statistic)
			if(datas.result=='good'){
				data.MonthData=datas.MonthData
				this.props.setUserStatistic(data)
				
			}
			
		  })	
	}
	statisticYear(year){
		let user_id=this.state.user_id
		let full_url_username=this.state.full_url_username
		agent.profile.statistic_year({user_id:user_id,year:year,full_url_username:full_url_username}).then((datas) => {
			//console.log(datas)
			var data=this.props.user_statistic
			//console.log(this.props.user_statistic)
			if(datas.result=='good'){
				data.YearData=datas.YearData
				this.props.setUserStatistic(data)
				
			}
			
		  })	
	}
	statisticQuater(quarter){
		let user_id=this.state.user_id
		let full_url_username=this.state.full_url_username
		agent.profile.statistic_quarter({user_id:user_id,quarter:quarter,full_url_username:full_url_username}).then((datas) => {
			//console.log(datas)
			var data=this.props.user_statistic
			//console.log(this.props.user_statistic)
			if(datas.result=='good'){
				data.QuarterData=datas.QuarterData
				this.props.setUserStatistic(data)
				
			}
			
		  })	
	}
	
	componentWillMount(){	
		//-----
		
		let $this=this;
		this.props.onLoad('profile');
		//=========================
		this.gethomebetlist();
		setTimeout(function() {
			//console.log($this.state.pathname)
			$this.getProfileInfo();
			$this.friendlistNew();
			$this.timeline();
			$this.statistic();
			
		}, 10);	
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		let pathname=nextProps.location.pathname
		let search=nextProps.location.search
		search=search.split('?').join('')
		let ol_pathname=pathname.split('/')
		let isActive=0;
		pathname=ol_pathname[1]
		if(search==2){
			isActive=2
		}
		if(search==3){
			isActive=3
		}
		//console.log(nextProps);	
		//var pathid=pathname.split(".");
		//let id=pathid[pathid.length-1];
		let full_url_username='https://www.coopbet.com/'+pathname
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
			this.getrandomad(nextProps.languageKey)
			
		}
		this.setState({pathname:pathname,full_url_username:full_url_username,isActive:isActive})
		
		//console.log(full_url_username)
		
	}
	
	
	isEmpty(obj) {
		var hasOwnProperty = Object.prototype.hasOwnProperty;
		// null and undefined are "empty"
		if (obj == null) return true;

		// Assume if it has a length property with a non-zero value
		// that that property is correct.
		if (obj.length > 0)    return false;
		if (obj.length === 0)  return true;

		// If it isn't an object at this point
		// it is empty, but it can't be anything *but* empty
		// Is it empty?  Depends on your application.
		if (typeof obj !== "object") return true;

		// Otherwise, does it have any properties of its own?
		// Note that this doesn't handle
		// toString and valueOf enumeration bugs in IE < 9
		for (var key in obj) {
			if (hasOwnProperty.call(obj, key)) return false;
		}

		return true;
	}
	render(){   
	//console.log(this.props.tab)
		let login=window.localStorage.getItem(TOKEN_NAME);
		let homebetList=this.state.homebetList
		let userInfo=this.state.userInfo
		//console.log(this.isEmpty(userInfo),homebetList)
		if(!this.isEmpty(userInfo)){
			return (
				<div>
					<HeaderProfile profileInfo={userInfo} isActive={this.state.isActive}/>
					<div id="body" style={{'minHeight':'400px'}}>   
						<div className="list-user">
							<div className="container">
								<HomeList homebetList={homebetList} //loadlist={this.gethomebetlistNew} 
									loginInfo={login} profileInfo={userInfo} user_id={this.state.user_id}
									loadlist={this.gethomebetlistNew} statisticMonth={this.statisticMonth}
									statisticYear={this.statisticYear} statisticQuater={this.statisticQuater}
									isLoading={this.state.isLoading} loadMore={this.loadMore}
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}else{
			return(
				<div></div>
			)
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)