import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
import { setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/tips.css'
import $ from 'jquery';
import {Col, Row, FormControl, FormGroup, Radio, Checkbox,Table, ControlLabel,ButtonToolbar,DropdownButton,MenuItem,Button,
		Tabs,Tab,Label} from 'react-bootstrap'
import NumericInput from 'react-numeric-input';
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,ADDTIPS_PAGE_LOADER} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: ADDTIPS_PAGE_LOADER })  
	},
});



 


class ItemTip extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			listTips:[],
			betlist:[],randomad:{},userinfo:{},waittocreatebetlist:[],
			loading:false,odd_info:{},stake :5,is_maxbet:0,comment:'',msgLogin:'',
			isOpenModal:false,count:0,startDate: moment(),filterdate:'',leagues:[],league_id:0,selectedOption:'',
			btnTime:'Trận đấu hôm nay',today_match_number:0,tomorrow_match_number:0,Openviewboxplayer:false,key:1,
			maingroups:[],othergroups:[],currentdate:'',leagueids:'',tiplist:[],running:'',sport_tips:2,
			data:{},index:'',leagueShow0:true,leagueShow:false
			
		}
		
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		
		//---
		
		//---
		
	}
	timemenuData(){
		this.setState({loading:true})
		const sport_tips=this.state.sport_tips
		agent.tips.timemenuData({sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				this.setState({tomorrow_match_number:data.tomorrow_match_number,today_match_number:data.today_match_number})
			}
		})
	}
	listtobet(league_id){
		//this.setState({loading:true})
		const sport_tips=this.state.sport_tips
		const filterdate=this.state.filterdate
		//console.log(filterdate,league_id)
		agent.tips.listtobet({league_id:league_id,sport_tips:sport_tips,filterdate:filterdate}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				this.setState({data:data.list[0]})
			}
		})
	}
	refesh(col){
		const filterdate=this.state.filterdate
		const league_id=this.state.league_id
		const sport_tips=this.state.sport_tips
		agent.tips.listtobet({filterdate:filterdate,league_id:league_id,sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				if(col=="waittocreatebetlist")
				this.setState({waittocreatebetlist:data.waittocreatebetlist})
			}
		})
	}
	infotobet(odd_id,bet_type){
		agent.tips.infotobet({odd_id:odd_id,bet_type:bet_type}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				this.setState({odd_info:data.odd_info})
			}
		})
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	componentWillMount(){		
		this.props.onLoad('addtips')
		let data=this.props.data
		let index=this.props.index
		let sport_tips=this.props.sport_tips
		let filterdate=this.props.filterdate
		this.setState({data:data,index:index,sport_tips:sport_tips,filterdate:filterdate})
		//--
		
		
	}
	CollapseList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').hide();
		$(thiss).find('.collapse').hide();
		$(thiss).find('.expand').show();
	}
	ExpandList(league_id,ev){
		//console.log(ev.target.closest('.bet-items'))leagueShow0
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').show();
		$(thiss).find('.collapse').show();
		$(thiss).find('.expand').hide();
		if(league_id>0){
			this.listtobet(league_id)
		}
		
	}
	handleExpandOrCollapan(league_id,ev){
		let thiss=ev.target.closest('.bet-items')
		if(league_id>0){
			//this.listtobet(league_id)
			let leagueShow=this.state.leagueShow;
			if(leagueShow==true){
				$(thiss).find('.sh-snap-menu').hide();
				$(thiss).find('.collapse').hide();
				$(thiss).find('.expand').show();
				this.setState({leagueShow:!leagueShow})
			}else{
				$(thiss).find('.sh-snap-menu').show();
				$(thiss).find('.collapse').show();
				$(thiss).find('.expand').hide();
				this.setState({leagueShow:!leagueShow})
				this.listtobet(league_id)
			}
		}else{
			let leagueShow0=this.state.leagueShow0;
			if(leagueShow0==true){
				$(thiss).find('.sh-snap-menu').hide();
				$(thiss).find('.collapse').hide();
				$(thiss).find('.expand').show();
				this.setState({leagueShow0:!leagueShow0})
			}else{
				$(thiss).find('.sh-snap-menu').show();
				$(thiss).find('.collapse').show();
				$(thiss).find('.expand').hide();
				this.setState({leagueShow0:!leagueShow0})
			}
		}
	}
	
	handleChangeField(field,ev){
		//console.log(field,ev)
		let val=''
		if(field=='is_maxbet'){
			val=(ev.target.checked)?1:0	
			if(ev.target.checked){
				this.setState({stake:10})
			}else{
				this.setState({stake:5})
			}
		}else if(field=="comment"){
			val=ev.target.value
		}else if(field=="stake"){
			val=ev
		}else if(field=="running"){
			val=(ev.target.checked)?1:0	
			
			
		}
		this.setState({[field]:val})
		//console.log(this.state.stake)
	}
	leagueFiler(ev){
		let val=ev.target.value
		this.setState({league_id:val},()=>{
			this.listtobet();
		})
	}
	gotopet(ev){
		this.setState({stake:5})
		let thiss=ev.target.closest('div')
		//console.log($(thiss).attr("dataid"),$(thiss).attr("type"))
		//this.infotobet($(thiss).attr("dataid"),$(thiss).attr("type"))
		this.props.infotobet($(thiss).attr("dataid"),$(thiss).attr("type"))
		$("#tipster-box").show();
	}
	closepet(ev){
		$("#tipster-box").hide();
	}
	showcomment(ev){
		
		let count=parseInt(this.state.count)+1;	
		this.setState({count:count})
		let thiss=ev.target.closest(".comments")
		$(thiss).find(".note-comment").show();
		$(thiss).find(".fa-power-off").show();
		$(thiss).find(".fa-list-alt").hide();
	}
	hidecomment(ev){
		let thiss=ev.target.closest(".comments")
		$(thiss).find(".note-comment").hide();
		$(thiss).find(".fa-power-off").hide();
		$(thiss).find(".fa-list-alt").show();
		
	}
	handletimeplay(date,ev){
		let thiss=ev.target.closest("li")
		let title=$(thiss).find(".left").text();
		$('#time-play li').removeClass('active');
		$(thiss).addClass('active');
		let filterdate='';
		if(date=='today'){
			let date = new Date();
			filterdate=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
		}else if(date=='tomorow'){
			var d = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
			filterdate=parseInt(d.getFullYear())+'-'+parseInt(d.getMonth()+1)+"-"+parseInt(d.getDate())
		}
		this.setState({btnTime:title,filterdate:filterdate},()=>{
			this.listtobet();
		})
	}
	changeOption(sport_tips,e){
		//console.log(sport_tips)
		$(".tool-ball").removeClass("active")
		this.setState({sport_tips:sport_tips},()=>{
			this.listtobet();
			this.timemenuData();
		})
		$(e.target).closest(".tool-ball").addClass("active")
	}
	 
	
	render(){   
		
		const listTips=this.state.listTips
		const userinfo=this.state.userinfo
		const randomad=this.state.randomad
		const betlist=this.state.betlist
		const waittocreatebetlist=this.state.waittocreatebetlist
		const loading=this.state.loading
		const odd_info=this.state.odd_info
		const leagues=this.state.leagues
		const tiplist=this.state.tiplist
		let payout=0
		if(odd_info.odds>0){
			payout=(parseFloat(this.state.stake))*parseFloat(odd_info.odds)
		}else{
			payout= (parseInt(this.state.stake))*1
		}
		payout= Math.round(payout* 100) / 100; 
		const maingroups=this.state.maingroups;
		const othergroups=this.state.othergroups;
		const sport_tips=this.state.sport_tips
		const data=this.state.data;
		const match_list=data.match_list;
		//console.log(this.state.sport_tips)
	return (
			<div className="bet-items">												
			{
				(this.state.index==0)?
				<div className="bet-league sh-item" onClick={this.handleExpandOrCollapan.bind(this,0)}>
					<a className="league-link" href="javascript:;" id={data.league_id} >{data.league_name}</a>
					<i className="collapse focus-mb" dataid="1" >&nbsp; </i>
					<i className="expand focus-mb" dataid="2" style={{'display':'none'}}>&nbsp; </i>
				</div>:
					<div className="bet-league sh-item" onClick={this.handleExpandOrCollapan.bind(this,data.league_id)}>
					<a className="league-link" href="javascript:;" id={data.league_id}>{data.league_name}</a>
					<i className="collapse focus-mb" id=""  style={{'display':'none'}}>&nbsp; </i>
					<i className="expand focus-mb" id=""  >&nbsp; </i>
				</div>
			}
				<div className="table_bound sh-snap-menu" style={{display:(this.state.index==0)?'':'none'}}>
				{
					(sport_tips==1 || sport_tips==3)?
					<Table className="oddmove" >
					<thead>
						<tr>
							<td colSpan="1" className="text_center" style={{'width':'50px'}}>Time</td>
							<td colSpan="1" className="text_center" style={{'width':'200px'}}>Match</td>
							<td className="text_center" colSpan="2" style={{'width':'120px'}}>Money Line</td>
							<td className="text_center" colSpan="2" style={{'width':'150px'}}>Asian Handicap</td>	
							<td className="text_center" colSpan="2" style={{'width':'140px'}}>Over/Under</td>
							<td className="text_center" colSpan="2">Odd/Even</td>
						</tr>
					</thead>
					<tbody id={"match-list-"+data.league_id}>  
						{
							(match_list)?match_list.map((items,index)=>{
								return(
								<tr className="boder_none odd-row " key={index}>
									<td colSpan="1">
										<div>{items.str_start_date}</div>
										<div>{items.str_start_time}</div>
										<div></div>
									</td>
									<td colSpan="1">
										<div>{items.home_team_name}</div>
										<div>{items.away_team_name}</div>
										<div></div>
									</td>
									<td colSpan="1">																		
										<div style={{'display':'none'}}>{(items.odd_moneyline_draw!=null && items.odd_moneyline_draw<=0)?items.odd_moneyline_draw.split('-').join(''):<span>&nbsp;</span>}</div>
										<div style={{'display':'none'}}>{(items.odd_moneyline_draw!=null && items.odd_moneyline_draw>0)?items.odd_moneyline_draw:<span>&nbsp;</span>}</div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
										{(items.odd_moneyline_home!='' && items.odd_moneyline_home!=null)?<div dataid={items.odd_moneyline_id} type={items.odd_moneyline_home_bet_type}>{(items.odd_moneyline_home>0)?<a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_moneyline_home}</a>:''}</div>:''}
										{(items.odd_moneyline_away!='' && items.odd_moneyline_away!=null)?<div dataid={items.odd_moneyline_id} type={items.odd_moneyline_away_bet_type}>{(items.odd_moneyline_away>0)?<a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_moneyline_away}</a>:''}</div>:''}
										<div>&nbsp;</div>	
									</td>
									<td colSpan="1" style={{'textAlign':'right'}}>																		
										<div>{(items.odd_handicap_draw!=null && items.odd_handicap_draw<=0)?items.odd_handicap_draw.split('-').join(''):<span>&nbsp;</span>}</div>
										<div>{(items.odd_handicap_draw!=null && items.odd_handicap_draw>0)?items.odd_handicap_draw:<span>&nbsp;</span>}</div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
										<div dataid={items.odd_handicap_id} type={items.odd_handicap_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_handicap_home}</a></div>
										<div dataid={items.odd_handicap_id} type={items.odd_handicap_away_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_handicap_away}</a></div>
										<div>&nbsp;</div>
									</td>
									
									<td colSpan="1" style={{'textAlign':'right'}}>
										<div>{(items.odd_overunder_draw!=null)?items.odd_overunder_draw:''}</div>
										<div>&nbsp;</div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
										{(items.odd_overunder_home!='' && items.odd_overunder_home!=null)?<div dataid={items.odd_overunder_id} type={items.odd_overunder_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_overunder_home}</a></div>:''}
										{(items.odd_overunder_away!='' && items.odd_overunder_away!=null)?<div dataid={items.odd_overunder_id} type={items.odd_overunder_away_bet_type}><a className="bet-link"  title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_overunder_away}</a></div>:''}
										<div>&nbsp;</div>
									</td>
									<td colSpan="1" style={{'textAlign':'right'}}>
										<div>{(items.odd_oddeven_draw!=null)?items.odd_oddeven_draw:""}</div>
										<div></div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
										{(items.odd_oddeven_home!='' && items.odd_oddeven_home!=null)?<div dataid={items.odd_oddeven_id} type={items.odd_oddeven_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_oddeven_home}</a></div>:''}
										{(items.odd_oddeven_away!='' && items.odd_oddeven_away!=null)?<div dataid={items.odd_oddeven_id} type={items.odd_oddeven_away_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_oddeven_away}</a></div>:''}
										<div>&nbsp;</div>
									</td>
									
								</tr>
								)
								
							}):''
						}
						
					</tbody>
				</Table>:(sport_tips==2)?
					<Table className="oddmove" >
					<thead>
						<tr>
							<td colSpan="1" className="text_center" style={{'width':'50px'}}>Time</td>
							<td colSpan="1" className="text_center" style={{'width':'200px'}}>Match</td>
							<td className="text_center" style={{'width':'60px'}}>1x2</td>
							<td className="text_center" colSpan="2" style={{'width':'130px'}}>Asian Handicap</td>
							<td className="text_center" colSpan="2" style={{'width':'130px'}}>Over/Under</td>
							<td className="text_center" colSpan="2" >Odd/Even</td>
						</tr>
					</thead>
					<tbody id={"match-list-"+data.league_id}>  
						{
							(match_list)?match_list.map((items,index)=>{
								return(
								<tr className="boder_none odd-row " key={index}>
									<td colSpan="1">
										<div>{items.str_start_date}</div>
										<div>{items.str_start_time}</div>
										<div></div>
									</td>
									<td colSpan="1">
										<div>{items.home_team_name}</div>
										<div>{items.away_team_name}</div>
										<div>Draw</div>
									</td>
									<td colSpan="1">
									{(items.odd_1x2_home!='' && items.odd_1x2_home!=null)?<div dataid={items.odd_1x2_id} type={items.odd_1x2_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_1x2_home}</a></div>:''}
									{(items.odd_1x2_away!='' && items.odd_1x2_away!=null)?<div dataid={items.odd_1x2_id} type={items.odd_1x2_away_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_1x2_away}</a></div>:''}
									{(items.odd_1x2_draw!='' && items.odd_1x2_draw!=null)?<div dataid={items.odd_1x2_id} type={items.odd_1x2_draw_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_1x2_draw}</a></div>:''}
									</td>
									<td colSpan="1" style={{'textAlign':'right'}}>																		
										<div>{(items.odd_handicap_draw!=null && items.odd_handicap_draw<=0)?items.odd_handicap_draw.split('-').join(''):<span>&nbsp;</span>}</div>
										<div>{(items.odd_handicap_draw!=null && items.odd_handicap_draw>0)?items.odd_handicap_draw:<span>&nbsp;</span>}</div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
										<div dataid={items.odd_handicap_id} type={items.odd_handicap_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_handicap_home}</a></div>
										<div dataid={items.odd_handicap_id} type={items.odd_handicap_away_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_handicap_away}</a></div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1" style={{'textAlign':'right'}}>
										<div>{(items.odd_overunder_draw!=null)?items.odd_overunder_draw:''}</div>
										<div>&nbsp;</div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
									{(items.odd_overunder_home!='' && items.odd_overunder_home!=null)?<div dataid={items.odd_overunder_id} type={items.odd_overunder_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_overunder_home}</a></div>:''}
									{(items.odd_overunder_away!='' && items.odd_overunder_away!=null)?<div dataid={items.odd_overunder_id} type={items.odd_overunder_away_bet_type}><a className="bet-link"  title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_overunder_away}</a></div>:''}
										<div>&nbsp;</div>
									</td>
									<td colSpan="1" style={{'textAlign':'right'}}>
										<div>{(items.odd_oddeven_draw!=null)?items.odd_oddeven_draw:""}</div>
										<div></div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
									{(items.odd_oddeven_home!='' && items.odd_oddeven_home!=null)?<div dataid={items.odd_oddeven_id} type={items.odd_oddeven_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_oddeven_home}</a></div>:''}
										{(items.odd_oddeven_away!=null && items.odd_oddeven_away!='')?<div dataid={items.odd_oddeven_id} type={items.odd_oddeven_away_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_oddeven_away}</a></div>:''}
										<div>&nbsp;</div>
									</td>
									
								</tr>
								)
								
							}):''
						}
						
					</tbody>
				</Table>:(sport_tips==300)?
				<Table className="oddmove" >
					<thead>
						<tr>
							<td colSpan="1" className="text_center" style={{'width':'50px'}}>Time</td>
							<td colSpan="1" className="text_center" style={{'width':'200px'}}>Match</td>
							<td className="text_center" colSpan="2" style={{'width':'150px'}}>Asian Handicap</td>
							<td className="text_center" colSpan="2" style={{'width':'140px'}}>Money Line</td>
						</tr>
					</thead>
					<tbody id={"match-list-"+data.league_id}>  
						{
							(match_list)?match_list.map((items,index)=>{
								return(
								<tr className="boder_none odd-row " key={index}>
									<td colSpan="1">
										<div>{items.str_start_date}</div>
										<div>{items.str_start_time}</div>
										<div></div>
									</td>
									<td colSpan="1">
										<div>{items.home_team_name}</div>
										<div>{items.away_team_name}</div>
										<div>Draw</div>
									</td>
									
									<td colSpan="1">																		
										<div>{(items.odd_handicap_draw!=null && items.odd_handicap_draw<=0)?items.odd_handicap_draw.split('-').join(''):<span>&nbsp;</span>}</div>
										<div>{(items.odd_handicap_draw!=null && items.odd_handicap_draw>0)?items.odd_handicap_draw:<span>&nbsp;</span>}</div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
										<div dataid={items.odd_handicap_id} type={items.odd_handicap_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_handicap_home}</a></div>
										<div dataid={items.odd_handicap_id} type={items.odd_handicap_away_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_handicap_away}</a></div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">																		
										<div>{(items.odd_moneyline_draw!=null && items.odd_moneyline_draw<=0)?items.odd_moneyline_draw.split('-').join(''):<span>&nbsp;</span>}</div>
										<div>{(items.odd_moneyline_draw!=null && items.odd_moneyline_draw>0)?items.odd_moneyline_draw:<span>&nbsp;</span>}</div>
										<div>&nbsp;</div>
									</td>
									<td colSpan="1">
										<div dataid={items.odd_moneyline_id} type={items.odd_moneyline_home_bet_type}><a className="bet-link" title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_moneyline_home}</a></div>
										<div dataid={items.odd_moneyline_id} type={items.odd_moneyline_away_bet_type}><a className="bet-link"  title="click to bet" onClick={this.gotopet.bind(this)}>{items.odd_moneyline_away}</a></div>
										<div>&nbsp;</div>
									</td>
									
								</tr>
								)
								
							}):''
						}
						
					</tbody>
				</Table>:''
				}
				
			</div>
		</div>
		)
									
		
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemTip)