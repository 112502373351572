import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { Cookies } from 'react-cookie' 
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/admin.css'
import {Col, Row, FormControl, FormGroup, Radio, Checkbox} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,USERLIST_PAGE_LOADER} from '../../constants/actionTypes'
import ModalDeleteUser from './ModalDeleteUser'
//import translations from '../common/captionhome.json';
import $ from 'jquery';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: USERLIST_PAGE_LOADER })  
	},
});

class UserList extends Component{ 	
	constructor(){
		super()
		this.state = {
			language:'1005',
			userList:[],
			avatar:'https://www.coopbet.com/media/no_avatar.png',
			search:'',
			countryList:[],
			roleList:[],
			UserNameContains:'',
			EmailContains:'',
			SinceDate:'',
			ShowonlySuspendedUsers:0,
			senior_tips:1,isOpenModal:false,user_id:'',deleteType:''
		}
		//---
		this.doKeySearch=e=>{
			if (e.key == 'Enter') {
				this.getUsers()
			}
		}
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		this.getUsers=this.getUsers.bind(this);
		this.search=this.search.bind(this);
		this.saveSeniorTips=this.saveSeniorTips.bind(this);
		
	}
	getRoleList(){
		agent.admin.adminrolelist().then((datas) => {
			//console.log(datas)
			if(datas!=null && datas.result=='good'){
				this.setState({roleList:datas.list})
			}
		})	
	}
	selectRole(ev){
		this.setState({role_id:ev.target.value})
	}
	selectJoinsDate(ev){
		this.setState({SinceDate:ev.target.value})
	}
	getUsers(){
		$(".imgload").show();
		const search=this.state.search
		agent.admin.userList({search:search}).then((datas) => {
			//console.log(datas)
			if(datas!=null && datas.result=='good'){
				this.setState({userList:datas.list})
			}
			$(".imgload").hide();
	  })	
	}
	getSeniorTips(){		
		agent.admin.seniorTips({}).then((datas) => {
			//console.log(datas)
			if(datas!=null && datas.result=='good'){
				this.setState({senior_tips:datas.senior_tips})
			}
	  })	
	}
	saveSeniorTips(){		
		agent.admin.saveSeniorTips({senior_tips:this.state.senior_tips}).then((datas) => {
			console.log(datas)
			if(datas.result=='good'){
			}
	  })	
	}
	ChangeField(field,ev){
		this.setState({[field]:ev.target.value})
	}
	handleSuspendedUsers(ev){
		//console.log(ev.target.checked)
		this.setState({ShowonlySuspendedUsers:ev.target.checked})
	}
	search(){
		let state=this.state
		$(".imgload").show();
		agent.admin.userList({
			UserNameContains:state.UserNameContains,
			role_id:state.role_id,
			EmailContains:state.EmailContains,
			JoinDateSince:state.SinceDate,
			ShowonlySuspendedUsers:state.ShowonlySuspendedUsers,
		}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.setState({userList:datas.list})
			}
			$(".imgload").hide();
	  })	
	}
	componentWillMount(){		
		this.props.onLoad('userlist');	
		this.getUsers();
		this.getRoleList();	
		this.getSeniorTips();	
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({language:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	openModalDelete(id,deleteType,ev){
		this.setState({isOpenModal:true,user_id:id,deleteType:deleteType})
	}
	render(){   
		const roleList=this.state.roleList;
		const userList=this.state.userList;
		//console.log("abc");

		let joinsdate=[{'value':'','title':'All'},{'value':'LAST_DAY','title':'Last day'},
			{'value':'LAST_WEEK','title':'Last week'},{'value':'LAST_MONTH','title':'Last month'},
			{'value':'LAST_YEAR','title':'Last year'}]
    return (
			<div id="body">   
				<div>
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">USER MANAGEMENT</span>
								</h1>
							</div>
							
							<div className="portlet-input input-inline input-small div-search-user">
								<div><a className="running" href="./createaduser">Create ad user</a></div>
							</div>		
						</div>
					</div>
					<div className="tipster-list">
						<div className="admin-search-user">
							<table>
								<tbody>
									<tr >
										<td colSpan="3" className="firstrow">Search Users</td>
									</tr>
									<tr className="headerrow">
										<td>Rank</td>
										<td>Username contains</td>
										<td>Email contains</td>
									</tr>
									<tr className="secondrow">
										<td className="firstcol" >
											<FormControl componentClass="select" placeholder="select" value={this.state.role_id} onChange={this.selectRole.bind(this)}>
													<option value=""> (No Filter) </option>
													{
														(roleList)?roleList.map(data=>{
															return(<option value={data.role_id} key={data.role_id}> {data.role_name} </option>)
														}):''
													}
											</FormControl>				
										</td>
										<td>
											<input id="UserNameContains" name="UserNameContains" type="text" value="" placeholder="User Name Contains" className="form-control" value={this.state.UserNameContains} onChange={this.ChangeField.bind(this,'UserNameContains')}/>
										</td>
										<td>
											<input id="EmailContains" name="EmailContains" type="text" value="" placeholder="Email Contains" className="form-control" value={this.state.EmailContains} onChange={this.ChangeField.bind(this,'EmailContains')}/>
										</td>
									</tr>			
									<tr className="normalrow">
										<td colSpan="3" >
											
										  <div className="sincedatetext">Filter by join date since: </div>
											<div className="sincedate">
											<FormControl componentClass="select" placeholder="select" value={this.state.SinceDate} onChange={this.selectJoinsDate.bind(this)}>
													{
														joinsdate.map(data=>{
															return(<option value={data.value} key={data.value}> {data.title} </option>)
														})
													}
											</FormControl>
											</div>
										</td>
									</tr>		
									<tr className="normalrow">
										<td colSpan="2" >
												<Checkbox  onChange={this.handleSuspendedUsers.bind(this)}>
												  Show only suspended users
												</Checkbox>
										</td>
										<td>
											<div>
												<span style={{'marginRight':'3px'}}>Senior: </span>
												<input id="Senior" name="Senior" type="text" value={this.state.senior_tips} placeholder="tips" className="form-control" style={{'width':'30%','display':'inline'}} onChange={this.ChangeField.bind(this,'senior_tips')}/> 
												<span> Tips </span>
												<a className="running" style={{'fontSize':'9px'}} onClick={this.saveSeniorTips}>Save</a>
											</div>
											
										</td>
									</tr>	
									<tr className="normalrow">
										<td colSpan="3"  className="tdsearch">
										  <input type="submit" className="btn-search" value="Search" onClick={this.search} style={{'fontSize':'14px'}}/>
										</td>
									</tr>						
								</tbody>
							</table>
						</div>
						<br/>
						<div className="container">
						<div className="col-md-12" style={{'textAlign':'center'}}>
							<img className="imgload" src="https://www.coopbet.com/media/SpinnerLoading.gif" style={{'display':'','position':'absolute'}}/>
						</div>
						<div className="items userlistheader">Users</div>
						{
							(userList!='') ? (userList.map((data,index)=>{
								const summary=data.SummaryBet
								return (
										<div className="items" key={index}>
											<div className="sort item">
												<span>{index+1}</span>
											</div>
											<figure className="item" style={{'width':'75px'}}>
											<a href={"./user-info/"+data.id}><img src={(data.imageurl!='' && data.imageurl!=null)?data.imageurl:this.state.avatar} width="70" height="70"/></a>
													
											</figure>
											<div className="name item">
												<h3 className="">
												{data.name}
												</h3>
												<span className="lbl">{data.tipster_title}</span><br/>
												<span className="ctn">{data.sport_tips}</span><br/>
												<span>{data.tipster_name}</span>
											</div>
											<div className="profit item">
												<ul>
													<li>
														<b>Email: </b>
														<span>{data.email}</span>
													</li>
													<li>
														<b>Last Visit: </b>
															<span>
																{data.last_visit_str}
															</span>
													</li>
													<li>
														<b>Locked: </b>
														<span>{data.Locked}</span>
													</li>
												</ul>
											</div>
											<div className="profit item">
												<ul>
													<li>
														<b>Rank: </b>
														<span>{data.role_name}</span>
													</li>
													<li>
														<b>Posts: </b>
															<span>
																{data.Posts}
															</span>
													</li>
													<li>
														<b>Display order: </b>
														<span>{data.display_order}</span>
													</li>
												</ul>
											</div>
											<div className="edit item">
												<div className="editbutton"><a className="running" href={"./adminedituser/"+data.id}>Edit</a></div>												
												<div className="editbutton"><a className="running" onClick={this.openModalDelete.bind(this,data.id,1)}>Delete</a></div>												
												<div className="editbutton"><a className="running" onClick={this.openModalDelete.bind(this,data.id,2)}>Delete Comments</a></div>												
											</div>
											
										</div>
								)
							})) : 
							
							<div className="items norecord">No records found.</div>
							
						}
						</div>
					</div>
				</div>
				<ModalDeleteUser isOpen={this.state.isOpenModal} close={this.close} user_id={this.state.user_id} deleteType={this.state.deleteType} 
								 getUsers={this.getUsers}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)