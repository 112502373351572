import React, {Component} from 'react';
import { connect } from 'react-redux'
import { PAGE_NOT_FOUND_LOADED } from '../constants/actionTypes'
import '../styles/App.css'

const mapDispatchToProps = dispatch => ({               
	onLoad: (tab) =>
		dispatch({ type: PAGE_NOT_FOUND_LOADED, tab }),		
});

class PageUnavailable extends Component{    
    
	componentWillMount(){
		this.props.onLoad('page-not-found')
	}
	
    render(){        
        return (
			<div className="col-md-12 page-404">
				<div className="details">
					<h3>Tài khoản của bạn đã bị khoá.</h3>
					<div><i className="fas fa-user-lock" style={{'fontSize':'25px'}}></i></div>
				</div>
			</div>			
        );
    }
}

export default connect(null, mapDispatchToProps)(PageUnavailable)