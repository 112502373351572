import React from 'react';
//import { Link } from 'react-router-dom'
import { Link,withRouter, history} from 'react-router-dom'
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import { connect } from 'react-redux'
import agent from '../agent'
import { Cookies } from 'react-cookie'
import {RESET_DASHBOARD, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
        CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME, PROFILE_LOADER,
        HOME_PAGE_LOADED,ONLY_FIENDS} from '../constants/actionTypes'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../styles/App.css'
import languages from '../languages'
import menu from './common/menu.json'
import header from './common/header.json'
import ModalErorr from './common/ModalErorr'
import FriendsBox from './common/Friends'
import Notification from './common/Notification'
import $ from 'jquery';
//import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
//import HeaderProfile from './HeaderProfile'
import Pusher from 'pusher-js';
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/all.css'


var pusher = new Pusher('d75bec97873cadcd8a43', {
 cluster: 'ap1',
 encrypted: true
});
const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
		only_friend: state.common.only_friend,
	})      
);

const mapDispatchToProps = dispatch => ({               
	onResetDashboard: (data) => {
		dispatch({ type: RESET_DASHBOARD, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	setMember: (data)=>{ 
		dispatch({ type: MEBER_NAME, data })
	},
	onLoadProfile: (data) => {
		dispatch({ type: 'PROFILE_LOADER', data})  
	},
	onLoadHome: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	setFiendMode: (data) =>{
		dispatch({ type: 'ONLY_FIENDS', data})  
	},
});

class App extends React.Component {
	constructor(){
		super()
		this.state = {
			showModalSave: false,
			messages: '',
			classMessage: '',
			language:'1005',
			locales:[],
			user_name:'',
			password:'',
			member_name:'',
			clicklogin:false,
			showlogout:false,
			isOpenModal:false,msgLogin:'',friendsList:{},showbox:false,loginId:'',url:'',
			listLookup:[],only_friend:'0',old_only_friend:'0',NumberofNewNotifications:0,
			limit:30,offset:0,limit_nf:10,offset_nf:0,listNF:[],loadedAll:false,scrollM:false,
			NumberofNewFriendRequests:0,name_contest:'',
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		this.changeLG=(ev)=>{
			this.setState({language:ev.target.value})
			const cookies = new Cookies();
			cookies.set('language', ev.target.value);
			this.props.onResetLanguesKey(ev.target.value); 
			window.localStorage.getItem(LANGUAGE_KEY,ev.target.value)
			window.localStorage.setItem(LANGUAGE_KEY,ev.target.value)
		};
		//----
		this.handleClickTab = menu => e => {
			e.preventDefault()
			//const token = window.localStorage.getItem(TOKEN_NAME); 
			const tab = this.props.tab
			//console.log(menu,tab);
			/*if(tab=="add-tips"){
				let url=window.location.href
				let urlOld=url.split("/add-tips/")
				let urlNew=urlOld[0]+"/"+menu
				window.location=urlNew
				//console.log(url,urlNew)
			}else if(tab=="running"){
				let url=window.location.href
				let urlOld=url.split("/running/")
				let urlNew=urlOld[0]+"/"+menu
				window.location=urlNew
				//console.log(url)
			}else if(tab=="adminedituser"){
				let url=window.location.href
				let urlOld=url.split("/adminedituser/")
				let urlNew=urlOld[0]+"/"+menu
				window.location=urlNew
				//console.log(url)				
			}else{
				this.props.onTabClick(menu)
			}*/
			if(tab=="add-tips" || tab=="addtips" || tab=="running"|| tab=="adminedituser" || tab=="edit-ad"){
				window.location="/"+menu;
			}else{
				this.props.onTabClick(menu)
			}
			
		}
		//----
		this.handleClickLogOut = menu => e => {
			e.preventDefault()
			window.localStorage.setItem( TOKEN_NAME, '');
			this.setState({showlogout:false,clicklogin:false}) 
			this.props.setMember('')
			const tab = this.props.tab
			this.props.history.push("/home")
			window.location.href = './home'
			/*if(tab=="add-tips"){
				let url=window.location.href
				let urlOld=url.split("/add-tips/")
				let urlNew=urlOld[0]+"/"+menu
				window.location=urlNew
				//console.log(url,urlNew)
			}else if(tab=="running"){
				let url=window.location.href
				let urlOld=url.split("/running/")
				let urlNew=urlOld[0]+"/"+menu
				window.location=urlNew
				//console.log(url)
			}else if(tab=="adminedituser"){
				let url=window.location.href
				let urlOld=url.split("/adminedituser/")
				let urlNew=urlOld[0]+"/"+menu
				window.location=urlNew
				//console.log(url)				
			}else{
				//this.props.onTabClick(menu)
				window.location=menu
			}*/
			//--------------
			
			
		}
		//----
		this.hideFormlogin=()=>{
			$(".box-login").hide();
		}
		//----
		this.login=()=>{
			let param={'user_name':this.state.user_name,'password':this.state.password,lang_id:this.state.language}
			agent.auth.doLogin(param).then(data => {
				if(data.result==='good'){
					window.localStorage.setItem( TOKEN_NAME, JSON.stringify(data) );
					let UserInfo=data.UserInfo;
					this.setState({member_name:UserInfo.member_name,showlogout:true})
					this.props.setMember(UserInfo.member_name)
					this.hideFormlogin();
					this.props.setToken(JSON.stringify(data))
					window.location.href = 'home'
					//this.props.onTabClick('home')
				}else{
					window.localStorage.setItem( TOKEN_NAME, '');
					this.setState({showlogout:false,msgLogin:data.message,isOpenModal:true}) 
					
				}
			})
			//
		}
		//----
		this.updateField=feild=>ev=>{
			const state = this.state;
			const newState = Object.assign({}, state, { [feild]: ev.target.value });
			this.setState(newState);
		}
		//----
		this.signinF = this.signinF.bind(this);
		//----
		this.signinG = this.signinG.bind(this);
		this.getSocket = this.getSocket.bind(this);
		this.detechAccount = this.detechAccount.bind(this);
		//----
		this.siginAc=menu=>ev=>{
			ev.preventDefault()
			//this.props.onTabClick(menu)
			this.hideFormlogin();
			this.props.history.push("/register")
		}
		//----
		this.userClick=prName=>e=>{
			e.preventDefault()
			//this.handleClickTab(prName)
			/*this.props.onTabClick(prName)
			this.props.onLoadProfile('profile');*/
			window.location="/"+prName
		}
		this.myFriendClick=prName=>e=>{
			e.preventDefault()
			window.location="/"+prName+"?2"
		}
		//----
		this.doKeySearch=e=>{
			//e.preventDefault()
			if (e.key == 'Enter') {
				this.login()
			}
			
		}
		//----
		this.doSearchHome=e=>{
			let login=window.localStorage.getItem(TOKEN_NAME);
		 
			if(login!='' && login!='null' && login!=null){
				this.tipLookup();
				if (e.key == 'Enter') {
					this.searchHome()
				}
			}
			
		}
		this.onScrollM = () => {
			
			const messgeContainer = document.getElementById("user-list-nf")
			const loadedAll = this.state.loadedAll

			if( !loadedAll && messgeContainer.scrollTop === (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
				let offset = parseInt(this.state.offset_nf) + this.state.limit_nf
				const newState = Object.assign({}, this.state, { offset_nf: offset, scrollM: true })
				this.setState(newState, () => {
					this.getList()
				})				
			}
		}
		this.searchHomeFromPickcode=(search)=>{
			$(".imgload").show()
			let only_friends=this.state.only_friend
			let limit=this.state.limit
			let offset=this.state.offset
			agent.home.gethomebetlistNew({search:search,only_friends:only_friends,limit:limit,offset:offset}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.props.onLoadHome(data.list);
					$(".imgload").hide()
				}
			})
		}
		this.handleWindowSizeChange = () => {
			 // this.setState({ width: window.innerWidth });
			 let width=window.innerWidth;
			 if(width<=1024){
				window.location='https://m.coopbet.com/login'
			 }else{
				//window.location='https://coopbet.com/home' 
				//window.location='http://localhost:3000/home' 
			 }
			// console.log(width)
		 
		};
		this.checksuspend=()=>{
			try{
				let login=window.localStorage.getItem(TOKEN_NAME);
				login=JSON.parse(login)
				if(login!='' && login!='null' && login!=null && login.UserInfo.role_id!=2 ){
					agent.auth.checksuspend().then(data => {
						//console.log(data)
						if(data!=null && data.result === 'good' && data.suspended==1){
							window.location='/un-available' 
						}
					})
				}
			}catch(e){
				console.log(e)
			}
		}
		this.contestInfo=()=>{
			agent.admin.contestInfo({}).then((datas) => {
				if(datas.result==='good'){
					let info=datas.info;					
					let name_contest=info.name;
					
					this.setState({name_contest:name_contest})
				}
			})
			
		}
		
		//----
		this.searchHome=this.searchHome.bind(this)
		this.tipLookup=this.tipLookup.bind(this)
		this.newNumber=this.newNumber.bind(this)
		//----
	}
	showformlogin(){		
		//console.log(this.state.clicklogin,this.state.showlogout)
		if(this.state.clicklogin===false && !this.state.showlogout){
			$(".box-login").show();
		}else{
			$(".box-login").hide();
		}
		this.setState({clicklogin:!this.state.clicklogin})
	}
	dologout(){
		//window.localStorage.setItem( TOKEN_NAME, '');
		//this.setState({showlogout:false}) 
		//this.props.setMember('')
		//this.props.onTabClick('home')
		//console.log(window.location.href)
	}
	
	signinF(res) {
		let postData;
		if (res.email) {
            postData = {
                name: res.name,
                provider: 'facebook',
                email: res.email,
                provider_id: res.id,
                provider_token: res.accessToken,
                provider_pic: res.picture.data.url
           };
		}

		if (postData) {
            console.log(postData);            
			agent.auth.fsignin(postData).then(data => {
                //console.log(data);                
				if(data.result==='good'){
					window.localStorage.setItem(TOKEN_NAME, JSON.stringify(data));
					let UserInfo=data.UserInfo;
					this.setState({member_name:UserInfo.member_name,showlogout:true})
					this.props.setMember(UserInfo.member_name)
					this.hideFormlogin();
					this.props.setToken(JSON.stringify(data))
					window.location.href = 'home'
				} else{
					window.localStorage.setItem(TOKEN_NAME, '');
					this.setState({showlogout:false}) 
				}
            })
            
		} else {

        }
		
    }
	//---------------------------
	signinG(res) {
		let postData;
		/*if (!res.error && res.w3.U3) {
		    postData = {
			    name: res.w3.ig,
			    email: res.w3.U3,
			    provider: 'google',
			    provider_id: res.El,
			    provider_token: res.Zi.access_token,
			    provider_pic: res.w3.Paa
		    };
		}*/
		//if (!res.error && res.tc) {
		if (!res.error) {
		    postData = {
			    name: res.profileObj.name,
			    email: res.profileObj.email,
			    provider: 'google',
			    provider_id: res.profileObj.googleId,
			   // provider_token: res.tc.access_token,
			    provider_token: res.accessToken,
			    provider_pic: res.Ca
		    };
		}

		if (postData) {
            //console.log(postData);
			agent.auth.gsignin(postData).then(data => {
                console.log(data);                
				if(data.result==='good'){
					window.localStorage.setItem(TOKEN_NAME, JSON.stringify(data));
					let UserInfo=data.UserInfo;
					this.setState({member_name:UserInfo.member_name,showlogout:true})
					this.props.setMember(UserInfo.member_name)
					this.hideFormlogin();
					this.props.setToken(JSON.stringify(data))
					window.location.href = 'home'
				} else{
					window.localStorage.setItem(TOKEN_NAME, '');
					this.setState({showlogout:false,msgLogin:data.message,isOpenModal:true}) 
				}
			})
		} else {

        }
		
    }
	//---------------------------
	getSocket(user_id){
		var thiss=this		
		//var user_id = '5';
		
		var channelcode = 'coopbet-' + user_id;
		var event = 'new-notification';
		
		var channel = pusher.subscribe(channelcode);
		channel.bind(event, function(data) {
			//alert(JSON.stringify(data));
			//console.log(data,data.NewNoticationCount)
			thiss.setState({NumberofNewNotifications:data.NewNoticationCount,NumberofNewFriendRequests:data.NumberofNewFriendRequests},()=>{
				thiss.getList()
			})
			
		});
	}
	newNumber(){
		agent.notification.newNumber().then(data => {
			//console.log(data);                
			if(data!=null && data.result==='good'){
				this.setState({NumberofNewNotifications:data.new_number,NumberofNewFriendRequests:data.new_friendrequests_number},()=>{
					this.getList()
				})
			}
		})
	}
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if (nextProps.common.redirectTo) {
		  /*const token = window.localStorage.getItem(TOKEN_NAME)
		  const nextRedirect = nextProps.common.redirectTo
		  if(token && nextRedirect === '/login'){ // login page
			nextProps.history.push('/')	
		  } else if(token){       		
			nextProps.history.push(nextProps.common.redirectTo)                  
		  } else {
			nextProps.history.push('/login') 
		  }*/
		  nextProps.history.push(nextProps.common.redirectTo)
		  this.props.onRedirect();
		}
		const showlogout=(nextProps.member_name!='' && nextProps.member_name!=null && nextProps.member_name!='undefined')?true:this.state.showlogout
		//this.setState({locales:nextProps.languages,member_name:nextProps.member_name,showlogout:showlogout})
		this.setState({locales:nextProps.languages,member_name:nextProps.member_name,only_friend:nextProps.only_friend})
	}	

	componentWillMount() {
		//-----------------------
		agent.header.getlangs().then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				this.props.onloadLangues(datas.list);
				const newState = Object.assign({}, this.state, {locales:datas.list })
				this.setState(newState)
			}
			
		})
		
		//-----------------------
		const cookies = new Cookies()
		const token = cookies.get(TOKEN_NAME)
		if(token){			
			agent.setToken(token)
			this.props.onLoad( token)       
		}
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({'language':language})
		//----------------------------
		let login=window.localStorage.getItem(TOKEN_NAME);
		//let NumberofNewNotifications=this.state.NumberofNewNotifications 
		 if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({member_name:login.UserInfo.tipster_name,showlogout:true,loginId:login.UserInfo.id,url:login.UserInfo.url_username})
			 this.props.setMember(login.UserInfo.tipster_name)
			this.getSocket(login.UserInfo.id)
			this.newNumber()
			window.addEventListener('click', this.checksuspend);
			this.contestInfo();
		 }else{
			window.localStorage.setItem( TOKEN_NAME, '');
			this.setState({showlogout:false}) 
			this.props.setMember('')
			//this.props.onTabClick('home')
		 }
		
		let only_friend=window.localStorage.getItem("only_friend");
		this.setState({old_only_friend:only_friend})
		//console.log(login)
		//-----------------------
		window.addEventListener('resize', this.handleWindowSizeChange);
		
		//-----------------------
		
	}	
	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	  window.removeEventListener('click', this.checksuspend);
	}
	showFriendsBox(ev){
		$('.friens-box').show();
		$('.friend-icon-w').show();
		$('.friend-icon').hide();
		//$('.friens-top').focus();
		this.friendlist();
		
	}
	hideFriendsBox(ev){
		$('.friens-box').hide();
		$('.friend-icon-w').hide();
		$('.friend-icon').show();
	}
	friendlist(){
		agent.friends.requestlistandcanknowlist({limit:5}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.setState({friendsList:datas},()=>{
					$(window).click(function(e){
						//console.log($(e.target).closest("#test").length)
						if($(e.target).closest("#test").length==0 && $(e.target).closest("#friend-icon").length==0 ){
							$('.friens-box').hide();
							$('.friend-icon-w').hide();
							$('.friend-icon').show();
						}
					})
				})
			}
			
		  })	
	}
	searchHome(){
		const tab = this.props.tab
		//console.log(tab)
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(tab!='home'){
			this.props.history.push("/home")
		}
		//this.props.onLoadHome([]); 
		if(login!='' && login!='null' && login!=null){
			$(".imgload").show()
			let search=$(".txt-search").val();
			let only_friends=this.state.only_friend
			let limit=this.state.limit
			let offset=this.state.offset
			//console.log(only_friends)
			agent.home.gethomebetlistNew({search:search,only_friends:only_friends,limit:limit,offset:offset}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					if(data.list!='' && data.list!=null){
						this.props.onLoadHome(data.list);
					}else{
						this.props.onLoadHome(true)
					}
					$(".imgload").hide()
				}
			})
		}else{
			$(".imgload").show()
			let search=$(".txt-search").val();
			//let only_friends=this.state.only_friend
			let limit=this.state.limit
			let offset=this.state.offset
			let lang_id=this.state.lguages
			agent.home.gethomebetlist({search:search,limit:limit,offset:offset,lang_id:lang_id}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					if(data.list!='' && data.list!=null){
						this.props.onLoadHome(data.list);
					}else{
						this.props.onLoadHome(true)
					}
					$(".imgload").hide()
				}
			})
			
		}
		
	}
	tipLookup(){
		let search=$(".txt-search").val();
		agent.home.tipLookup({search:search}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({listLookup:data.list})
			}
		})
	}
	handleFriendMode(e){
		let only_priend=(e.target.checked==true)?1:'0'	
		this.setState({only_friend:only_priend,old_only_friend:only_priend},()=>{
			window.localStorage.setItem('only_friend',only_priend)
			this.props.setFiendMode(only_priend)
			this.searchHome();
		})
	}
	detechAccount(e) {
		e.preventDefault();
		if(alert('Are you sure?')) {
		  console.log('Bye bye!');
		  return true;
		}
		return false;
	}
	getList(){
		let offset_nf=this.state.offset_nf
		let limit_nf=this.state.limit_nf
		agent.notification.getList({offset:offset_nf,limit:limit_nf}).then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				//this.setState({listNF:datas.list})
				const list = this.state.scrollM ? this.state.listNF.concat(datas.list):datas.list
				const loadedAll = datas.list.length < this.state.limit_nf ? true: false
				const newState = Object.assign({}, this.state, { listNF: list, loadedAll: loadedAll, scrollM: false})
				this.setState(newState)	
			}
			
		})
	}
	
	render() {
		const token = this.props.token
		const tab = this.props.tab
		const locales = this.state.locales?this.state.locales:'';
		const search = localizeKey(menu)('search', this.state.language);
		//if(token && tab !== 'baseline' && tab !== 'page-not-found'){
		const active = this.props.active
		const member_name=this.state.member_name||''
		const loginId=this.state.loginId||''
		//console.log(tab)
		//--------
		const responseFacebook = (response) => {
		    //console.log(response);
		    this.signinF(response);
		}
		//---------
		const responseGoogle = (response) => {
		    //console.log(response);
		    this.signinG(response);
		}
		//---------
		let userlg=window.localStorage.getItem( TOKEN_NAME)
		let usertype='';let userRole='';
		if(userlg!=='' && typeof userlg !== 'undefined' && userlg != null ){
			userlg = $.parseJSON(userlg) ;
			const userInfo=userlg.UserInfo
			usertype=userInfo.usertype
			userRole=userInfo.role_id
		}
		//let prName=member_name.split(' ').join('.');
		//prName=prName.toLowerCase()+"."+loginId
		let urlP=''
		//console.log(this.state.url)
		if(this.state.url!='' && this.state.url!='null' && this.state.url!=null){
			let prName=this.state.url.split("/");
			 urlP=prName[prName.length-1];
		}else{
			 urlP='';
		}
		let listLookup=this.state.listLookup
		//console.log(loginId)
		
		//---------
		if(tab!='active'){
			return (
				<div id="">
					<div className="head-page">
						<div className="menu-top-fix">
							<div className="container">
							
								<div className="item pull-right">
									<div className="language">						
										<select id="selectLang" className="ctn" value={this.state.language} onChange={this.changeLG}>
												{
													(locales!='' && locales.length>0)?
													locales.map(loc => (
													<option key={loc.id} value={loc.id}  defaultValue={this.state.language}> {loc.display_name} </option>
													)):''
												}
										</select>
										
										
									</div>
								</div>
								{
									//---onClick={this.handleClickTab('mysetting')} to={"/profile/"+prName}
								}
								{
								(member_name!='' && member_name!=null)?
								<div>
									<div className="line"></div>
									<div className="item top-friend">
										<span className="friend-icon" id="friend-icon" onClick={this.showFriendsBox.bind(this)}></span>
										{(member_name!='' && this.state.NumberofNewFriendRequests>0)?<span className="badge badge-default">{this.state.NumberofNewFriendRequests}</span>:''}
										<span className="friend-icon-w" onClick={this.hideFriendsBox.bind(this)} style={{'display':'none'}}></span>
										<div className="friens-box" ><FriendsBox friendsList={this.state.friendsList}/></div>
									</div>
									
									<div className="line" ></div>
									<div className="item pull-right">
									
										<div className="msg msg-nofi">
											
											<DropdownMenu triggerType='icon' trigger='glyphicon glyphicon-bell' fadeIn="true" position='left'>
												<div className="noti-box" >
												
												{
													(member_name!='')?
														<Notification listNF={this.state.listNF} onScrollM={this.onScrollM} searchHomeFromPickcode={this.searchHomeFromPickcode} newNumber={this.newNumber}/>:''
												}												
											</div>
											</DropdownMenu>	
											{
												//<i className="fa fa-bell-o" style={{'color':'white'}}></i>
												(member_name!='' && this.state.NumberofNewNotifications>0)?<span className="badge badge-default">{this.state.NumberofNewNotifications}</span>:''
											}
											
										</div>
									
									</div>
								</div>:''
								}
								<div className="line" style={{display:''}}></div>
								<div className="item pull-right">
									<div className="login-top"> 
										<div className="login-partial">
												<ul>												
													<li>
													{
														(member_name!='')?
														<a href="#" className="user" onClick={this.userClick(urlP)} ><span>{member_name}</span></a>:
														<a href="#" className="login" onMouseEnter={this.showformlogin1} onClick={this.showformlogin.bind(this)} ><span>{localizeKey(menu)('login', this.state.language)}</span></a>
													}
														
														
													</li>
												</ul>
										</div>
										{
											//---
										
										}
										<div className="dropdown-content box-login" style={{'overflow':'hidden','display':'none'}}>
											<h1>
												<span className="txt">{localizeKey(menu)('login', this.state.language)}</span>
											</h1>
											<div className="content" onMouseLeave={this.hideFormlogin1}>   
												<div className="input-group">
													<span className="input-group-addon">
														<i className="fa fa-user"></i>
													</span>
													<input className="form-control" placeholder={localizeKey(header)('email', this.state.language)} type="text" id="user_name" onChange={this.updateField('user_name')}/>
												</div>
												<div className="input-group">
													<span className="input-group-addon">
														<i className="fa fa-key"></i>
													</span>
													<input className="form-control" placeholder={localizeKey(header)('password', this.state.language)} type="password" name="pass_word" id="pass_word" onChange={this.updateField('password')} onKeyPress={this.doKeySearch}/>
												</div>
												<div className="input-group">
													<div className="checkbox">
														<input type="checkbox" id="signin_remember" name="signin_remember"/>
														<span>{localizeKey(menu)('rememnerMe', this.state.language)}</span>
													</div>
												</div>
												<button id="btn_login" className="btn btn-sm" onClick={this.login}>{localizeKey(header)('lgUs', this.state.language)}</button>
												<a className="forgot-password" title={localizeKey(menu)('forgotpass', this.state.language)} href="./forgot-password">{localizeKey(menu)('forgotpass', this.state.language)}</a>
												<div>
													<div className="externall-login">
														
														<div className="item-ex">
														{
															//<a className="login-google">
																//<span className="fa fa-face">g<b>+</b> </span><span>{localizeKey(header)('lgGg', this.state.language)}</span>
															//</a>
														}
															<a className="login-google">
																<span className="fa fa-face">g<b>+</b> </span>
																<GoogleLogin
																	//clientId="1097469557398-2jj3g8bb3qku1l98qvrs6p5o02bo22bl.apps.googleusercontent.com"
																	clientId="297127343422-18fj0lh0i70ep2du87k7sa211bldql3f.apps.googleusercontent.com"
																	buttonText={localizeKey(menu)('lgGg', this.state.language)}
																	onSuccess={responseGoogle}
																	onFailure={responseGoogle}
																/>
															</a>
														</div>
													</div>
												</div>
												<div className="new-account">
													<span className="lbl">{localizeKey(menu)('txsg', this.state.language)}</span><br/>
													<a onClick={this.siginAc('register')}>{localizeKey(header)('sigin', this.state.language)}</a>
													
												</div>
												
												
												
											</div>
										</div>
										{
											//---
										}	
									</div>
								</div>
								
								
								{
									/*
									<div className="line"></div>
									<div className="item pull-right">
										<a className="shopping-cart"></a>
									</div>
									<div className="item pull-right">
										<a href="" className="link face">f</a>
									</div>
									*/
								}
								<div className="bg-search">    
									<div className="left" style={{'marginLeft':(usertype==="admin")?'335px':'345px'}}>
										<div style={{'position':'relative'}}>  
											<input type="text" name="keyword" className="txt-search" placeholder={search} onKeyPress={this.doSearchHome}  list="search-list"/>
											<button type="button" className="bt-search" onClick={this.searchHome}></button>
											<input name="pageSize" type="hidden" value="16"/>
											<input name="pageIndex" type="hidden" value="1"/>
											<datalist className="datalist" id="search-list" open="open">
											{
												(listLookup!='' && listLookup!='null')?listLookup.map((data,idx)=>{
													return (<option id="" value={data} label="" key={idx}></option>)
												}):''
											}
												
											</datalist>
										</div>        
									</div>
								</div>
								
								
							</div>
						</div>  
						
						{
						//---
						}
						<div className="menu-top">
							<div className="container">
								<div className="logo">
									<a href="/">
									{/*<img src="https://front1.coopbet.com/media/logo_ap.png" width="180" title="coopbet.com"/>*/}
									<img src="/media/web_coopbet_logo.png" width="290px" title="coopbet.com" style={{'marginTop':'8px'}}/>
									</a>
								</div>
								<div className="menu-setting">
								{
									(member_name!='')?
									<div className="setting">
										<a href="#" className="dropsetting"></a>										
										<ul className="s-menu">
											<li>
												<a href="">{localizeKey(menu)('drdw1', this.state.language)}</a>
												<ul className="ss-menu">
													<li>
														<Link className="friends-list" to={"/"+urlP+"?3"}>{localizeKey(menu)('subdrdw1', this.state.language)}</Link>
													</li>
													<li>
														<Link className="friends-list" to={"/management-tips"}>Quản lý tips</Link>
													</li>
													<li style={{'display':'none'}}><a href="">{localizeKey(menu)('subdrdw2', this.state.language)}</a></li>
													<li style={{'display':'none'}}><a href="">{localizeKey(menu)('subdrdw3', this.state.language)}</a></li>
												</ul>
											</li>
											<li>
												<a href="">{localizeKey(menu)('drdw2', this.state.language)}</a>
												<ul className="ss-menu">
													<li><a href="" onClick={this.handleClickTab('bankroll')}>Bankroll</a></li>
													<li><a href="" onClick={this.handleClickTab('buy-tipter')}>Mua Tips</a></li>
													<li><a href="" onClick={this.handleClickTab('mysetting')}>{localizeKey(menu)('subdrdw4', this.state.language)}</a></li>
													<li><a href="" onClick={this.myFriendClick(urlP)}>{localizeKey(menu)('frie', this.state.language)}</a></li>
													<li style={{'display':'none'}}><a href="">{localizeKey(menu)('subdrdw5', this.state.language)}</a></li>
													<li style={{'display':'none'}}><a href="">{localizeKey(menu)('subdrdw6', this.state.language)}</a></li>
													<li className="logout" style={{'display':(this.state.showlogout==false)?'none':''}}><a href="" onClick={this.handleClickLogOut('home')}>{localizeKey(menu)('subdrdw7', this.state.language)}</a></li>
												</ul>
											</li>
										</ul>
									</div>:''
									
								}
									
									<div className="add-tips" style={{'marginRight':(member_name!='')?'5px':'7px'}}>
										<Link to={(member_name!='')?'/addtips':'/login'}>{localizeKey(menu)('btndt', this.state.language)}</Link>
										{
											(member_name!='')?
											<label className="switch" title={(this.state.only_friend==1 || this.state.old_only_friend==1)?localizeKey(menu)('viewonlyFriend', this.state.language):localizeKey(menu)('viewall', this.state.language)}>
												<input type="checkbox" className="friend-mode"
													onChange={this.handleFriendMode.bind(this)}
													checked={(this.state.only_friend==1 || this.state.old_only_friend==1)?true:false}
												/>
											  <span className="slider round"></span>
											</label>:''
										}
									</div>
								</div>
								{
									(usertype==="admin")?
									<ul className="menu menu-l" style={{'marginLeft':'30px'}}>
										<li>
											<a className={active === 'tips-exchange' ? 'active' : ''} href="" onClick={this.handleClickTab('tips-exchange')}>Tips Exchange</a>
										</li>
										<li style={{'display':'none'}}>
											<a className={active === 'uploads' ? 'active' : ''} href="" onClick={this.handleClickTab('uploads')}>UPLOADS</a>
										</li>
										<li style={{'display':'none'}}>
											<a  className={active === '' ? 'active' : ''} href="" onClick={this.handleClickTab('')}>COMMENTS</a>
											
										</li>
										<li>
											<a className={active === 'tipters' ? 'active' : ''} href="" onClick={this.handleClickTab('tipters')}>TIPSTERS</a>
										</li>
										<li>
											<a className={active === 'tips' ? 'active' : ''} href="" onClick={this.handleClickTab('tips')}>TIPS</a>
										</li>
										<li>
											<a className={active === 'matchs' ? 'active' : ''} href="" onClick={this.handleClickTab('matchs')}>MATCHS</a>
										</li>
										<li>
											<a className={active === 'ads' ? 'active' : ''} href="" onClick={this.handleClickTab('ads')}>ADS</a>
										</li>
										<li>
											<a className={active === 'userlist' ? 'active' : ''} href="" onClick={this.handleClickTab('userlist')}>USERS</a>
										</li>
										<li>
											<a className={active === 'home' ? 'active' : ''} href="" onClick={this.handleClickTab('home')}>{localizeKey(menu)('menu1', this.state.language)}</a>
										</li>
									</ul>:(userRole==3)?
									<ul className="menu addB">
									
										<li style={{'display':'none'}}>
											<a className={active === 'results' ? 'active' : ''} href="" onClick={this.handleClickTab('results')}>{localizeKey(menu)('menu6', this.state.language)}</a>
										</li>
										<li style={{'display':'none'}}>
											<a className={active === 'jackpot' ? 'active' : ''} href="" onClick={this.handleClickTab('jackpot')}>{localizeKey(menu)('menu5', this.state.language)}</a>
											
										</li>
										<li>
											<a className={active === 'tipters-contest' ? 'active' : ''} href="" onClick={this.handleClickTab('tipters-contest')}>{this.state.name_contest}</a>
										</li>
										<li>
											<a className={active === 'tipters' ? 'active' : ''} href="" onClick={this.handleClickTab('tipters')}>TIPSTERS</a>
										</li>
										<li>
											<a className={active === 'bookmakers' ? 'active' : ''} href="" onClick={this.handleClickTab('bookmakers')}>{localizeKey(menu)('bookmaker', this.state.language)}</a>
										</li>
										<li>
											<a className={active === 'ad-user' ? 'active' : ''} href="#" onClick={this.handleClickTab('ad-user')}>Ads</a>
										</li>
										<li>
											<a className={active === 'home' ? 'active' : ''} href="" onClick={this.handleClickTab('home')}>{localizeKey(menu)('menu1', this.state.language)}</a>
										</li>
									</ul>:
									<ul className="menu menu-l" style={{'marginLeft':'76px'}}>
									
										<li>
											<a className={active === 'tipters-contest' ? 'active' : ''} href="" onClick={this.handleClickTab('tipters-contest')}>{this.state.name_contest}</a>
										</li>
										<li>
											<a className={active === 'contact' ? 'active' : ''} href="" onClick={this.handleClickTab('contact')}>{localizeKey(menu)('contact', this.state.language)}</a>
										</li>
										<li style={{'display':(member_name!='')?'':'none'}}>
											<a className={active === 'tipters' ? 'active' : ''} href="" onClick={this.handleClickTab('tipters')}>TIPSTERS</a>
										</li>
										
										<li>
											<a className={active === 'bookmakers' ? 'active' : ''} href="" onClick={this.handleClickTab('bookmakers')}>{localizeKey(menu)('bookmaker', this.state.language)}</a>
										</li>
										<li>
											<a className={active === 'home' ? 'active' : ''} href="" onClick={this.handleClickTab('home')}>{localizeKey(menu)('menu1', this.state.language)}</a>
										</li>
									</ul>
									
								}
								
							</div>
						</div>
						{
						//---
						}
					</div>
					{
					(tab!='profile')?
						<header style={{display:'none'}}>
							<div className="container">
								<div className="header-1">
									<span className="t-1">{localizeKey(header)('text1', this.state.language)}</span>
									<span className="t-2">{localizeKey(header)('text2', this.state.language)}</span>
								</div>
								<div className="header-2">
									<div className="we">
										<span className="lbl">{localizeKey(header)('text3', this.state.language)}</span>
										<span className="ctn">{localizeKey(header)('text4', this.state.language)}</span>
									 
										<span className="ctn">{localizeKey(header)('text5', this.state.language)}</span>
									</div>
									<div className="you">
										<span className="lbl">{localizeKey(header)('text6', this.state.language)}</span>
										<span className="ctn">{localizeKey(header)('text7', this.state.language)}</span>
									</div>
								</div>
							</div>
						</header>:''
					}
					
					{
					//----
					}
					<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
				</div>
			)			
		}else{
			return(
			<div id="">
					<div className="head-page">
						<div className="menu-top-fix">
							<div className="container">
							
								<div className="item pull-right">
									<div className="language" style={{'marginTop':'30px'}}>	
									</div>
								</div>
								
							</div>
						</div>  
						
						{
						//---
						}
						<div className="menu-top">
							<div className="container">
								<div className="logo">
									<a href="/">
										<img src={BASE_IMAGE['logo.png']} width="180" title="coopbet.com"/>
									</a>
								</div>
								
							</div>
						</div>
						{
						//---
						}
					</div>
				</div>
			)
		}
		return null;
	}
}

//export default connect(mapStateToProps, mapDispatchToProps)(App)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));