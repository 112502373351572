import React, {Component} from 'react';
import { connect } from 'react-redux';
import { localizeKey} from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import tranlate from './common/footer.json'
import { TOKEN_NAME,LANGUAGE_KEY } from '../constants/actionTypes';
import '../styles/App.css'
const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},	
});
class Footer extends Component{    
	constructor() {
        super();
		
        this.state = {	
			lguages:'1005'
        }
		//this.goToTop=()=>{
			//alert("tetstt")
			// $('html, body').animate({scrollTop:0}, 'slow');
		//}
	}
    componentWillReceiveProps(nextProps) {
		
		//console.log(nextProps.languageKey)
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	componentWillMount() {
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//console.log(cookies.get('language'))
	}	
	
    render(){        
		//const token = window.localStorage.getItem(LANGUAGE_KEY); console.log(token)
		//if(token){
			//console.log(this.state.lguages)
			return (
				<footer>
					<div className="container">
						<div className="top">
							<div className="row">
								<div className="col-md-3 links">
									<h4 className="header">{localizeKey(tranlate)('Links', this.state.lguages)}</h4>
									<div className="content">
										<ul>
												<li>
													<a href="">{localizeKey(tranlate)('Link1', this.state.lguages)}</a>
												</li>
												<li>
													<a href="">{localizeKey(tranlate)('Link2', this.state.lguages)}</a>
												</li>
												<li>
													<a href="">{localizeKey(tranlate)('Link3', this.state.lguages)}</a>
												</li>
										</ul>
									</div>
									
								</div>
								<div className="col-md-3">
									<h4 className="header">{localizeKey(tranlate)('paymentMethod', this.state.lguages)}</h4>
									<div className="content">
										<div className="item">
											<img src="https://www.coopbet.com/media/visaicon.png"/>
										</div>
										<div className="item">
											<img src="https://www.coopbet.com/media/paypalicon.png"/>
										</div>
										<div className="item">
											<img src="https://www.coopbet.com/media/mastercarticon.png"/>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<h4 className="header">{localizeKey(tranlate)('Followus', this.state.lguages)}</h4>
									<div className="content follow-us">
										<label>You Can Find Us There: https://www.coopbet.com: </label><a href="https://www.facebook.com/coopbet/">f</a>
									</div>
								</div>
								<div className="col-md-3">
									<h4 className="header">
										<a href="">{localizeKey(tranlate)('Contactus', this.state.lguages)}</a>
									</h4>
								</div>
							</div>
						</div>
						<div className="bottom">
							<span>© 2017 Coopbet.com. All rights reserved.</span>
							<img src="https://www.coopbet.com/media/18.gif" style={{'cursor':'pointer'}}/>
							
							
						</div>
					</div>
				</footer>		
			);			
		//} else {
		//	return null;
		//}

    }
}

//export default Footer;

export default connect(mapStateToProps, mapDispatchToProps)(Footer)