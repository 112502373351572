import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import {CONTACT_LOADER,LANGUAGE_KEY,CHANGE_TAB,TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'
import $ from 'jquery';
import Captcha from './formCaptcha.js'
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: CONTACT_LOADER })  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Contact extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',fullName:'',Company:'',address:'',phone:'',email:'',website:'',message:'',dorepas:true,isOpenModal:false
		}
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.updateCaptcha=this.updateCaptcha.bind(this) 
		this.sendContact=()=>{
			let state=this.state;
			agent.auth.contactCreate({
				full_name:state.fullName,company:state.Company,
				address:state.address,phone:state.phone,
				email:state.email,website:state.website,message:state.message,
			}).then((datas) => {
			//console.log(datas)
				if(datas.result==='good'){
					let msg='Thank you ! We will contact you soon.'
					let thiss=this;
					this.setState({msgRigister:msg,isOpenModal:true,
							fullName:'',Company:'',address:'',phone:'',email:'',
							website:'',message:''})
				}else{
					this.setState({msgRigister:datas.message,isOpenModal:true})
				}
		   })
		}
  }
		//---
	componentWillMount(){		
		this.props.onLoad('contact')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({lguages:language})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//console.log(login)
		if(login!='' && login!='null' && login!=null){
		}
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			//this.getbookMaker(nextProps.languageKey)
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
    }

    componentWillUnmount() {
    }
	handleChangeFiled(feild,ev){
		//console.log(ev.target.value)
		this.setState({[feild]:ev.target.value})
	}
	updateCaptcha(vale){
		//console.log(vale)  
		this.setState({captcharValue:vale})
		if(vale!='' && vale!=null && vale!="null"){
			this.setState({dorepas:false})
		}else{
			this.setState({dorepas:true})
		}
	}
	render(){   
		//console.log(this.state.lguages)
		let list=this.state.List
        return (
			<div id="body">   
				<div className="heder-page" style={{'padding':'20px 0'}}>
					<div className="container" style={{'marginTop':'80px'}}>
						<div className="item lbl-1">
							<h1>
								<span className="txt">Contact</span>
							</h1>
						</div>									
					</div>
				</div>
				<div className="container">
					<Row>
						<Col md={12} style={{'color':'#777','marginBottom':'10px'}}>Please fill the form below.</Col>
					</Row>
					<Row>
						<Col md={1}><label className="lbl-contact">Full Name:</label></Col>
						<Col md={8}>
							<input name="Name" type="text" placeholder="" className="form-control input-contact" value={this.state.fullName} onChange={this.handleChangeFiled.bind(this,'fullName')}/>
						</Col>
					</Row>
					<Row>
						<Col md={1}><label className="lbl-contact">Company:</label></Col>
						<Col md={8}>
							<input name="Company" type="text" placeholder="" className="form-control input-contact" value={this.state.Company} onChange={this.handleChangeFiled.bind(this,'Company')}/>
						</Col>
					</Row>
					<Row>
						<Col md={1}><label className="lbl-contact">Address:</label></Col>
						<Col md={8}>
							<input name="address" type="text" placeholder="" className="form-control input-contact" value={this.state.address} onChange={this.handleChangeFiled.bind(this,'address')}/>
						</Col>
					</Row>
					<Row>
						<Col md={1}><label className="lbl-contact">Phone:</label></Col>
						<Col md={8}>
							<input name="phone" type="text" placeholder="" className="form-control input-contact" value={this.state.phone} onChange={this.handleChangeFiled.bind(this,'phone')}/>
						</Col>
					</Row>
					<Row>
						<Col md={1}><label className="lbl-contact">Email:</label></Col>
						<Col md={8}>
							<input name="email" type="text" placeholder="" className="form-control input-contact" value={this.state.email} onChange={this.handleChangeFiled.bind(this,'email')}/>
						</Col>
					</Row>
					<Row>
						<Col md={1}><label className="lbl-contact">Website:</label></Col>
						<Col md={8}>
							<input name="website" type="text" placeholder="" className="form-control input-contact" value={this.state.website} onChange={this.handleChangeFiled.bind(this,'website')}/>
						</Col>
					</Row>
					<Row>
						<Col md={8}><label className="lbl-contact">Message:</label></Col>
						<Col md={9}>
							<textarea name="message" rows="5" placeholder="" className="form-control" value={this.state.message} onChange={this.handleChangeFiled.bind(this,'message')}/>
						</Col>
					</Row>
					<Row style={{'marginTop':'10px'}}>
						<Col md={4}>
							<Captcha captcha={this.updateCaptcha} />
						</Col>
						<Col md={5} style={{'textAlign':'right','marginTop':'15px'}}><input type="submit" className="btn-sigin" value="SEND" disabled={this.state.dorepas}  style={{'fontSize':'14px'}} onClick={this.sendContact}/></Col>
					</Row>
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)