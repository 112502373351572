
import agent from './agent';
import {
  LOGIN,
  LOGOUT
} from './constants/actionTypes';

import { TOKEN_NAME } from './constants/actionTypes'


const localStorageMiddleware = store => next => action => {
  if ( action.type === LOGIN && action.data.LOGIN_STATUS !== 'FAIL') {
      const userInfo = action.data
	  //userInfo.time_get_token = Date.now()
      window.localStorage.setItem( TOKEN_NAME, JSON.stringify(userInfo) );
      //agent.setToken(action.data.assess_token);
  } else if (action.type === LOGOUT) {      //LOGOUT
		window.localStorage.setItem( TOKEN_NAME, '');
		agent.setToken(null);
  }

  next(action);
};


export { localStorageMiddleware }