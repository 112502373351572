import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button,Tabs,Tab,Table,Radio} from 'react-bootstrap'
import {TIPSTERS_LOADER,LANGUAGE_KEY,CHANGE_TAB,TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import ModalErorr from '../common/ModalErorr'
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
//import Avatar from 'react-avatar';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
//import UploadInvoice from '../common/UploadInvoice'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: TIPSTERS_LOADER })  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Tipter extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',idUser:'',isOpenModal:false,msg:'',
			startDate:new Date(),month:'',seller_open_order_list:[],
			isOpen:false,order_id:'',seller_paid_order_list:[],keyTab:'key1',
			price:0,isLoadingBuy:false,
		}
		
		
		this.sellerapprove=(order_id)=>{
			agent.orders.sellerapprove({order_id:order_id}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({keyTab:'key2'},()=>{
						this.getListForSellerAgain();
					})
					
				}
			})
			//this.setState({keyTab:'key2'})
			//$("#tab-buy-tab-key2").click();
		}
		this.getListForSellerAgain=()=>{
			let month = this.state.month
			agent.orders.getListForSeller({month:month}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					let PriceTips=data.PriceTips
					let seller_open_order_list=data.seller_open_order_list
					let seller_paid_order_list=data.seller_paid_order_list
					this.setState({seller_open_order_list:seller_open_order_list,
								   seller_paid_order_list:seller_paid_order_list,
								   price:PriceTips
								  })
				}
			})
		}
		this.getListForSeller=()=>{
			let month = this.state.month
			agent.orders.getListForSeller({month:month}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					let PriceTips=data.PriceTips
					let seller_open_order_list=data.seller_open_order_list
					let seller_paid_order_list=data.seller_paid_order_list
					this.setState({seller_open_order_list:seller_open_order_list,
								   seller_paid_order_list:seller_paid_order_list,
								   price:PriceTips
								   })
				}
			})
		}
		this.vieImg=(id,e) => {
			
			let letms=this.state.seller_open_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=true
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({seller_open_order_list:letms})
			
		}
		this.hideImg=(id,e) => {
			
			let letms=this.state.seller_open_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=false
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({seller_open_order_list:letms})
			
		}
		this.vieImgP=(id,e) => {
			
			let letms=this.state.seller_paid_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=true
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({seller_paid_order_list:letms})
			
		}
		this.hideImgP=(id,e) => {
			
			let letms=this.state.seller_paid_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=false
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({seller_paid_order_list:letms})
			
		}
		this.onPenModalUp=(order_id,e)=>{
			this.setState({order_id:order_id,isOpen:true,isOpenModal:false})
		}
		this.viewError=(msg)=>{
			this.setState({isOpenModal:true,isOpen:false,msg:msg})
		}
		this.callTab=(tab)=>{
			this.setState({keyTab:tab})
		}
		this.priceMonthSave=()=>{
			this.setState({isLoadingBuy:true})
			let price=this.state.price
			let date=new Date();
			let mt=date.getMonth()+1;
			let year=date.getFullYear();
			let month=mt+"/"+year;
			agent.home.priceMonthSave({price:price,month :month }).then(data => {
			//console.log(data)
				if(data.result === 'good'){
					
				}else{
					this.setState({isOpenModal:true,msgLogin:data.message})
				}
				this.setState({isLoadingBuy:false})
			})
		}
  }
		//---
	componentWillMount(){		
		this.props.onLoad('tipters')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({lguages:language},()=>{
			//this.getrandomad();
		})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//console.log(login)
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id})
		}
		let dates=new Date();
		let mth=dates.getMonth()+1
		let year=dates.getFullYear()
		let month=mth+"/"+year
		this.setState({month:month},()=>{
			this.getListForSeller();
		})
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	  //document.getElementById("text1").innerText =''
    }

    componentWillUnmount() {
       
    }
	handleChangeDate(date){
		let dates=date.getDate();
		var month = date.getMonth() + 1;
		let year=date.getFullYear();
		let mth=month+"/"+year
		this.setState({startDate:date,month:mth},()=>{
			this.getListForSeller();
		})
	}
	handleChangeFiled(e){
		let val=e.target.value.split(',').join('');	
		//console.log(e.target.value)
		this.setState({price: val})
	}
	numberFormat(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	render(){   
		//console.log(this.state.keyTab)
		const listOpen=this.state.seller_open_order_list
		const listPaid=this.state.seller_paid_order_list
        return (
			<div id="body">   
				<div className="heder-page" style={{'padding':'24px 0'}}>
					<div className="container">
						<div className="item lbl-1 lef-text">
							<h1 id="text1">
								<span className="txt">Tips management</span>
							</h1>
						</div>
						<div className="ri-tool">
							<div className="left-ft-admth">
								<input id="price_mth" name="price" type="text" className="form-control" value={this.numberFormat(this.state.price)} onChange={this.handleChangeFiled.bind(this)} />
								<span className="unit-price">$/MONTH</span>
								<span className="sym1">|</span>
								<i className="fas fa-shopping-cart"></i>
							</div>
							<div className="">
								<Button
									id="btn-save-month-buy"
									bsStyle="primary"
									disabled={this.state.isLoadingBuy}
									onClick={!this.state.isLoadingBuy ? this.priceMonthSave : null}>
									{this.state.isLoadingBuy ? <span style={{'fontSize':'14px'}}><i className='fa fa-spinner fa-spin '></i> SAVE</span> :<span>SAVE </span>}
								</Button>
							</div>
						</div>	
					</div>
				</div>
				<div className="tipster-list">
					<div className="container">
						<Row>
							<Col md={2}></Col>
							<Col md={8}>
								
							</Col>
							<Col md={2} className="rank-mth" style={{'marginBottom':'-50px'}}>
								<DatePicker
								  selected={this.state.startDate}
								  onChange={this.handleChangeDate.bind(this)}
								  dateFormat="MM/yyyy"
								  showMonthYearPicker
								  
								/>
								<i className="fa fa-caret-down icon-caret"></i>
							</Col>
						</Row>
						<Row id="rank-tipter" >
							<Tabs defaultActiveKey={this.state.keyTab} id="tab-buy" className="tab-rank" onSelect={this.callTab.bind(this)}>
								<Tab eventKey="key1" title="OPEN" >									
									<Row>										
										<Col md={12} className="pd0">
											<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
												<thead>
													<tr>
														<th colSpan="1" style={{'width':'5%'}}>No.</th>
														<th colSpan="1" style={{'width':'15%'}}>Mã đơn hàng</th>
														<th colSpan="1" style={{'width':'15%'}}>Price</th>
														<th colSpan="1" style={{'width':'20%'}}>Khách hàng</th>	
														<th colSpan="1" style={{'width':'15%','textAlign':'center'}}>Order Date</th>
														<th colSpan="1" style={{'width':'10%'}}>Method</th>	
														<th colSpan="1" style={{'width':'10%','textAlign':'center'}}>Invoice file</th>
														<th colSpan="1" style={{'width':'20%','textAlign':'center'}}>Approve</th>
													</tr>
												</thead>
												<tbody>
												{
													(listOpen!=='')?listOpen.map((dt,idx)=>{
														let orderDate=dt.order_datetime
														orderDate=orderDate.split(" ")
														let file=dt.proof_of_payment_url
														var res = (file!==null)?'...'+file.substring(file.length-12):'';
														return(
														<tr key={idx}>
															<td>0{idx+1}</td>
															<td><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
															<td>{dt.PriceTips}</td>
															<td>{dt.buyer_tipster_name}</td>
															<td style={{'textAlign':'center'}}>{orderDate[0]}</td>
															<td>{dt.seller_bank_type_name}</td>
															<td style={{'textAlign':'center','color':'#c90a0a'}}>
																
																<a className="cusor" onClick={this.vieImg.bind(this,dt.order_id)}>{res}</a>
																	{
																		dt.isOpenIM && (
																		  <Lightbox
																			mainSrc={dt.proof_of_payment_url}
																		   onCloseRequest={this.hideImg.bind(this,dt.order_id)}
																			
																		  />
																	
																		)
																	}
															</td>
															<td style={{'textAlign':'center'}}><input type="button" className="btn-search btn-approve" value="Approve" onClick={this.sellerapprove.bind(this,dt.order_id)}/></td>
														</tr>	
														)
													}):''
												}												
												</tbody>
											</Table>
										</Col>
									</Row>
								</Tab>
								<Tab eventKey="key2" title="PAID">								
									<Row>										
										<Col md={12} className="pd0">
											<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
												<thead>
													<tr>
														<th colSpan="1" style={{'width':'5%'}}>No.</th>
														<th colSpan="1" style={{'width':'15%'}}>Mã đơn hàng</th>
														<th colSpan="1" style={{'width':'15%'}}>Price</th>
														<th colSpan="1" style={{'width':'20%'}}>Khách hàng</th>	
														<th colSpan="1" style={{'width':'15%','textAlign':'center'}}>End Date</th>
														<th colSpan="1" style={{'width':'15%'}}>Method</th>	
														<th colSpan="1" style={{'width':'15%','textAlign':'center'}}>Invoice file</th>
														
													</tr>
												</thead>
												<tbody>
												{
													(listPaid!=='')?listPaid.map((dt,idx)=>{
														let orderDate=dt.order_enddate
														orderDate=orderDate.split(" ")
														let file=dt.proof_of_payment_url
														var res = (file!==null)?'...'+file.substring(file.length-12):'';
														return(
														<tr key={idx}>
															<td>0{idx+1}</td>
															<td><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
															<td>{dt.PriceTips}</td>
															<td>{dt.buyer_tipster_name}</td>
															<td style={{'textAlign':'center'}}>{orderDate[0]}</td>
															<td>{dt.seller_bank_type_name}</td>
															<td style={{'textAlign':'center','color':'#c90a0a'}}>
																
																<a className="cusor" onClick={this.vieImgP.bind(this,dt.order_id)}>{res}</a>
																	{
																		dt.isOpenIM && (
																		  <Lightbox
																			mainSrc={dt.proof_of_payment_url}
																		   onCloseRequest={this.hideImgP.bind(this,dt.order_id)}
																			
																		  />
																	
																		)
																	}
															</td>
															
														</tr>	
														)
													}):''
												}												
												</tbody>
											</Table>
										</Col>
									</Row>
								</Tab>
								
							</Tabs>
						</Row>
					</div>
				</div>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tipter)