import React, {Component} from 'react'
import { connect } from 'react-redux'
//import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import {AD_LONG_DESCPT} from '../../constants/actionTypes'
import agent from '../../agent'
//import languages from '../../languages'
//import translations from '../common/captionhome.json';
//import { Cookies } from 'react-cookie'
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import Header from '../../components/Header';
import Avatar from 'react-avatar';
import UploadInvoice from '../common/UploadInvoice'
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey,
		ad_long_description: state.common.ad_long_description
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'RUNNING_LOADER', data})  
	},
	
});



 


class NewInfo extends Component{ 	
	constructor(){
		super()
		this.state = {
			order_id:'',order_info:{},SellerBankInfo:{},isOpenModal:false,isOpen:false,
			msg:'',
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,isOpen:false})
		}
		this.getOrderInfo=(id)=>{
			agent.home.getOrderInfo({order_id:id,}).then(data => {
				if(data.result=="good"){					
					this.setState({order_info:data.order_info,SellerBankInfo:data.order_info.SellerBankInfo})
				}else{
					
				}
			})
		}
		this.goUploadrecept=()=>{
			//window.location='/upload-receipt/'+this.state.order_id
			this.setState({isOpenModal:true,isOpen:false})
		}
		this.viewError=(msg)=>{
			this.setState({isOpenModal:false,isOpen:true,msg:msg})
		}
		
  }
		//---
	componentWillMount(){
		this.props.onLoad('running');
		let id=this.props.match.params.id
		this.setState({order_id:id})
		this.getOrderInfo(id)
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	componentDidMount() {
      // let content = document.getElementById('my-content');
	  // let title=content.firstElementChild
		//console.log($(title).text());
		//this.setState({title:$(title).text()})
    }
	
    componentWillUnmount() {
       
    }
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	}
	numberFormat(x) {
		if(x>0){
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}else return;
	}
	render(){   
		let order_info=this.state.order_info
		let SellerBankInfo=this.state.SellerBankInfo
		//let SellerBankInfo=(order_info!=='' && order_info!==null)?order_info.SellerBankInfo:{}
        return (
			<div>
				<Header/>
				<div id="body">   
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">BUY TIPS</span>
								</h1>
							</div>	
							
						</div>
					</div>
					<div className="tipster-list">
						<div className="container">
							<Row className="mg0">
								<Col md={12} id="my-content" className="div-bdr">
									<Row className="mg0">
										<Col md={12} id="buy-tip-ct" className="di-ct-iv">
											<Row className="mg0">
												<Col md={1} className="pd0 img-buy-tip">
													<figure>
														<a>
														{
															(order_info.seller_imageurl!=null && order_info.seller_imageurl!='')?<img src={order_info.seller_imageurl}/>:
															<Avatar size="75" round={true} className="avatar-title" color={this.generateColor()}
																	name={order_info.seller_tipster_name}/> 
														}
															
														</a>
													</figure>
												</Col>
												<Col md={3} className="left-ct-buy">
													<h4 className="name-tips">{order_info.seller_tipster_name}</h4>
													<div className="lh22"><b>Lợi nhuận:</b> {order_info.seller_profit}</div>
													<div className="lh22"><b>Lợi nhuận %:</b> {order_info.seller_profitpercentage}</div>
												</Col>
												<Col md={4} className="ct-invoice">
													<h4 className="inv-title">Invoice</h4>
													<div className="inv-no">No.: {order_info.order_number}</div>
												</Col>												
												<Col md={4} className="fl-r">
													<h4 className="txt-cst">{order_info.PriceTips}$</h4>
													<h4 className="txt-cst pr-txt-r">{this.numberFormat(order_info.PriceTips_VND)} VNĐ</h4>
													<div className="name-buyer">Buyer: {order_info.buyer_tipster_name}</div>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="mg0 line-1px"></Row>
									<Row className="ct-r-1">
										<Col md={4}></Col>
										<Col md={6} className="txt-al-r">
											<div className="txt-b">Thông tin chuyển khoản</div>
											<Row className="ct-name-pson">
												<Col md={4} className="txt-tk-l">Tên tài khoản</Col>
												<Col md={8} className="txt-tk-r">
													<i className="far fa-copy ic-cpi"></i>
													<label className="name-per-iv">{(SellerBankInfo!==null && SellerBankInfo!=='')?SellerBankInfo.bank_user:''}</label>
												</Col>
											</Row>
											<Row className="ct-name-pson">
												<Col md={4} className="txt-tk-l">Số tài khoản</Col>
												<Col md={8} className="txt-tk-r">
													<i className="far fa-copy ic-cpi"></i>
													<label className="name-per-iv">{(SellerBankInfo!==null && SellerBankInfo!=='')?SellerBankInfo.bank_no:''}</label>
												</Col>
											</Row>
											<Row className="ct-name-pson">
												<Col md={4} className="txt-tk-l">Ngân hàng</Col>
												<Col md={8} className="txt-tk-r">
													<i className="far fa-copy ic-cpi"></i>
													<label className="name-per-iv">{(SellerBankInfo!==null && SellerBankInfo!=='')?SellerBankInfo.bank_name:''}</label>
												</Col>
											</Row>
											<Row className="ct-name-pson">
												<Col md={4} className="txt-tk-l">Số tiền</Col>
												<Col md={8} className="txt-tk-r">
													<i className="far fa-copy ic-cpi"></i>
													<label className="name-per-iv">{this.numberFormat(order_info.PriceTips_VND)} vnd</label>
												</Col>
											</Row>
											<Row className="ct-name-pson">
												<Col md={4} className="txt-tk-l">Mã chuyển tiền</Col>
												<Col md={8} className="txt-tk-r">
													<i className="far fa-copy ic-cpi"></i>
													<label className="name-per-iv">{order_info.order_number}</label>
												</Col>
											</Row>
										</Col>
										<Col md={2} className="txt-r-r">{order_info.created_at}</Col>
									</Row>
								</Col>
								<Col md={12} className="iv-ft">
									<Button
										id="btn-send-iv"
										bsStyle="primary"
										onClick={this.goUploadrecept.bind(this)}
									>
										<span>Gửi bằng chứng thanh toán</span>	
									</Button>
								</Col>
							</Row>
						</div>
					</div>
				</div>
				<ModalErorr isOpen={this.state.isOpen} close={this.close} text={this.state.msg}/>
				<UploadInvoice isOpen={this.state.isOpenModal} close={this.close} 
				               order_id={this.state.order_id} viewError={this.viewError}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInfo)