import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Link } from 'react-router-dom'
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/tips.css'
import $ from 'jquery';
import {Col, Row, FormControl, FormGroup, Radio, Checkbox,Table, ControlLabel,ButtonToolbar,DropdownButton,MenuItem,Button,
		Tabs,Tab,Label} from 'react-bootstrap'
import NumericInput from 'react-numeric-input';
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,ADDTIPS_PAGE_LOADER} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import menu from '../common/menu.json'
import { Cookies } from 'react-cookie'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: ADDTIPS_PAGE_LOADER })  
	},
});



 


class selectCompetition extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			filterdate:'',key:1,
			maingroups:[],othergroups:[],currentdate:'',leagueids:'',loading:false,sport_tips:2
			
		}
		
		
		//---
		this.handleSelect = this.handleSelect.bind(this);
		//---
		
	}
	
	leagueListtobet(){
		this.setState({loading:true})
		let filterdate=this.state.filterdate
		const sport_tips=this.state.sport_tips;
		agent.tips.leagueListtobet({filterdate:filterdate,sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				let maingroups=(data.maingroups!=null)?data.maingroups.map(dt=>{
					dt.show=1;
					return dt
				}):[]
				let othergroups=(data.othergroups!=null)?data.othergroups.map(dt=>{
					dt.show=0;
					return dt
				}):[]
				this.setState({othergroups:othergroups,maingroups:maingroups,loading:false})
			}
		})
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	componentWillMount(){		
		this.props.onLoad('addtips')
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//--
		let date = new Date();
		//let dateFormat=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
		let dateFormat='today'
		let spt=window.localStorage.getItem("SportTIP")
		let sport_tips=(parseInt(spt)>0)?parseInt(spt):this.state.sport_tips
		this.setState({filterdate:dateFormat,currentdate:dateFormat,sport_tips:sport_tips},()=>{
			this.leagueListtobet();
		})
		//--
		
		
	}
	CollapseList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').hide();
		$(thiss).find('.collapse').hide();
		$(thiss).find('.expand').show();
	}
	ExpandList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').show();
		$(thiss).find('.collapse').show();
		$(thiss).find('.expand').hide();
	}
	handleExpandOrCollapan(show,maingroup_order,ev){
		if(show>0){
			let maingroups=(this.state.maingroups!=null)?this.state.maingroups.map(dt=>{
					if(maingroup_order==dt.maingroup_order){
						dt.show=0;
					}
					return dt
				}):[]
			this.setState({maingroups:maingroups})
			this.CollapseList(ev);
			
		}else{
			let maingroups=(this.state.maingroups!=null)?this.state.maingroups.map(dt=>{
					if(maingroup_order==dt.maingroup_order){
						dt.show=1;
					}
					return dt
				}):[]
			this.setState({maingroups:maingroups})
			this.ExpandList(ev);
		}
		
	}
	handleExpandOrCollapanOrthe(show,maingroup_order,ev){
		if(show>0){
			let othergroups=(this.state.othergroups!=null)?this.state.othergroups.map(dt=>{
					if(maingroup_order==dt.maingroup_order){
						dt.show=0;
					}
					return dt
				}):[]
			this.setState({othergroups:othergroups})
			this.CollapseList(ev);
			
		}else{
			let othergroups=(this.state.othergroups!=null)?this.state.othergroups.map(dt=>{
					if(maingroup_order==dt.maingroup_order){
						dt.show=1;
					}
					return dt
				}):[]
			this.setState({othergroups:othergroups})
			this.ExpandList(ev);
		}
		
	}
	
	gofilter(){
		$('.tab-content').find('.box-legue input:checked').each(function(e){
			console.log($(this).attr("league_id"))
		})
	}
	 handleSelect(key) {
		//alert(`selected ${key}`);
		let filterdate='';
		if(key==1){
			let date = new Date();
			//filterdate=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
			filterdate='today'
		}else if(key==2){
			var d = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
			//filterdate=parseInt(d.getFullYear())+'-'+parseInt(d.getMonth()+1)+"-"+parseInt(d.getDate())
			filterdate='tomorrow'
		}else if(key==3){
			
			filterdate='all'
		}
		
		this.setState({ key:key,filterdate:filterdate},()=>{
			this.leagueListtobet();
		});
	  }
	 handleEditCheck(ev){
		//console.log(ev.target)
		let thiss=ev.target.closest('.bet-items')
		if(!ev.target.checked==true){
			$(thiss).find('.chk-all input').prop("checked",false)
		}
		let leagueids='';
		$('.tab-content').find('.box-legue input:checked').each(function(e){
			//console.log($(this).attr("league_id"))
			leagueids=leagueids+$(this).attr("league_id")+"|"
		})
		leagueids=leagueids.substring(0,leagueids.length-1)
		leagueids=(leagueids!='')?leagueids:''
		//console.log(leagueids)
		this.setState({leagueids:leagueids})
	}
	handleCheckAll(ev){
		
		let thiss=ev.target.closest('.bet-items')
		if(ev.target.checked==true){
			$(thiss).find('.checkbox input').prop("checked",true)
		}else{
			$(thiss).find('.checkbox input').prop("checked",false)
		}
		//console.log(ev.target.checked,thiss)
		let leagueids='';
		$('.tab-content').find('.box-legue input:checked').each(function(e){
			//console.log($(this).attr("league_id"))
			leagueids=leagueids+$(this).attr("league_id")+"|"
		})
		leagueids=leagueids.substring(0,leagueids.length-1)
		leagueids=(leagueids!='')?leagueids:''
		//console.log(leagueids)
		this.setState({leagueids:leagueids})
	}
	handleCheckAllLeagues(ev){
		
		let thiss=ev.target.closest('.leagues-all')
		if(ev.target.checked==true){
			$(thiss).find('.checkbox input').prop("checked",true)
		}else{
			$(thiss).find('.checkbox input').prop("checked",false)
		}
		//console.log(ev.target.checked,thiss)
		let leagueids='';
		$('.tab-content').find('.box-legue input:checked').each(function(e){
			//console.log($(this).attr("league_id"))
			leagueids=leagueids+$(this).attr("league_id")+"|"
		})
		leagueids=leagueids.substring(0,leagueids.length-1)
		leagueids=(leagueids!='')?leagueids:''
		//console.log(leagueids)
		this.setState({leagueids:leagueids})
	}
	changeOption(sport_tips,e){
		//console.log(sport_tips)
		$(".tool-ball").removeClass("active")
		this.setState({sport_tips:sport_tips},()=>{
			this.leagueListtobet();
			//this.timemenuData();
		})
		$(e.target).closest(".tool-ball").addClass("active")
		window.localStorage.setItem("SportTIP",sport_tips)
	}
	render(){   
		
		
		const maingroups=this.state.maingroups;
		const othergroups=this.state.othergroups;
		const loading=this.state.loading;
		const sport_tips=this.state.sport_tips;
		//console.log(maingroups)

		
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">{localizeKey(menu)('leagues', this.state.lguages)}</span>
								</h1>
							</div>
							<div className="tool-r">
                            </div>
							
						</div>
					</div>
					
					<div className="container leagues-all">
						<Row className="mgbt10 ">
							<Col md={7}>
								<Row className="mg0">
									<Col md={4} xs={4} sm={4} className={(sport_tips==2)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,2)}>
										<i className="far fa-futbol icon-tool" ></i>
										<div className="tool-ball-title">{localizeKey(menu)('soccer', this.state.lguages)}</div>
									</Col>
									<Col md={4} xs={4} sm={4} className={(sport_tips==1)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,1)}>
										<i className="fas fa-basketball-ball icon-tool"></i>
										<div className="tool-ball-title">{localizeKey(menu)('basketball', this.state.lguages)}</div>
									</Col>
									<Col md={4} xs={4} sm={4} className={(sport_tips==3)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,3)}>
										<img src="https://m.coopbet.com/img/tennis_ball.png" className="img_tennis" style={{'display':'none'}}/>
										<i className="fas fa-baseball-ball icon-tool"></i>
										<div className="tool-ball-title">{localizeKey(menu)('tennis', this.state.lguages)}</div>
									</Col>
								</Row>
							</Col>
							<Col md={5}>
								<span className="chk-select-all">
									<Checkbox  onChange={this.handleCheckAllLeagues.bind(this)}>
										{localizeKey(menu)('selectall', this.state.lguages)}
									</Checkbox>
								</span>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								
							</Col>
							<Col md={12}>
								<Tabs
									activeKey={this.state.key}
									onSelect={this.handleSelect}
									id="controlled-tab-example"
								  >
									<Tab eventKey={1} title={localizeKey(menu)('todatmatch', this.state.lguages)}>
									{										
										loading ? (
											<div className="loader"></div>
										) : null
									}
									{
										(!loading)?
										<Row>
											<Row className="header-tab">
											<div className="header-title" style={{'textTransform':'uppercase'}}>{localizeKey(menu)('mainlegues', this.state.lguages)}</div>
										</Row>
										<Row id="tab-today" className="clear-fix">
											{
												(maingroups!='')?maingroups.map((data,index)=>{
													return(
														<Col md={12} className="bet-items pd0" key={index}>
															<div className="bet-league sh-item" >
																<div className="dOptin" onClick={this.handleExpandOrCollapan.bind(this,data.show,data.maingroup_order)}> 
																	<a className="league-link" href="javascript:;" >{data.group_name}</a>
																	<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
																	<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
																</div>
																<span className="chk-all">
																	<Checkbox  onChange={this.handleCheckAll.bind(this)}>
																		All
																	</Checkbox>
																</span>
															</div>
															<Col md={12} className="sh-snap-menu box-legue pd0">
																{
																	(data.leaguelist!='')?data.leaguelist.map((item,idx)=>{
																		return(
																			<Col md={6} className="" style={{'borderRight':'1px solid #ddd'}} key={idx}>
																				<Checkbox  onChange={this.handleEditCheck.bind(this)} league_id={item.league_id}>
																					{item.league_name}
																				</Checkbox>
																				<button type="button" className="btn blue-hoki btn-r">{item.match_nums}</button>
																			</Col>
																		)
																	}):''
																}
																
																
															</Col>
														 </Col>
													)
												}):<Row className="bet-items">{localizeKey(menu)('txtselectleguesnone', this.state.lguages)}</Row>
											}
											  
										</Row>
										<Row className="clear-fix header-tab">
											<div className="header-title" style={{'textTransform':'uppercase'}}>{localizeKey(menu)('orderlegues', this.state.lguages)}</div>
										</Row>
										<Row id="tab-today" className="clear-fix">
											{
												(othergroups!='')?othergroups.map((data,index)=>{
													return(
														<Col md={12} className="bet-items pd0" key={index}>
															<div className="bet-league sh-item">
																<div className="dOptin" onClick={this.handleExpandOrCollapanOrthe.bind(this,data.show,data.maingroup_order)}>
																	<a className="league-link" href="javascript:;" >{data.group_name}</a>
																	<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
																	<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} >&nbsp; </i>
																</div>	
																<span className="chk-all">
																	<Checkbox  onChange={this.handleCheckAll.bind(this)}>
																		All
																	</Checkbox>
																</span>
															</div>
															<Col md={12} className="sh-snap-menu box-legue pd0" style={{'display':'none'}}>
																{
																	(data.leaguelist!='')?data.leaguelist.map((item,idx)=>{
																		return(
																			<Col md={6} className="" style={{'borderRight':'1px solid #ddd'}} key={idx}>
																				<Checkbox  onChange={this.handleEditCheck.bind(this)} league_id={item.league_id} >
																					{item.league_name}
																				</Checkbox>
																				<button type="button" className="btn blue-hoki btn-r">{item.match_nums}</button>
																			</Col>
																		)
																	}):''
																}
																
																
															</Col>
														 </Col>
													)
												}):<Row className="bet-items">{localizeKey(menu)('txtselectleguesnone', this.state.lguages)}</Row>
											}
											  
										</Row>
										<Row className="text-center">
										{
											(this.state.leagueids!='')?<Link className="btn btn-lg green" to={'/add-tips/'+this.state.leagueids+'/'+this.state.filterdate}>GO</Link>:<Link className="btn btn-lg green" to='/addtips'>GO</Link>
										}
												
										</Row>
									</Row>:''
									}
										
									</Tab>
									<Tab eventKey={2} title={localizeKey(menu)('tomorrowmatch', this.state.lguages)}>
									{										
										loading ? (
											<div className="loader"></div>
										) : null
									}
									{
										(!loading)?
										<Row>
											<Row className="header-tab">
											<div className="header-title">{localizeKey(menu)('mainlegues', this.state.lguages)}</div>
										</Row>
										<Row id="tab-today" className="clear-fix">
											{
												(maingroups!='')?maingroups.map((data,index)=>{
													return(
														<Col md={12} className="bet-items pd0" key={index}>
															<div className="bet-league sh-item">
																<a className="league-link" href="javascript:;" >{data.group_name}</a>
																<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
																<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
																<span className="chk-all">
																	<Checkbox  onChange={this.handleCheckAll.bind(this)}>
																		All
																	</Checkbox>
																</span>
															</div>
															<Col md={12} className="sh-snap-menu box-legue pd0">
																{
																	(data.leaguelist!='')?data.leaguelist.map((item,idx)=>{
																		return(
																			<Col md={6} className="" style={{'borderRight':'1px solid #ddd'}} key={idx}>
																				<Checkbox  onChange={this.handleEditCheck.bind(this)} league_id={item.league_id}>
																					{item.league_name}
																				</Checkbox>
																				<button type="button" className="btn blue-hoki btn-r">{item.match_nums}</button>
																			</Col>
																		)
																	}):''
																}
																
																
															</Col>
														 </Col>
													)
												}):<Row className="bet-items">{localizeKey(menu)('txtselectleguesnone', this.state.lguages)}</Row>
											}
											  
										</Row>
										<Row className="clear-fix header-tab">
											<div className="header-title">{localizeKey(menu)('orderlegues', this.state.lguages)}</div>
										</Row>
										<Row id="tab-today" className="clear-fix">
											{
												(othergroups!='')?othergroups.map((data,index)=>{
													return(
														<Col md={12} className="bet-items pd0" key={index}>
															<div className="bet-league sh-item">
																<a className="league-link" href="javascript:;" >{data.group_name}</a>
																<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
																<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} >&nbsp; </i>
																<span className="chk-all">
																	<Checkbox  onChange={this.handleCheckAll.bind(this)}>
																		All
																	</Checkbox>
																</span>
															</div>
															<Col md={12} className="sh-snap-menu box-legue pd0" style={{'display':'none'}}>
																{
																	(data.leaguelist!='')?data.leaguelist.map((item,idx)=>{
																		return(
																			<Col md={6} className="" style={{'borderRight':'1px solid #ddd'}} key={idx}>
																				<Checkbox  onChange={this.handleEditCheck.bind(this)} league_id={item.league_id}>
																					{item.league_name}
																				</Checkbox>
																				<button type="button" className="btn blue-hoki btn-r">{item.match_nums}</button>
																			</Col>
																		)
																	}):''
																}
																
																
															</Col>
														 </Col>
													)
												}):<Row className="bet-items">{localizeKey(menu)('txtselectleguesnone', this.state.lguages)}</Row>
											}
											  
										</Row>
										<Row className="text-center">
										{
											(this.state.leagueids!='')?<Link className="btn btn-lg green" to={'/add-tips/'+this.state.leagueids+'/'+this.state.filterdate}>GO</Link>:<Link className="btn btn-lg green" to='/addtips'>GO</Link>
										}
												
										</Row>
									</Row>:''
									}
									  
									</Tab>
									<Tab eventKey={3} title={localizeKey(menu)('allmatch', this.state.lguages)}>
									{										
										loading ? (
											<div className="loader"></div>
										) : null
									}
									{
										(!loading)?
										<Row>
											<Row className="header-tab">
											<div className="header-title">{localizeKey(menu)('mainlegues', this.state.lguages)}</div>
										</Row>
										<Row id="tab-today" className="clear-fix">
											{
												(maingroups!='')?maingroups.map((data,index)=>{
													return(
														<Col md={12} className="bet-items pd0" key={index}>
															<div className="bet-league sh-item">
																<a className="league-link" href="javascript:;" >{data.group_name}</a>
																<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
																<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
																<span className="chk-all">
																	<Checkbox  onChange={this.handleCheckAll.bind(this)}>
																		All
																	</Checkbox>
																</span>
															</div>
															<Col md={12} className="sh-snap-menu box-legue pd0">
																{
																	(data.leaguelist!='')?data.leaguelist.map((item,idx)=>{
																		return(
																			<Col md={6} className="" style={{'borderRight':'1px solid #ddd'}} key={idx}>
																				<Checkbox  onChange={this.handleEditCheck.bind(this)} league_id={item.league_id}>
																					{item.league_name}
																				</Checkbox>
																				<button type="button" className="btn blue-hoki btn-r">{item.match_nums}</button>
																			</Col>
																		)
																	}):''
																}
																
																
															</Col>
														 </Col>
													)
												}):<Row className="bet-items">{localizeKey(menu)('txtselectleguesnone', this.state.lguages)}</Row>
											}
											  
										</Row>
										<Row className="clear-fix header-tab">
											<div className="header-title">{localizeKey(menu)('orderlegues', this.state.lguages)}</div>
										</Row>
										<Row id="tab-today" className="clear-fix">
											{
												(othergroups!='')?othergroups.map((data,index)=>{
													return(
														<Col md={12} className="bet-items pd0" key={index}>
															<div className="bet-league sh-item">
																<a className="league-link" href="javascript:;" >{data.group_name}</a>
																<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
																<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} >&nbsp; </i>
																<span className="chk-all">
																	<Checkbox  onChange={this.handleCheckAll.bind(this)}>
																		All
																	</Checkbox>
																</span>
															</div>
															<Col md={12} className="sh-snap-menu box-legue pd0" style={{'display':'none'}}>
																{
																	(data.leaguelist!='')?data.leaguelist.map((item,idx)=>{
																		return(
																			<Col md={6} className="" style={{'borderRight':'1px solid #ddd'}} key={idx}>
																				<Checkbox  onChange={this.handleEditCheck.bind(this)} league_id={item.league_id}>
																					{item.league_name}
																				</Checkbox>
																				<button type="button" className="btn blue-hoki btn-r">{item.match_nums}</button>
																			</Col>
																		)
																	}):''
																}
																
																
															</Col>
														 </Col>
													)
												}):<Row className="bet-items">{localizeKey(menu)('txtselectleguesnone', this.state.lguages)}</Row>
											}
											  
										</Row>
										<Row className="text-center">
										{
											(this.state.leagueids!='')?<Link className="btn btn-lg green" to={'/add-tips/'+this.state.leagueids+'/'+this.state.filterdate}>GO</Link>:<Link className="btn btn-lg green" to='/addtips'>GO</Link>
										}
												
										</Row>
									</Row>:''
									}
									  
									</Tab>
								  </Tabs>
							</Col>
						</Row>
					
					</div>
					
				</div>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(selectCompetition)