import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import ReactDOM from 'react-dom';
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import '../../styles/upload.css'
import {Col, Row, FormControl, FormGroup, Radio, Checkbox, Table,Button} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,UPLOAD_PAGE_LOAD} from '../../constants/actionTypes'
import NumberFormat from 'react-number-format'
import ModalEror from './ModalEror'
//import languages from '../../languages'
//import FileInput from 'react-file-input';
import $ from 'jquery';

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
        
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: UPLOAD_PAGE_LOAD })  
	},
});



 


class Match extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
           importFile:'',fieldUp:'',matchresult:0,leagueevent:0,isLoading:false,isOpenModal: false,message:'',
           typeSport: 'Soccer,Basketball,Tennis',
           timecoeff:0
			
		}
		//---
		this.handleChangeFIle=this.handleChangeFIle.bind(this)
		this.handleClick=this.handleClick.bind(this)
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,message:''})
		}
	}                                       
    ChangeField(field,ev){
        this.setState({[field]:ev.target.value,offset: 0})
    }
    componentWillMount(){		
		this.props.onLoad('uploads');	
		const cookies = new Cookies()
		const token = cookies.get(TOKEN_NAME)
		if(token){			
			agent.setToken(token)
			this.props.onLoad( token)       
		}
		let language=(cookies.get('language')!=='' && cookies.get('language') !==null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	checkUpdate(field,ev){
		//let matchresult=this.state.let matchresult
		//let leagueevent=this.state.let leagueevent
		if(ev.target.checked && field=='leagueevent'){
			this.setState({[field]:1,matchresult:0})
		}else if(ev.target.checked && field=='matchresult'){
			this.setState({[field]:1,leagueevent:0})
		}
		
	}
	handleChangeFIle(field,e){
		e.preventDefault();		
		let reader = new FileReader();
		let file = e.target.files[0];   
		this.setState({importFile: file,fieldUp:field})
		console.log(field,file)
	}
	handleClick(){
		this.setState({isLoading: true});
        this.setState({isLoading: true});
		let matchresult=this.state.matchresult
		let leagueevent=this.state.leagueevent
        let sportName=this.state.typeSport;
        let timecoeff=this.state.timecoeff;
        //console.log(sportName);
		//console.log(matchresult,leagueevent)
		if(matchresult==0 && leagueevent==0){
			this.setState({isOpenModal:true,message:"File import empty."})
			setTimeout(() => {
			  this.setState({isLoading: false});
			  $(".error")
			}, 2000);	
		}else if(matchresult==1){
			this.importmatchresult(sportName);
		}else if(leagueevent==1){
			this.importallleagueevent(sportName,timecoeff);
		}
		
	}
    importallleagueevent(sportName,timecoeff){
        //alert(timecoeff);
		agent.upload.importallleagueevent({importFile:this.state.importFile,sportName:sportName,timecoeff:timecoeff}).then(data => {	
			//console.log(data)
			if(data.result === 'good'){
				this.setState({isOpenModal:true,message:"Upload successful."})				
			}else{
				this.setState({isOpenModal:true,message:data.upload})
			}
			setTimeout(() => {
				this.setState({isLoading: false});
			}, 2000);	
		})
	}
	importmatchresult(sportName){
    //alert("aaaa");
      agent.upload.importmatchresult({importFile:this.state.importFile,sportName:sportName}).then(data => {	
			//console.log(data)
			if(data.result === 'good'){
				this.setState({isOpenModal:true,message:"Upload successful."})					
			}else{
				this.setState({isOpenModal:true,message:data.upload})
			}
			setTimeout(() => {
			  this.setState({isLoading: false});
			}, 2000);	
		})
	}
	typeSportChange(e){
       let sportType = e.target.value; 
       this.setState({typeSport:sportType});
       if (sportType=='Soccer') {
           this.setState({timecoeff:0});
       } else this.setState({timecoeff:-1})
       //alert(e.target.value);  
    }
	render(){   
		//console.log(this.state.matchresult,this.state.leagueevent)
		let isLoading = this.state.isLoading;
        return (
			<div id="body">   
				<div>
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<Row>
                                <Col md={4}>
                                <h1>
									<span className="txt">Uploads</span>
								</h1>
                                </Col>
                                
                                <Col md={4} style={{'paddingTop':'10px'}}>
                               
                                    <span className="">Select Sport:</span>
                               
                                </Col>
                                <Col md={4}>
                                 <FormControl componentClass="select" className="select-sport" value={this.state.typeSport} onChange={this.typeSportChange.bind(this)}>
                                        <option value="Soccer,Basketball,Tennis">All</option>
                                        <option value="Soccer">Soccer</option>
                                        <option value="Basketball">Basketball</option>
                                        <option value="Tennis">Tennis</option>
                                 </FormControl>
                                 </Col> 
                                 </Row>
                                 
                                 <Row>
                                 <Col md={4}></Col>                                
                                 <Col md={4} style={{'paddingTop':'15px'}}>
                                   <span  className="select-sport">Time Coefficient</span>
                                 </Col>
                                
                                <Col md={4} style={{'paddingTop':'10px'}}>
                                    <input id="timecoeff" name="timecoeff" type="text" value={this.state.timecoeff} placeholder="" className="select-sport" onChange={this.ChangeField.bind(this,'timecoeff')}/>
                                </Col>
                               
                                 </Row>  
							</div>
                          
						</div>
					</div>
					<div className="container">
						<Row>
							<Col md={7}>
								<div className="portlet box blue-hoki">
									<div className="portlet-title">
										<div className="caption">
											IMPORT FILES
										</div>
										
									</div>
									<div className="portlet-body form">
										<form className="form-horizontal" id="form-import" method="POST" encType="multipart/form-data">
											<div className="form-actions top" style={{'display':'none'}}>
												
											</div>
											<div className="form-body">
												<Row>
													<Col md={12}>
														<Col md={4}>
															<Radio name="check" className="opt-ups" checked={(this.state.leagueevent==1)?true:false} onChange={this.checkUpdate.bind(this,'leagueevent')}>League Event</Radio>
														</Col>
														<Col md={8}>
															<input type="file" name="leagueeFile" id="import-leaguee" onChange={this.handleChangeFIle.bind(this,'leagueevent')} disabled={(this.state.leagueevent==1)?false:true}/>
														</Col>
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<Col md={4}>
															<Radio name="check" className="opt-ups" checked={(this.state.matchresult==1)?true:false} onChange={this.checkUpdate.bind(this,'matchresult')}>Match Result</Radio>
														</Col>
														<Col md={8}>
															<input type="file" name="matchFile" id="import-match" onChange={this.handleChangeFIle.bind(this,'matchresult')} disabled={(this.state.matchresult==1)?false:true}/>
														</Col>
													</Col>
												</Row>
											</div>
											<div className="form-actions">
												<Row>
													<Col md={12} >
														 <Button
															id="btn-run"
															bsStyle="primary"
															disabled={isLoading}
															onClick={!isLoading ? this.handleClick : null}>
															{isLoading ? <span><i className='fa fa-spinner fa-spin '></i> Uploading...</span> :<span>Upload </span>}
														 </Button>
													</Col>
													
													<Col md={12} className="msg-save"></Col>
												</Row>
											</div>
										</form>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<ModalEror isOpen={this.state.isOpenModal} close={this.close} message={this.state.message}/><ModalEror isOpen={this.state.isOpenModal} close={this.close} message={this.state.message}/>
			</div>
        );
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(Match)