import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY,USERID_NF,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import translations from '../common/captionhome.json';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		user_fiend: state.common.user_fiend,
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class MyFriends extends React.Component {
	constructor(){
		super()
		this.state = {
			lguages:'1005',	
			url:'https://www.coopbet.com/media/user_none.jpg',number_of_follows:0,fllow:false,role_id:'',
			list:[],isOpenModal:false,msgLogin:'',user_id:'',follow_list:[],number_of_friends:0,id_profile:'',id_user_lg:''
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		this.viewFriend=()=>{
			this.setState({fllow:false})
		}
		this.viewFollow=()=>{
			this.setState({fllow:true})
		}
		//----
		
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps.user_fiend!='' && nextProps.user_fiend!=null){
			//this.friendlistNew(nextProps.user_id)
			let user_fiend=nextProps.user_fiend;
			let list=user_fiend.list;
			let follow_list=user_fiend.follow_list;
			let number_of_follows=user_fiend.number_of_follows;
			let number_of_friends=user_fiend.number_of_friends;
			this.setState({list:list,follow_list:follow_list,number_of_follows:number_of_follows,
						   number_of_friends:number_of_friends,id_user_lg:nextProps.id_user_lg,id_profile:nextProps.id_profile})
		}
		
	}	

	componentWillMount() {
		//---
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		 if(login!='' && login!='null' && login!=null){
			// this.friendlist()
			let lg=JSON.parse(login)
			this.setState({role_id:lg.UserInfo.role_id})
			//console.log(lg)
			 
		 }
		 //console.log(login)
		
	}	
	componentDidMount() {
		
	}
	
	
	showBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").show();
		//console.log($this)
	}
	hideBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").hide();
	}
	deleteFriend(ev){
		let $this=ev.target
		let id=$($this).attr("data-id")
		agent.friends.deleteFriend({friend_user_id:id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				let newList=this.state.list.map(item=>{
					if(item!='' && item!='null' && item!=null && item.user_id == id){
						item.FriendStatusWithLoginUser='';
						
					}
					return item;
				})
				this.setState({list:newList})
			}
			
		  })	
	}
	removeFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.removeFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				let newList=this.state.list.map(item=>{
					if(item!='' && item!='null' && item!=null && item.user_id == id){
						item.LoginUserFollowStatus='';
						
					}
					return item;
				})
				this.setState({list:newList})
			}
		})
	}
	createrequest(ev){
		
		let thiss=ev.target.closest('.add-friend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				const list=this.state.list.map(item=>{
					if(item.user_id==to_user_id){
						item.FriendStatusWithLoginUser='Pending'
					}
					return item;
				})
				this.setState({isOpenModal:false,msgLogin:'',list:list})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
				$(thiss).find('.loading').hide();
			}
		})
	}
	createrequestFl(ev){
		
		let thiss=ev.target.closest('.add-friend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				const follow_list=this.state.follow_list.map(item=>{
					if(item.user_id==to_user_id){
						item.FriendStatusWithLoginUser='Pending'
					}
					return item;
				})
				this.setState({isOpenModal:false,msgLogin:'',follow_list:follow_list})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
				$(thiss).find('.loading').hide();
			}
		})
	}
	goProfile(url,e){
		window.location.href='/'+url;
	}
	render() {
		$(".body").css({'background':'#e9ebee'})
		$(window).hover(function(e){
			if($(e.target).closest(".g-btn").length==0 && $(e.target).closest(".dr-fr").length==0 ){
				$(".dr-fr").hide();
			}
		})
		let url=this.state.url
		const list=this.state.list
		const follow_list=this.state.follow_list
		const id_profile=this.state.id_profile
		const id_user_lg=this.state.id_user_lg
		const fllow=this.state.fllow
		//console.log(list)
		//---------
			return (
				
					
						<Row>
							<Col md={12} className="friend-body">
								<div className="fiend-box-top" id="friend-request">
									<div className="frd-top">
										<div className="ft-items">
										{
											(id_profile==id_user_lg && this.state.role_id!=3)?
											<h3 className="header _hdf">
												<button className="btn btn-a" onClick={this.viewFriend}>
													<i className="fas fa-user-friends icon-friend"></i>
													<span className={(fllow==false)?"txt-red":""}>Friends </span>({this.state.number_of_friends})
												</button>
												<span className="line-h"></span>
												<button className="btn btn-a" onClick={this.viewFollow}>
													<span className={(fllow==true)?"txt-red":""}>Followings </span>({this.state.number_of_follows})
												</button>
											</h3>:
											<h3 className="header _hdf">
												<button className="btn btn-a">
													<i className="fas fa-user-friends icon-friend"></i>
													<span>Friends </span>({this.state.number_of_friends})
												</button>
											</h3>
										}
										{	
											(fllow==false)?
											<Col md={12} className="pd0 ctn mg10">
												{
													(list!='' && list!='null' && list!=null)?list.map((data,index)=>{
														if(data!='' && data!='null' && data!=null){
															var url_username=data.url_username
															let prName='';
															let url_profile='';
															if(url_username!='' && url_username!='null' && url_username!=null){
																prName=url_username.split("/");
																url_profile=prName[prName.length-1];
															}
															return(
																<Col md={6} className="ft-item ft-item-0 ft-item-bst" key={index}>
																	<figure className="pull-left">
																		<img className="cr-pnt" onClick={this.goProfile.bind(this,url_profile)} src={(data.imageurl!='' && data.imageurl!=null)?data.imageurl:url} className="_rv"/>
																	</figure>
																	<div className="fr-it-body">	
																		<div className="name name-fr pull-left">
																			<span className="fwb cr-pnt" onClick={this.goProfile.bind(this,url_profile)}>{data.tipster_name}</span>
																			<div className="count-fr">{data.friendcount} {(data.friendcount>1)?'friends':'friend'}</div>
																		</div>
																		{
																			(id_user_lg==data.user_id)?'':
																			(data.FriendStatusWithLoginUser=="Confirmed")?
																			<div className="g-btn pull-right">
																				<a className="btn-2 pull-right tfd-delete" onMouseOver={this.showBoxFriend.bind(this)}><i className="fa fa-angle-down fa-it"></i>Friends</a>
																				<ul role="menu" className="dropdown-menu dr-fr" onMouseLeave={this.hideBoxFriend.bind(this)}>
																					<li><a data-id={data.user_id} onClick={this.deleteFriend.bind(this)}>Unfriend</a></li>
																					{
																						(data.LoginUserFollowStatus!='')?<li><a data-id={data.user_id} onClick={this.removeFollow.bind(this)}>Unfollowing</a></li>:''
																					}
																					
																				</ul>
																			</div>:
																			<div className="g-btn pull-right">
																				<a className="btn-2 pull-right add-friend" data-id={data.user_id} onClick={this.createrequest.bind(this)}>
																					<i className="fas fa-user-plus mrgr3"></i>
																					{(data.FriendStatusWithLoginUser=="Pending")?'Friend Request Sent':(data.FriendStatusWithLoginUser=="PendingYou")?'Waiting you confirm':localizeKey(translations)('AF', this.state.lguages)}
																					<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
																				</a>
																			</div>
																		}
																		
																	</div>
																</Col>
															)
														}
														
													}):<div></div>
												}
												
												
													
											</Col>:
											<Col md={12} className="pd0 ctn mg10">
												{
													(follow_list!='' && follow_list!='null' && follow_list!=null)?follow_list.map((data,index)=>{
														if(data!='' && data!='null' && data!=null){
															var url_username=data.url_username
															let prName='';
															let url_profile='';
															if(url_username!='' && url_username!='null' && url_username!=null){
																prName=url_username.split("/");
																url_profile=prName[prName.length-1];
															}
															return(
																<Col md={6} className="ft-item ft-item-0 ft-item-bst" key={index}>
																	<figure className="pull-left"><img src={(data.imageurl!='' && data.imageurl!=null)?data.imageurl:url} className="_rv"/></figure>
																	<div className="fr-it-body">	
																		<div className="name name-fr pull-left">
																			<span className="fwb cr-pnt" onClick={this.goProfile.bind(this,url_profile)}>{data.tipster_name}</span>
																			<div className="count-fr">{data.friendcount} {(data.friendcount>1)?'friends':'friend'}</div>
																		</div>
																		{
																			(data.FriendStatusWithLoginUser=="Confirmed")?
																			<div className="g-btn pull-right">
																				<a className="btn-2 pull-right tfd-delete" onMouseOver={this.showBoxFriend.bind(this)}><i className="fa fa-angle-down fa-it"></i>Friends</a>
																				<ul role="menu" className="dropdown-menu dr-fr" onMouseLeave={this.hideBoxFriend.bind(this)}>
																					<li><a data-id={data.user_id} onClick={this.deleteFriend.bind(this)}>Unfriend</a></li>
																					{
																						(data.LoginUserFollowStatus!='')?<li><a data-id={data.user_id} onClick={this.removeFollow.bind(this)}>Unfollowing</a></li>:''
																					}
																				</ul>
																			</div>:
																			<div className="g-btn pull-right">
																				<a className="add-friend" data-id={data.user_id} onClick={this.createrequestFl.bind(this)}>
																					<i className="fas fa-user-plus mrgr3"></i>
																					{(data.FriendStatusWithLoginUser=="Pending")?'Friend Request Sent':(data.FriendStatusWithLoginUser=="PendingYou")?'Waiting you confirm':localizeKey(translations)('AF', this.state.lguages)}
																					<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
																				</a>
																			</div>
																		}
																	</div>
																</Col>
															)
														}
														
													}):<div></div>
												}
												
												
													
											</Col>
										}
										</div>
									</div>
								</div>
							</Col>
						</Row>
						
						
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyFriends)
