import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox, Form, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format'
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Link } from 'react-router-dom'
import agent from '../../agent'
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

//import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
 TOKEN_NAME, CHANGE_TAB,LOAD_AD_DATA,AD_EDIT_LOAD
  
}from '../../constants/actionTypes'; 
import UploadLogo from '../common/UploadLogo'
import Header from '../../components/Header';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey,
		//ad_info: state.common.ad_info,
	}) 

);

const mapDispatchToProps = dispatch => ({    
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: AD_EDIT_LOAD })  
	},
});
class EditAd extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',userList:[],userid:'',language:'',
			old_avatar: 'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png',
			avatar: 'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png',
			ad_title:'',ad_url:'',startDate:new Date(),endDate:new Date(),ad_startdate:'',ad_enddate:'',ad_id:'',
			ad_description:'',suspended:'',isOpenModalconfirm:false,adList:[],startdateH:'',enddateH:'',
			editorState: EditorState.createEmpty(),
			isOpenModal:false,msgRigister:'',
			
		}
		//---
		this.close=()=>{
			this.setState({isOpenModalconfirm:false,isOpen:false})
		}
		this.hideModal = ev => {	
		  $(".lblMessage").text('');
		  this.setState({
			isOpen: false,isOpen:false
		  });
		 this.props.close();
			$(".lblMessage").text('');
		  //this.props.refeshorder();
		};
		//=====
		this.handleGetFileUpload = (imageURL) => { 
			console.log(imageURL)
			const avatar = (imageURL!='') ? imageURL : this.state.old_avatar				
			const newState = Object.assign({}, this.state,{avatar:avatar})
			this.setState(newState)	
		}
		//=====
		this.onEditorStateChange = (editorState) => {
			this.setState({
			  editorState:editorState
			});
		  };
		  this.close=()=>{
				this.setState({isOpenModal:false})
			}
		this.editAd=this.editAd.bind(this)
		//this.deleteAd=this.deleteAd.bind(this)
		//this.getadList=this.getadList.bind(this)
	}                                       
  
   componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			//this.setState({isOpen:nextProps.isOpen,ad_id:nextProps.ad_id,adList:nextProps.adList});
			let info=nextProps.ad_info;
			if(info!='' && info!=null){
				let ad_id=info.ad_id
				let ad_description=info.ad_description
				let ad_enddate=info.ad_enddate
				let ad_user_id=info.ad_user_id
				let ad_logo_url=info.ad_logo_url
				let ad_startdate=info.ad_startdate
				let ad_title=info.ad_title
				let ad_url=info.ad_url
				let ad_lang_display_name=info.ad_lang_display_name
				let suspended=info.suspended
				this.setState({avatar:ad_logo_url ,ad_title:ad_title,ad_url:ad_url,
							   ad_startdate:ad_startdate,ad_enddate:ad_enddate,ad_description:ad_description,
							   userid:ad_user_id,language:ad_lang_display_name,startDate:new Date(ad_startdate),
							   endDate:new Date(ad_enddate),suspended:info.suspended,ad_id:ad_id})
				$(".seelct-ad-user").val(ad_user_id)
				let startdateH=$('.seelct-ad-user  option:selected').attr("startdate")
				let enddateH=$('.seelct-ad-user  option:selected').attr("enddate")
				this.setState({startdateH:startdateH,enddateH:enddateH})
			}
				
			//this.getAdInfo(nextProps.ad_id)
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		//const idb=nextState.valueBuild;
		//console.log(nextState)
		return nextState;
		
	}
	componentDidMount() {
		
	}
	componentWillMount() {
		this.props.onLoad('edit-ad');
		this.getAdUserList()
		let ad_id=this.props.match.params.id;
		if(ad_id!='' && ad_id!=null){
			this.getAdInfo(ad_id)
			this.setState({ad_id:ad_id})
		}
		//console.log(ad_id)
	}
	/*getAdInfo(ad_id){
		agent.ads.adUserInfo({ad_id:ad_id}).then((datas) => {
		console.log(datas)
			if(datas.result=='good'){
				let info=datas.info
				if(info!='' && info!=null){
					let ad_id=info.ad_id
					let ad_description=info.ad_description
					let ad_enddate=info.ad_enddate
					let ad_user_id=info.ad_user_id
					let ad_logo_url=info.ad_logo_url
					let ad_startdate=info.ad_startdate
					let ad_title=info.ad_title
					let ad_url=info.ad_url
					let ad_lang_display_name=info.ad_lang_display_name
					let suspended=info.suspended
					let ad_long_description=info.ad_long_description
					this.setState({avatar:ad_logo_url ,ad_title:ad_title,ad_url:ad_url,
								   ad_startdate:ad_startdate,ad_enddate:ad_enddate,ad_description:ad_description,
								   userid:ad_user_id,language:ad_lang_display_name,startDate:moment(ad_startdate),
								   endDate:moment(ad_enddate),suspended:info.suspended,ad_id:ad_id,
								   editorState: EditorState.createWithContent(
										ContentState.createFromBlockArray(
										  convertFromHTML(ad_long_description)
										)
									  ),
								})
					$(".seelct-ad-user").val(ad_user_id)
					let startdateH=$('.seelct-ad-user  option:selected').attr("startdate")
					let enddateH=$('.seelct-ad-user  option:selected').attr("enddate")
					this.setState({startdateH:startdateH,enddateH:enddateH})
				}
			}else{
				
			}
		  })	
	}
	*/
	getAdUserList(){
		agent.ads.adUserList().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({userList:datas.list})
		}else{
			this.setState({userList:[]})
		}
	  })	
	}
	handleChangeField(field,ev){
		//console.log(field,ev.target.value)
		if(field=='userid'){
			let language=$('.seelct-ad-user  option:selected').attr("datalang")
			let startdateH=$('.seelct-ad-user  option:selected').attr("startdate")
			let enddateH=$('.seelct-ad-user  option:selected').attr("enddate")
			this.setState({[field]:ev.target.value,language:language,startdateH:startdateH,enddateH:enddateH})
		}else{
			this.setState({[field]:ev.target.value})
		}
	 }	
	 handleChange(field,date) {
		let day=date.getDate();
		let month=date.getMonth()+1;
		let year=date.getFullYear();
		let newdate=year+"-"+month+"-"+day
		//console.log(date)	
		if(field=='ad_startdate'){
			this.setState({
			  startDate: date,ad_startdate:newdate
			});
		}
		if(field=='ad_enddate'){
			this.setState({
			  endDate: date,ad_enddate:newdate
			});
		}
		
	}
	editAd(){
		let ad_user_id=this.state.userid
		let ad_logo_url=this.state.avatar
		let ad_url=this.state.ad_url
		let ad_title=this.state.ad_title
		let ad_description=this.state.ad_description
		let ad_startdate=this.state.ad_startdate
		let ad_enddate=this.state.ad_enddate
		let ad_id=this.state.ad_id
		let suspended=this.state.suspended
		let ad_long_description=draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
		//console.log(ad_user_id,ad_logo_url,ad_url,ad_title,ad_description,ad_startdate,ad_enddate)
		//alert(ad_id)
		agent.ads.adUserEdit({ad_id:ad_id,ad_user_id:ad_user_id,ad_logo_url:ad_logo_url,
							ad_url:ad_url,ad_title:ad_title,ad_description:ad_description,
							ad_startdate:ad_startdate,ad_enddate:ad_enddate,suspended:suspended,
							ad_long_description:ad_long_description}).then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				this.setState({isOpenModal:true,msgRigister:'Save successful.'})
			}else{
				//$(".eror-saved").html(datas.message)
				this.setState({isOpenModal:true,msgRigister:datas.message})
				setTimeout(function() {
				  $(".eror-saved").html('')
				}, 7000)
			}
		  })	
	}
	getAdInfo(ad_id){
		agent.ads.adUserInfo({ad_id:ad_id}).then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				let info=datas.info;
				let ad_description=info.ad_description
				let ad_enddate=info.ad_enddate
				let ad_user_id=info.ad_user_id
				let ad_logo_url=info.ad_logo_url
				let ad_startdate=info.ad_startdate
				let ad_title=info.ad_title
				let ad_url=info.ad_url
				let ad_lang_display_name=info.ad_lang_display_name
				let suspended=info.suspended
				let ad_long_description=info.ad_long_description
				let editorState=this.state.editorState
				
				if(ad_long_description!=null && ad_long_description!=''){
					const blocksFromHtml = htmlToDraft(ad_long_description);
					const { contentBlocks, entityMap } = blocksFromHtml;
					const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
					 editorState = EditorState.createWithContent(contentState);
				}
				
				
				this.setState({avatar:ad_logo_url ,ad_title:ad_title,ad_url:ad_url,
							   ad_startdate:ad_startdate,ad_enddate:ad_enddate,ad_description:ad_description,
							   userid:ad_user_id,language:ad_lang_display_name,startDate:new Date(ad_startdate),
							   endDate:new Date(ad_enddate),suspended:info.suspended,
							   editorState: editorState,
							})
				$(".seelct-ad-user").val(ad_user_id)
			}else{
				
			}
		  })	
	}
	
	getadList(adList){
		//this.props.getadList()
		this.props.updateAdList(adList)
	}
	render(){   
		const adList=this.state.adList;
		let userList=this.state.userList
        return (
			<div>
				<Header/>
				<div id="body">   
					<div>
						<div className="heder-page">
							<div className="container">
								<div className="item lbl-1">
									<h1>
										<span className="txt">COOPBET EDITS AD</span>
									</h1>
								</div>
									
							</div>
						</div>
						<div className="tipster-list">
							<div className="container">
								<Row>
									<Col md={8}>
										<Form className="form-ad">
											<FormGroup>
												<ControlLabel className="label-sale"> Ad User </ControlLabel>	
												<FormControl componentClass="select" className="seelct-ad-user" placeholder="select" onChange={this.handleChangeField.bind(this,'userid')} disabled={true}>
													<option value='' datalang=''>Select-ad-user </option>	
													{
														(userList!='')?userList.map((data,idx)=>{
															return(
																<option value={data.ad_user_id} startdate={data.adhiring_startdate} enddate={data.adhiring_enddate} datalang={data.lang_display_name} key={idx}>{data.first_name+" "+data.last_name} ({data.tipster_name})</option>
															)
														}):''
													}
												
												</FormControl>	
												{
													(this.state.language!='')?<div className="language-ad">
														<span>Language: {this.state.language}</span>
														<span className="padlhr">Hiring Duration: {this.state.startdateH}->{this.state.enddateH}</span>
													</div>:''
												}
											</FormGroup>
											
											<FormGroup style={{'display':'none'}}>
												<ControlLabel className="label-sale">Language </ControlLabel>
												<input className="form-control" onChange={this.handleChangeField.bind(this,'language')} value={this.state.language}/>
											</FormGroup>
											<FormGroup id="img-logo">
												<ControlLabel className="label-sale">Logo </ControlLabel>
												<Row>
													<Col md={6}>
														<div className="avatar-container">
															<figure className="mgt0">>
																<img className="avatar1" src={this.state.avatar} alt="" />
															</figure>
														</div>	
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<div className="ctnavatar-container" style={{'padding':'0'}} >												
															<UploadLogo text='Choose Avatar'  
																multiFiles={false} resetFile={this.state.resetFile}
																updateFiles={this.handleGetFileUpload} />																								
														</div>
													</Col>	
												</Row>
											</FormGroup>
											<FormGroup>
												<ControlLabel className="label-sale">Ad Title </ControlLabel>
												<input className="form-control" onChange={this.handleChangeField.bind(this,'ad_title')} value={this.state.ad_title}/>
											</FormGroup>
											<FormGroup>
												<ControlLabel className="label-sale">Ad URL</ControlLabel>
												<input className="form-control" onChange={this.handleChangeField.bind(this,'ad_url')} value={this.state.ad_url}/>
											</FormGroup>
											<FormGroup>
												<ControlLabel className="label-sale">Ad Description</ControlLabel>
												<textarea
													className="form-control input-sale"
													id="txt-desc"
													rows="3"
													value={this.state.ad_description} onChange={this.handleChangeField.bind(this,'ad_description')}
												/>
											</FormGroup>
											
											<FormGroup>
												<ControlLabel className="label-sale">Long Description</ControlLabel>
												 <div>
													<Editor
													  editorState={this.state.editorState}
													  wrapperClassName="demo-wrapper"
													  editorClassName="demo-editor"
													  onEditorStateChange={this.onEditorStateChange}
													  handlePastedText={() => false}
													/>
													<textarea
													  disabled
													  style={{'display':'none'}}
													  value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}
													/>
												  </div>
											</FormGroup>
											<FormGroup>
												<Row>
													<Col md={6}>
														<ControlLabel className="label-sale">Start Date</ControlLabel>
													</Col>
													<Col md={6}>
														<ControlLabel className="label-sale">End Date</ControlLabel>
													</Col>
												</Row>
												<Row id="input-date">
													<Col md={6}>
														<DatePicker className="input-date"
															selected={this.state.startDate}
															onChange={this.handleChange.bind(this,'ad_startdate')}	
															disabled={true}
														/>
													</Col>
													<Col md={6}>
														<DatePicker className="input-date"
															disabled={true}
															selected={this.state.endDate}
															onChange={this.handleChange.bind(this,'ad_enddate')}										
														/>
													</Col>
												</Row>
												
											</FormGroup>
											
										</Form>			
									</Col>
								</Row>
								<Row className="rct textr" style={{'padding':'0px 15px'}}>
									<span className="eror-saved" style={{'float':'left','color':'red'}}></span>
								</Row>
								<Row className="rct textr" style={{'padding':''}}>
									<Col md={8} style={{'textAlign':'center','marginTop':'15px'}}>
										<Button onClick={this.editAd} bsStyle="primary" id="btn-start"> Save</Button>
									</Col>
								</Row>
							</div>
						</div>
					</div>
					
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
			</div>
        );
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(EditAd)