import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button,Tabs,Tab,Table,Radio} from 'react-bootstrap'
import {TIPS_PAGE_LOADER,LANGUAGE_KEY,CHANGE_TAB,TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import ModalErorr from '../common/ModalErorr'
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
//import Avatar from 'react-avatar';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ViewReport from '../common/ViewReport'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: TIPS_PAGE_LOADER })  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Tipter extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',idUser:'',isOpenModal:false,msg:'',
			startDate:new Date(),month:'',open_order_list:[],
			isOpen:false,order_id:'',paid_order_list:[],order_report_list:[]
		}
		
		this.close=()=>{
			this.setState({isOpenModal:false,isOpen:false,})
		}
		
		this.getOrdersofAdmin=()=>{
			let month = this.state.month
			agent.orders.getOrdersofAdmin({month:month}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					let open_order_list=data.open_order_list
					let paid_order_list=data.paid_order_list
					let order_report_list=data.order_report_list
					this.setState({open_order_list:open_order_list,
								   paid_order_list:paid_order_list,
								   order_report_list:order_report_list})
				}
			})
		}
		this.vieImg=(id,e) => {
			
			let letms=this.state.open_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=true
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({open_order_list:letms})
			
		}
		this.hideImg=(id,e) => {
			
			let letms=this.state.open_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=false
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({open_order_list:letms})
			
		}
		this.vieImgP=(id,e) => {
			
			let letms=this.state.paid_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=true
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({paid_order_list:letms})
			
		}
		this.hideImgP=(id,e) => {
			
			let letms=this.state.paid_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=false
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({paid_order_list:letms})
			
		}
		this.onPenModalView=(order_id,e)=>{
			this.setState({order_id:order_id,isOpen:true,isOpenModal:false})
		}
		
  }
		//---
	componentWillMount(){		
		this.props.onLoad('tips')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({lguages:language},()=>{
			//this.getrandomad();
		})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//console.log(login)
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id})
		}
		let dates=new Date();
		let mth=dates.getMonth()+1
		let year=dates.getFullYear()
		let month=mth+"/"+year
		this.setState({month:month},()=>{
			this.getOrdersofAdmin();
		})
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	  //document.getElementById("text1").innerText =''
    }

    componentWillUnmount() {
       
    }
	handleChangeDate(date){
		let dates=date.getDate();
		var month = date.getMonth() + 1;
		let year=date.getFullYear();
		let mth=month+"/"+year
		this.setState({startDate:date,month:mth},()=>{
			this.getOrdersofAdmin();
		})
	}
	
	render(){   
		const listOpen=this.state.open_order_list
		const listPaid=this.state.paid_order_list
		const listReport=this.state.order_report_list
        return (
			<div id="body">   
				<div className="heder-page">
					<div className="container">
						<div className="item lbl-1">
							<h1 id="text1">
								<span className="txt">Admin tips management</span>
							</h1>
						</div>									
					</div>
				</div>
				<div className="tipster-list">
					<div className="container">
						<Row>
							<Col md={2}></Col>
							<Col md={8}>
								
							</Col>
							<Col md={2} className="rank-mth" style={{'marginBottom':'-50px'}}>
								<DatePicker
								  selected={this.state.startDate}
								  onChange={this.handleChangeDate.bind(this)}
								  dateFormat="MM/yyyy"
								  showMonthYearPicker
								  
								/>
								<i className="fa fa-caret-down icon-caret"></i>
							</Col>
						</Row>
						<Row id="rank-tipter">
							<Tabs defaultActiveKey="key1" id="tab-buy" className="tab-rank">
								<Tab eventKey="key1" title="OPEN" >									
									<Row>										
										<Col md={12} className="pd0">
											<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
												<thead>
													<tr>
														<th colSpan="1" style={{'width':'5%'}}>No.</th>
														<th colSpan="1" style={{'width':'15%'}}>Mã đơn hàng</th>
														<th colSpan="1" style={{'width':'15%'}}>Tipster</th>
														<th colSpan="1" style={{'width':'10%'}}>Price</th>
														<th colSpan="1" style={{'width':'15%'}}>Khách hàng</th>
														<th colSpan="1" style={{'width':'10%'}}>Method</th>	
														<th colSpan="1" style={{'width':'15%','textAlign':'center'}}>Invoice file</th>
														<th colSpan="1" style={{'width':'15%','textAlign':'center'}}>Order Date</th>														
													</tr>
												</thead>
												<tbody>
												{
													(listOpen!=='')?listOpen.map((dt,idx)=>{
														let orderDate=dt.order_datetime
														orderDate=orderDate.split(" ")
														let file=dt.proof_of_payment_url
														var res = (file!==null)?'...'+file.substring(file.length-13):'';
														return(
														<tr key={idx}>
															<td>0{idx+1}</td>
															<td><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
															<td>{dt.seller_tipster_name}</td>
															<td>{dt.PriceTips}</td>
															<td>{dt.buyer_tipster_name}</td>
															<td>{dt.seller_bank_type_name}</td>
															<td style={{'textAlign':'center','color':'#c90a0a'}}>
																
																<a className="cusor" onClick={this.vieImg.bind(this,dt.order_id)}>{res}</a>
																	{
																		dt.isOpenIM && (
																		  <Lightbox
																			mainSrc={dt.proof_of_payment_url}
																		   onCloseRequest={this.hideImg.bind(this,dt.order_id)}
																			
																		  />
																	
																		)
																	}
																
															</td>
															<td style={{'textAlign':'center'}}>{orderDate[0]}</td>
															
														</tr>	
														)
													}):''
												}												
												</tbody>
											</Table>
										</Col>
									</Row>
								</Tab>
								<Tab eventKey="key2" title="PAID">								
									<Row>										
										<Col md={12} className="pd0">
											<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
												<thead>
													<tr>
														<th colSpan="1" style={{'width':'5%'}}>No.</th>
														<th colSpan="1" style={{'width':'15%'}}>Mã đơn hàng</th>
														<th colSpan="1" style={{'width':'15%'}}>Tipster</th>
														<th colSpan="1" style={{'width':'10%'}}>Price</th>
														<th colSpan="1" style={{'width':'15%'}}>Khách hàng</th>
														<th colSpan="1" style={{'width':'10%'}}>Method</th>	
														<th colSpan="1" style={{'width':'15%','textAlign':'center'}}>Invoice file</th>
														<th colSpan="1" style={{'width':'15%','textAlign':'center'}}>Order Date</th>														
													</tr>
												</thead>
												<tbody>
												{
													(listPaid!=='')?listPaid.map((dt,idx)=>{
														let orderDate=dt.order_datetime
														orderDate=orderDate.split(" ")
														let file=dt.proof_of_payment_url
														var res = (file!==null)?'...'+file.substring(file.length-13):'';
														return(
														<tr key={idx}>
															<td>0{idx+1}</td>
															<td><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
															<td>{dt.seller_tipster_name}</td>
															<td>{dt.PriceTips}</td>
															<td>{dt.buyer_tipster_name}</td>
															<td>{dt.seller_bank_type_name}</td>
															<td style={{'textAlign':'center','color':'#c90a0a'}}>
																
																<a className="cusor" onClick={this.vieImgP.bind(this,dt.order_id)}>{res}</a>
																	{
																		dt.isOpenIM && (
																		  <Lightbox
																			mainSrc={dt.proof_of_payment_url}
																		   onCloseRequest={this.hideImgP.bind(this,dt.order_id)}
																			
																		  />
																	
																		)
																	}
																
															</td>
															<td style={{'textAlign':'center'}}>{orderDate[0]}</td>
															
														</tr>	
														)
													}):''
												}												
												</tbody>
											</Table>
										</Col>
									</Row>
								</Tab>
								<Tab eventKey="key3" title="Report">								
									<Row>										
										<Col md={12} className="pd0">
											<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
												<thead>
													<tr>
														<th colSpan="1" style={{'width':'5%'}}>No.</th>
														<th colSpan="1" style={{'width':'20%'}}>Mã đơn hàng</th>
														<th colSpan="1" style={{'width':'20%'}}>Tipster</th>
														<th colSpan="1" style={{'width':'15%'}}>Price</th>
														<th colSpan="1" style={{'width':'20%','textAlign':'center'}}>Report</th>
														<th colSpan="1" style={{'width':'20%','textAlign':'center'}}>View</th>														
													</tr>
												</thead>
												<tbody>
												{
													(listReport!=='')?listReport.map((dt,idx)=>{
														return(
														<tr key={idx}>
															<td>0{idx+1}</td>
															<td><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
															<td>{dt.seller_tipster_name}</td>
															<td>{dt.PriceTips}</td>
															<td style={{'textAlign':'center'}}>{dt.Reports}</td>
															<td style={{'textAlign':'center'}}><input type="button" className="btn-search btn-view" value="View" onClick={this.onPenModalView.bind(this,dt.order_id)}/></td>
															
														</tr>	
														)
													}):''
												}												
												</tbody>
											</Table>
										</Col>
									</Row>
								</Tab>
								
							</Tabs>
						</Row>
					</div>
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
				<ViewReport isOpen={this.state.isOpen} close={this.close} 
				               order_id={this.state.order_id}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tipter)