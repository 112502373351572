import React, {Component} from 'react'
import { connect } from 'react-redux'
//import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import {AD_LONG_DESCPT} from '../../constants/actionTypes'
import agent from '../../agent'
//import languages from '../../languages'
//import translations from '../common/captionhome.json';
//import { Cookies } from 'react-cookie'
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import Header from '../../components/Header';

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey,
		ad_long_description: state.common.ad_long_description
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'RUNNING_LOADER', data})  
	},
	
});



 


class NewInfo extends Component{ 	
	constructor(){
		super()
		this.state = {
			title:'',ad_id:'',ad_long_description:'',ad_user_id:'',
			first_name:''
		}
		//---
		this.getAdlongdescription=(ad_id)=>{			
			agent.ads.adlongdescription({ad_id:ad_id}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({title:data.ad_title,ad_long_description:data.ad_long_description,
					               first_name:data.first_name,ad_user_id:data.ad_user_id})
				}
			})
		}
		
  }
		//---
	componentWillMount(){
		this.props.onLoad('running');
		let ad_id=this.props.match.params.id
		if(ad_id>0){
			this.setState({ad_id:ad_id},()=>{
				this.getAdlongdescription(ad_id);
			})
		}
		
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	componentDidMount() {
      // let content = document.getElementById('my-content');
	  // let title=content.firstElementChild
		//console.log($(title).text());
		//this.setState({title:$(title).text()})
    }
	
    componentWillUnmount() {
       
    }
	goBack(e){
		window.localStorage.setItem( 'ad_user_id', this.state.ad_user_id);
		window.localStorage.setItem( 'ad_title', this.state.first_name);
		window.location.href="/ad-list"
	}
	
	render(){   
		let content=''
		let title=''
		let ct=window.localStorage.getItem('ad_long_description')
		if(ct!=null && ct!=''){
			ct=JSON.parse(ct)
			content=ct.text;
			title=ct.title;
		}
		//console.log(this.state.ad_id)
        return (
			<div>
				<Header/>
				<div id="body">   
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">{this.state.title}</span>
								</h1>
							</div>	
							<div style={{'float': 'right'}}><a className="running" onClick={this.goBack.bind(this)}>Back</a></div>
						</div>
					</div>
					<div className="tipster-list">
						<div className="container">
							<Row>
								<Col md={12} id="my-content">
									{ReactHtmlParser(this.state.ad_long_description)}
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInfo)