import React from 'react';
import { Link,withRouter, history} from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,NOTIFICATION_LOADER,
CHANGE_TAB,BASE_IMAGE} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import { Cookies } from 'react-cookie'
import { localize, localizeKey } from 'react-redux-localization';
import menu from '../common/menu.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({             
	onLoadPage: (data) => {
		dispatch({ type: NOTIFICATION_LOADER, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class AllNotification extends React.Component {
	constructor(){
		super()
		this.state = {
			avartarDf:'https://www.coopbet.com/media/avatar_none.png',msgLogin:'',list:[],limit:10,offset:0,
			loadedAll:false,scrollM:false,language:'1005',
		}
		//---
		this.goToOption=this.goToOption.bind(this)
		
		//----
		this.onScrollTip = (e) => {
			const loadedAll = this.state.loadedAll
			if(!loadedAll && $(window).scrollTop() + $(window).height() == $(document).height()) {
				   let offset = parseInt(this.state.offset) + this.state.limit
					//let pageno = parseInt(this.state.pageno) + this.state.pagelength
					const newState = Object.assign({}, this.state, { offset: offset, scrollM: true })
					this.setState(newState, () => {
						this.getList();
					})	
			   }
		}
		this.readOne=(notification_id)=>{
			agent.notification.readOne({notification_id:notification_id}).then((datas) => {
				//console.log(datas)
				if(datas.result==='good'){
					let list=this.state.list.map(dt=>{
						if(dt.notification_id==notification_id){
							dt.read=1
						}
						
						return dt;
					})
					this.setState({list:list})
				}
				
			})
		}
		
	}
	
	
	//---------------------------
    	

	componentWillMount() {
		this.props.onLoadPage();
		//---
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({'language':language})
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		 if(login!='' && login!='null' && login!=null){
			this.getList()
		 }else{
			
		 }
		
	}	
	componentWillReceiveProps(nextProps) {
		if(nextProps.languageKey){
			this.setState({language:nextProps.languageKey});
		}
	}
	componentDidMount() {
		window.addEventListener('scroll', this.onScrollTip, true);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScrollTip);
		//this.setState({list:[],loadedAll:false,scrollM:false})
	}
	getList(){
		let limit=this.state.limit
		let offset=this.state.offset
		agent.notification.getList({offset:offset,limit:limit}).then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				//this.setState({list:datas.list})
				if(datas.list!='' && datas.list!=null){
					const loadedAll = datas.list.length < this.state.limit ? true: false	
					const list = this.state.scrollM ? this.state.list.concat(datas.list):datas.list
					const newState = Object.assign({}, this.state, { list: list,scrollM: false,loadedAll:loadedAll })
					this.setState(newState)	
				}
			}
			
		})
	}
	goToOption(type,url_username,pick_code,notification_id,user_id,pick_user_id,parent_id,e){
		//console.log(type)
		this.readOne(notification_id)
		let userId=(pick_user_id>0)?pick_user_id:user_id
		if(type=='new_friend_suggestion'){
			let prName='';
			let url='';
			if(url_username!='' && url_username!='null' && url_username!=null){
				prName=url_username.split("/");
				url=prName[prName.length-1];
			}
			
			window.location="/"+url
		}else{
			//this.props.history.push("/home")
			//$(".txt-search").val(pick_code)
			//this.props.searchHomeFromPickcode(pick_code)
			if(parent_id>0){
				window.location="/topic/"+userId+"_"+pick_code+"_"+parent_id;;
			}else{
				window.location="/topic/"+userId+"_"+pick_code;
			}
		}
	}
	render() {
		$(".body").css({'background':'#e9ebee'})
		const list=this.state.list
		const avartarDf=this.state.avartarDf
		//console.log(list)
		//---------
			return (
				<div className="body">
					<div className="container">
						<Row>
							<Col md={8} className="friend-body">
								<div className="friens-top" id="friend-request">
									<div className="frd-top">
										<div className="ft-items">
											<h3 className="header _hdf">{localizeKey(menu)('yourNotification', this.state.language)}</h3>
											<div className="ctn">
											{
												(list!='')?list.map((data,key)=>{
													/*let type=data.type
													type=type.split('user_').join('');
													type=type.split('friend_').join('');
													type=type.split('_').join(' ');*/
													let pfile=data.data
													let msg='';
													let message=data.message.split(" ");
													message.map((it,key)=>{
														if(key>0){
															msg+=it+" "
														}
													})
													return(
														<div className={'ft-item row-ntf ft-item-'+key} key={key} style={{'backgroundColor':(data.read==0)?'aliceblue':'#fff'}} onClick={this.goToOption.bind(this,data.type,pfile.url_username,pfile.pick_code,data.notification_id,pfile.user_id,pfile.pick_user_id,pfile.parent_id)}>
															<figure className="pull-left">
																	<img className="img-ntf" src={(pfile.imageurl!='' && pfile.imageurl!='null' && pfile.imageurl!=null)?pfile.imageurl:avartarDf} width="50" height="50"/>
															</figure>
															<div className="ct-user-nf">
																<a className="name-user">{pfile.tipster_name}</a>
																<span>{msg}.</span>
																<div>
																	<i className="fa fa-clock-o ic-ntf"></i>
																	<span className="time-ntf">{data.str_notification_time}</span>
																</div>
															</div>
															
														</div>
													)
												}):<div className="empty list-gray" style={{'display':''}} >
														{localizeKey(menu)('txtNoNotify', this.state.language)}
													</div>
											}
													
													
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						
					</div>
				</div>
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AllNotification)
//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllNotification));