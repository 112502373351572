import {
  TOKEN_NAME,APP_LOAD,REDIRECT,RESET_DASHBOARD,HOME_PAGE_LOADED,
  UPDATE_VERTICAL,UPDATE_LINE_CHART,UPDATE_DRAG_AREA_CHART,
  UPDATE_STATUS,UPDATE_KPI_SIMULATION,UPDATE_KPI_TOP_PERCENT,
  LOGIN,LOGOUT,BASELINE_LOADED,PAGE_NOT_FOUND_LOADED,
  LANGUAGE_KEY,CHANGE_TAB,LANGUAGE_LIST,TIPMAKET_PAGE_LOADER,
  TODAYTIP_PAGE_LOADER,TIPSTERLIST_PAGE_LOADER,JACKPOT_PAGE_LOADER,
  RESULTS_PAGE_LOADER,MYSETTING_PAGE_LOADER,USERLIST_PAGE_LOADER,
  MATCHS_PAGE_LOADER,  MEBER_NAME,USER_STATISTIC,
  ADDTIPS_PAGE_LOADER,LIKE_DATA,IMG_USER,PROFILE_LOADER,USERID_NF,USER_TIMELINE,
  ADD_TIPS_PAGE_LOADER,RUNNING_LOADER,RUNNING_HOME_PICK,UPLOAD_PAGE_LOAD,
  AD_PAGE_LOAD,LOAD_AD_DATA,CREATE_AD_USER,ADMIN_EDIT_USER,AD_USER_LOAD,ADD_MATCH,
  ONLY_FIENDS,REQUEST_LOADER,UDATE_PICK_COMENT,ACTIVE_LOADER,CONTACT_LOADER,
  FRIEND_REQUEST_LOADER,NOTIFICATION_LOADER,AD_EDIT_LOAD,AD_LONG_DESCPT,TIPSTERS_LOADER,
  TIPS_PAGE_LOADER,TIPS_EXCHANGE_LOADER
} from '../constants/actionTypes';

const defaultState = {
	appName: 'coopbet',
	tab: 'home',
	dashboardData: [],
	updateProps: true, 
	token: null,
	active: 'home'
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case APP_LOAD:
			return  Object.assign({}, state, {
				appLoaded: true,
				token: action.access_token || null				
			});  
		case HOME_PAGE_LOADED:
			return  Object.assign({}, state, {
				dashboardData: action.data,
				tab: 'home',
				updateProps: true,
				active:'home'
			});	
		case UPDATE_KPI_TOP_PERCENT:
		case UPDATE_KPI_SIMULATION:
		case UPDATE_STATUS:
		case UPDATE_DRAG_AREA_CHART:
		case UPDATE_LINE_CHART:
		case UPDATE_VERTICAL:
			return Object.assign({}, state, {
							updateProps: false
						}) 	
		case RESET_DASHBOARD:
			return  Object.assign({}, state, {
				dashboardData: action.data,
				updateProps: true
			});	
		case LOGIN:
			return Object.assign({}, state, {
				redirectTo: action.redirectTo, 
				token: action.token,
				tab: 'baseline'
			})
		case REDIRECT:                   
			return  Object.assign({}, state, {redirectTo: null }); 	
		case BASELINE_LOADED:
			return Object.assign({}, state, {tab: 'login'})
		case TIPMAKET_PAGE_LOADER:
		return Object.assign({}, state, { active: 'bookmakers', tab: 'bookmakers'})
		case TODAYTIP_PAGE_LOADER:
			return Object.assign({}, state, { active: 'todaytips', tab: 'todaytips'})
		case TIPSTERLIST_PAGE_LOADER:
			return Object.assign({}, state, { active: 'tipsterlist', tab: 'tipsterlist'})
		case JACKPOT_PAGE_LOADER:
			return Object.assign({}, state, { active: 'jackpot', tab: 'jackpot' })	
		case RESULTS_PAGE_LOADER:
			return Object.assign({}, state, { active: 'results', tab: 'results'})
		case MYSETTING_PAGE_LOADER:
			return Object.assign({}, state, { active: 'mysetting', tab: 'mysetting'})
		case USERLIST_PAGE_LOADER:
			return Object.assign({}, state, { active: 'userlist',tab: 'mysetting' })
		case MATCHS_PAGE_LOADER:
			return Object.assign({}, state, { active: 'matchs', tab: 'matchs'})
		case ADDTIPS_PAGE_LOADER:
			return Object.assign({}, state, { active: 'addtips', tab: 'addtips'})
		case ADD_TIPS_PAGE_LOADER:
			return Object.assign({}, state, { active: 'add-tips-filter', tab: 'add-tips'})
		case PROFILE_LOADER:
			return Object.assign({}, state, {tab: 'profile',active: 'profile'})
		case RUNNING_LOADER:
			return Object.assign({}, state, {tab: 'running',active: 'running'})
		case UPLOAD_PAGE_LOAD:
			return Object.assign({}, state, {tab: 'uploads',active: 'uploads'})
		case AD_PAGE_LOAD:
			return Object.assign({}, state, {tab: 'ads',active: 'ads'})
		case AD_USER_LOAD:
			return Object.assign({}, state, {tab: 'ad-user',active: 'ad-user'})
		case AD_EDIT_LOAD:
			return Object.assign({}, state, {tab: 'edit-ad',active: 'edit-ad'})
		case TOKEN_NAME:
			return Object.assign({}, state, { token: action.data,updateProps: true})		
		case PAGE_NOT_FOUND_LOADED:
			return Object.assign({}, state, {tab: action.tab})
		case LOGOUT:
			return Object.assign({}, state, {tab: '', token: '', redirectTo: 'results'})
		case LANGUAGE_KEY:
			return  Object.assign({}, state, {
				languageKey: action.data,
				//tab: 'home',
				updateProps: true
			});
		case LANGUAGE_LIST:
			return  Object.assign({}, state, {
				languageslist: action.data,
				//tab: 'home',
				updateProps: true
			});
		case MEBER_NAME:
			return Object.assign({}, state, {
				member_name: action.data,
				updateProps: true
			});
		case USERID_NF:
			return Object.assign({}, state, {
				user_fiend: action.data,
				updateProps: true
			});
		case USER_TIMELINE:
			return Object.assign({}, state, {
				user_timeline: action.data,
				updateProps: true
			});
		case USER_STATISTIC:
			return Object.assign({}, state, {
				user_statistic: action.data,
				updateProps: true
			});
		case LIKE_DATA:
			return Object.assign({}, state, {
				like_data: action.data,
				updateProps: true
			});
		case RUNNING_HOME_PICK:
			return Object.assign({}, state, {
				home_pick_data: action.data,
				updateProps: true
			});
		case IMG_USER:
			return Object.assign({}, state, {
				img_user: action.data,
				updateProps: true
			});
		case ONLY_FIENDS:
			return Object.assign({}, state, {
				only_friend: action.data,
				updateProps: true
			});
		case LOAD_AD_DATA:
			return Object.assign({}, state, {
				adData: action.data,
				updateProps: true
			});
		case UDATE_PICK_COMENT:
			return Object.assign({}, state, {
				pick_coment: action.data,
				updateProps: true
			});
		case AD_LONG_DESCPT:
			return Object.assign({}, state, {
				ad_long_description: action.data,
				updateProps: true
			});
		
		case CREATE_AD_USER:
			return Object.assign({}, state, {tab: 'createaduser',active: 'createaduser'})
		case ADD_MATCH:
            return Object.assign({}, state, {tab: 'matchinfo',active: 'matchinfo'})
		case ADMIN_EDIT_USER:
			return Object.assign({}, state, {tab: 'adminedituser',active: 'adminedituser'}) 
		case REQUEST_LOADER:
			return Object.assign({}, state, {tab: 'request',active: 'request'}) 
		case ACTIVE_LOADER:
			return Object.assign({}, state, {tab: 'active',active: 'active'}) 
		case CONTACT_LOADER:
			return Object.assign({}, state, {tab: 'contact',active: 'contact'}) 
		case FRIEND_REQUEST_LOADER:
			return Object.assign({}, state, {tab: 'friends-request',active: 'friends-request'}) 
		case NOTIFICATION_LOADER:
			return Object.assign({}, state, {tab: 'notifications',active: 'notifications'}) 
		case TIPSTERS_LOADER:
			return Object.assign({}, state, {tab: 'tipters',active: 'tipters'}) 
		case TIPS_PAGE_LOADER:
			return Object.assign({}, state, {tab: 'tips',active: 'tips'}) 
		case TIPS_EXCHANGE_LOADER:
			return Object.assign({}, state, {tab: 'tips-exchange',active: 'tips-exchange'}) 
		case CHANGE_TAB:                   
			return  Object.assign({}, state, {tab: action.tab, redirectTo: action.tab, refresh: true,active:action.tab }); 
			
		default:
			return Object.assign({}, state)
  }
};
