import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import ReactDOM from 'react-dom';
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'

import {Col, Row, FormControl, FormGroup, Radio, Checkbox, Table} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,MATCHS_PAGE_LOADER,MATCH_ID} from '../../constants/actionTypes'
import NumberFormat from 'react-number-format'
import DatePicker from 'react-datepicker';
//import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: MATCHS_PAGE_LOADER })  
	},
});



 


class Match extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
            sport_tips:2,
            matchList:[],
            sporttipList:[],
            avatar:'https://www.coopbet.com/media/no_avatar.png',
            search:'',
            typeMatch: '',
			offset:0,limit:20,loading:false,loadedAll:false,scrollCus:false,
			//startDate: moment(),
			startDate: new Date(),
			filterdate:'',
			isOpenModal:false,msgaprove:'',
			
		}
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.doKeySearch=e=>{
            if (e.key == 'Enter') {
                this.getMatchs(this.state.sport_tips) ;
            }
            
        }
        this.getMatchs=this.getMatchs.bind(this)    
        this.onScrollCus = () => {
			
			const messgeContainer = document.getElementById("match-list")
			const loadedAll = this.state.loadedAll

			//if( !loadedAll && messgeContainer.scrollTop === (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
			if( !loadedAll && parseInt(messgeContainer.scrollTop) >= (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
				//let offset = parseInt(this.state.offset) + this.state.limit
				let pageno = parseInt(this.state.offset) + this.state.limit
				const newState = Object.assign({}, this.state, { offset: pageno, scrollCus: true })
				this.setState(newState, () => {
					this.getMatchs(this.state.sport_tips)
				})				
			}
		}
		//---
		this.approve=this.approve.bind(this)
        this.deletematch=this.deletematch.bind(this)
        this.allapprove=this.allapprove.bind(this)
		//---
	}                                       
  
    getMatchs(sportId){
        const search=this.state.search;
        const typeMatch=this.state.typeMatch;
       // this.setState({loading:true})
       if (typeof sportId !== 'undefined') {
           this.setState({'sport_tips':sportId});
       } else {
           sportId = this.state.sport_tips;
       }
       this.setState({'sport_tips':sportId});
        agent.admin.matchList({sportid:sportId,search:search,filteroptions:typeMatch,offset:'',
							 limit:'',filterdate:this.state.filterdate}).then((datas) => {
        //console.log(datas)
        if(datas.result=='good'){
			const matchList = this.state.scrollCus ? this.state.matchList.concat(datas.list) : datas.list
			const loadedAll = datas.list.length < this.state.limit ? true: false
			const newState = Object.assign({}, this.state, { matchList: datas.list, loading: false, loadedAll: loadedAll, scrollCus: false })
			this.setState(newState)	
            //this.setState({matchList:datas.list})
        }
      })    
    }
    typeMatchChange(e){
       this.setState({typeMatch:e.target.value,offset:0},()=>{
		   this.getMatchs(this.state.sport_tips)
	   })
       //alert(this.state.typeMatch);  
    }
    
    matchinfo(ev){
        let thiss=ev.target.closest("tr")
        let matchid=$(thiss).attr("dataid");
       
        window.localStorage.setItem( MATCH_ID, matchid);
        window.location.href = 'matchinfo';
    }
    
    
	approve(ev){
		let thiss=ev.target.closest("tr")
		let matchid=$(thiss).attr("dataid")
		let homegoal=$(thiss).find(".homegoal").val()
		let awaygoal=$(thiss).find(".awaygoal").val()
		//console.log(matchid,homegoal,awaygoal)
		 agent.admin.matchapproved({matchid:matchid,homegoal:homegoal,awaygoal:awaygoal}).then((data)=>{
			 if(data.result=="good"){
				this.setState({msgaprove:data.Approved,isOpenModal:true})
			 }
			
			 console.log(data)
		 })
	}
    
    
    deletematch(ev){
        let thiss=ev.target.closest("tr")
        let matchid=$(thiss).attr("dataid")
        let homegoal=$(thiss).find(".homegoal").val()
        let awaygoal=$(thiss).find(".awaygoal").val()
        //alert(matchid);
        //console.log(matchid,homegoal,awaygoal)
       // return;
         agent.admin.deletematch({matchid:matchid}).then((data)=>{
             if(data.result=="good"){
               this.getMatchs(this.state.sport_tips); 
               this.setState({msgaprove:"Delete match successfully!",isOpenModal:true});
             }
            
             console.log(data)
         })
    }

    
    allapprove(ev){
         let matchList=[];
		 $("#table-match tbody tr").each(function(ev){
			let matchid=$(this).attr("dataid") 
			let homegoal=$(this).find(".homegoal").val()
			let awaygoal=$(this).find(".awaygoal").val()
			matchList.push({matchid:matchid,homegoal:homegoal,awaygoal:awaygoal})
			var newarr=[];
			newarr["matchid"]=matchid
			newarr["homegoal"]=homegoal
			newarr["awaygoal"]=awaygoal
		//	matchList.push(newarr)
			
		 })
		 //console.log(matchList)
         agent.admin.allmatchapproved({matchList:matchList}).then((data)=>{
          //  if(data.result=="good"){
               // this.setState({msgaprove:data.Approved,isOpenModal:true})
           // }
            
             console.log(data)
         })
    }
	handleChange(date) {
		//let day=date.date();
		let day=date.getDate();;
		let month=date.getMonth() + 1;
		let year=date.getFullYear();
		let newdate=year+"-"+month+"-"+day
		//console.log(newdate)	
		this.setState({
		  startDate: date,filterdate:newdate
		},()=>{
			this.getMatchs(this.state.sport_tips);
		});
	}
	componentWillMount(){		
		this.props.onLoad('matchs');	
        
		const cookies = new Cookies()
		const token = cookies.get(TOKEN_NAME)
		if(token){			
			agent.setToken(token)
			this.props.onLoad( token)       
		}
        this.getSporttip();
		let language=(cookies.get('language')!=='' && cookies.get('language') !==null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		let date = new Date();
		let dateFormat=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
		this.setState({'lguages':language,filterdate:dateFormat},()=>{
			this.getMatchs(this.state.sport_tips);
		})
		//----
		
		
		
		//console.log(dateFormat)
		//----
	}
	ChangeField(field,ev){
        //alert(ev.target.value);
        this.setState({[field]:ev.target.value,offset: 0})
      
    }
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
    getSporttip(){
        agent.auth.sporttip().then((datas) => {
            if(datas.result=='good'){
                this.setState({sporttipList:datas.list})
            }
      })    
    }
    //---
    selectSportTips(ev){
        this.setState({sport_tips:ev.target.value})
      //  alert(ev.target.value);
        this.getMatchs(ev.target.value);
       // this.setState({sportid:datas.list})
       // this.getLeagues({sportID:ev.target.value});
        
    }
    seachmatches(field,ev) {
        
     this.setState({[field]:ev.target.value,offset: 0})
     this.getMatchs(ev.target.value);
      
    }
	handleChangeText(){
	 
	}	
	//------
    goalResult(match) {
        var homegoal = match.home_goal;
		var awaygoal = match.away_goal;
		
        var ptime = match.start_time;
        var isApproved = match.is_approved;
        var currentDate = new Date();
        var playtime = new Date(ptime);
        if (currentDate.getTime() < playtime.getTime()) {
                return (<span>?  -  ?</span>)
        } else if (homegoal!=null && awaygoal!=null )  {
			var sportid = match.sportid;
			var totalhomegoal = match.home_total_score;
			var totalawaygoal = match.away_total_score;
			if (totalhomegoal==0)  totalhomegoal="?";
			if (totalawaygoal==0)  totalawaygoal="?";
			var strtotalcore = totalhomegoal+" - "+totalawaygoal;
			if (sportid!=3) {
				strtotalcore ="";
			}
                //if (isApproved!=null)  {
                   // return (homegoal + " - " + awaygoal)
               // } else {
					return (
						<div>
							<NumberFormat thousandSeparator={true} className="form-control input-result homegoal" 
								value={homegoal} onValueChange={this.handleChangeText.bind(this)} />-
							<NumberFormat thousandSeparator={true} className="form-control input-result awaygoal" 
								value={awaygoal} onValueChange={this.handleChangeText.bind(this)} />
						<div style={{'paddingLeft':'4px'}}>{strtotalcore}</div>    
						

						</div>
						
					)
                    //return (<span><input type="text" defaultValue ={homegoal} className="form-control form-control-solid input-circle input-match home_goal"/>-<input type="text" defaultValue={awaygoal} className="form-control form-control-solid input-circle input-match away_goal"/></span>)
                //}
             } else {
                if (homegoal==null)  homegoal="";
                if (awaygoal==null)  awaygoal="";
               // return (<span><input type="text" defaultValue ={homegoal} className="form-control form-control-solid input-circle input-match home_goal"/>-<input type="text" defaultValue={awaygoal} className="form-control form-control-solid input-circle input-match away_goal"/></span>)
				return (
						<div>
							<NumberFormat thousandSeparator={true} className="form-control input-result homegoal" 
								value={homegoal} onValueChange={this.handleChangeText.bind(this)} />
								-
							<NumberFormat thousandSeparator={true} className="form-control input-result awaygoal" 
								value={awaygoal} onValueChange={this.handleChangeText.bind(this)} />
						
						</div>
					)
             }       
      
    }
    //--------------------------------------------------
    //------
    addmatch() {
      alert("hello match");      
      
    }
    
	render(){   
		const matchList=this.state.matchList;
		const loading=this.state.loading;
        const sporttipList=this.state.sporttipList;
		//console.log(matchList)
        return (
			<div id="body">   
				<div>
					<div className="heder-page" style={{'padding':'10px 0'}}>
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">MATCHS</span>
								</h1>
                                
							</div>
                            <Col md={12} style={{'padding':'0px'}}>
                            <Col md={2} style={{'paddingTop':'10px','paddingLeft':'0px'}}>
                            <a className="addmatch" href="./matchnew" style={{'margin':'0px'}}>  Match Management  </a></Col>
                            <Col md={10} style={{'paddingRight':'0px'}}>
                                <div className="tool-r" id="match-captions">							
								    <div className="input-icon right input-icon-search">
									    <i className="fa fa-search search-match" onClick={this.seachmatches.bind(this,"search")}></i>
									    <input type="text" className="form-control form-control-solid input-circle input-search"                                        
										    placeholder="Search"
										    onChange={this.ChangeField.bind(this,'search')}
										    onKeyPress={this.doKeySearch}  
									    />
								    </div>
								    <FormControl componentClass="select" className="select-search" value={this.state.typeMatch} onChange={this.typeMatchChange.bind(this)}>
									    <option value="all">All</option>
									    <option value="have_result">Result</option>
									    <option value="need_to_approve">Need to approved</option>
								    </FormControl>
                                    
                                    <div className="daterange" style={{'paddingRight':'5px'}}>
                                        <FormControl style={{'borderRadius':'10px'}} componentClass="select" placeholder="select" value={this.state.sport_tips} onChange={this.selectSportTips.bind(this)}>
                                            
                                            {
                                                (sporttipList)?sporttipList.map(data=>{
                                                    return( <option value={data.id} key={data.id}> {data.title} </option> )
                                                }):''
                                            }
                                        </FormControl> 
                                    </div>
                                	    
								    <div id="daterange" style={{'float':'right','paddingTop':'5px'}}>
									    <DatePicker className="input-date"
										    selected={this.state.startDate}
										    onChange={this.handleChange.bind(this)}										
									    />
								    </div>
                                </div>
                             
                             </Col>    
                            </Col>   
						</div>
					</div>
					<div className="tipster-list">
					<div className="container">
                        <Row>
                       <Col md={12} className="msg-error" id="msg-error"></Col>
                                <Col md={12} className="msg-succesfull" id="msg-succesfull" style={{'paddingLeft':'0px','color':'green'}}></Col> 
                                
                       </Row> 
						<Row>
                        	<Col md={12} >
								<div className="table_bound">
									<Table className="common" style={{'marginBottom':'0px'}}>
											<thead>
												<tr>
													<td>League</td>
													<td style={{'width':'25%'}}>Match</td>
													<td style={{'width':'10%'}}>Start time</td>
													<td style={{'width':'15%'}}>Result</td>
													<td style={{'width':'10%'}}>Approved at</td>
                                                    <td style={{'width':'10%','textAlign':'center'}}>Delete</td>
													<td style={{'width':'10%','textAlign':'right'}}><a className="approve apr-all" onClick={this.allapprove}>Approve all</a></td>
														
												</tr>
											</thead>
									</Table>
									<div className="scroller11" id="match-list" onScroll={this.onScrollCus11}>
										{										
											loading ? (
												<div className="loader"></div>
											) : null
										}  
										<Table className="common" id="table-match">	
											<tbody>    
												  {
                                                    (matchList)?matchList.map((data,index)=>{
                                                        //const summary=data.SummaryBet
                                                        let str_start_time = data.str_start_time;
														str_start_time=str_start_time.split(" ");
														let startD=str_start_time[0]
														let startT=str_start_time[1]
														let startM=str_start_time[2]
                                                        var hgoal = data.home_goal;
                                                        var agoal = data.away_goal;
                                                        var match_code = data.code;
                                                      //  console.log("match code:"+ match_code.length);
														let approD='';let approT='';let approM='';
                                                        let str_approved_at=data.str_approved_at;
														if(str_approved_at){
															str_approved_at=str_approved_at.split(" ");
															approD=str_approved_at[0]
															approT=str_approved_at[1]
															approM=str_approved_at[2]
														}
                                                        var ptime = data.start_time;
														var isApproved = data.is_approved;
														var currentDate = new Date();
														var playtime = new Date(ptime);
														
                                                        return (
                                                                <tr className="matchs" key={index} dataid={data.id}>
                                                                    <td>
                                                                        <span>{data.league_name}</span>
                                                                    </td>
                                                                    <td  style={{'width':'25%'}}>
                                                                    <a className="match-link" onClick={this.matchinfo}><span>{data.home_team_name.split('&amp;').join('')} - {data.away_team_name}</span></a>
                                                                    
                                                                         
                                                                    </td>
                                                                    <td style={{'width':'10%'}}>
                                                                        <div>{startD}</div>    
                                                                        <div>{startT} {startM}</div>    
                                                                    </td>
                                                                    <td style={{'width':'15%'}}>
                                                                        {this.goalResult(data)}
																	
                                                                    </td>
                                                                   <td style={{'width':'10%'}}>
                                                                        <div>{approD}</div>    
                                                                        <div>{approT} {approM}</div> 
                                                                    </td>
                                                                    <td style={{'width':'10%','textAlign':'center','paddingTop':'15px','textAlign':'right'}}>
                                                                        <a className="approve" onClick={this.deletematch}>{(data.code.length<=12)?'Delete':'Clear'}</a>  
                                                                        
                                                                    </td>
                                                                    <td style={{'width':'10%','textAlign':'center','paddingTop':'15px','textAlign':'right'}}>
                                                                        {(currentDate.getTime() < playtime.getTime())?'':<a className="approve" onClick={this.approve}>{(data.is_approved==1)?'Edit':'Approve'}</a>}  
                                                                        
                                                                    </td>
                                                                </tr>
                                                        )
                                                    }):''
                                                }
											</tbody>
										</Table>
									</div>
								</div>
							</Col>
						</Row>
                        
					</div>
					</div>
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgaprove}/>
			</div>
        );
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(Match)