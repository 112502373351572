import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox } from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';

import agent from '../../agent'


import '../../styles/modal.css'; 

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  //BUILDING_LOADED,
  
}from '../../constants/actionTypes'; 
import menu from '../common/menu.json'
import { Cookies } from 'react-cookie'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import TextareaAutosize from 'react-textarea-autosize';

const mapStateToProps = state => (
    Object.assign({}, state, {
		languageKey: state.common.languageKey
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ModalErorr extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,
			txt_title:'',
			lguages:'1005',
			txt_description:'',PickCode:'',comment_id:''
			
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};		
		
		//---
		this.handleVale=(field,ev)=>{
			this.setState({[field]:ev.target.value})
		}		
		this.editComment=()=>{
			let comment=this.state.txt_description
			let for_pick_code=this.state.PickCode
			let comment_id=this.state.comment_id
			agent.home.edit_in_own_topic({comment_id:comment_id,for_pick_code:for_pick_code,comment:comment}).then(data => {
				if(data.result=="good"){
					this.props.updateCommentList(comment_id,comment)
					this.props.close();
				}else{
					this.props.callModalError(data.message)
				}
			})
		}
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,
				txt_title:nextProps.txt_title,txt_description:nextProps.txt_comment,PickCode:nextProps.PickCode,
				comment_id:nextProps.comment_id,
			});
			
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		
		
		
	}
	componentDidMount() {
		
		
		
		
	}
	componentWillMount() {
		
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		
	}
 
  render() {
	  // const oderInfo=this.props.orderParams;//console.log(oderInfo)
	
        return (
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="modal-edit_descr"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title"><span style={{'color':'#b81900'}}>{this.state.txt_title}</span></span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body>
					<Row className="mg0">		
						<TextareaAutosize className="textarea-edit"
							value={this.state.txt_description}
							onChange={this.handleVale.bind(this,'txt_description')}
						/>
						
						<Col md={12} className="lblMessage" id="lblMessage" style={{'color':'red'}}></Col>
					</Row>
			  </Modal.Body>
			  <Modal.Footer>
					<div className="col-lg-12" style={{'textAlign':'left','padding':'0'}}> 						
						<button type="button" className="btn btn-primary" onClick={this.editComment}>{localizeKey(menu)('save', this.state.lguages)}</button>
					</div>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalErorr); 
//export default ModalComfirmOrder