import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import {Col, Row, FormControl, FormGroup, Radio, OverlayTrigger, Popover, Button} from 'react-bootstrap'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB} from '../../constants/actionTypes'
import $ from 'jquery';
import Captcha from './formCaptcha.js'
import ModalErorr from '../common/ModalErorr'
import { withRouter, history} from 'react-router-dom'
import { localize, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import Lqhome from '../common/home.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Forgot extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',email:'',number:'',newpass:'',captcharValue:'',dorepas:true,
			isOpenModal:false,msgError:'',
			
		}
		//---
		this.goSendNum=this.goSendNum.bind(this)
		this.goSendNewPass=this.goSendNewPass.bind(this)
		this.cancel=this.cancel.bind(this)
		this.updateCaptcha=this.updateCaptcha.bind(this) 
		this.newpw=this.newpw.bind(this) 
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.emailRecoverycode=(email)=>{			
			agent.auth.emailRecoverycode({email:email,lang_id:this.state.lguages}).then(data => {
				if(data.result=="good"){
					$(".email-div").hide();
					$(".mabm-div").show();
					$(".error1").html('')
				}else{
					this.setState({msgError:data.message,isOpenModal:true}) 
				}
			})			
		}
		this.checkRecoverycode=(recoverycode)=>{
			let email=this.state.email
			agent.auth.checkRecoverycode({recoverycode:recoverycode,email:email,lang_id:this.state.lguages}).then(data => {
				if(data.result=="good"){
					$(".email-div").hide();
					$(".mabm-div").hide();
					$(".newpass-div").show();
					$(".error2").html('')
				}else{
					this.setState({msgError:data.message,isOpenModal:true}) 
				}
			})
		}
		//---
  }
	//---
	
	componentWillMount(){		
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	handleValue(feild,e){
		this.setState({[feild]:e.target.value})
		$(".error1").html('')
	}
	goSendNum(e){
		let email=this.state.email;
		$(".error1").html('')
		if(email!=''){			
			this.emailRecoverycode(email)
		}else{
			$(".error1").html('The email field is required.')
		}
		
	}
	goSendNewPass(e){
		let number=this.state.number;
		$(".error2").html('')
		//if(number!=''){
			this.checkRecoverycode(number);
		//}else{
			//$(".error2").html('The recovery code field is required.')
		//}
		
	}
	cancel(e){
		this.setState({email:'',number:'',newpass:''})
		window.location='/home'
	}
	updateCaptcha(vale){
		//console.log(vale)  
		this.setState({captcharValue:vale})
		if(vale!='' && vale!=null && vale!="null"){
			this.setState({dorepas:false})
		}else{
			this.setState({dorepas:true})
		}
	}
	newpw(){
		let email=this.state.email
		let new_password=this.state.newpass
		let recoverycode=this.state.number
		agent.auth.newpw({email:email,recoverycode:recoverycode,new_password:new_password,lang_id:this.state.lguages}).then(data => {
			console.log(data)
			if(data.result=="good"){
				this.props.history.push("/reset-password")
			}else{
				this.setState({msgError:data.message,isOpenModal:true}) 
			}
		})
	}
	render(){   
		
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">Forgot Password</span>
								</h1>
							</div>
						</div>
					</div>
					
					<div className="container">
					{
						//---
					}
						<div className="register page">
							<div className="regis-ctn">
								<Row className="email-div">
									<Col md={3}></Col>
									<Col md={6}>
										<div className="portlet box green">
											<div className="portlet-title">
												<div className="caption">Email tài khoản </div>
											</div>
											<div className="portlet-body">
												<Row className="mg0">
													<Col md={1}></Col>
													<Col md={10}>
														<Col md={12} className="mgbt10"> {localizeKey(Lqhome)('txtfg1', this.state.lguages)}</Col>
														<Col md={12} className="mgbt10">
															<input type="text" id="email" className="form-control" value={this.state.email}  onChange={this.handleValue.bind(this,'email')} placeholder="email"/>
														</Col>
														<Col md={12} className="mgbt10" id="capcha">
															<Captcha captcha={this.updateCaptcha} />
														</Col>
														<Col md={12} className="error1"></Col>
														<Col md={12} className="txtR">
															<Button className="btn-info" disabled={this.state.dorepas} onClick={this.goSendNum}>Tiếp tục</Button>
															 <Button className="mgl5" onClick={this.cancel}>Hủy</Button>
														</Col>
													</Col>
													<Col md={1}></Col>
												</Row>
											</div>
										</div>
									</Col>
									<Col md={3}></Col>
								</Row>
								
								<Row className="mabm-div" style={{'display':'none'}}>
									<Col md={2}></Col>
									<Col md={8}>
										<div className="portlet box green">
											<div className="portlet-title">
												<div className="caption">Nhập mã bảo mật </div>
											</div>
											<div className="portlet-body">
												<Row className="mg0">
													<Col md={1}></Col>
													<Col md={10}>
														<Col md={12} className="mgbt10"> {localizeKey(Lqhome)('logwithothe2', this.state.lguages)}</Col>
														<Col md={12} className="mgbt10 pd0">
															<Col md={5}>
																<input type="text" id="" className="form-control" value={this.state.number}  onChange={this.handleValue.bind(this,'number')} placeholder="Nhập mã"/>
															</Col>
															<Col md={2}></Col>
															<Col md={5} className="">
																<div>{localizeKey(Lqhome)('logwithothe3', this.state.lguages)}:</div>
																<div className="txtL">{this.state.email}</div>
															</Col>
															
														</Col>
														<Col md={12} className="error2"></Col>
														<Col md={12} className="txtR">
															<Button className="btn-info" onClick={this.goSendNewPass}>Tiếp tục</Button>
															 <Button className="mgl5" onClick={this.cancel}>Hủy</Button>
														</Col>
													</Col>
													<Col md={1}></Col>
												</Row>
											</div>
										</div>
									</Col>
									<Col md={2}></Col>
								</Row>
								
								<Row className="newpass-div" style={{'display':'none'}}>
									<Col md={2}></Col>
									<Col md={8}>
										<div className="portlet box green">
											<div className="portlet-title">
												<div className="caption">Chọn mật khẩu mới </div>
											</div>
											<div className="portlet-body">
												<Row className="mg0">
													<Col md={1}></Col>
													<Col md={10}>
														<Col md={12} className="mgbt10"> {localizeKey(Lqhome)('logwithothe4', this.state.lguages)}</Col>
														<Col md={12} className="mgbt10 pd0">
															<Col md={3} className="lh32">{localizeKey(Lqhome)('logwithothe5', this.state.lguages)}:</Col>
															<Col md={6} className="pd0">
																<input type="password" id="newpass" className="form-control" value={this.state.newpass}  onChange={this.handleValue.bind(this,'newpass')} placeholder="New password" style={{'width':'304px'}}/>
																<div className="note-text">Password phải gồm 8 ký tự bao gồm chữ, số và ký tự đặt biệt (1,2,3 ..., a, b, c, … &amp; $,#,%, …)</div>
															</Col>															
														</Col>														
														<Col md={12} className="txtR">
															<Button className="btn-info" onClick={this.newpw}>Tiếp tục</Button>
															 <Button className="mgl5" onClick={this.cancel}>Hủy</Button>
														</Col>
													</Col>
													<Col md={1}></Col>
												</Row>
											</div>
										</div>
									</Col>
									<Col md={2}></Col>
								</Row>
								
							</div>
						</div>
					{
						//---
					}
					</div>
					<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgError}/>
				</div>
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Register)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgot));