import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY,USERID_NF,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import Datetime from "react-datetime"
//import Moment from "moment";

//import YearPicker from "react-year-picker";
import MonthPickerInput from 'react-month-picker-input';
//require('react-month-picker-input/dist/react-month-picker-input.css');
import { localize, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import Lqhome from '../common/home.json'
import cahome from '../common/captionhome.json';
import DatePicker from 'react-datepicker';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		user_statistic: state.common.user_statistic,
		languageKey: state.common.languageKey,
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class ListBet extends React.Component {
	constructor(){
		super()
		this.state = {
			lguages:'1005',year:'',month:'',
			AlltimeData:{},MonthData:{},QuarterData:{},YearData:{},
			value:'',startDate: new Date()
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		this.onChange = this.onChange.bind(this);
		//----
		
		
	}
	
	 onChange(e) {
		this.setState({ value: e });
	  }
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		//console.log(nextProps)
		if(nextProps.user_statistic){
			let data=nextProps.user_statistic;
			this.setState({YearData:data.YearData,QuarterData:data.QuarterData,MonthData:data.MonthData,AlltimeData:data.AlltimeData})
		}
	}	

	componentWillMount() {
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//---
		let date = new Date();
		let dateFormat=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
		
		
		//this.setState({year:parseInt(date.getFullYear()),month:parseInt(date.getMonth())})
		this.setState({year:parseInt(date.getFullYear()),month:3})
		
	}
	componentDidMount(){
		$(".react-datepicker-ignore-onclickoutside").prop("readonly",true);
	}
	CollapseList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').hide();
		$(thiss).find('.collapse').hide();
		$(thiss).find('.expand').show();
	}
	ExpandList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').show();
		$(thiss).find('.collapse').show();
		$(thiss).find('.expand').hide();
	}
	handleChangeMonth(date){
		this.setState({ value: date.valueOf() });
		let dates=date.toDate();
		let month=dates.getMonth() + 1;
		let year=dates.getFullYear();
		var mth=month+"/"+year;
		this.props.statisticMonth(mth)
		//console.log(mth)
		
	}
	handleChangeYear(date){
		let dates=date.toDate();
		let year=dates.getFullYear();
		this.props.statisticYear(year);
		//console.log(year)
		
	}
	handleChangeQuater(date){
		let dates=date.toDate();
		var month = dates.getMonth() + 1;
		var quarter=Math.ceil(month / 3);
		let year=dates.getFullYear();
		let Qa=quarter+"/"+year;
		console.log(Qa)
		this.props.statisticQuater(Qa)
	}
	
	handleChangeQ(date){
	    let dates=new Date(date)
		var month = date.getMonth() + 1;
		var quarter=Math.ceil(month / 3);
		let year=date.getFullYear();
		let Qa=quarter+"/"+year;
		//console.log(Qa)
		this.props.statisticQuater(Qa)
		//console.log(date,Qa)
		this.setState({startDate:date})
	}
	render() {
		let MonthData=this.state.MonthData
		let QuarterData=this.state.QuarterData
		let YearData=this.state.YearData
		let AlltimeData=this.state.AlltimeData
		var date = new Date();
		//console.log(this.state.lguages)
		return(
			<Row>
			{
				//---month
			}
				<Col md={3} className=" bet-items profile-box" id="list-month">
					<div className="bet-title sh-item">
						<a className="league-link" href="javascript:;">{localizeKey(Lqhome)('month', this.state.lguages)}: </a>
						<div className="div-month">
						{
							/*
							<MonthPickerInput 
								year={this.state.year}
								month={this.state.month}	
								onChange={this.handleChangeMonth.bind(this)}
								className="months"
								dateFormat={"MM/YYYY"}
							/>
							<Datetime dateFormat="YYYY-MM" timeFormat={false} 
								defaultValue={new Date('2018-12-01')}
								onChange={this.handleChangeMonth.bind(this)}
							/>
							*/
						}
							
							<Datetime dateFormat="MM/YYYY" timeFormat={false} 
								//defaultValue={new Date('2018-12-01')}
								defaultValue={new Date()}
								onChange={this.handleChangeMonth.bind(this)}
								closeOnSelect={true}
								inputProps={{readOnly: true}}
							/>
							
						</div>
						<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
						<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
					</div>
					<Row className="data sh-snap-menu">
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(Lqhome)('rank', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{MonthData.Rank}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(Lqhome)('picked', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{MonthData.Picked}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="won">Win</span></span></Col>
							<Col md={5} ><span className="num">{MonthData.Win}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="draw">Draw</span></span></Col>
							<Col md={5} ><span className="num">{MonthData.Draw}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="lost">Lose</span></span></Col>
							<Col md={5} ><span className="num">{MonthData.Lost}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('title5', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{MonthData.Amount}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('Profit', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{MonthData.Profit}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('Yield', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{MonthData.ProfitPercentage2}</span></Col>
						</Col>
					</Row>
				</Col>
				{
					//--- quarter
				} 
				<Col md={3} className=" bet-items profile-box" id="list-quarter">
					<div className="bet-title sh-item">
						<a className="league-link" href="javascript:;">{localizeKey(Lqhome)('quarter', this.state.lguages)}: </a>
						<div className="div-quarter">
						{
							/*<Datetime dateFormat="Q" timeFormat={false} 
								//defaultValue={new Date('2018-12-01')}
								defaultValue={new Date()}
								onChange={this.handleChangeQuater.bind(this)}
								closeOnSelect={true}
								inputProps={{readOnly: true}}
							/>*/
						}	
							<DatePicker
							  selected={this.state.startDate}
							  onChange={this.handleChangeQ.bind(this)}
							  dateFormat="yyyy, QQQ"
							  showQuarterYearPicker
							  
							/>
						</div>
						<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
						<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
					</div>
					<Row className="data sh-snap-menu">
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(Lqhome)('rank', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{QuarterData.Rank}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(Lqhome)('picked', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{QuarterData.Picked}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="won">Win</span></span></Col>
							<Col md={5} ><span className="num">{QuarterData.Win}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="draw">Draw</span></span></Col>
							<Col md={5} ><span className="num">{QuarterData.Draw}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="lost">Lose</span></span></Col>
							<Col md={5} ><span className="num">{QuarterData.Lost}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('title5', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{QuarterData.Amount}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('Profit', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{QuarterData.Profit}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('Yield', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{QuarterData.ProfitPercentage2}</span></Col>
						</Col>
					</Row>
				</Col>
				{
					//--- year
				}
				<Col md={3} className=" bet-items profile-box" id="list-year">
					<div className="bet-title sh-item">
						<a className="league-link" href="javascript:;">{localizeKey(Lqhome)('year', this.state.lguages)}: </a>
						<div className="div-year">
						{
							//<YearPicker onChange={this.handleChangeYear.bind(this)}
							//defaultValue={2018} 
							///>
							//<Datetime dateFormat="YYYY" value = {new Date('2018-01-01')}/>
						}
							<Datetime dateFormat="YYYY" 
								//defaultValue = {new Date('2018-01-01')}
								defaultValue = {new Date()}
								onChange={this.handleChangeYear.bind(this)}
								closeOnSelect={true}
								inputProps={{readOnly: true}}
							/>
						</div>
						<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
						<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
					</div>
					<Row className="data sh-snap-menu">
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(Lqhome)('rank', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{YearData.Rank}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(Lqhome)('picked', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{YearData.Picked}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="won">Win</span></span></Col>
							<Col md={5} ><span className="num">{YearData.Win}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="draw">Draw</span></span></Col>
							<Col md={5} ><span className="num">{YearData.Draw}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="lost">Lose</span></span></Col>
							<Col md={5} ><span className="num">{YearData.Lost}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('title5', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{YearData.Amount}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('Profit', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{YearData.Profit}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('Yield', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{YearData.ProfitPercentage2}</span></Col>
						</Col>
					</Row>
				</Col>
				{
					//--- all time
				}
				<Col md={3} className=" bet-items profile-box" id="list-year">
					<div className="bet-title sh-item">
						<a className="league-link" href="javascript:;">{localizeKey(Lqhome)('alltime', this.state.lguages)}: </a>
						
						
						<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
						<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
					</div>
					<Row className="data sh-snap-menu">
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(Lqhome)('rank', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{AlltimeData.Rank}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(Lqhome)('picked', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{AlltimeData.Picked}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="won">Win</span></span></Col>
							<Col md={5} ><span className="num">{AlltimeData.Win}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="draw">Draw</span></span></Col>
							<Col md={5} ><span className="num">{AlltimeData.Draw}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label"><span className="lost">Lose</span></span></Col>
							<Col md={5} ><span className="num">{AlltimeData.Lost}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('title5', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{AlltimeData.Amount}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('Profit', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{AlltimeData.Profit}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={7} ><span className="label">{localizeKey(cahome)('Yield', this.state.lguages)}</span></Col>
							<Col md={5} ><span className="num">{AlltimeData.ProfitPercentage2}</span></Col>
						</Col>
					</Row>
				</Col>
				{
					//---
				}
			</Row>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ListBet)
