import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {FRIEND_REQUEST_LOADER, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import { Cookies } from 'react-cookie'
import { localize, localizeKey } from 'react-redux-localization';
import menu from '../common/menu.json'
import cphome from '../common/captionhome.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({               
	onLoadPage: (data) => {
		dispatch({ type: FRIEND_REQUEST_LOADER, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class AllFriends extends React.Component {
	constructor(){
		super()
		this.state = {
			url:'https://www.coopbet.com/media/avatar_none.png',
			CanKnowList:[],PendingFriendRequestList:[],isOpenModal:false,msgLogin:'',
			language:'1005',
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		
		//----
		
		
	}
	
	
	//---------------------------
   

	componentWillMount() {
		this.props.onLoadPage();
		//---
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({'language':language})
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		 if(login!='' && login!='null' && login!=null){
			 this.friendlist()
		 }else{
			
		 }
		
	}	
	componentWillReceiveProps(nextProps) {
		if(nextProps.languageKey){
			this.setState({language:nextProps.languageKey});
		}
	}
	friendlist(){
		agent.friends.requestlistandcanknowlist({limit:0}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				const CanKnowList=datas.CanKnowList.map(data=>{
					data.status='';
					return data;
				})
				this.setState({CanKnowList:datas.CanKnowList,PendingFriendRequestList:datas.PendingFriendRequestList})
			}
			
		  })	
	}
	createrequest(ev){
		
		let thiss=ev.target.closest('.tfd-addfriend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				const CanKnowList=this.state.CanKnowList.map(item=>{
					if(item.user_id==to_user_id){
						item.status='Pending'
					}
					return item;
				})
			this.setState({CanKnowList:CanKnowList})	
				
			}else{
				
				$(thiss).find('.loading').hide();
			}
		})
	}
	
	removeRequest(ev){
		let thiss=ev.target.closest('.tfd-delete');
		const to_user_id=$(thiss).attr("data-id")
		 let filteredArray = this.state.CanKnowList.filter(item => {
			 if(item!='' && item!='null' && item!=null && item.user_id != to_user_id){
				 return item
			 }else{
				 return null;
			 }
		 })
    
		this.setState({CanKnowList:filteredArray})
	}
	deleteRequest(ev){
		let thiss=ev.target.closest('.tfd-delete');
		let thisss=ev.target.closest('.ft-item');
		let friend_request_id=$(thiss).attr("data-id");
		agent.friends.deleteRequest({friend_request_id:friend_request_id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				const PendingFriendRequestList=this.state.PendingFriendRequestList.map(item=>{
					if(item!='' && item!='null' && item!=null && item.request_id!=friend_request_id){
						return item;
					}else{
						return null;
					}
					
				})
				this.setState({isOpenModal:false,msgLogin:'',PendingFriendRequestList:PendingFriendRequestList})
			}
			else{
				this.setState({isOpenModal:true,msgLogin:datas.message})
			}
			
		  })	
	}
	confirmRequest(ev){
		let thiss=ev.target.closest('.tfd-addfriend');
		let thisss=ev.target.closest('.ft-item');
		let friend_request_id=$(thiss).attr("data-id");
		agent.friends.confirmRequest({friend_request_id:friend_request_id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				const PendingFriendRequestList=this.state.PendingFriendRequestList.map(item=>{
					if(item!='' && item!='null' && item!=null && item.request_id!=friend_request_id){
						return item;
					}else{
						return null;
					}
					
				})
				this.setState({isOpenModal:false,msgLogin:'',PendingFriendRequestList:PendingFriendRequestList})
				//$(thisss).remove();
			}else{
				this.setState({isOpenModal:true,msgLogin:datas.message})
			}
			
		  })	
	}
	
	
	render() {
		$(".body").css({'background':'#e9ebee'})
		const PendingList=this.state.PendingFriendRequestList
		const CanKnowList=this.state.CanKnowList
		const url=this.state.url
		//---------
			return (
				<div className="body">
					<div className="container">
						<Row>
							<Col md={8} className="friend-body">
								<div className="friens-top" id="friend-request">
									<div className="frd-top">
										<div className="ft-items">
											<h3 className="header _hdf">{localizeKey(menu)('txtRepondRequest', this.state.language)}</h3>
											<div className="ctn">
											{
												(PendingList!='' && PendingList!='null' && PendingList!=null)?PendingList.map((data,index)=>{
													if(data!='' && data!='null' && data!=null){
														var url_username=data.url_username
														let prName='';
														let url_profile='';
														if(url_username!='' && url_username!='null' && url_username!=null){
															prName=url_username.split("/");
															url_profile=prName[prName.length-1];
														}
														return (
															<div className="ft-item ft-item-7" key={index}>
																<figure className="pull-left">
																		<img className="img-ntf" src={(data.imageurl!='' && data.imageurl!='null' && data.imageurl!=null)?data.imageurl:url} width="50" height="50"/>
																</figure>
																<div className="name pull-left" style={{'width':'auto'}}>
																	<a href={"/"+url_profile}><span>{data.tipster_name}</span></a>
																	<div className="txt-fmf">{data.created_at_string}</div>
																</div>
																<div className="g-btn pull-right">
																	<a className="btn-2 pull-right tfd-delete tfd-delete-7" data-id={data.request_id} onClick={this.deleteRequest.bind(this)}>Delete Request</a>
																	<a className="btn-1 pull-right tfd-addfriend" data-id={data.request_id} onClick={this.confirmRequest.bind(this)}>
																		Confirm
																		<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20"/>
																	</a>
																</div>
															</div>
														)
													}
												}):<div className="empty list-gray" style={{'display':''}} >
														{localizeKey(menu)('txtNoRequestFrn', this.state.language)}
													</div>
											}
													
													
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={8} className="friend-body">
								<div className="friens-top" id="friend-know">
									<div className="frd-top">
										<div className="ft-items">
											<h3 className="header _hdf">{localizeKey(menu)('frdYcanKnown', this.state.language)}</h3>
											<div className="ctn">
											{
											(CanKnowList!='')?CanKnowList.map((data,key)=>{
												var url_username=data.url_username
												let prName='';
												let url_profile='';
												if(url_username!='' && url_username!='null' && url_username!=null){
													prName=url_username.split("/");
													url_profile=prName[prName.length-1];
												}
												return(
													<div className={'ft-item ft-item-'+key} key={key}>
														<figure className="pull-left">
																<img className="img-ntf" src={(data.imageurl!='' && data.imageurl!='null' && data.imageurl!=null)?data.imageurl:url} width="50" height="50"/>
														</figure>
														<div className="name pull-left" style={{'width':'auto'}}>
															<a href={"/"+url_profile}><span>{data.tipster_name}</span></a>
														</div>
														<div className="g-btn pull-right">
															<a className="btn-2 pull-right tfd-delete tfd-delete-7" data-id={data.user_id} onClick={this.removeRequest.bind(this)} style={{'display':(data.status=='')?'inherit':'none'}}>{localizeKey(menu)('delete', this.state.language)}</a>
															{
																(data.status=='')?<a className="btn-1 pull-right tfd-addfriend" data-id={data.user_id} onClick={this.createrequest.bind(this)}>
																	<i className="_3-8_ img sp_QO36LKJrAiM sx_1f63e7"></i>{localizeKey(cphome)('AF', this.state.language)}
																	<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20"/>
																</a>:
																<a className="btn-1 pull-right tfd-addfriend-gray" data-id={data.user_id}>
																	<i className="_3-8_ img sp_88nz5MexVSt sx_7ffadc"></i>{localizeKey(cphome)('requestSend', this.state.language)}
																	<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20"/>
																</a>
															}
															
														</div>
													</div>
												)
											}):''
										}
													
													
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
					</div>
				</div>
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AllFriends)
