import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale,localizeKey } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import {Col, Row, FormControl, FormGroup, Radio, OverlayTrigger, Popover, Button,Checkbox } from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB, MEBER_NAME} from '../../constants/actionTypes'
import $ from 'jquery';
import Datetime from "react-datetime"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
//import languages from '../../languages'
//import translations from '../common/captionhome.json';
import ModalErorr from '../common/ModalErorr'
import UploadBanner from '../common/UploadBanner'
import UploadAdUserLogo from '../common/UploadAdUserLogo'
import UploadBannerMobile from '../common/UploadBannerMobile'
import { Cookies } from 'react-cookie' 
import menu from '../common/menu.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setMember: (data)=>{ 
		dispatch({ type: MEBER_NAME, data })
	},
});



 


class Register extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			old_avatar: 'https://www.coopbet.com/media/no_avatar.png',
			avatar: 'https://www.coopbet.com/media/no_avatar.png',
			years:[],	
			sport_tips:1,sporttipList:[],timezoneList:[],countryList:[],
			country_id:'',time_zone:4,odds_formart:1,member_name:'',tipster_name:'',
			email:'',password_confirmation:'',password:'',user_name:'',name:'',sex:2,
			date:'',month:'',year:'',first_name:'',last_name:'',imgCheck:'fa fa-check',
			textCheck:'Username url is available.',isOpenModal:false,msgRigister:'',url_username:'',
			editpassword:false,mobile_num:'',mobile_number:'',verify_code:'',id_user:'',verify :'verify ',
			is_validate:0,role_id:'',languageList:[],ad_lang_id:'1005',aduser_url:'',
			aduser_introduction:'',adhiring_startdate:'',adhiring_startdateobj:new Date(),
			adhiring_enddate:'',adhiring_enddateobj:new Date(),
			old_aduser_logo_url: 'https://back-end.coopbet.com/gf/e2IogaxeSt2826_no-logo.png',
			aduser_logo_url:'https://back-end.coopbet.com/gf/e2IogaxeSt2826_no-logo.png',
			bannermobileadurl:'https://back-end.coopbet.com/gf/iPAcUstMKm2436_no-banner.png',
			old_banneradurl:'https://back-end.coopbet.com/gf/iPAcUstMKm2436_no-banner.png',
			banneradurl:'https://back-end.coopbet.com/gf/iPAcUstMKm2436_no-banner.png',
			aduser_number_posttips_perday:'',checkurl :0,is_validate:'',validated_time:'',
			suffix_url_name:''
		}
		//---
		this.handleGetFileUpload = (imageURL) => { 
			//console.log(imageURL)
			const avatar = (imageURL!='') ? imageURL : this.state.old_avatar				
			const newState = Object.assign({}, this.state,{avatar:avatar})
			this.setState(newState)	
		}		
		//---
		this.handleGetBannerUpload = (imageURL) => { 
			//console.log("handleGetBannerUpload")
			const banneradurl	= (imageURL!='') ? imageURL : this.state.old_banneradurl			
			const newState = Object.assign({}, this.state,{banneradurl:banneradurl})
			this.setState(newState)	
		}
		//---
		this.handleGetAduserLogoUpload = (imageURL) => { 
			//console.log("handleGetBannerUpload")
			const aduser_logo_url	= (imageURL!='') ? imageURL : this.state.old_aduser_logo_url			
			const newState = Object.assign({}, this.state,{aduser_logo_url:aduser_logo_url})
			this.setState(newState)
		}
		this.handleGetMobileBannerUpload = (imageURL) => { 
			//console.log("handleGetBannerUpload")
			const bannermobileadurl 	= (imageURL!='') ? imageURL : this.state.old_banneradurl			
			const newState = Object.assign({}, this.state,{bannermobileadurl :bannermobileadurl })
			this.setState(newState)	
		}
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		this.editProfile=this.editProfile.bind(this) 
		this.check_url=this.check_url.bind(this) 
		this.check_url_friendly=this.check_url_friendly.bind(this) 
		//---
		this.handleClickTab = menu => e => {
			e.preventDefault()
			this.props.onTabClick(menu)
			
		}
		this.sendotpsms=()=>{
			let id_user=this.state.id_user;
			let NumberToSend=this.state.mobile_number
			let PhoneCode =this.state.mobile_num
			//console.log(NumberToSend)
			agent.auth.sendotpsms({userid:id_user,NumberToSend:NumberToSend,PhoneCode:PhoneCode,OtpType:1}).then((datas) => {
			//console.log(datas)
				if(datas.result=='good'){
					this.setState({msgRigister:datas.otpreturn,isOpenModal:true})
				}else{
					this.setState({msgRigister:datas.otpreturn,isOpenModal:true})
				}
			})
		}
		this.validateotpsms=()=>{
			let id_user=this.state.id_user;
			let NumberToSend=this.state.mobile_number
			let PhoneCode =this.state.mobile_num
			let OtpContent=this.state.verify_code;
			let urlname=this.state.tipster_name.split(' ').join('');
			//let UserUrl="coopbet.com/"+urlname.toLowerCase();
			let UserUrl=this.state.url_username;
			//console.log(NumberToSend)
			agent.auth.validateotpsms({userid:id_user,NumberToSend:NumberToSend,PhoneCode:PhoneCode,OtpType:1,OtpContent:OtpContent,UserUrl:UserUrl}).then((datas) => {
			//console.log(datas)
				if(datas.result=='good'){
					this.setState({msgRigister:datas.otpreturn,isOpenModal:true,verify:'verified',is_validate:1})
				}else{
					this.setState({msgRigister:datas.otpreturn,isOpenModal:true})
				}
			})
		}
		//---
  }
	//---
	getSporttip(){
		agent.auth.sporttip().then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				this.setState({sporttipList:datas.list})
			}
		})	
	}
	//---
	getTimezone(){
		agent.auth.timezone().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({timezoneList:datas.list})
		}
	  })	
	}
	//---
	getCountry(){
		agent.auth.country().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({countryList:datas.list})
		}
	  })	
	}
	//---
	selectCountry(ev){
		this.setState({country_id:ev.target.value})
	}
	//---
	selectTimeZone(ev){
		this.setState({time_zone:ev.target.value})
	}
	//---
	selectSportTips(ev){
		this.setState({sport_tips:ev.target.value})
	}
	//---
	selectOddFormat(ev){
		this.setState({odds_formart:ev.target.value})
	}
	//---
	handleChangeFiled(feild,ev){
		//console.log(ev.target.value)
		this.setState({[feild]:ev.target.value})
		if(feild=='tipster_name'){
			//console.log(ev.target.value.length)
			//console.log($(".url-user").text(),ev.target.value.toLowerCase())
			//var url="coopbet.com/"+ev.target.value.toLowerCase();
			//this.check_url(url);
			
		}
	}
	selectBirthday(feild,ev){
		//console.log(ev.target.value)
		this.setState({[feild]:ev.target.value})
	}
	//---
	check_url(url_username){
		$(".ch_U").show()
		agent.profile.check_url({url_username:url_username}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.setState({imgCheck:'fa fa-check',textCheck:localizeKey(menu)('urlAvailabe', this.state.lguages),url_username:url_username})
			}else{
				this.setState({imgCheck:'fa fa-times',textCheck:localizeKey(menu)('urlnotAvailabe', this.state.lguages),url_username:url_username})
			}
			$(".ch_U").hide()
	  })
	}
	check_url_friendly(){
		let suffix_url_name=this.state.suffix_url_name
	//	let tipster_name=this.state.tipster_name
		//tipster_name=tipster_name.split(" ").join("");
		var url_username="coopbet.com/"+suffix_url_name.toLowerCase();
		$(".ch_U").show()
		let user_id=this.state.id_user
		agent.profile.check_url_friendly({url_username:url_username,user_id:user_id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.setState({imgCheck:'fa fa-check',textCheck:localizeKey(menu)('urlAvailabe', this.state.lguages),url_username:url_username})
			}else{
				this.setState({imgCheck:'fa fa-times',textCheck:localizeKey(menu)('urlnotAvailabe', this.state.lguages),msgRigister:datas.message,isOpenModal:true})
			}
			this.setState({checkurl:1})
			$(".ch_U").hide()
	  })
	}
	
	
	handleChange(ev){
		console.log(ev)
	}
	popoverTop(){
		return (
			<Popover id="popover-positioned-top" title="" className="overView-birthday">
				<div className="body-ct">
					<p style={{'display':'none'}}>
						<b>Providing your birthday</b> help make sure you get the right Facebook experience for your age.
						If you want to change to see this, go to the About section of your profile.
						For more details, please visit out <a style={{'color':'blue'}}>Data policy</a>.
					</p>
					<p>{localizeKey(menu)('txtbirth', this.state.lguages)}</p>
				</div>
				<Button bsStyle="primary" onClick={this.hidePopover}>Okay</Button>
		    </Popover>
		)
	}
	hidePopover(){
		document.body.click()
	}
	//---
	getProfile(){
		agent.auth.getProfile().then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				const data=datas.data
				const avatar=(data.imageurl!='')?data.imageurl:this.state.avatar
				const name=(data.name!='')?data.name:this.state.name
				const user_name=(data.user_name!='')?data.user_name:this.state.user_name
				const country_id=(data.country_id!='')?data.country_id:this.state.country_id
				const odds_formart=(data.odds_formart!='')?data.odds_formart:this.state.odds_formart
				const sport_tips=(data.sport_tips!='')?data.sport_tips:this.state.sport_tips
				const time_zone=(data.time_zone!='' && data.time_zone!=null && data.time_zone!= 'undefined')?data.time_zone:this.state.time_zone
				const tipster_name=(data.tipster_name!='')?data.tipster_name:this.state.tipster_name
				const member_name=(data.member_name!='')?data.member_name:this.state.member_name
				const email=(data.email!='')?data.email:this.state.email
				const first_name=(data.first_name!='')?data.first_name:this.state.first_name
				const last_name=(data.last_name!='')?data.last_name:this.state.last_name
				const sex=(data.sex > 0)?data.sex:this.state.sex
				const url_username=(data.url_username!='')?data.url_username:this.state.url_username
				const birthday=(data.birthday!='')?data.birthday:this.state.birthday
				const phone_code=(data.phone_code!=null)?data.phone_code:this.state.mobile_num
				const otp_sort_number=(data.otp_sort_number!=null)?data.otp_sort_number:this.state.mobile_number
				const is_validate=(data.is_validate!='')?data.is_validate:this.state.is_validate
				const role_id=data.role_id
				const ad_lang_id=(data.ad_lang_id!='')?data.ad_lang_id:this.state.ad_lang_id
				const aduser_url=(data.aduser_url!='')?data.aduser_url:this.state.aduser_url
				const aduser_introduction=(data.aduser_introduction!='')?data.aduser_introduction:this.state.aduser_introduction
				let year=''
					let month=''
					let date=''
				if(birthday!=''){
					let dates=new Date(birthday);
					 year=dates.getFullYear();
					 month=dates.getMonth()+1;
					 date=dates.getDate();	
					 date=(date>9)?date:'0'+date
					 //console.log(year,month,date)
				}else{
					 year=''
					 month=''
					 date=''
				}
				const adhiring_startdate=(data.adhiring_startdate!='')?data.adhiring_startdate:this.state.adhiring_startdate
				const adhiring_enddate=(data.adhiring_enddate!='')?data.adhiring_enddate:this.state.adhiring_enddate
				
				let adhiring_startdateobj = new Date(adhiring_startdate);
				let adhiring_enddateobj = new Date(adhiring_enddate);
				const banneradurl=(data.banneradurl!='')?data.banneradurl:this.state.banneradurl
				const bannermobileadurl=(data.bannermobileadurl!='' && data.bannermobileadurl!=null)?data.bannermobileadurl:this.state.bannermobileadurl
				const aduser_logo_url=(data.aduser_logo_url!='')?data.aduser_logo_url:this.state.aduser_logo_url
				const aduser_number_posttips_perday=(data.aduser_number_posttips_perday!='')?data.aduser_number_posttips_perday:this.state.aduser_number_posttips_perday
				const validated_time=data.validated_time
				let suffix_url_name=url_username.split("/")
				suffix_url_name=(suffix_url_name!=null && suffix_url_name!='')?suffix_url_name[1]:''
				//console.log(suffix_url_name)
				this.setState({dataInfo:data,avatar:avatar,name:name,user_name:user_name,country_id:country_id,
							   odds_formart:odds_formart,time_zone:time_zone,sport_tips:sport_tips,
							   tipster_name:tipster_name,email:email,member_name:member_name,
							   first_name:first_name,last_name:last_name,sex:sex,year:year,month:month,
							   date:date,url_username:url_username,mobile_num:phone_code,mobile_number:otp_sort_number,
							   is_validate:is_validate,role_id:role_id,ad_lang_id:ad_lang_id,aduser_url:aduser_url,
							   adhiring_startdateobj:adhiring_startdateobj,adhiring_enddateobj:adhiring_enddateobj,
							   aduser_introduction:aduser_introduction,aduser_logo_url:aduser_logo_url,
							   banneradurl:banneradurl,bannermobileadurl:bannermobileadurl,
							   aduser_number_posttips_perday:aduser_number_posttips_perday,
							   validated_time:validated_time,suffix_url_name:suffix_url_name
							})
			}
	  })	
	}
	//---
	editProfile(){
		let state=this.state;
		//console.log(state.time_zone)
		const edit_password=(this.state.editpassword)?1:0
		let date=state.date
		let month=state.month
		let year=state.year
		let first_name=state.first_name
		let last_name=state.last_name
		//let birthday=date+"/"+month+"/"+year
		let birthday=year+"-"+month+"-"+date
		let sex=state.sex
		let email=state.email
		let tipster_name=state.tipster_name
		let imageurl=state.avatar
		let sport_tips=state.sport_tips
		let time_zone=state.time_zone
		let odds_formart=state.odds_formart
		let country_id=state.country_id
		let password=state.password
		let password_confirmation=state.password_confirmation
		let ad_lang_id=state.ad_lang_id
		let banneradurl=state.banneradurl
		let bannermobileadurl=state.bannermobileadurl
		let aduser_logo_url=state.aduser_logo_url
		let aduser_url=state.aduser_url
		let aduser_introduction=state.aduser_introduction
		let checkurl=state.checkurl
		let suffix_url_name=state.suffix_url_name
			
		//console.log(this.state.aduser_introduction)
		agent.auth.editProfile({
			first_name:state.first_name,last_name:state.last_name,
			password:state.password,sex:sex,
			password_confirmation:state.password_confirmation,
			email:state.email,tipster_name:state.tipster_name,
			birthday:birthday,sport_tips:state.sport_tips,
			odds_formart:state.odds_formart,time_zone:state.time_zone,
			country_id:state.country_id,imageurl:state.avatar,edit_password:edit_password,
			ad_lang_id:ad_lang_id,banneradurl:banneradurl,bannermobileadurl:bannermobileadurl,
			aduser_logo_url:aduser_logo_url,aduser_url:aduser_url,aduser_introduction:aduser_introduction,
			lang_id:this.state.lguages,checkurl:checkurl,suffix_url_name:suffix_url_name
			
		}).then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				//ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
				/*$("#msg-succesfull").html("Saved sucessfully.")
				setTimeout(function(){
					//ReactDOM.render('', document.getElementById('msg-succesfull'));
					$("#msg-succesfull").html("")
				},9000);*/
				let msg='Saved sucessfully.'
				let thiss=this;
				this.props.setMember(datas.userinfo.tipster_name)
				let login=window.localStorage.getItem(TOKEN_NAME);
				 if(login!='' && login!='null' && login!=null){
					login=JSON.parse(login)
					login.UserInfo.tipster_name=datas.userinfo.tipster_name
					login.UserInfo.url_username=datas.userinfo.url_username
					//console.log(login) 
					window.localStorage.setItem(TOKEN_NAME,JSON.stringify(login)) 
				 }
				this.setState({msgRigister:msg,isOpenModal:true},()=>{
					//this.props.onTabClick('home')
					setTimeout(function(){
						thiss.setState({msgRigister:'',isOpenModal:false})
					},9000);
				})
			}else{
				//ReactDOM.render(datas.message, document.getElementById('msg-error'));
				//$("#msg-error").html(datas.message)
				this.setState({msgRigister:datas.message,isOpenModal:true})
				setTimeout(function(){
					//ReactDOM.render('', document.getElementById('msg-error'));
					$("#msg-error").html('')
				},9000);
			}
	   })	
	}
	//---
	
	componentWillMount(){	
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language,textCheck:localizeKey(menu)('urlAvailabe', language)})
		//----------------------------------
		let dates=new Date();
		let year=dates.getFullYear();
		let years=this.state.years;
		//console.log(year)
		for(let i=0;i<100;i++){
			let y=year-parseInt(i+8)
			years.push(y)
			
		}
		this.setState({years:years})
		//----------------------------------
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({id_user:login.UserInfo.id})
		}
		//----------------------------------
		this.getSporttip();
		this.getTimezone();
		this.getCountry();
		this.getProfile();
		this.getLanguage();
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey,textCheck:localizeKey(menu)('urlAvailabe', nextProps.languageKey)});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	handleSex(e){
		//console.log(e.target.value)
		this.setState({sex:e.target.value})
	}
	handleEditpass(ev){
		//console.log(ev.target.checked)
		this.setState({editpassword:ev.target.checked})
	}
	getLanguage(){
		agent.header.getlangs().then((datas) => {
			if(datas.result==='good'){
				this.setState({languageList:datas.list})
			}
		})
	}
	selectLanguage(ev){
		this.setState({ad_lang_id:ev.target.value})
	}
	handleDateChange(field,date) {
		//console.log(field,date);
		let day=date.getDate();
		let month=date.getMonth()+1;
		let year=date.getFullYear();
		let newdate=year+"-"+month+"-"+day
		//console.log(newdate)	
		if(field=='adhiring_startdate'){
			this.setState({
				adhiring_startdate: newdate,
				adhiring_startdateobj: date
			});
		}
		if(field=='adhiring_enddate'){
			this.setState({
				adhiring_enddate: newdate,
				adhiring_enddateobj: date
			});
		}
	}
	render(){ 
		const countryList=this.state.countryList;
		const timezoneList=this.state.timezoneList;
		const sporttipList=this.state.sporttipList;
		const languageList=this.state.languageList;
		//console.log(this.state.sporttipList);
		//let urlname=this.state.tipster_name.split(' ').join('');
		let days=['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
		let months=[{'key':'1','value':'Jan'},{'key':'2','value':'Feb'},{'key':'3','value':'Mar'},
				   {'key':'4','value':'Apr'},{'key':'5','value':'May'},{'key':'6','value':'Jun'},
				   {'key':'7','value':'Jul'},{'key':'8','value':'Aug'},{'key':'9','value':'Sep'},
				   {'key':'10','value':'Oct'},{'key':'11','value':'Nov'},{'key':'12','value':'Dec'}]
		let years=this.state.years
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">Setting</span>
								</h1>
							</div>
						</div>
					</div>
					
					<div className="container">
					{
						//---
					}
						<div className="register page">
							<div className="regis-ctn">
								<Col md={12} className='items-1'>
									<Col md={6} className="item-l-1">
										<Row className="form-group item">
											<Col md={4} className="pdr0">
												<span className="lbl">{localizeKey(menu)('firstName', this.state.lguages)}:</span>
											</Col>
											<Col md={8}>
												<input id="FName" name="FName" type="text" value="" placeholder={localizeKey(menu)('firstName', this.state.lguages)} className="form-control" value={this.state.first_name} onChange={this.handleChangeFiled.bind(this,'first_name')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={4} className="pdr0">
												<span className="lbl">{localizeKey(menu)('lastName', this.state.lguages)}:</span>
											</Col>
											<Col md={8}>
												<input id="LName" name="UserName" type="text" value="" placeholder={localizeKey(menu)('lastName', this.state.lguages)} className="form-control" value={this.state.last_name} onChange={this.handleChangeFiled.bind(this,'last_name')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={4} className="pdr0">
												<span className="lbl">{localizeKey(menu)('emailTips', this.state.lguages)}:</span>
											</Col>
											<Col md={8}>
												<input id="Email_Tip" name="Email_Tip" type="text" value="" placeholder="Tipster Email" className="form-control" value={this.state.email} onChange={this.handleChangeFiled.bind(this,'email')} disabled={true}/>
											</Col>
										</Row>
										<Row className="form-group item" style={{'marginBottom':'0px'}}>
											<Col md={4} className="pdr0"></Col>
											<Col md={8} className="pdr0">
												<Checkbox  onChange={this.handleEditpass.bind(this)}>
												  {localizeKey(menu)('changepass', this.state.lguages)}
												</Checkbox>
											</Col>
											
										</Row>
										{
										(this.state.editpassword==true)?
										<div>
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">{localizeKey(menu)('password', this.state.lguages)}:</span>
												</Col>
												<Col md={7}>
													<input name="Password" type="password" value="" placeholder={localizeKey(menu)('password', this.state.lguages)} className="form-control" value={this.state.password} onChange={this.handleChangeFiled.bind(this,'password')}/>
													<div className="note-text">{localizeKey(menu)('txt_password', this.state.lguages)}</div>
												</Col>
											</Row>
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">{localizeKey(menu)('confirmPass', this.state.lguages)}:</span>
												</Col>
												<Col md={7}>
													<input  name="confirm_password" type="password" value="" placeholder={localizeKey(menu)('confirmPass', this.state.lguages)} className="form-control" value={this.state.password_confirmation} onChange={this.handleChangeFiled.bind(this,'password_confirmation')}/>
												</Col>
											</Row>
										</div>:''
										}
										
									</Col>
									<Col md={1}></Col>
									<Col md={5}>
										<Row>
											<Col md={12}>
												<Row>
													<Col md={12} className="form-group" style={{'lineHeight':'34px'}}>
														<span className="lbl">Birthday</span>
													</Col>
												</Row>
												<Row>
													<Col md={12} className="form-group" style={{'display':'none'}}>
														<Datetime
															defaultValue={new Date('2000-01-01')}
															dateFormat={"DD/MM/YYYY"}
															timeFormat={false} 
															onChange={this.handleChange}
														/>
													</Col>
													<Col md={6}>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
															<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} value={this.state.month} onChange={this.selectBirthday.bind(this,'month')}>
															{
																months.map((data,i)=>{
																	return (
																		<option value={data.key} key={i}>{data.value}</option>
																	)
																})
															}
															</FormControl>		
														</Col>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
																<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} value={this.state.date} onChange={this.selectBirthday.bind(this,'date')}>
																{
																	days.map((data,i)=>{
																		return(<option value={data} key={i}>{data}</option>)
																	})
																}
																</FormControl>		
														</Col>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
																<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} value={this.state.year} onChange={this.selectBirthday.bind(this,'year')}>
																{
																	years.map((data,i)=>{
																		return(<option value={data} key={i}>{data}</option>)
																	})
																}
																</FormControl>		
														</Col>														
													</Col>
													<Col md={6} style={{'lineHeight':'','padding':'5px 0px'}}>
														
														 <OverlayTrigger trigger="click" placement="top" overlay={this.popoverTop()} rootClose={true}>
														  <a className="overlay-bt" style={{'color':'blue'}}>{localizeKey(menu)('whydo', this.state.lguages)}</a>
														</OverlayTrigger>
													</Col>
												</Row>
												<Row>
													<Col md={6} className="form-group male" style={{'marginBottom':'0px'}}>
														<FormGroup style={{'paddingTop':'10px','textAlign':'center'}}>
														  <Radio name="gt" inline value={1} onChange={this.handleSex.bind(this)} checked={(this.state.sex==1)?true:false}>
															{localizeKey(menu)('female', this.state.lguages)}
														  </Radio>{' '}
														  <Radio name="gt" inline value={2} onChange={this.handleSex.bind(this)} checked={(this.state.sex==2)?true:false}>
															{localizeKey(menu)('male', this.state.lguages)}
														  </Radio>{' '}
														  
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<label className="col-md-2 font-normal" style={{'padding':'9 0'}}></label>
															<div className="avatar-container">
																<figure>
																	<img className="avatar1" src={this.state.avatar} alt="" />
																</figure>
															</div>	
														</div>
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<label className="col-md-2 font-normal" style={{'padding':'9 0'}}></label>
															<div className="ctnavatar-container" style={{'padding':'0'}}>												
																<UploadImage text={localizeKey(menu)('choseAv', this.state.lguages)}  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetFileUpload} />																								
															</div>	
														</div>
													</Col>
												</Row>
											</Col>
											
										</Row>
									</Col>
								</Col>
								<Col md={12} className='items-2'>
									<Row className="mg0">          
										<Col md={6}>
											<Row className="form-group item" style={{'marginBottom':'3px'}}>
												<Col md={4} className="pdr0">
													<span className="lbl">{localizeKey(menu)('tipname', this.state.lguages)}:</span>
												</Col>
												<Col md={8}>
													<input id="Name_Tip" name="Name_Tip" type="text" placeholder={localizeKey(menu)('tipname', this.state.lguages)} className="form-control" value={this.state.tipster_name} onChange={this.handleChangeFiled.bind(this,'tipster_name')}/>
													<Row className="form-group item mg0">
														<span className="note" style={{'margin':'0'}}>{localizeKey(menu)('textTipname', this.state.lguages)}</span>
													</Row>
												</Col>
												<Col md={2} className="pd0"></Col>
											</Row>	
											
										</Col>
										<Col md={6}>
											<Row className="form-group item" style={{'marginBottom':'3px'}}>
												<Col md={5} className="pdr0">
													<span className="lbl">{localizeKey(menu)('mobileNum', this.state.lguages)}:</span>
												</Col>
												<Col md={2} style={{'paddingRight':'0px'}}>
													<input id="mobile_num" name="mobile_num" type="text" placeholder="" className="form-control" value={this.state.mobile_num} maxLength="5" onChange={this.handleChangeFiled.bind(this,'mobile_num')}/>
												</Col>
												<Col md={3} style={{'paddingRight':'0px','paddingLeft':'5px'}}>
													<input id="mobile_number" name="mobile_number" type="text" placeholder="" className="form-control" disabled={(this.state.is_validate==1)?true:false} value={this.state.mobile_number} maxLength="11" onChange={this.handleChangeFiled.bind(this,'mobile_number')}/>
												</Col>
												<Col md={2} style={{'paddingRight':'5px'}}>
													<input type="submit" className="btn-sigin bnt-send-code" value={localizeKey(menu)('sendCode', this.state.lguages)} onClick={this.sendotpsms} disabled={(this.state.is_validate==1)?true:false} />
												</Col>
												<Col md={1} style={{'padding':'0px','display':'none'}}>
													<span className="txt-scon">(120s)</span>
												</Col>
											</Row>
											<Row className="form-group item">
												<span className="note">{localizeKey(menu)('txtMoblie', this.state.lguages)}</span>
											</Row>
										</Col>
									</Row>
									
									<Row className="mg0">
										<Col md={6}>
											<Row className="form-group item" style={{'marginBottom':'3px'}}>
												<Col md={4} className="pdr0" >
													<span className="lbl">{localizeKey(menu)('userUrl', this.state.lguages)}:</span>
												</Col>
												
												<Col md={8} style={{'paddingRight':'0','lineHeight':'34px'}}>
													<Row>
														<Col md={5}>
															{
																//<span className="url-user">{this.state.url_username}</span>
															}
															<span className="url-user">https://www.coopbet.com/</span>
														</Col>
														<Col md={4} style={{'padding':'0 7px'}}>
															<input id="suffix_url_name" style={{'padding':'1px'}} name="suffix_url_name" type="text"  className="form-control" value={this.state.suffix_url_name} onChange={this.handleChangeFiled.bind(this,'suffix_url_name')} maxLength="50"/>
														</Col>
														<Col md={3} style={{'padding':'0'}}>
															<input type="button" className="btn-sigin bnt-send-code btn-chk-url" value={localizeKey(menu)('checkurl', this.state.lguages)} onClick={this.check_url_friendly} style={{'lineHeight':'20px'}} />
														</Col>
													</Row>
													{
													(this.state.suffix_url_name!='')?
														<Row>
															<Col md={12}>
																<img className="ch_U" src="https://www.coopbet.com/media/loading_dots.gif" style={{'width':'35px','display':'none'}}/>
																<i className={this.state.imgCheck}></i>
																<span>{this.state.textCheck}</span>
															</Col>
															
															<Col md={12} className="item mg0" style={{'lineHeight':'15px'}}>
																<span className="note" style={{'margin':'0'}}>{localizeKey(menu)('txtUrl', this.state.lguages)}</span>
															</Col>
														</Row>:''
													}
												</Col>
												
												
											</Row>	
											
										</Col>
										<Col md={6}>
											<Row className="form-group item" style={{'marginBottom':'3px'}}>
												<Col md={5} className="pdr0">
													<span className="lbl">{localizeKey(menu)('entercodeverify', this.state.lguages)}:</span>
												</Col>
												<Col md={5} style={{'paddingRight':'0px'}}>
													<input id="verify_code" name="verify_code" type="text" placeholder="" className="form-control" value={this.state.verify_code}  onChange={this.handleChangeFiled.bind(this,'verify_code')}/>
													{
														(this.state.is_validate==1)?
														<div className="txt-note-red">Verify date:{this.state.validated_time}</div>:''
													}
												</Col>
												<Col md={2} style={{'paddingRight':'5px'}}>
													<input type="submit" className="btn-sigin bnt-send-code" value={localizeKey(menu)('verify', this.state.lguages)} onClick={this.validateotpsms} disabled={(this.state.is_validate==1)?true:false}/>
												</Col>
												
											</Row>
										</Col>
									</Row>
									
								</Col>
								<Col md={12} className='items-3'>
									<Col md={6}>
										<Row className="form-group item">
											<Col md={4} className="pdr0">
												<span className="lbl">{localizeKey(menu)('porttip', this.state.lguages)}:</span>
											</Col>
											<Col md={8}>
												<FormControl componentClass="select" placeholder="select" value={this.state.sport_tips} onChange={this.selectSportTips.bind(this)}>
													
													{
														(sporttipList)?sporttipList.map(data=>{
															return( <option value={data.id} key={data.id}> {(data.id==1)?localizeKey(menu)('basketball', this.state.lguages):(data.id==2)?localizeKey(menu)('soccer', this.state.lguages):localizeKey(menu)('tennis', this.state.lguages)} </option> )
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={4} className="pdr0">
												<span className="lbl">{localizeKey(menu)('oddFm', this.state.lguages)}:</span>
											</Col>
											<Col md={8}>
												<FormGroup className="sub-item oddsF">
												  <Radio name="radioGroup" checked={true} value={1} onChange={this.selectOddFormat.bind(this)}>
													Decimal 2.5
												  </Radio>
												  <Radio name="radioGroup" value={2} onChange={this.selectOddFormat.bind(this)}>
													FRACTIONAL 3/2
												  </Radio>
												  
												</FormGroup>
											</Col>
										</Row>
									</Col>
									<Col md={6}>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('timezone', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<FormControl componentClass="select" placeholder="select" value={this.state.time_zone} onChange={this.selectTimeZone.bind(this)}>
													
													{
														(timezoneList)?timezoneList.map(data=>{
															return (<option value={data.id} key={data.id}> {data.desc} </option>)
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('selectCtr', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<FormControl componentClass="select" placeholder="select" value={this.state.country_id} onChange={this.selectCountry.bind(this)}>
													<option value=""> {localizeKey(menu)('selectCtr', this.state.lguages)} </option>
													{
														(countryList)?countryList.map(data=>{
															return(<option value={data.id} key={data.id}> {data.title} </option>)
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
									</Col>
								</Col>
								{
									(this.state.role_id==3)?
									<Col md={12} className="items-ads">
										<Col md={6}>
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Language for ads:</span>
												</Col>
												<Col md={7}>
													<FormControl componentClass="select" placeholder="select" value={this.state.ad_lang_id} onChange={this.selectLanguage.bind(this)} disabled={true}>														
														{
															(languageList)?languageList.map(data=>{
																return( <option value={data.id} key={data.id}> {data.display_name} </option> )
															}):''
														}
													</FormControl>																	
												</Col>
											</Row>			
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Introduction URL:</span>
												</Col>
												<Col md={7}>
													<input id="aduser_url" name="aduser_url" type="text" value="" placeholder="ad user url" className="form-control" value={this.state.aduser_url} onChange={this.handleChangeFiled.bind(this,'aduser_url')}/>
												</Col>
											</Row>			
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Introduction:</span>
												</Col>
												<Col md={7}>												
													<textarea
														className="form-control admin-input-introduction"
														id="txt-introduction"
														rows="5"
														value={this.state.aduser_introduction} onChange={this.handleChangeFiled.bind(this,'aduser_introduction')}
													/>
												</Col>
											</Row>
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Hiring start date:</span>
												</Col>
												<Col md={7}>												
													<DatePicker className="input-date"
														selected={this.state.adhiring_startdateobj}
														onChange={this.handleDateChange.bind(this,'adhiring_startdate')}	
														disabled={true}
													/>
												</Col>
											</Row>
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Hiring end date:</span>
												</Col>
												<Col md={7}>												
													<DatePicker className="input-date"
														selected={this.state.adhiring_enddateobj}
														onChange={this.handleDateChange.bind(this,'adhiring_enddate')}		
														disabled={true}
													/>
												</Col>
											</Row>
										</Col>
										<Col md={6}>
											<Row>
												<Col md={12} className="pd0">
													<Col md={1}></Col>
													<Col md={5} id="img-aduserlogo">
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<label className="col-md-2 font-normal" style={{'padding':'9 0','display':'none'}}></label>
															<div className="avatar-container">
																<figure>
																	<img className="avatar1" src={this.state.aduser_logo_url} alt="" />
																</figure>
															</div>	
														</div>
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<label className="col-md-2 font-normal" style={{'padding':'9 0','display':'none'}}></label>
															<div className="ctnavatar-container" style={{'padding':'0'}}>												
																<UploadAdUserLogo text='Choose ad user logo (100x50)'  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetAduserLogoUpload} />																								
															</div>	
														</div>
													</Col>
													<Col md={6} className="pd0" id="img-banner-mobile">
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<div className="avatar-container">
																<figure>
																	<img className="avatar1" src={this.state.bannermobileadurl} alt="" />
																</figure>
															</div>	
														</div>
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<div className="ctnavatar-container" style={{'padding':'0'}}>												
																<UploadBannerMobile text='Choose Mobile Banner (150x50)'  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetMobileBannerUpload} />																								
															</div>
														</div>
													</Col>
												</Col>
											</Row>
											<Row>
												<Col md={1}></Col>
												<Col md={11} id="img-banner">
													<div className="col-md-12 form-group" style={{'padding':'0'}}>
														<label className="col-md-2 font-normal" style={{'padding':'9 0','display':'none'}}></label>
														<div className="avatar-container">
															<figure>
																<img className="avatar1" src={this.state.banneradurl} alt="" />
															</figure>
														</div>	
													</div>
													<div className="col-md-12 form-group" style={{'padding':'0'}}>
														<label className="col-md-2 font-normal" style={{'padding':'9 0','display':'none'}}></label>
														<div className="ctnavatar-container" style={{'padding':'0'}}>												
															<UploadBanner text='Choose Banner (260x160)'  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetBannerUpload} />																								
														</div>	
													</div>
												</Col>
											</Row>
											<Row className="form-group item" style={{'margin':'10px 0'}}>
												<Col md={5} className="pdr0">
													<span className="lbl">Number post tips /day:</span>
												</Col>
												<Col md={2}>												
													<input  name="number_post_tip" type="text" value={this.state.aduser_number_posttips_perday} placeholder="Number post tips /day" className="form-control" onChange={this.handleChangeFiled.bind(this,'aduser_number_posttips_perday')} disabled={true}/>
												</Col>
											</Row>
										</Col>
									</Col>:''
								}
								<Col md={12} className="tex-center item-btm" >
									 {localizeKey(menu)('txtFinal', this.state.lguages)}
								</Col>
								<Col md={12} className="tex-center">
									<input type="submit" className="btn-sigin" value={localizeKey(menu)('save', this.state.lguages)} onClick={this.editProfile} style={{'fontSize':'14px'}}/>
								</Col>
								<Col md={12} className="msg-error" id="msg-error"></Col>
								<Col md={12} className="" id="msg-succesfull" style={{'color':'green'}}></Col>
							</div>
						</div>
					{
						//---
					}
					</div>
					
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)