import React, {Component} from 'react'
import findDOMNode from 'react-dom'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/sticker.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import TextTruncate from 'react-text-truncate';
//import ReactTooltip from 'react-tooltip'
import {CHANGE_TAB,TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import ModalErorr from '../common/ModalErorr'
import Comment from './Comment'
import ModalLikes from '../common/ModalLikes'
import Lqhome from '../common/home.json'
import menu from '../common/menu.json'
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import ModalEditDescr from './ModalEditDescr'


const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey,
		
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});



 


class HomeList extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',			
			homebetList:[],
			member_name:'',id_user:'',
			avatar:'https://www.coopbet.com/media/avartar_cm.jpg',loginInfo:'',
			isOpenModal:false,msgLogin:'',imageurl:'',isOpenModalLike:false,numlike:'',
			idpet:'',likeList:[],isRunging:false,only_friend:'',lengtList:false,isOpenModalEditDes:false,
			txt_description:'',PickCode:'',txt_title:'',mth:''
		}
		//---
		this.handleClickTab = menu => e => {
			e.preventDefault()
			//const token = window.localStorage.getItem(TOKEN_NAME); 
			this.props.onTabClick(menu)
			
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,isOpenModalLike:false,isOpenModalEditDes:false})
		}
		//---
		//this.oPenModalLike=this.oPenModalLike.bind(this)
		//---
		this.checksuspend=()=>{
			agent.auth.checksuspend().then(data => {
				//console.log(data)
				if(data!=null && data.result === 'good' && data.suspended==1){
					window.location='/un-available' 
				}
			})
		}
		this.callModalError=(message)=>{
			this.setState({isOpenModal:true,msgLogin:message,isOpenModalEditDes:false})
		}
		this.showModalEditDescr=(PickCode,txt_description)=>{
			this.setState({isOpenModal:false,isOpenModalLike:false,isOpenModalEditDes:true,
						   txt_description:txt_description,PickCode:PickCode,txt_title:'Edit Description'})
		}
		this.updateHomeLis=(PickCode,PickComment)=>{
			const homebetList=this.state.homebetList.map(dt=>{
				if(dt.PickCode==PickCode){
					dt.PickComment=PickComment
					dt.edit_decrpt=1
				}
				return dt;
			});
			this.setState({homebetList:homebetList})
		}
		this.goBuy=(id,e)=>{
			window.location="/buy-tips/"+id
		}
	}
	
	componentWillMount(){		
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		let dates=new Date();
		this.setState({'lguages':language,mth:dates.getMonth()+1})
		//-----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({member_name:login.UserInfo.member_name,id_user:login.UserInfo.id,imageurl:login.UserInfo.imageurl})
		}
		//console.log(login)
		//---
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.homebetList);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps.homebetList!='' && nextProps.homebetList!=null){
			let loginInfo=nextProps.loginInfo
			if(loginInfo!='' && loginInfo!='null' && loginInfo!=null){
				 let login=JSON.parse(loginInfo)
				 this.setState({member_name:login.UserInfo.member_name,id_user:login.UserInfo.id})
			}
			let homebetList=nextProps.homebetList;
			if(homebetList!=true){
				homebetList=homebetList.map(dt=>{
					if(dt!='' && dt!=null){
						dt.pick_dot=0;
					}
					return dt;
				})
			}
			
			this.setState({homebetList:homebetList,lengtList:(nextProps.homebetList.length>0)?false:true,loginInfo:nextProps.loginInfo,only_friend:nextProps.only_friend});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	onDotToll(PickCode,e){
		let homebetList=this.state.homebetList.map(data=>{
			if(data!=null && PickCode==data.PickCode){
				data.pick_dot=1;
			}else if(data!=null && PickCode!=data.PickCode){
				data.pick_dot=0;
			}
			return data;
		})
		this.setState({homebetList:homebetList})
	}
	leaveDotTool(e){
		let homebetList=this.state.homebetList.map(data=>{
			if(data!=null){
				data.pick_dot=0;
				return data;
			}
		})
		this.setState({homebetList:homebetList})
	}
	togglelikebet(bet_id,ev){
		//console.log(bet_id)
		let thiss=ev.target.closest('.like');
		$(thiss).attr('id',"like-pr")
		agent.home.togglelikebet({bet_id:bet_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				//this.props.loadlist();
				var LikedNum=data.LikedNum
				var LikedUserIDList=data.LikedUserIDList
				var LikedUserList=data.LikedUserList
				let homebetList=this.state.homebetList;
				homebetList=(homebetList!='')?
					homebetList.map(dt=>{
						let BetList=dt.BetList;
						if(BetList!=''){
							BetList.map(it=>{
								if(it.bet_id==bet_id){
									it.LikedNum=LikedNum
									it.LikedUserIDList=LikedUserIDList
									it.LikedUserList=LikedUserList
								}
								return it;
							})
						}
						return data;
					}):''
				this.setState({isOpenModal:false,msgLogin:''})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message,isOpenModalEditDes:false})
			}
		})
		
	}
	createrequest(ev){
		
		let thiss=ev.target.closest('.add-friend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				const homebetList=this.state.homebetList.map(item=>{
					if(item.UserInfo.id==to_user_id){
						item.UserInfo.FriendStatusWithLoginUser='Pending'
					}
					return item;
				})
				this.setState({isOpenModal:false,msgLogin:'',homebetList:homebetList})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
				$(thiss).find('.loading').hide();
			}
		})
	}
	createFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.createFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				let homebetList=this.state.homebetList.map(it=>{
					if(it.UserInfo.id==id){
						it.UserInfo.LoginUserFollowStatus="Following"
						it.UserInfo.FollowNumbers=data.FollowNumbers
					}
					return it;
				})
				this.setState({homebetList:homebetList})
				
			}
		})
	}
	removeFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.removeFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				let homebetList=this.state.homebetList.map(it=>{
					if(it.UserInfo.id==id){
						it.UserInfo.LoginUserFollowStatus=""
						it.UserInfo.FollowNumbers=data.FollowNumbers
					}
					return it;
				})
				this.setState({homebetList:homebetList})
			}
		})
	}
	tooltip(it){
		//console.log(it)
		return(
			<Tooltip id="tooltip">
			{
				it.map((data,id)=>{
					return(
						<div key={id}>{data.tipster_name}</div>
					)
				})
			}
				
			</Tooltip>
		)
	}
	getLikeList(idpet){
		let login_user_id=this.state.id_user
		let bet_id=idpet
		agent.home.likeList({bet_id:bet_id,login_user_id:login_user_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({likeList:data.list})
			}
		})
	}
	oPenShowLike(numlike,idpet){
		this.setState({isOpenModalLike:true,numlike:numlike,idpet:idpet})
		this.getLikeList(idpet)
	}
	oPenModalLike(numlike,likeList,isRunging){
		let thiss=this;
		let login=window.localStorage.getItem(TOKEN_NAME);
		login=JSON.parse(login)
		if(login!='' && login!='null' && login!=null && login.UserInfo.role_id!=2){
			agent.auth.checksuspend().then(data => {
					//console.log(data)
				if(data!=null && data.result === 'good' && data.suspended==1){
					window.location='/un-available' 
				}else{
					thiss.setState({isOpenModalLike:true,numlike:numlike,likeList:likeList,isRunging:isRunging})
				}
			})
		}
		//this.setState({isOpenModalLike:true,numlike:numlike,likeList:likeList,isRunging:isRunging})
	}
	togglelikepick(ev){
		let pick_code=$(ev.target).attr("pick_code")
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
			agent.home.togglelikepick({pick_code:pick_code}).then(data => {
				//console.log(data)
				if(data.result=="good"){
					let homebetList=this.state.homebetList;
					let PickLikedNum=data.PickLikedNum;
					let PickLikedUserIDList=data.PickLikedUserIDList;
					let PickLikedUserList=data.PickLikedUserList;
					let newHomeList=homebetList.map(dt=>{
						if(dt.PickCode==pick_code){
							dt.PickLikedNum=PickLikedNum
							dt.PickLikedUserList=PickLikedUserList
							dt.PickLikedUserIDList=PickLikedUserIDList
						}
						return dt;
					})
					this.setState({homebetList:newHomeList})
				}
			})
		
		}
	}
	emptyPickComment(PickCode,ev){
		agent.admin.emptyPickComment({PickCode:PickCode}).then(data => {
			if(data.result=="good"){
				let homebetList=this.state.homebetList.map(dt=>{
					if(dt!=null && dt.PickCode==PickCode){
						dt.PickComment='';
					}
					return dt;
				})
				this.setState({homebetList:homebetList})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
			}
		})
	}
	noGoBuy(PriceTips,e){
		if(PriceTips>0){
			let message="The seller sold tips to the buyer in the month."
			this.setState({isOpenModal:true,msgLogin:message})
		}else{
			let message="Invalid price."
			this.setState({isOpenModal:true,msgLogin:message})
		}
		
	}
	render(){ 
		
		//---homebetList
		const homebetList=this.state.homebetList;
		//---
		const member_name=this.state.member_name;
		const id_user=this.state.id_user;
		//---
		let i=0;let ii=0;
		//let only_friend=window.localStorage.getItem("only_friend");
		//------------------------------------------
		//console.log(homebetList)
		//------------------------------------------
        return (
			<div className="items" style={{'marginTop':'10px'}} id="all-list-home">

				<div className="row">
					<div className="col-md-12" style={{'textAlign':'center'}}>
						<img className="imgload" src="https://www.coopbet.com/media/SpinnerLoading.gif" style={{'display':'none','position':'absolute'}}/>
					</div>
				</div>
				{
					
					(this.state.lengtList==true && this.state.only_friend==1)?
					<Row className="mg0 txt-ct">
						<h3>{localizeKey(Lqhome)('welcometo', this.state.lguages)} Coopbet</h3>
						<Col md={12}>
							<span className="txt-str">{localizeKey(Lqhome)('txtaddfri', this.state.lguages)}</span>
							<a className="btn flwg fnt-up" href="/friends-request">{localizeKey(Lqhome)('next', this.state.lguages)}</a>
						</Col>
						<Col md={12} style={{'marginTop':'5px'}}>
							<span className="txt-str">{localizeKey(Lqhome)('txtpsyrTip', this.state.lguages)}</span>
							<a className="btn flwg fnt-up" href="/addtips">{localizeKey(menu)('btndt', this.state.lguages)}</a>
						</Col>
					</Row>:
					(homebetList!=true)?homebetList.map(data=>{
						let UserInfo=data.UserInfo;
						let BetList=data.BetList;
						//let SummaryBet=UserInfo.SummaryBet;
						let SummaryBet=UserInfo.MonthStatistic;
						i+=1;
						//let prName=UserInfo.tipster_name.split(' ').join('.');
						//let userid=UserInfo.id
						//prName=prName.toLowerCase()+"."+userid
						var url_username=UserInfo.url_username
						let prName='';
						let url='';
						if(url_username!='' && url_username!='null' && url_username!=null){
							prName=url_username.split("/");
							url=prName[prName.length-1];
						}
						//------
						var likeLst=data.PickLikedUserList;
						var pesons='';
						var _status='';
						for( let i=0;i<likeLst.length;i++){
							if(i==likeLst.length-1){
								pesons+=likeLst[i].tipster_name;
							}else{
								pesons+=likeLst[i].tipster_name+', ';
							}
							if(likeLst[i].user_id==id_user){
								_status="liked"
							}
							
						}
						//------
						var runLst=data.PickCodeComingUsers;
						var html = data.PickComment;
						if(html!='' && html!=null){
							html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
						}
						
						var div = document.createElement("div");
						div.innerHTML = html;
						var textmessge = html;
						//console.log(likeLst,runLst)
						//------
						return(
							<div key={i}>
								<div className="row" id="home-list">
									<div className="col-md-3 left">
										<h3 className="header">
										<Link to={(id_user!='' && id_user!=null)?"/"+url:"/login"} className="userprofile">{UserInfo.tipster_name}</Link>
										</h3>
										<div className="content">
											<div className="ctn">
												<figure>
														<Link to={(id_user!='' && id_user!=null)?"/"+url:"/login"} className="userprofile"><img src={(UserInfo.imageurl!='' && UserInfo.imageurl!=null && UserInfo.imageurl!='null')?UserInfo.imageurl:'https://www.coopbet.com/media/no_avatar.png'} width="70" height="70"/></Link>
												</figure>
												<div className="tipster">
													<Link to={(id_user!='' && id_user!=null)?"/"+url:"/login"} className="usertip"><span className="lbl">{UserInfo.tipster_title}</span></Link>
													<span className="ctn">{UserInfo.FollowNumbers}</span>
												</div>
												{
													(UserInfo.role_id==3 && UserInfo.banneradurl!='')?
													<div className="sport-tip" style={{"marginBottom":"0px","backgroundColor":"#fff","padding":"0px"}}>
														<div className="div-baner">
															<a href={UserInfo.aduser_url} target="_blank"><img className="img-baner" src={UserInfo.banneradurl} /></a>
														</div>
													</div>:
													<div>
														<div className="sport-tip">
														{
															(UserInfo.sporttips_title=='SOCCER')?
															<i className="far fa-futbol icon-sport fnt26px" aria-hidden="true"></i>:
															(UserInfo.sporttips_title=='BASKETBALL')?
															<i className="fas fa-basketball-ball icon-sport fnt26px" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport fnt26px" aria-hidden="true"></i>
														}
															<span>{UserInfo.sporttips_title}</span>
														</div>
														
														<div className="profit" >
															<div id="triangle-top-left" title={"Month: "+this.state.mth}>
																<div className="triangle-text">
																{this.state.mth}								
																</div>
															</div>
															<ul className="not-sticker-mth">
																<li>
																	<b>{localizeKey(translations)('Profit', this.state.lguages)}: </b>
																	<span>{SummaryBet.profit}</span>
																</li>
																<li>
																	<b>{localizeKey(translations)('Yield', this.state.lguages)}: </b>
																		<span>
																			{SummaryBet.profitpercentage}
																		</span>
																</li>
																<li>
																	<b>{localizeKey(translations)('Wrate', this.state.lguages)}: </b>
																	<span>{SummaryBet.IswonPercentage}</span>
																</li>
																<li>
																	<b>{localizeKey(translations)('NOtips', this.state.lguages)}: </b>
																	<span>{SummaryBet.NumberOfTips}</span>
																</li>
															</ul>
														</div>
													</div>
												}
												
											</div>
											<div className="footer" style={{'borderTop':(UserInfo.role_id==3 && UserInfo.banneradurl!='')?'0px':'1px','display':(UserInfo.role_id==3 && UserInfo.banneradurl!='')?'none':''}}>
											{
												(id_user==UserInfo.id)?
												<Link className="friends-list" to={(id_user!='' && id_user!=null)?"/"+url+"?2":"/login"}>{localizeKey(translations)('frdList', this.state.lguages)}</Link>:
												<div>
												{
													(UserInfo.FriendStatusWithLoginUser=="Confirmed")?
													<a className="add-friended">
														Friend
													</a>:
													<a className="add-friend" data-id={UserInfo.id} onClick={(member_name!='')?this.createrequest.bind(this):this.handleClickTab('login')}>
														{(UserInfo.FriendStatusWithLoginUser=="Pending")?localizeKey(translations)('requestSend', this.state.lguages):(UserInfo.FriendStatusWithLoginUser=="PendingYou")?localizeKey(menu)('waitingconfirm', this.state.lguages):localizeKey(translations)('AF', this.state.lguages)}
														<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
													</a>
												}
												{
													/*
													<a className={(UserInfo.FriendStatusWithLoginUser=="Confirmed")?'add-friended':'add-friend'} data-id={UserInfo.id} onClick={(member_name!='')?this.createrequest.bind(this):this.handleClickTab('login')}>
														{(UserInfo.FriendStatusWithLoginUser=="Confirmed")?<i className="fa fa-angle-down fa-it" style={{'paddingRight':'5px'}}></i>:''}
														{(UserInfo.FriendStatusWithLoginUser=="Confirmed")?'Friend':(UserInfo.FriendStatusWithLoginUser=="Pending")?'Friend Request Sent':(UserInfo.FriendStatusWithLoginUser=="PendingYou")?'Waiting you confirm':localizeKey(translations)('AF', this.state.lguages)}
														<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
													</a>
													*/
												}
												{
													(UserInfo.FriendStatusWithLoginUser!="Confirmed")?
													<a className="following" className={(UserInfo.LoginUserFollowStatus!='')?'following flwg':'following'} data-id={UserInfo.id} onClick={(member_name!='')?((UserInfo.LoginUserFollowStatus!='')?this.removeFollow.bind(this):this.createFollow.bind(this)):this.handleClickTab('login')}>
														{(UserInfo.LoginUserFollowStatus!='')?localizeKey(translations)('followings', this.state.lguages):localizeKey(translations)('Follow', this.state.lguages)}
													</a>:''
												}
												</div>
												
											}
											{
												(id_user!==UserInfo.id && UserInfo.HasChargedTips === true && UserInfo.PriceTips>0)?
												<div className="footer footer-charge">
													<div className="wd20">&nbsp;&nbsp;</div>
													<div className="left-ft-admth ct-ft-charge" onClick={(UserInfo.SoldMeTipsInMonth === false && UserInfo.PriceTips>0)?this.goBuy.bind(this,UserInfo.id):this.noGoBuy.bind(this,UserInfo.PriceTips)}>
														<span className="price-charge">{UserInfo.PriceTips}$/MONTH</span>
														<span className="sym-charge">|</span>
														<i className="fas fa-shopping-cart"></i>
													</div>
													<div className="wd20">&nbsp;&nbsp;</div>
												</div>:''
											}
											</div>
										</div>
									</div>
									<div className="col-md-9 right">
									<Row>
										<Col md={12}>
											<div className="header">
												
												<div className="w-0" style={{'width':'85px'}}><span>{localizeKey(translations)('title1', this.state.lguages)}</span></div>
												<div className="w-1" style={{'width':'115px'}}><span>{localizeKey(translations)('title2', this.state.lguages)}</span></div>
												<div className="w-2"><span>{localizeKey(translations)('title3', this.state.lguages)}</span></div>
												<div className="w-3" style={{'width':'160px'}}><span>{localizeKey(translations)('title4', this.state.lguages)}</span></div>
												<div className="w-1"><span>{localizeKey(translations)('title5', this.state.lguages)}</span></div>
												
												<div className="w-1"><span>{localizeKey(translations)('title6', this.state.lguages)}</span></div>
												<div className="w-1" style={{'width':'90px'}}><span>{localizeKey(translations)('title7', this.state.lguages)}</span></div>
												<div className="w-1" style={{'display':'none'}}><span>{localizeKey(translations)('title8', this.state.lguages)}</span></div>
											</div>
											<div className="ctn">
												{
													(BetList.length>0)?
													BetList.map((item,idx)=>{
														ii=ii+1;
														var likeLst=item.LikedUserList;
														var pesons='';
														var status='';
														for( let i=0;i<likeLst.length;i++){
															if(i==likeLst.length-1){
																pesons+=likeLst[i].tipster_name;
															}else{
																pesons+=likeLst[i].tipster_name+', ';
															}
															if(likeLst[i].user_id==id_user){
																status="liked"
															}
															
														}
														return (
															<div className="sitem" key={ii} style={{'paddingTop':(item.is_charged_tip === 1)?'0':'5px'}}>
																{
																	(item.is_charged_tip === 1)?
																	<div className="triangle-top-left-charge">
																		<div className="triangle-text-charge ">Tips</div>
																	</div>:''
																}
																<div className="w-0" style={{'width':'85px'}}>
																	<img src={item.sporttip_imageurl} style={{display:'none'}}/>
																{
																	//(item.sportid==1)?<i className="fas fa-basketball-ball icon-sport fnt26px" aria-hidden="true"></i>:(item.sportid==2)?<i className="far fa-futbol icon-sport fnt26px" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport fnt26px" aria-hidden="true"></i>
																	<img src="/media/24x25_KTO-Logo.png" style={{'width':'26px'}}/>
																}
																</div>
																<div className="w-1" style={{'width':'115px'}}>
																	<span>
																	{item.start_date} {item.start_time}
																	</span>
																</div>
																<div className="w-2">
																	<span>
																		[{item.league_name}]<br/>
																		{item.hometeam_name} - {item.awayteam_name}
																	</span>
																</div>
																<div className="w-3" style={{'width':'160px'}}>
																		<span className="bet-type" style={{'color':(item.is_charged_tip===1)?'#42b1a9':''}}>{item.tip_desc}</span>
																</div>
																<div className="w-1"><span>{parseInt(item.amount)}/10</span></div>
																
																<div className="w-1" style={{'padding':'inherit'}}>
																	{
																		(item.home_goal!=null && item.home_goal!='null')?
																		<span>{item.home_goal} - {item.away_goal}</span>:
																		<span> ? </span>
																	}
																		
																</div>
																<div className="w-1" style={{'padding':'inherit','width':'90px'}}>
																	{
																		(item.bet_status=='WIN')?
																		<span className="status won" title="Won">
																			{localizeKey(translations)('Win', this.state.lguages)}
																		</span>:(item.bet_status=='DRAW')?
																		<span className="status draw" title="Draw">
																			{localizeKey(translations)('Draw', this.state.lguages)}
																		</span>:(item.bet_status=='WAIT')?
																		<span className="status pending" title="Pending">
																			{localizeKey(translations)('wait', this.state.lguages)}
																		</span>:(item.bet_status=='LOST')?
																		<span className="status lost" title="Lost">
																			{localizeKey(translations)('lost', this.state.lguages)}
																		</span>:''
																	}
																	
																</div>
																<div className="w-1 like" style={{'display':'none'}}>
																	{
																		(item.LikedNum>0 && status=='liked')?
																		<span>
																			<a className="like-bet like-bet-id active" data-id={item.bet_id} title="Unlike" onClick={(member_name!='')?this.togglelikebet.bind(this,item.bet_id):this.handleClickTab('login')}></a>
																			<a onClick={this.oPenShowLike.bind(this,item.LikedNum,item.bet_id)}>
																				<OverlayTrigger placement="bottom" overlay={this.tooltip(likeLst)}>
																				  <span>{item.LikedNum}</span>
																				</OverlayTrigger>
																			</a>
																		</span>:
																		<span>
																			<a className="like-bet" data-id={item.bet_id} title="Like" onClick={(member_name!='')?this.togglelikebet.bind(this,item.bet_id):this.handleClickTab('login')}></a>
																			<a onClick={this.oPenShowLike.bind(this,item.LikedNum,item.bet_id)}>
																				{
																					(item.LikedNum>0)?
																					<OverlayTrigger placement="bottom" overlay={this.tooltip(likeLst)}>
																					  <span>{item.LikedNum}</span>
																					</OverlayTrigger>: <span>{item.LikedNum}</span>
																				}
																			</a>
																		</span>
																	}
																</div>
																
																{
																		//(idx === BetList.length-1 )?((data.PickCodeRunning==1)?<div style={{'float':'right','padding':'10px 15px'}}><a className="running" href={"./running/"+data.UserInfo.id+"_"+data.PickCode}>Running</a></div>:''):''
																}
																
																{
																	(item.comment!='')?
																	<div className="comment">
																
																		<div className="content" style={{'height':'54px'}}>
																			<span>
																				<TextTruncate
																					line={3}
																					truncateText="…"
																					text={item.comment}
																					textTruncateChild={<a href="#">Xem thêm</a>}
																				/>
																			</span>
																		</div>
																		<a className="action" style={{'display':'none'}}>Xem thêm</a>
																	</div>:''
																}
																
															</div>
														)
													}):''
												}
													
													
											</div>											
											</Col>
											
										</Row>
										<Row style={{'margin':'0px'}}>
											<Col  md={12} className="r-footer l-footer">
												<Col md={4} className="pd0 f-left">
													<a className={(data.PickLikedNum>0 && _status=='liked')?"like-bet-active":"like-bet"} title={localizeKey(Lqhome)('like', this.state.lguages)} pick_code={data.PickCode} onClick={(member_name!='')?this.togglelikepick.bind(this):this.handleClickTab('login')}>		
														{
															(data.PickLikedNum>0 && _status=='liked')?<i className="fas fa-thumbs-up" pick_code={data.PickCode} aria-hidden="true"></i>:<i className="far fa-thumbs-up" pick_code={data.PickCode} aria-hidden="true"></i>
														}
														{(data.PickLikedNum>0)?
															<OverlayTrigger placement="bottom" overlay={this.tooltip(likeLst)} onClick={this.oPenModalLike.bind(this,data.PickLikedNum,likeLst,false)}>
															  <span className="num-like">{localizeKey(Lqhome)('like', this.state.lguages)} ({data.PickLikedNum})</span>
															</OverlayTrigger>:
															<span className="num-like">{localizeKey(Lqhome)('like', this.state.lguages)} (0)</span>
														}
													</a>
													
												</Col>
												<Col md={4} className="runing-ct">
													{
														((data.PickCodeRunning==1)?
															<div>
																<a href={(member_name!='')?"./running/"+data.UserInfo.id+"_"+data.PickCode:'/login'}> 
																	<i className="fas fa-running icon-running" title={localizeKey(Lqhome)('goRunning', this.state.lguages)} aria-hidden="true"></i>
																</a>
																{
																	(data.PickCodeComingNumber>0)?
																	<OverlayTrigger placement="bottom" overlay={this.tooltip(runLst)} onClick={this.oPenModalLike.bind(this,data.PickCodeComingNumber,runLst,true)}>
																		<span className="num-running">Running ({data.PickCodeComingNumber})</span>
																	</OverlayTrigger>:''
																}
																
															</div>:'')
													}
												</Col>
												<Col md={4} className="f-right create-date">
													<span className="lbl">{localizeKey(translations)('Create', this.state.lguages)}: </span>
													<span className="ctn-dt"> {data.PickDatetimeSTR}</span>
													
												</Col>
											</Col>
										</Row>
										{
											//---comment
										}
										
										{
											(data.PickComment!='' && data.PickComment!=null)?
											<Row className="mg0">
												<Col md={12} className="pd10_0">
													<Col md={1} className="pl0" >
														<img src={(UserInfo.imageurl!='' && UserInfo.imageurl!=null)?UserInfo.imageurl:this.state.avatar} width="35" height="35" className="img-icon"/>
													</Col>
													<Col md={11} className="dot-hv rm-dsc" onMouseEnter={this.onDotToll.bind(this,data.PickCode)} onMouseLeave={this.leaveDotTool.bind(this)}>
														<Row><h4 className="name-comment">{UserInfo.tipster_name}</h4></Row>
														<Row>
															<div className="date-comment">
																<span>{data.PickDatetimeSTR}</span>
																{(data.edit_decrpt==1)?<span className="txt-small"> (edited)</span>:''}
																{((id_user==data.UserInfo.id || id_user==5) && data.pick_dot==1)?
																<DropdownMenu triggerType='text' trigger='...'  className="tool-of-user">
																	<Row className="mg0 noti-box pd0">	
																	{(id_user==data.UserInfo.id)?
																		<Col md={12} className="lhi0 bdbt1">
																			<a className="text-cmt-tl" onClick={this.showModalEditDescr.bind(this,data.PickCode,data.PickComment)}>
																				<i className="fa fa-pencil"></i> edit
																			</a>
																		</Col>:''
																	}																		
																		<Col md={12} className="lhi0">
																			<a className="text-cmt-tl" onClick={this.emptyPickComment.bind(this,data.PickCode)}>
																				<i className="fa fa-trash-o"></i> delete
																			</a>
																		</Col>														
																	</Row>
																</DropdownMenu>:''
																}
															</div>
														</Row>
														<Row>
															<div className="content-comment">
																{
																	//data.PickComment
																	ReactHtmlParser(textmessge)
																}
															</div>
														</Row>
														
													</Col>
												</Col>
											</Row>:''
										}
										<Row className="mg0 br-tp no-bd">
										
											<Comment 
												imageurl={this.state.imageurl} 
												TotalComments={data.TotalComments} 
												CommentList={data.CommentList} 
												PickCode={data.PickCode}
												member_name={member_name}
												numi={i}
												ViewAllComments={data.ViewAllComments}
												user_id={data.UserInfo.id}
												user_id_login={id_user}
												callModalError={this.callModalError}
											/>
										</Row>
										{
											//end
										}
									</div>
								</div>
								<div className="row" style={{'marginBottom':'20px'}}>
									<div className="col-md-12">
										<div className="r-footer">
											<div className="f-left" style={{'display':'none'}}>
												<a href="#" className="icon-2" onClick={(member_name!='')?this.handleClickTab(this.props.tab):this.handleClickTab('login')}></a>
											</div>
											<div className="f-right" style={{'display':'none'}}>
												<span className="lbl"><span>{localizeKey(translations)('Create', this.state.lguages)}</span>: </span>
												<span className="ctn">{data.PickDatetime}</span>
												{
													((data.PickCodeRunning==1)?<div style={{'float':'right','paddingLeft':'10px','marginTop':'-2px'}}><a className="running" href={"./running/"+data.UserInfo.id+"_"+data.PickCode}>Running</a></div>:'')
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					}):<div>{localizeKey(Lqhome)('search_not_found', this.state.lguages) }</div>
				}
				
				<ModalEditDescr isOpen={this.state.isOpenModalEditDes} close={this.close}
								txt_description={this.state.txt_description} PickCode={this.state.PickCode}
								txt_title={this.state.txt_title} callModalError={this.callModalError}
								updateHomeLis={this.updateHomeLis} />
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
				<ModalLikes isOpen={this.state.isOpenModalLike} close={this.close} numlike={this.state.numlike}
					idpet={this.state.idpet} id_user={this.state.id_user} likeList={this.state.likeList}
					isRunging={this.state.isRunging}
				/>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeList)