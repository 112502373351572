import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button,Tabs,Tab,Table,Radio} from 'react-bootstrap'
import {TIPS_EXCHANGE_LOADER,LANGUAGE_KEY,CHANGE_TAB,TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'
import $ from 'jquery';
import { Link } from 'react-router-dom'

import DatePicker from 'react-datepicker';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: TIPS_EXCHANGE_LOADER })  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class ExchangeTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',startDate:new Date(),month:'',exchange_summary:{},seller_list:[]
		}
		
		
		
		this.getExchangeTips=()=>{
			let month = this.state.month
			agent.orders.getExchangeTips({month:month}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					let seller_list=data.seller_list
					let exchange_summary=data.exchange_summary
					this.setState({seller_list:seller_list,exchange_summary:exchange_summary})
				}
			})
		}
		
  }
		//---
	componentWillMount(){		
		this.props.onLoad('tips-exchange')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({lguages:language},()=>{
			//this.getrandomad();
		})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//console.log(login)
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id})
		}
		let dates=new Date();
		let mth=dates.getMonth()+1
		let year=dates.getFullYear()
		let month=mth+"/"+year
		this.setState({month:month},()=>{
			this.getExchangeTips();
		})
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	  //document.getElementById("text1").innerText =''
    }

    componentWillUnmount() {
       
    }
	handleChangeDate(date){
		let dates=date.getDate();
		var month = date.getMonth() + 1;
		let year=date.getFullYear();
		let mth=month+"/"+year
		this.setState({startDate:date,month:mth},()=>{
			this.getExchangeTips();
		})
	}
	
	render(){   
		const exchange_summary=this.state.exchange_summary
		const seller_list=this.state.seller_list
		return (
			<div id="body">   
				<div className="heder-page">
					<div className="container">
						<div className="item lbl-1">
							<h1 id="text1">
								<span className="txt">Exchange stips stock</span>
							</h1>
						</div>									
					</div>
				</div>
				<div className="tipster-list">
					<div className="container">
						<Row className="mgec-tip">
							<Col md={2} style={{'textAlign':'center'}}>
								<label className="txt-rpt">Total Tips</label>
								<label className="txt-rpt">{exchange_summary.total_tips}</label>
							</Col>
							<Col md={2}>
								<label className="txt-rpt">Total Tipsters</label>
								<label className="txt-rpt">{exchange_summary.total_tipsters}</label>
							</Col>
							<Col md={2} style={{'textAlign':'center'}}>
								<label className="txt-rpt">GD thành công</label>
								<label className="txt-rpt">{exchange_summary.total_paid_orders}</label>
							</Col>
							<Col md={2} style={{'textAlign':'center'}}>
								<label className="txt-rpt">Tổng tiền</label>
								<label className="txt-rpt">{exchange_summary.total_tips_price}</label>
							</Col>
						</Row>
						<Row className="mg0">
							<Col md={2}></Col>
							<Col md={8}>
								
							</Col>
							<Col md={2} className="rank-mth">
								<DatePicker
								  selected={this.state.startDate}
								  onChange={this.handleChangeDate.bind(this)}
								  dateFormat="MM/yyyy"
								  showMonthYearPicker
								  
								/>
								<i className="fa fa-caret-down icon-caret"></i>
							</Col>
						</Row>
						<Row className="mg0">								
							<Col md={12} className="pd0 tips-exchange">
								<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
									<thead>
										<tr>
											<th colSpan="1" style={{'width':'5%','textAlign':'center'}}>No.</th>
											<th colSpan="1" style={{'width':'25%'}}>Tipster</th>
											<th colSpan="1" style={{'width':'10%','textAlign':'center'}}>Tips</th>
											<th colSpan="1" style={{'width':'20%','textAlign':'center'}}>Số người mua</th>											
											<th colSpan="1" style={{'width':'20%','textAlign':'center'}}>GD thành công</th>											
											<th colSpan="1" style={{'width':'20%','textAlign':'center'}}>Tổng tiền</th>											
										</tr>
									</thead>
									<tbody>
									{
										(seller_list!=='')?seller_list.map((dt,idx)=>{
											var url_username=dt.seller_url_username
											let prName='';
											let url='';
											if(url_username!='' && url_username!='null' && url_username!=null){
												prName=url_username.split("/");
												url=prName[prName.length-1];
											}
											return(
											<tr key={idx}>
												<td style={{'textAlign':'center'}}>0{idx+1}</td>
												<td><a href={'./'+url}>{dt.seller_tipster_name}</a></td>
												<td style={{'textAlign':'center'}}>{dt.tips_num}</td>
												<td style={{'textAlign':'center'}}>{dt.buyer_nums}</td>
												<td style={{'textAlign':'center'}}>{dt.paid_order_nums}</td>
												<td style={{'textAlign':'center'}}>{dt.sum_price_tips}</td>
												
											</tr>	
											)
										}):''
									}												
									</tbody>
								</Table>
							</Col>
						</Row>
					</div>
				</div>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeTips)