import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import ReactDOM from 'react-dom';
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import '../../styles/matchs.css'
import {Col, Row, FormControl, FormGroup, Radio, Checkbox, Table} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,ADD_MATCH} from '../../constants/actionTypes'
import NumberFormat from 'react-number-format'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import ModalErorr from '../common/ModalErorr'
import Select from 'react-select';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: ADD_MATCH })  
	},
});



 


class MatchNew extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
            matchList:[],
            avatar:'https://www.coopbet.com/media/no_avatar.png',
            search:'',
            typeMatch: '',
			offset:0,limit:20,loading:false,loadedAll:false,scrollCus:false,
			isOpenModal:false,msgaprove:'',
            
            sport_tips:2,
            league_id:1139,
            away_team_id: 2464,
            away_team: 'Bayern Munchen',
            home_team_id: 2465,
            home_team:'Borussia Dortmund',
            
            startDate: new Date(),filterdate:'',
            KickHour : '19',
            KickMinute:'00',
            
            sporttipList:[],
            leagueList :[],
            setLeagueList :[],
            teamList :[],
            
            teamListSetLeague: [],
            set_team_id:0,
            set_league_id:0,
                       
            odd_1x2_home:1.61,
            odd_1x2_away:4.80,
            odd_1x2_draw:3.70,
            
            odd_handicap_home:0.78,
            odd_handicap_away:1.13,
            odd_handicap_home_draw:0.75,
            odd_handicap_away_draw:0.00,
            
            odd_overunder_home: 0.88,
            odd_overunder_away: 1.02,
            odd_overunder_home_draw: 2.50,
            odd_overunder_away_draw: 0.00,
            
			odd_oddeven_away: 0.90,
            odd_oddeven_draw: 0.00,
            odd_oddeven_home: 1.00,
            first_odd_header:"1x2",
            new_league_name:"",
            current_league_id:0,
            new_team_name:"",
            current_team_id:0,
            
            league_end:0,
            league_status:"Open",
            league_order:100,
            to_league_id:0,
            to_league_name:'select league',
            set_league_name:'league',
			
			all_league_groups:[],
			all_leagues:[],
			info:{},
			name_contest:'',contest_id:'',
			list_legues_contest:[],selectedOption: {value: "ENGLAND", label: "ENGLAND"},
			optionLegues:[],selectedOptionLG: null,optionGr:[],league_id_ct:'',showcontest:1,
            msg:'',error:''
		}
        this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.doKeySearch=e=>{
            if (e.key == 'Enter') {
                
            }
            
        }
        
        this.saveMatch=this.saveMatch.bind(this) ;
        this.addLeague=this.addLeague.bind(this) ;
        this.deleteLeague=this.deleteLeague.bind(this);
        this.editLeague=this.editLeague.bind(this);    
        this.addTeam=this.addTeam.bind(this) ;
        this.deleteTeam=this.deleteTeam.bind(this) ;
        this.editTeam=this.editTeam.bind(this) ;   
        this.setTeamToLeague=this.setTeamToLeague.bind(this) ; 
        this.removeTeamFromLeague=this.removeTeamFromLeague.bind(this) ; 
        this.setLeaguePriority=this.setLeaguePriority.bind(this) ;   
        this.openOrCloseLeague=this.openOrCloseLeague.bind(this) ;   
                         
        this.onScrollCus = () => {
			
			const messgeContainer = document.getElementById("match-list")
			const loadedAll = this.state.loadedAll

			//if( !loadedAll && messgeContainer.scrollTop === (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
			if( !loadedAll && parseInt(messgeContainer.scrollTop) >= (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
				//let offset = parseInt(this.state.offset) + this.state.limit
				let pageno = parseInt(this.state.offset) + this.state.limit
				const newState = Object.assign({}, this.state, { offset: pageno, scrollCus: true })
				this.setState(newState, () => {
					
				})				
			}
		}
		this.contestInfo=()=>{
			agent.admin.contestInfo({}).then((datas) => {
				if(datas.result==='good'){
					let info=datas.info;
					let all_leagues=datas.all_leagues;
					let all_league_groups=datas.all_league_groups;
					let name_contest=info.name;
					let list_legues_contest=(info.league_list!==undefined)?info.league_list:[];
					let showcontest=(info.showcontest!==undefined)?info.showcontest:0;
					let contest_id=info.id
					let name=(info.name!==undefined)?info.name:'';
					let optionGr=[]
					all_league_groups.map((dt,i)=>{
							optionGr.push({value:dt,label:dt})
							return dt;
						})
					this.setState({info:info,all_leagues:all_leagues,all_league_groups:all_league_groups,
								  list_legues_contest:list_legues_contest,name_contest:name_contest,optionGr:optionGr,
								  selectedOption:optionGr[0],showcontest:showcontest,contest_id:contest_id,
								  name_contest:name
								  },()=>{
									  this.getOptionLegus();
								  })
				}
			})
			
		}
		this.addLeguestocontest=()=>{
			$(".ct-contets").removeClass("selected")
			let selectedOptionLG=this.state.selectedOptionLG;
			let list_legues_contest=this.state.list_legues_contest
			let flag=false;
			list_legues_contest.map(dt=>{
				if(dt.league_id===selectedOptionLG.value){
					flag=true
				}
				return dt;
			})
			if(!flag){
				let values=[{contest_id: '',
						 group_name: "",
						 league_id: selectedOptionLG.value,
						 league_name: selectedOptionLG.label}]
					this.setState({list_legues_contest:values.concat(this.state.list_legues_contest),league_id_ct:selectedOptionLG.value})
			}
			
		}
		this.choiceLegues=(league_id,e)=>{
			this.setState({league_id_ct:league_id})
		}
		this.removeLg=()=>{
			let list_legues_contest=this.state.list_legues_contest
			list_legues_contest=list_legues_contest.filter(dt => dt.league_id !== this.state.league_id_ct);
			this.setState({list_legues_contest:list_legues_contest})
		}
		this.checkshowcontest=(vl,ev)=>{
			if(vl===1 && ev.target.checked){
				this.setState({showcontest:1})
			}else{
				this.setState({showcontest:0})
			}
		}
		this.saveContest=()=>{
			let contest_id=this.state.contest_id
			let name=this.state.name_contest
			let showcontest=this.state.showcontest
			let league_ids=[];
			let list_legues_contest=this.state.list_legues_contest.map(dt=>{
				league_ids.push(dt.league_id)
				return dt;
			})
			league_ids=(JSON.stringify(league_ids))
			//console.log(league_ids)
			agent.admin.saveContest({contest_id:contest_id,name:name,showcontest:showcontest,league_ids:league_ids}).then((datas) => {
				if(datas.result==='good'){
					this.setState({msg:"Saved "+datas.time})
				}else{
					this.setState({error:datas.message})
				}
			})
			
		}
		
	}                                       
  
    
	
	handleChange(date) {
		let day=date.getDate();
		let month=date.getMonth()+1;
		let year=date.getFullYear();
		let KickDate=year+"-"+month+"-"+day
		console.log(KickDate);
		this.setState({
		  startDate: date,filterdate:KickDate
		},()=>{
			
		});
	}
	componentWillMount(){		
        
        this.contestInfo();
        this.getSporttip();
        
        this.getLeagues({sportID:this.state.sport_tips});
        
        this.getTeams(this.state.league_id);
        
        this.getSetLeagues({sportID:this.state.sport_tips});
        this.getTeamsSetLeague(this.state.set_league_id);
        
		this.props.onLoad('matchs');	
        
		const cookies = new Cookies()
		const token = cookies.get(TOKEN_NAME)
		if(token){			
			agent.setToken(token)
			this.props.onLoad( token)       
		}
		let language=(cookies.get('language')!=='' && cookies.get('language') !==null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		let date = new Date();
		let dateFormat=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
		this.setState({'lguages':language,filterdate:dateFormat},()=>{
			
		})
		//----
		
		
		
		//console.log(dateFormat)
		//----
	}
	ChangeField(field,ev){
        this.setState({[field]:ev.target.value,offset: 0})
    }
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	handleChangeText(){
	 
	}	
	
    //------
    addLeague() {
      let leaguename =  this.state.new_league_name;
      let sport_id =  this.state.sport_tips;
      let params = {league_name:leaguename,sport_id:sport_id};
    //  alert(sport_id);
      agent.upload.savenewleague(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Saved sucessfully.")
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
            
            this.setState({new_league_name:""}) 
      }) 
    }  
    //------
    deleteLeague() {
      let league_id =  this.state.current_league_id;
      let sport_id =  this.state.sport_tips;
      let params = {league_id:league_id,sport_id:sport_id};
    //  alert(league_id);
      agent.upload.deleteleague(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                  this.getSetLeagues({sportID:this.state.sport_tips});
                  this.setState({set_league_name:''});
                  
                $("#msg-succesfull").html("Delete sucessfully.")
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
            
            this.setState({league_id:0}) 
      }) 
            
      
    }
    
    //------
    deleteTeam() {
      let current_team_id =  this.state.current_team_id;
      let sport_id =  this.state.sport_tips;
      let league_id =  this.state.set_league_id;
      let params = {team_id:current_team_id,sport_id:sport_id,league_id:league_id};
      
      agent.upload.deleteteam(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Delete sucessfully.")
                
                //this.getSporttip();
        
                //this.getLeagues({sportID:this.state.sport_tips});
                this.getTeamsSetLeague(league_id);
                //this.getTeams(league_id);
                this.setState({new_team_name:'',current_team_id:0});
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
            
            this.setState({league_id:0}) 
      }) 
            
      
    }
    //------
    addTeam() {
      let teamname =  this.state.new_team_name;
      let league_id =  this.state.set_league_id;
      let sport_id =  this.state.sport_tips;
      let params = {team_name:teamname,league_id:league_id,sport_id:sport_id};
     // alert(league_id);
      agent.upload.savenewteam(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Saved sucessfully.")
                //this.getSporttip();
        
               // this.getLeagues({sportID:this.state.sport_tips});
                //this.getSetLeagues({sportID:this.state.sport_tips});
                this.getTeamsSetLeague(league_id);
                ///this.getTeams(league_id);
                this.setState({new_team_name:'',current_team_id:0});
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })      
      
    }
    //------
    editTeam() {
      let teamname =  this.state.new_team_name;
      let league_id =  this.state.set_league_id;
      let sport_id =  this.state.sport_tips;
      let params = {team_name:teamname,team_id:this.state.current_team_id,league_id:league_id,sport_id:sport_id};
     // alert(league_id);
      agent.upload.editteam(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Saved sucessfully.")
                this.getSporttip();
        
               // this.getLeagues({sportID:this.state.sport_tips});
                this.getTeamsSetLeague(league_id);
                //this.getTeams(league_id);
                this.setState({new_team_name:''});
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })      
    }
    //------
    setTeamToLeague() {
     //   alert("hi");
      let team_id =  this.state.set_team_id;
      let league_id =  this.state.to_league_id;
      let sport_id =  this.state.sport_tips;
      let params = {team_id:team_id,league_id:league_id,sport_id:sport_id};
     // alert(league_id);
      agent.upload.setteamtoleague(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Set team to League sucessfully.")
                //this.getLeagues({sportID:this.state.sport_tips});
                //this.getSetLeagues({sportID:this.state.sport_tips});
                this.setState({set_league_name:''});
                this.getTeams(this.state.league_id);
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })      
    }
    
    //------
    removeTeamFromLeague() {
   
      let team_id =  this.state.set_team_id;
      let league_id =  this.state.set_league_id;
      let sport_id =  this.state.sport_tips;
      let params = {team_id:team_id,league_id:league_id,sport_id:sport_id};
     // alert(league_id);
      agent.upload.removeteamfromleague(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Remove team from league sucessfully.")
                //this.getLeagues({sportID:this.state.sport_tips});
                this.getTeams(league_id);
                //this.getSetLeagues({sportID:this.state.sport_tips});
                this.getTeamsSetLeague(league_id);
                this.setState({set_league_name:'',new_team_name:''});
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })      
    }
    //------
    setLeaguePriority() {
      let league_order =  this.state.league_order;
      let league_id =  this.state.current_league_id;
      let sport_id =  this.state.sport_tips;
      let params = {league_order:league_order,league_id:league_id,sport_id:sport_id};
     // alert(league_id);
      agent.upload.setleaguepriority(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Set Priority League sucessfully.")
                this.getLeagues({sportID:this.state.sport_tips});
                this.getTeams(this.state.league_id);
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })      
    }
    
    //------
    openOrCloseLeague() {
      let league_status =  this.state.league_status;
      let league_end = 0;
    
      if (league_status=="Close") { league_end=0;}
      else league_end=1;
    
      let league_id =  this.state.current_league_id;
      let sport_id =  this.state.sport_tips;
      let params = {league_end:league_end,league_id:league_id,sport_id:sport_id};
     // alert(league_id);
      agent.upload.openorcloseleague(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Open/Close League sucessfully.")
                //this.getLeagues({sportID:this.state.sport_tips});
                this.refreshStatus(league_id);
                this.getTeams(this.state.league_id);
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })      
    }
    
    
      //------
    editLeague() {
      let leaguename =  this.state.new_league_name;
      let league_id =  this.state.current_league_id;
      let sport_id =  this.state.sport_tips;
      let params = {league_name:leaguename,league_id:league_id,sport_id:sport_id};
     // alert(league_id);
      agent.upload.editleague(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Saved sucessfully.")
                this.getSporttip();
        
                this.getLeagues({sportID:this.state.sport_tips});
                this.getTeams(this.state.league_id);
                this.setState({new_league_name:'',current_team_id:0});
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
      })  
    }
    //---
    selectAwayTeam(ev){
        this.setState({away_team_id:ev.target.value})
      
        const teamList=this.state.teamList;
        var selected_team =  teamList.filter(function(team) {
            return team.id == ev.target.value;
        });
        console.log(selected_team[0].team_name);
        this.setState({away_team:selected_team[0].team_name});
    }
    
     //---
    selectHomeTeam(ev){
        this.setState({home_team_id:ev.target.value})
        const teamList=this.state.teamList;
        var selected_team =  teamList.filter(function(team) {
            return team.id == ev.target.value;
        });
        console.log(selected_team[0].team_name);
        this.setState({home_team:selected_team[0].team_name});
       //this.getTeams({sportID:ev.target.value});
    }
    
    selectLeague(ev) {
        this.setState({league_id:ev.target.value});
        this.getTeams(ev.target.value);
    }
    
    selectCurrentLeague(ev) {
        this.setState({current_league_id:ev.target.value});
        const leagueList=this.state.leagueList;
        var selected_league =  leagueList.filter(function(league) {
            return league.id == ev.target.value;
        });
        if (selected_league[0].league_end==1)  this.setState({new_league_name:selected_league[0].league_name,league_end:selected_league[0].league_end,league_status:"Close",league_order:selected_league[0].league_order});
        else {
            this.setState({new_league_name:selected_league[0].league_name,league_end:selected_league[0].league_end,league_status:"Open",league_order:selected_league[0].league_order});
        }
        
        //this.getTeams(ev.target.value);
    }
    
    refreshStatus(leagueid) {
        let sportID = this.state.sport_tips;
        agent.upload.getleaguelist({sportID:this.state.sport_tips}).then((datas) => {
            if(datas.result=='good'){
                this.setState({leagueList:datas.list,current_league_id:leagueid});
             //   console.log(datas.list);
                const leagueList = datas.list;
                var selected_league =  leagueList.filter(function(league) {
                    return league.id == leagueid;
                });
                
                
                if (selected_league[0].league_end==1)  this.setState({new_league_name:selected_league[0].league_name,league_end:selected_league[0].league_end,league_status:"Close",league_order:selected_league[0].league_order});
                else {
                    this.setState({new_league_name:selected_league[0].league_name,league_end:selected_league[0].league_end,league_status:"Open",league_order:selected_league[0].league_order});
                }
            }
        })    
      
       // alert(leagueid);
        
    }
    
    
    
    selectSetLeague(ev) {
        const setLeagueList=this.state.setLeagueList;
        var selected_league =  setLeagueList.filter(function(league) {
            return league.id == ev.target.value;
        });
        this.setState({set_league_id:ev.target.value,set_league_name:selected_league[0].league_name});
        this.getTeamsSetLeague(ev.target.value);
    }
    
    selectToLeague(ev) {
        const leagueList=this.state.leagueList;
        var selected_league =  leagueList.filter(function(league) {
            return league.id == ev.target.value;
        });
        this.setState({to_league_id:ev.target.value,to_league_name:selected_league[0].league_name});
        
       // const setLeagueList=this.state.setLeagueList;
        
        /*var selected_league =  setLeagueList.filter(function(league) {
            return league.id == ev.target.value;
        });
        */
        //this.getTeamsSetLeague(ev.target.value);
    }
    
    selectSetTeam(ev) {
       // alert(ev.target.value); 
        this.setState({set_team_id:ev.target.value,current_team_id:ev.target.value})
        const teamList=this.state.teamListSetLeague;
        var selected_team =  teamList.filter(function(team) {
            return team.id == ev.target.value;
        });
        console.log(selected_team[0].team_name);
        this.setState({new_team_name:selected_team[0].team_name});
        
        
    }
    
    
     //---
    selectSportTips(ev){
        this.setState({sport_tips:ev.target.value})
        //alert(ev.target.value);
        this.getLeagues({sportID:ev.target.value});
        this.getSetLeagues({sportID:ev.target.value});
        if (ev.target.value==2) this.setState({first_odd_header:"1x2"});
        else this.setState({first_odd_header:"Money Line"});
        
    }
    
    selectHour(feild,ev){
        this.setState({[feild]:ev.target.value})
    }
    
    selectMinute(feild,ev){
        this.setState({[feild]:ev.target.value})
    } 
    getSporttip(){
        agent.auth.sporttip().then((datas) => {
            if(datas.result=='good'){
                this.setState({sporttipList:datas.list})
            }
      })    
    }
    
    getLeagues(sportID){
        //alert(sportID);
        agent.upload.getleaguelist(sportID).then((datas) => {
            if(datas.result=='good'){
               // console.log(datas.list);
             //  this.setState({});
                this.setState({leagueList:datas.list,league_id:datas.list[0]['id']});
                this.getTeams(datas.list[0]['id']);
            }
      })    
    }
    
    getSetLeagues(sportID){
        //alert(sportID);
        agent.upload.getleaguelist(sportID).then((datas) => {
            if(datas.result=='good'){
               // console.log(datas.list);
         //      this.setState({});
                this.setState({new_league_name:'',leagueList:datas.list,setLeagueList:datas.list,set_league_id:datas.list[0]['id'],current_league_id:datas.list[0]['id'],league_id:datas.list[0]['id']});
                this.getTeamsSetLeague(datas.list[0]['id']);
            }
      })    
    }
    getTeams(league_id){
        //alert(sportID);
        let state=this.state;
        let params = {league_id:league_id,sport_id:state.sport_tips};
        //alert(state.league_id);
        agent.upload.getteamlist(params).then((datas) => {
            if(datas.result=='good'){
               //console.log(datas.list);
               if (datas.list.length>0) {
                this.setState({teamList:datas.list,home_team_id:datas.list[0]['id'],away_team_id:datas.list[0]['id'],home_team:datas.list[0]['team_name'],away_team:datas.list[0]['team_name']})
               } else {
                   this.setState({teamList:[]});
               }
            }
      })    
    }
    
    getTeamsSetLeague(league_id){
        //alert(sportID);
        let state=this.state;
        let params = {league_id:league_id,sport_id:state.sport_tips};
        //alert(state.league_id);
        agent.upload.getteamlist(params).then((datas) => {
            if(datas.result=='good'){
               //console.log(datas.list);
               if (datas.list.length>0) {
                this.setState({teamListSetLeague:datas.list,set_team_id:datas.list[0]['id']})
               } else {
                   this.setState({teamListSetLeague:[]});
               }
            }
      })    
    }
    saveMatch() {
       // alert("saveMatch");
        let state=this.state;
        console.log(state.filterdate);
        let params = {sport_id:state.sport_tips,league_id:state.league_id,home_team_id:state.home_team_id,away_team_id:state.away_team_id,                  KickDate:state.filterdate,KickHour:state.KickHour,KickMinute:state.KickMinute,
                odd_1x2_home:state.odd_1x2_home,
                odd_1x2_away:state.odd_1x2_away,
                odd_1x2_draw:state.odd_1x2_draw,
                odd_handicap_home:state.odd_handicap_home,
                odd_handicap_away:state.odd_handicap_away,
                odd_handicap_home_draw:state.odd_handicap_home_draw,
                odd_handicap_away_draw:state.odd_handicap_away_draw,
                odd_overunder_home: state.odd_overunder_home,
                odd_overunder_away: state.odd_overunder_away,
                odd_overunder_home_draw: state.odd_overunder_home_draw,
                odd_overunder_away_draw: state.odd_overunder_away_draw,odd_oddeven_away: state.odd_oddeven_away,
                odd_oddeven_draw: state.odd_oddeven_draw,
                odd_oddeven_home: state.odd_oddeven_home};
        agent.upload.savenewmatch(params).then((datas) => {
            if(datas.result==='good'){
                //ReactDOM.render('Saved sucessfully.', document.getElementById('msg-succesfull'));
                $("#msg-succesfull").html("Saved sucessfully.")
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-succesfull'));
                    $("#msg-succesfull").html("")
                },5000);
            }else{
                //ReactDOM.render(datas.message, document.getElementById('msg-error'));
                $("#msg-error").html(datas.message)
                setTimeout(function(){
                    //ReactDOM.render('', document.getElementById('msg-error'));
                    $("#msg-error").html('')
                },5000);
            }
            
            
      })  
      
      
    } 
	handleChange = (selectedOption) => {
		//console.log(`Option selected:`, selectedOption)
		this.setState({ selectedOption:selectedOption}, () =>{
		  this.getOptionLegus();
		});
	}
	handleChangeLG = (selectedOption) => {
		//console.log(`Option selected:`, selectedOption)
		this.setState({ selectedOptionLG:selectedOption}, () =>{
		  //this.getOptionLegus();
		});
	}
	getOptionLegus(){
		let all_leagues=this.state.all_leagues;
		let value=this.state.selectedOption.value;
		let optionLegues=[];
		all_leagues.map((dt,i)=>{
			if(value===dt.group_name){
				optionLegues.push({value:dt.league_id,label:dt.league_name})
			}
			return dt;
		}) 
		//console.log(optionLegues[0])
		this.setState({optionLegues:optionLegues,selectedOptionLG:optionLegues[0]})
	}
	render(){   
		const matchList=this.state.matchList;
        const sporttipList=this.state.sporttipList;
        const leagueList=this.state.leagueList;
        const teamList=this.state.teamList;
		const loading=this.state.loading;
        const setLeagueList=this.state.setLeagueList;
        const teamListSetLeague=this.state.teamListSetLeague;
        let hous=['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
        let minutes=['00','05','10','15','20','25','30','35','40','45','50','55']
		//-----------
		let info=this.state.info;
		let all_leagues=this.state.all_leagues;
		let all_league_groups=this.state.all_league_groups;
		let name_contest=this.state.name_contest;
		let list_legues_contest=this.state.list_legues_contest;
		
		//-----------
		//console.log(list_legues_contest)
        return (
			<div id="body">   
				<div>
					<div className="heder-page" style={{'padding':'10px 0'}}>
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">Enter New Match Infomation </span>
								</h1>
                                
							</div>
                        </div>
                    </div>    
				</div>
                
                <div>
                    <Col md={12} className='items-3'>
                        
                        <Col md={5}>
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">Sport Tips:</span>
                                </Col>
                                <Col md={5}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.sport_tips} onChange={this.selectSportTips.bind(this)}>
                                        
                                        {
                                            (sporttipList)?sporttipList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.title} </option> )
                                            }):''
                                        }
                                    </FormControl>    
                                </Col>
                            </Row>
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">League:</span>
                                </Col>
                                <Col md={5}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.league_id} onChange={this.selectLeague.bind(this)}>
                                        
                                        {
                                            (leagueList)?leagueList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.league_name} </option> )
                                            }):''
                                        }
                                    </FormControl>
                                      
                                </Col>
                                <Col md={2}>
                                    
                                </Col>
                            </Row>
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">Kiss Of Time:</span>
                                </Col>
                                <Col md={3}>
                                    <div className="kickofftime" id="daterange">
                                        <DatePicker className="input-date"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange.bind(this)}                                        
                                        />
                                    </div>
                                </Col>
                                <Col md={1} className="form-group colleft" style={{'padding':'0','width':'50px'}}>
                                        <FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} onChange={this.selectHour.bind(this,'KickHour')}>
                                        {
                                            hous.map((data,i)=>{
                                                return(<option value={data} key={i}>{data}</option>)
                                            })
                                        }
                                        </FormControl>        
                                </Col>
                                <Col md={1} className="form-group colleft" style={{'padding':'0','width':'50px'}}>
                                        <FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} onChange={this.selectMinute.bind(this,'KickMinute')}>
                                        {
                                            minutes.map((data,i)=>{
                                                return(<option value={data} key={i}>{data}</option>)
                                            })
                                        }
                                        </FormControl>        
                                </Col>    
                                                        
                            </Row>
                            
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">Home:</span>
                                </Col>
                                <Col md={5}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.home_team_id} onChange={this.selectHomeTeam.bind(this)}>
                                        
                                        {
                                            (teamList)?teamList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.team_name} </option> )
                                            }):''
                                        }
                                    </FormControl>    
                                </Col>
                            </Row>
                            
                            <Row className="form-group item">
                                <Col md={2}></Col>
                                <Col md={3} className="pdt5">
                                    <span className="lbl">Away:</span>
                                </Col>
                                <Col md={5}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.away_team_id} onChange={this.selectAwayTeam.bind(this)}>
                                        
                                        {
                                            (teamList)?teamList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.team_name} </option> )
                                            }):''
                                        }
                                    </FormControl>    
                                </Col>
                            </Row>
                        </Col>
                        
                        
                        <Col md={7}>
                            <div className="sh-snap-menu">
                                <Table className="oddmove" >
                                    <thead>
                                        <tr>
                                           
                                            <td colSpan="1"  style={{'width':'180px','textAlign': 'center','paddingLeft': '0px'}}>Match</td>
                                            <td style={{'width':'100px','textAlign': 'center','paddingRight': '20px'}}>{this.state.first_odd_header}</td>
                                            
                                            <td colSpan="2" style={{'width':'150px','textAlign': 'center','paddingRight': '20px'}}>Asian Handicap</td>
                                            
                                            <td colSpan="2" style={{'width':'100px','textAlign': 'center','paddingRight': '0px'}}>Over/Under</td>
                                            <td colSpan="2" style={{'width':'80px','textAlign': 'center','paddingLeft': '0px'}}>Odd/Even</td>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="boder_none odd-row ">
                                            <td colSpan="1">
                                                <div style={{'paddingTop':'10px'}}>{this.state.home_team}</div>
                                                <div style={{'paddingTop':'20px'}}>{this.state.away_team}</div>
                                                <div style={{'paddingTop':'20px'}}>Draw</div>
                                            </td>
                                            
                                             <td colSpan="1">
                                                <div className="divw65"><input id="odd_1x2_home" name="odd_1x2_home" type="text" value={this.state.odd_1x2_home} placeholder="odd 1x2 home" className="form-control" onChange={this.ChangeField.bind(this,'odd_1x2_home')}/></div>
                                                <div className="divw65"><input id="odd_1x2_away" name="odd_1x2_away" type="text" value={this.state.odd_1x2_away} placeholder="odd 1x2 away" className="form-control" onChange={this.ChangeField.bind(this,'odd_1x2_away')}/></div>
                                                <div className="divw65"><input id="odd_1x2_draw" name="odd_1x2_draw" type="text" value={this.state.odd_1x2_draw} placeholder="odd 1x2 draw" className="form-control" onChange={this.ChangeField.bind(this,'odd_1x2_draw')}/></div>
                                            </td>
                                            
                                            <td style={{'width':'60px','paddingRight':'0px'}}>
                                                <div className="divw65"><input id="odd_handicap_home_draw" name="odd_handicap_home_draw" type="text" value={this.state.odd_handicap_home_draw} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_handicap_home_draw')}/></div>
                                                <div className="divw65"><input id="odd_handicap_away_draw" name="odd_handicap_away_draw" type="text" value={this.state.odd_handicap_away_draw} placeholder="odd handicap away draw" className="form-control" onChange={this.ChangeField.bind(this,'odd_handicap_away_draw')}/></div>
                                                <div>&nbsp;</div>
                                                
                                                
                                            </td>
                                            
                                            <td colSpan="1" style={{'paddingLeft':'0px'}}>
                                                <div className="divw65"><input id="odd_handicap_home" name="odd_handicap_home" type="text" value={this.state.odd_handicap_home} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_handicap_home')}/></div>
                                                <div className="divw65"><input id="odd_handicap_away" name="odd_handicap_away" type="text" value={this.state.odd_handicap_away} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_handicap_away')}/></div>
                                                <div className="divw65">&nbsp;</div>
                                            </td>
                                            
                                            <td style={{'paddingRight':'0px','width':'40px'}}>
                                                
                                                <div className="divw65" style={{'paddingTop':'5px'}}><input id="odd_overunder_home_draw" name="odd_overunder_home_draw" type="text" value={this.state.odd_overunder_home_draw} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_overunder_home_draw')}/></div>
                                                <div className="divw65" style={{'paddingTop':'5px','display':'none'}}><input id="odd_overunder_away_draw" name="odd_overunder_away_draw" type="text" value={this.state.odd_overunder_away_draw} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_overunder_away_draw')}/></div>
                                                <div>&nbsp;</div>
                                            </td>
                                            <td colSpan="1" style={{'paddingLeft':'0px','width':'40px'}}>
                                                <div className="divw65"><input id="odd_overunder_home" name="odd_overunder_home" type="text" value={this.state.odd_overunder_home} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_overunder_home')}/></div>
                                                <div className="divw65"><input id="odd_overunder_away" name="odd_overunder_away" type="text" value={this.state.odd_overunder_away} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_overunder_away')}/></div>
                                                <div className="divw65">&nbsp;</div>
                                            </td>
                                            
                                            <td colSpan="2" style={{'paddingLeft': '25px'}}>
                                                <div className="divw65"><input id="odd_oddeven_home" name="odd_oddeven_home" type="text" value={this.state.odd_oddeven_home} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_oddeven_home')}/></div>
                                                <div className="divw65"><input id="odd_oddeven_away" name="odd_oddeven_away" type="text" value={this.state.odd_oddeven_away} placeholder="" className="form-control" onChange={this.ChangeField.bind(this,'odd_oddeven_away')}/></div>
                                                <div className="divw65">&nbsp;</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    
                                </Table>
                            </div>
                            <div>
                                <Col md={12} style={{'paddingLeft':'0px'}}>
                                    <a className="addmatch" style={{'margin':'0px'}} onClick={this.saveMatch} >  Save Match </a>
                                </Col>
                            </div>
                            
                            
                        </Col>
                    </Col>
                    
                </div>
                <div>
                    <Col md={12} className='items-3'>
                    
                        <div>
                            <div className="heder-page" style={{'padding':'10px 0'}}>
                                <div className="container">
                                    <div className="item lbl-1">
                                        <h1>
                                            <span className="txt">Leagues/Teams Management</span>
                                        </h1>
                                        
                                    </div>
                                </div>
                            </div>    
                        </div>
                
                        <div style={{'paddingTop':'0px'}}>
                            <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px'}}><Col md={2}></Col>
                            <Col md={3}>
                                <h4>
                                        <span className="txt" style={{'color':'#05978b'}}>Leagues Management</span>
                                </h4>
                            </Col>
                                
                            </Row>
                        </div>
                        
                        <div style={{'paddingTop':'0px'}}>
                            <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px'}}>
                                <Col md={2} style={{'paddingLeft':'0px','textAlign':'right'}}>Select League:</Col>
                                 <Col md={3} style={{'paddingLeft':'20px'}}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.current_league_id} onChange={this.selectCurrentLeague.bind(this)}>
                                        
                                        {
                                            (leagueList)?leagueList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.league_name} </option> )
                                            }):''
                                        }
                                    </FormControl>
                                </Col>
                               
                            </Row>
                            
                            <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px'}}>
                                <Col md={2} style={{'paddingLeft':'0px','textAlign':'right'}}>League:</Col>
                                 <Col md={3} style={{'paddingLeft':'20px'}}>
                                    <input id="new_league_name" name="new_league_name" type="text" value={this.state.new_league_name} style={{'width':'100%'}} placeholder=""  onChange={this.ChangeField.bind(this,'new_league_name')}/>
                                </Col>
                                <Col md={6} style={{'paddingLeft':'0px','paddingTop':'5px'}}>
                                    <a className="addmatch" style={{'margin':'0px'}} onClick={this.addLeague} >  Add League</a>&nbsp;&nbsp;<a className="addmatch" style={{'margin':'0px'}} onClick={this.editLeague} >Update League</a>&nbsp;&nbsp;<a className="addmatch" style={{'margin':'0px'}} onClick={this.deleteLeague} >Delete League</a>
                                </Col>
                            </Row>
                        </div>
                        
                        <div style={{'paddingTop':'0px'}}>
                            <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px'}}>
                                <Col md={2} style={{'paddingLeft':'0px','textAlign':'right'}}></Col>
                                 <Col md={2} style={{'paddingLeft':'20px'}}>
                                    League Status : {this.state.league_status}
                                   
                                </Col>
                                <Col md={2} style={{'paddingLeft':'0px','paddingTop':'5px'}}>
                                    <a className="addmatch" style={{'margin':'0px'}} onClick={this.openOrCloseLeague} >  Open/Close League</a>
                                </Col>
                               
                            </Row>
                        </div>
                        
                        <div style={{'paddingTop':'0px'}}>
                            <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px'}}>
                                <Col md={2} style={{'paddingLeft':'0px','textAlign':'right'}}>
                                    Priority Level :  
                                </Col>
                                 <Col md={2} style={{'paddingLeft':'20px'}}>
                                    <input id="league_order" name="league_order" type="text" value={this.state.league_order} style={{'width':'100%'}} placeholder=""  onChange={this.ChangeField.bind(this,'league_order')}/> 
                                </Col>
                                <Col md={2} style={{'paddingLeft':'0px','paddingTop':'5px'}}>
                                    <a className="addmatch" style={{'margin':'0px'}} onClick={this.setLeaguePriority} >  Set League Priority</a>
                                </Col>
                               
                            </Row>
                        </div>
                        
                        <div style={{'paddingTop':'0px'}}>
                            <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px'}}><Col md={2}></Col>
                            <Col md={3}>
                                <h4>
                                        <span className="txt" style={{'color':'#05978b'}}>Teams Management</span>
                                </h4>
                            </Col>
                                
                            </Row>
                        </div>
                            
                        <div style={{'paddingTop':'0px'}}>
                                
                                <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px'}}>
                                    <Col md={2} style={{'paddingLeft':'0px','textAlign':'right'}}>
                                    Choose a team :  
                                    </Col>
                                    <Col md={3} style={{'paddingLeft':'20px'}}>
                                        <FormControl componentClass="select" placeholder="select" value={this.state.set_league_id} onChange={this.selectSetLeague.bind(this)}>
                                        
                                        {
                                            (setLeagueList)?setLeagueList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.league_name} </option> )
                                            }):''
                                        }
                                    </FormControl>
                                    </Col>
                                    <Col md={3} style={{'paddingLeft':'0px'}}>
                                    <FormControl componentClass="select" placeholder="select" value={this.state.set_team_id} onChange={this.selectSetTeam.bind(this)}>
                                        
                                        {
                                            (teamListSetLeague)?teamListSetLeague.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.team_name} </option> )
                                            }):''
                                        }
                                    </FormControl>    
                                    </Col>
                                     <Col md={1} style={{'paddingLeft':'0px','textAlign':'right'}}>
                                    Add to league :  
                                    </Col>
                                    <Col md={3} style={{'paddingLeft':'20px'}}>
                                        <FormControl componentClass="select" placeholder="select" value={this.state.to_league_id} onChange={this.selectToLeague.bind(this)}>
                                        
                                        {
                                            (setLeagueList)?setLeagueList.map(data=>{
                                                return( <option value={data.id} key={data.id}> {data.league_name} </option> )
                                            }):''
                                        }
                                    </FormControl>
                                    </Col>
                                    
                                    
                                </Row>
                                <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px','TextAlign':'right'}}>
                                <Col md={5} style={{'paddingTop':'0px','textAlign':'right'}}>Set this team:  
                                    <span style={{'color':'#05978b'}}> {this.state.new_team_name} </span> to  
                                    <span style={{'color':'#05978b'}}> {this.state.to_league_name}</span>
                                </Col>
                                <Col md={4} style={{'paddingLeft':'0px','paddingTop':'0px'}}>
                                            <a className="addmatch" style={{'margin':'0px'}} onClick={this.setTeamToLeague} >  Set Team To League  </a>&nbsp; <a className="addmatch" style={{'margin':'0px'}} onClick={this.removeTeamFromLeague} >  Remove Team From League  </a>
                                
                                </Col>
                                <Col md={3} style={{'paddingLeft':'0px','paddingTop':'0px','Textalign':'left'}}> Remove this team: 
                                    <span style={{'color':'#05978b'}}> {this.state.new_team_name} </span> from  
                                    <span style={{'color':'#05978b'}}> {this.state.set_league_name}</span>
                                </Col>
                                </Row>
                        </div>
                        
                        <div style={{'paddingTop':'0px'}}>
                                <Row className="form-group item" style={{'paddingTop':'0px','paddingLeft':'15px'}}>
                                    <Col md={2} style={{'paddingLeft':'0px','textAlign':'right'}}>Team:</Col>
                                    <Col md={3} style={{'paddingLeft':'20px'}}>
                                        <input id="new_team_name" name="new_team_name" type="text" value={this.state.new_team_name} style={{'width':'100%'}} placeholder=""  onChange={this.ChangeField.bind(this,'new_team_name')}/>
                                    </Col>
                                    
                                    <Col md={6} style={{'paddingLeft':'0px','paddingTop':'5px'}}>
                                        <a className="addmatch" style={{'margin':'0px'}} onClick={this.addTeam} >  Add Team  </a>&nbsp;&nbsp;&nbsp;&nbsp;<a className="addmatch" style={{'margin':'0px'}} onClick={this.editTeam} >  Update Team  </a>&nbsp;&nbsp;&nbsp;&nbsp;<a className="addmatch" style={{'margin':'0px'}} onClick={this.deleteTeam} >  Delete Team  </a>
                                    </Col>
                                    
                                </Row>
                        </div>
                        <div>
                            <Col md={12} className="msg-error" id="msg-error"></Col>
                            <Col md={12} className="msg-succesfull" id="msg-succesfull" style={{'paddingLeft':'0px','color':'green'}}></Col> 
                        </div>
                    </Col>
                </div>
				<div id="contest">
					<Col md={12} className='items-3'>
						<div className="heder-page" style={{'padding':'10px 0'}}>
							<div className="container">
								<div className="item lbl-1">
									<h1>
										<span className="txt">contest</span>
									</h1>
									
								</div>
							</div>
						</div>  
					</Col>
					<Col md={12} style={{'minHeight':'300px','marginTop':'15px'}}>
						<Col md={6}>
							<Row className="form-group item mg0" style={{'paddingTop':'0px','paddingLeft':'15px'}}>
                                <Col md={4} style={{'paddingLeft':'0px','textAlign':'right'}}></Col>
                                 <Col md={6} style={{'paddingLeft':'20px'}}>
                                    <h4>
										<span className="txt" style={{'color':'#05978b'}}>Teams Management</span>
									</h4>
                                </Col>                               
                            </Row>
							<Row className="form-group item mg0" style={{'paddingTop':'0px','paddingLeft':'15px'}}>
                                <Col md={4} style={{'paddingLeft':'0px','textAlign':'right'}}> select league group:</Col>
                                 <Col md={6} style={{'paddingLeft':'20px'}}>
                                    
									<Select
										value={this.state.selectedOption}
										onChange={this.handleChange}
										options={this.state.optionGr}
									  />
                                </Col>                               
                            </Row>
							<Row className="form-group item mg0" style={{'marginTop':'15px','paddingLeft':'15px'}}>
                                <Col md={4} style={{'paddingLeft':'0px','textAlign':'right'}}>Select League:</Col>
                                 <Col md={6} style={{'paddingLeft':'20px'}}>
                                   <Select
											value={this.state.selectedOptionLG}
											onChange={this.handleChangeLG}
											options={this.state.optionLegues}
										  />
								   
                                </Col>                               
                            </Row>
							<Row className="form-group item mg0" style={{'marginTop':'15px','paddingLeft':'15px'}}>
                                <Col md={4} style={{'paddingLeft':'0px','textAlign':'right'}}>League:</Col>
                                 <Col md={6} style={{'paddingLeft':'20px'}}>
                                    <input className="form-control" name="new_league_name" type="text" value={(this.state.selectedOptionLG!==null)?this.state.selectedOptionLG.label:''} style={{'width':'100%'}} placeholder=""  onChange={this.ChangeField.bind(this,'test')}/>
                                </Col>
                                <Col md={2} style={{'paddingLeft':'0px','paddingTop':'8px'}}>
                                    <a className="addmatch" style={{'margin':'0px','fontSize':'12px'}} onClick={this.addLeguestocontest.bind(this)} >  Add League</a>
                                </Col>
                            </Row>
							
						</Col>
						<Col md={6}>
							<Row className="form-group item mg0" style={{'marginTop':'15px','paddingLeft':'15px'}}>
                                <Col md={4} style={{'paddingLeft':'0px','textAlign':'right','color':'#05978b'}}>Leagues Contest Name:</Col>
                                 <Col md={4}>
                                    <input className="form-control" name="new_league_name" type="text" value={this.state.name_contest} style={{'width':'100%'}} placeholder=""  onChange={this.ChangeField.bind(this,'name_contest')}/>
                                </Col>
                                
                            </Row>
							<Row className="form-group item mg0" style={{'marginTop':'15px','paddingLeft':'15px'}}>
                                <Col md={12} style={{'paddingLeft':'50px','fontSize':'13px'}}>List of leagues</Col>
                                 <Col md={7} style={{'paddingLeft':'50px','fontSize':'13px'}}>
                                    <div className="list-legues-eruo">
									{
										(list_legues_contest!==undefined && list_legues_contest.length>0)?list_legues_contest.map((dt,i)=>{
											return(
												<div className={(dt.league_id===this.state.league_id_ct)?"ct-contets selected":"ct-contets"} key={i} onClick={this.choiceLegues.bind(this,dt.league_id)}>{dt.league_name}</div>
											)
										}):''
									}
									</div>
								</Col>
                                <Col md={5}>
									 <Row style={{'marginTop':'5px'}}>
										<a className="addmatch" style={{'margin':'0px','fontSize':'12px'}}  onClick={this.removeLg.bind(this)}>  Remove</a>
									</Row> 
									<Row style={{'marginTop':'15px'}} id="sho_hide">
										<Col md={8} className="pdr0">
											<Checkbox checked={(this.state.showcontest===1)?true:false}  onChange={this.checkshowcontest.bind(this,1)}>
											  Show
											</Checkbox>
										</Col>
										<Col md={8} className="pdr0">
											<Checkbox checked={(this.state.showcontest!==1)?true:false}  onChange={this.checkshowcontest.bind(this,0)}>
											  Hide
											</Checkbox>
										</Col>
									</Row>
								</Col>
                            </Row>
							<Row className="form-group item mg0" style={{'marginTop':'15px'}}>
								<Col md={8} style={{'textAlign':'center'}}>
								 <a className="addmatch" style={{'margin':'0px','fontSize':'12px'}} onClick={this.saveContest.bind(this)} >  Save</a>
								</Col>
								<Col md={12} className="succes">{this.state.msg}</Col>
								<Col md={12} className="error">{this.state.error}</Col>
							</Row>
							
						</Col>
					</Col>
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgaprove}/>
			</div>
        );
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(MatchNew)