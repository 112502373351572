import React from 'react';
import { Link } from 'react-router-dom'
//import {Navbar, Nav, NavItem, Modal} from 'react-bootstrap'
import { connect } from 'react-redux'
import agent from '../agent'
import { Cookies } from 'react-cookie'
import {RESET_DASHBOARD, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME} from '../constants/actionTypes'
//import 'bootstrap/dist/css/bootstrap.css';
//import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../styles/profile.css'
import $ from 'jquery';
import translations from './common/captionhome.json';


const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	setMember: (data)=>{ 
		dispatch({ type: MEBER_NAME, data })
	}
});

class HeaderProfile extends React.Component {
	constructor(){
		super()
		this.state = {
			profileInfo:{},
			avatarDefaultOld:"https://bootdey.com/img/Content/avatar/avatar1.png",
			avatarDefault:"https://www.coopbet.com/media/avatar_none.png",
			isActive:'',id_user_login:'',
			lguages:'1005',	
		}
		//---
		
		//----
		
	}
		
	
	//---------------------------
	
	
    
	componentWillReceiveProps(nextProps) {
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		//console.log(nextProps)
		if(nextProps!=''){
			this.setState({profileInfo:nextProps.profileInfo,isActive:nextProps.isActive})
			if(nextProps.isActive==2){
				$("li>a").removeClass("active")
				$(".friend").addClass("active")
				$("#profile-friend").show();
				$("#profile-statistic").hide();
				$("#profile-time-line").hide();
			}
			if(nextProps.isActive==3){
				$("li>a").removeClass("active")
				$(".statistic").addClass("active")
				$("#profile-friend").hide();
				$("#profile-statistic").show();
				$("#profile-time-line").hide();
			}
		}
	}	

	componentWillMount() {
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({id_user_login:login.UserInfo.id})
		}
	}	
	handleChangeTab(tab,ev){
		let thiss=ev.target
		$("li>a").removeClass("active")
		$(thiss).addClass("active")
		
		if(tab=='friend'){
			$("#profile-friend").show();
			$("#profile-statistic").hide();
			$("#profile-time-line").hide();
			$("#profile-block").hide();
		}else if(tab=='statistic'){
			$("#profile-friend").hide();
			$("#profile-statistic").show();
			$("#profile-time-line").hide();
			$("#profile-block").hide();
		}else if(tab=='timeline'){
			$("#profile-friend").hide();
			$("#profile-statistic").hide();
			$("#profile-time-line").show();
			$("#profile-block").hide();
		}else if(tab=='block'){
			$("#profile-friend").hide();
			$("#profile-statistic").hide();
			$("#profile-time-line").hide();
			$("#profile-block").show();
		}
	}
	
	
	
	
	
	render() {
		let profileInfo=this.state.profileInfo
		let isActive=this.state.isActive
		let tab=this.props.tab;
		let id_user_login=this.state.id_user_login
		//console.log(id_user_login,profileInfo.id)
		//----
		return (
				<div className="container">
					
					<header className="heeader-profile">	
						<div className="slider">
						<div id="carousel-example-generic" className="carousel slide" data-ride="carousel" data-interval="500">
						  <div className="carousel-inner" role="listbox">
							<div className="item active">
							 
							</div>
						  </div>
						</div>
					  </div>
					  
					  <nav className="navbar navbar-default">
						<div className="navbar-header">
						  <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#mainNav">
							<span className="sr-only">Toggle navigation</span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						  </button>
						  <a className="navbar-brand" href="#">
							<img className="img-responsive" style={{'width':'100%','height':'100%'}} src={(profileInfo.imageurl!='' && profileInfo.imageurl!=null)?profileInfo.imageurl:this.state.avatarDefault}/>
						</a>
						  <span className="site-name"><b>{profileInfo.tipster_name}</b></span>
						  <span className="site-description" style={{'display':'none'}}>Developer by passion</span>
						</div>
						<div className="collapse navbar-collapse" id="mainNav">
						  <ul className="nav main-menu navbar-nav">
							<li><a href="#" className="active" onClick={this.handleChangeTab.bind(this,'timeline')}>{localizeKey(translations)('timeline', this.state.lguages)}</a></li>
							<li><a href="#" className="statistic" onClick={this.handleChangeTab.bind(this,'statistic')}>{localizeKey(translations)('statistics', this.state.lguages)}</a></li>
							<li><a href="#" className="friend" onClick={this.handleChangeTab.bind(this,'friend')} >{localizeKey(translations)('frdlist', this.state.lguages)}</a></li>
							{(id_user_login==profileInfo.id)?
								<li><a href="#" className="block" onClick={this.handleChangeTab.bind(this,'block')} >{localizeKey(translations)('block', this.state.lguages)}</a></li>:''
							}
						  </ul>
						  
						</div>
					  </nav>
					</header>
				</div>
				)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderProfile)
