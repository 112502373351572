import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale } from 'react-redux-localization';
import { Link } from 'react-router-dom'
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/tips.css'
import $ from 'jquery';
import {Col, Row, FormControl, FormGroup, Radio, Checkbox,Table, ControlLabel,ButtonToolbar,DropdownButton,MenuItem,Button,
		Tabs,Tab,Label} from 'react-bootstrap'
import NumericInput from 'react-numeric-input';
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,ADDTIPS_PAGE_LOADER} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
//import DatePicker from 'react-datepicker';
//import moment from 'moment';
//import 'react-datepicker/dist/react-datepicker.css';
//import MonthPicker from 'react-simple-month-picker';
import YearPicker from "react-year-picker";
import MonthPickerInput from 'react-month-picker-input';
//import MonthPickerInput = require('react-month-picker-input');
require('react-month-picker-input/dist/react-month-picker-input.css');


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: ADDTIPS_PAGE_LOADER })  
	},
});



 


class selectCompetition extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',year:'',month:''
			
		}
		
		
		//---
	
		//---
		
	}
	
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	componentWillMount(){		
		this.props.onLoad('addtips')
		//--
		let date = new Date();
		let dateFormat=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
		//$('.year-picker .year-input').val(parseInt(date.getFullYear()));
		
		this.setState({year:parseInt(date.getFullYear()),month:parseInt(date.getMonth())})
		//--
		
		
	}
	CollapseList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').hide();
		$(thiss).find('.collapse').hide();
		$(thiss).find('.expand').show();
	}
	ExpandList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').show();
		$(thiss).find('.collapse').show();
		$(thiss).find('.expand').hide();
	}
	handleChangeMonth(date){
		console.log(date)
		
	}
	handleChangeYear(date) {
		console.log(date);
	  }
	
	render(){  
		$('.year-picker .year-input').val(this.state.year);
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">TIPSTERS » ADMIN</span>
								</h1>
							</div>
							<div className="tool-r">
                            </div>
							
						</div>
					</div>
					
					<div className="container">
						<Row>
						{
							//---month
						}
							<Col md={3} className=" bet-items profile-box" id="list-month">
								<div className="bet-title sh-item">
									<a className="league-link" href="javascript:;">Month: </a>
									<div className="div-month">
										<MonthPickerInput 
											year={this.state.year}
											month={this.state.month}	
											onChange={this.handleChangeMonth.bind(this)}
											className="months"
											dateFormat={"MM/YYYY"}
										/>
									</div>
									<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
									<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
								</div>
								<Row className="data sh-snap-menu">
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Rank</span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Picked</span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="won">Win</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="draw">Draw</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="lost">Lose</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Amount</span></Col>
										<Col md={6} ><span className="num">0.00</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Profit</span></Col>
										<Col md={6} ><span className="num">0.00</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Yield</span></Col>
										<Col md={6} ><span className="num">100.00 %</span></Col>
									</Col>
								</Row>
							</Col>
							{
								//--- quarter
							} 
							<Col md={3} className=" bet-items profile-box" id="list-quarter">
								<div className="bet-title sh-item">
									<a className="league-link" href="javascript:;">Quarter: </a>
									<div className="div-quarter">
										<MonthPickerInput 
											year={this.state.year}
											month={this.state.month}	
											onChange={this.handleChangeMonth.bind(this)}
											className="months"
											dateFormat={"MM/YYYY"}
										/>
									</div>
									<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
									<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
								</div>
								<Row className="data sh-snap-menu">
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Rank</span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Picked</span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="won">Win</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="draw">Draw</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="lost">Lose</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Amount</span></Col>
										<Col md={6} ><span className="num">0.00</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Profit</span></Col>
										<Col md={6} ><span className="num">0.00</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Yield</span></Col>
										<Col md={6} ><span className="num">100.00 %</span></Col>
									</Col>
								</Row>
							</Col>
							{
								//--- year
							}
							<Col md={3} className=" bet-items profile-box" id="list-year">
								<div className="bet-title sh-item">
									<a className="league-link" href="javascript:;">Year: </a>
									<div className="div-year">
										<YearPicker onChange={this.handleChangeYear.bind(this)}
										selectedYear={2018} 
										/>
									</div>
									<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
									<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
								</div>
								<Row className="data sh-snap-menu">
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Rank</span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Picked</span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="won">Win</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="draw">Draw</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label"><span className="lost">Lose</span></span></Col>
										<Col md={6} ><span className="num">0</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Amount</span></Col>
										<Col md={6} ><span className="num">0.00</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Profit</span></Col>
										<Col md={6} ><span className="num">0.00</span></Col>
									</Col>
									<Col md={12} className="pd0 btt1">
										<Col md={6} ><span className="label">Yield</span></Col>
										<Col md={6} ><span className="num">100.00 %</span></Col>
									</Col>
								</Row>
							</Col>
							{
								//--- all time
							}
							<Col md={3} className=" bet-items profile-box" id="list-year">
								<div className="bet-title sh-item">
									<a className="league-link" href="javascript:;">All time: </a>
									
									
									<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
									<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
								</div>
								<Row className="data sh-snap-menu">
									
								</Row>
							</Col>
							{
								//---
							}
						</Row>
					
					</div>
					
				</div>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(selectCompetition)