import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button,Tabs,Tab,Table,Radio} from 'react-bootstrap'
import {TIPSTERS_LOADER,LANGUAGE_KEY,CHANGE_TAB,TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import ModalErorr from '../common/ModalErorr'
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import Avatar from 'react-avatar';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: TIPSTERS_LOADER })  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Tipter extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',idUser:'',isOpenModal:false,msg:'',
			offset: 0,limit:30, pageCount: Math.ceil(35 / 10),
			startDate:new Date(),search:'',
			qcinfo:{},month:'',limitM:30,offsetM:0,listM:[],totalcountM:0,
			selected:0,limitY:30,offsetY:0,listY:[],totalcountY:0,
			selectedY:0
		}
		
		this.close=()=>{
			this.setState({isOpenModal:false},()=>{
				
			})
		}
		this.handlePageClick = data => {			
			//console.log(data)
			let selected = data.selected;
			let offset = Math.ceil(selected * this.state.limitM);

			this.setState({ offsetM: offset,selected:selected }, () => {
			  this.tipsterListIncontest();
			});
		}
		
		this.getrandomad=()=>{
			let param={lang_id:this.state.lguages}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		this.tipsterListIncontest=()=>{
			let param={
			           limit:this.state.limitM,
					   offset:this.state.offsetM}
			agent.admin.tipsterListIncontest(param).then(data => {
				//console.log(data)
				if(data && data.result === 'good'){
					//let listM=this.state.listM.concat(data.list)
					let listM=data.list
					this.setState({listM:listM,totalcountM:data.totalcount})
					//console.log(data)
				}else{
					this.setState({isOpenModal:true,msg:"Login has expired. Please login again."})
				}
			})
		}
		
		this.goBuy=(id,e)=>{
			window.location="/buy-tips/"+id
		}
  }
		//---
	componentWillMount(){		
		this.props.onTabClick('tipters-contest')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({lguages:language},()=>{
			this.getrandomad();
		})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//console.log(login)
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id})
		}
		
		this.tipsterListIncontest();
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	  //document.getElementById("text1").innerText =''
    }

    componentWillUnmount() {
       
    }
	
	
	
	
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	  }
	noGoBuy(e){
		let message="The seller sold tips to the buyer in the month."
		this.setState({isOpenModal:true,msg:message})
	}
	render(){   
		const qcinfo=this.state.qcinfo;
		let imageurl=''
		let url=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:''
			url=qcinfo.ad_url?qcinfo.ad_url:'';
		}

		let listM=this.state.listM 	
		let totalcountM=this.state.totalcountM 
		let limitM=this.state.limitM 
		let pageCountM= Math.ceil(parseInt(totalcountM) /parseInt(limitM))
		
		let listY=this.state.listY 	
		let totalcountY=this.state.totalcountY 
		let limitY=this.state.limitY 
		let pageCountY= Math.ceil(parseInt(totalcountY) /parseInt(limitY))
		//console.log(limitM,pageCountM,pageCountM)
        return (
			<div id="body">   
				<div className="heder-page">
					<div className="container">
						<div className="item lbl-1">
							<h1 id="text1">
								<span className="txt">Tipsters</span>
							</h1>
						</div>									
					</div>
				</div>
				<div className="tipster-list">
					<div className="container">
						<Row id="rank-tipter">
							<div id="tab-bankroll" className="tab-rank">
								
								<Row>
									<Col md={2}></Col>
									<Col md={8}>
										<ReactPaginate
										  //previousLabel={'previous'}
										  previousLabel={'<'}
										  //nextLabel={'next'}
										  nextLabel={'>'}
										  breakLabel={'...'}
										  breakClassName={'break-me'}
										  pageCount={pageCountM}
										  marginPagesDisplayed={2}
										  pageRangeDisplayed={5}
										  onPageChange={this.handlePageClick}
										  containerClassName={'pagination'}
										  subContainerClassName={'pages pagination'}
										  activeClassName={'active'}
										  forcePage={this.state.selected}
										/>
									</Col>
									<Col md={2} className="rank-mth">
										<label className="tile-er">WORLDCUP 2022</label>
									</Col>
								</Row>
								<Row>
									<Col md={2}>
										{/*<Row className="mg0 txt-f-t"> Find Tipters </Row>
										<Row className="r-txt-search"> 
											<FormControl className="search-tipter" type="text" value={this.state.search} onChange={this.handleChangeField.bind(this,'search')}/>
										</Row>
										<Row className="mg0">
											<button className="btn-search-tip">search</button>
										</Row>*/
										}
										<Row className="ad-img-o-tip">
											<a href={url} target="_blank" className="rand-logo">
												<img src={imageurl} width="100" height="50"/>
											</a>
										</Row>
									</Col>
									<Col md={10} style={{'paddingRight':'0px'}}>
										<Table className="tbl-tipters" >
											<thead>
												<tr>
													<th colSpan="1" style={{'width':'10%'}}>Ranking</th>
													<th colSpan="1" style={{'width':'40%'}}>Tipster</th>
													<th colSpan="1" style={{'width':'16%','textAlign':'center'}}>Picked</th>
													<th colSpan="1" style={{'width':'16%','textAlign':'center'}}>Profit</th>	
													<th colSpan="1" style={{'width':'18%','textAlign':'center'}}>Yeild</th>
													
												</tr>
											</thead>
											<tbody>
												
												{
													(listM!='')?listM.map((dt,idx)=>{
														var url_username=dt.url_username
														let prName='';
														let url='';
														if(url_username!='' && url_username!='null' && url_username!=null){
															prName=url_username.split("/");
															url=prName[prName.length-1];
														}
														return(
															<tr key={idx}>
																<td>{dt.Rank}</td>
																<td>
																	<Row>
																		<Col md={2} className="pd0">
																			{(dt.imageurl!=null && dt.imageurl!='')?
																				<img src={dt.imageurl} className="img-tipster" />:
																				<Avatar size="50" round={true} className="avatar-title" 
																					color={this.generateColor()} name={dt.tipster_name} /> 
																			}	
																		</Col>
																		<Col md={10} className="pd6_0">
																			<div className="name-tip"><a href={"/"+url}>{dt.tipster_name}</a></div>
																			<div className="verifi-tip">{(dt.verified==1)?'verified':'none'}</div>
																		</Col>
																	</Row>
																</td>
																<td style={{'textAlign':'center'}}>{dt.PickedTips}</td>
																<td style={{'textAlign':'center'}}>{dt.Profit}</td>
																<td style={{'textAlign':'center'}}>{dt.ProfitPercentage2}{(dt.ProfitPercentage2!==null && dt.ProfitPercentage2!=='')?'%':''}</td>
																
															</tr>
														)
													}):<tr><td colSpan="5">No data.</td></tr>
												}
											</tbody>
										</Table>
									</Col>
								</Row>
								
							</div>
						</Row>
					</div>
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tipter)