import React from 'react';
import { Link,withRouter, history} from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row} from 'react-bootstrap'
import ModalErorr from './ModalErorr'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {RESET_DASHBOARD, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME} from '../../constants/actionTypes'
import { Cookies } from 'react-cookie'
import { localize, localizeKey } from 'react-redux-localization';
import menu from './menu.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({               
	onResetDashboard: (data) => {
		dispatch({ type: RESET_DASHBOARD, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class Notification extends React.Component {
	constructor(){
		super()
		this.state = {
			msgLogin:'',limit:15,offset:0,list:[],avartarDf:'https://www.coopbet.com/media/avatar_none.png',
			language:'1005',
		}
		//---
		this.goToOption=this.goToOption.bind(this)
		//----
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		this.onScrollM=()=>{
			this.props.onScrollM();
		}
		this.readAll=()=>{
			agent.notification.readAll().then((datas) => {
				//console.log(datas)
				if(datas.result==='good'){
					/*let list=this.state.list.map(dt=>{
						dt.read=1
						return dt;
					})
					this.setState({list:list})
					*/
					this.props.newNumber();
				}
				
			})
		}
		this.readOne=(notification_id)=>{
			agent.notification.readOne({notification_id:notification_id}).then((datas) => {
				//console.log(datas)
				if(datas.result==='good'){
					this.props.newNumber();
				}
				
			})
		}
		
	}
	
	
	//---------------------------
    
	
	componentWillMount() {
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({'language':language})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//this.getList();
		
	}	
	componentWillReceiveProps(nextProps) {
		if(nextProps.languageKey){
			this.setState({language:nextProps.languageKey});
		}
		//console.log(nextProps)
		if(nextProps.listNF!='' && nextProps.listNF!=null){
			this.setState({list:nextProps.listNF})
		}
		
	}
	
	getList(){
		let limit=this.state.limit
		let offset=this.state.offset
		agent.notification.getList({offset:offset,limit:limit}).then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				this.setState({list:datas.list})
			}
			
		})
	}
	goToOption(type,url_username,pick_code,notification_id,user_id,pick_user_id,parent_id,e){		
		this.readOne(notification_id)
		let userId=(pick_user_id>0)?pick_user_id:user_id
		if(type=='new_friend_suggestion'){
			let prName='';
			let url='';
			if(url_username!='' && url_username!='null' && url_username!=null){
				prName=url_username.split("/");
				url=prName[prName.length-1];
			}
			
			window.location="/"+url
		}else{
			//this.props.history.push("/home")
			//$(".txt-search").val(pick_code)
			//this.props.searchHomeFromPickcode(pick_code)
			if(parent_id>0){
				window.location="/topic/"+userId+"_"+pick_code+"_"+parent_id;;
			}else{
				window.location="/topic/"+userId+"_"+pick_code;
			}
			
		}
	}
	render() {
		//---------
		let list=this.state.list
		let avartarDf=this.state.avartarDf
		//console.log(list)
		//---------
			return (
				<div className="friens-top"  tabIndex={0} id="notifi-content">
					<div className="frd-top">
						<div className="ft-items">
							<Row className="mg0">
								<Col md={6} className="pd0"><h3 className="header bdnone">{localizeKey(menu)('notify', this.state.language)}</h3></Col>
								<Col md={6} className="rtool">
									<a href="javascript:void(0);" onClick={this.readAll}>{localizeKey(menu)('asRead', this.state.language)}</a>
								</Col>
							</Row>
							<Row className="brd1px"></Row>
						</div>

						<div className="scroll-nt ft-items" id="user-list-nf" onScroll={this.onScrollM}>
							<div className="ctn">
							{
								(list!='')?list.map((data,key)=>{
									/*let type=data.type
									type=type.split('user_').join('');
									type=type.split('friend_').join('');
									type=type.split('_').join(' ');*/
									let pfile=data.data
									let msg='';
									let message=data.message.split(" ");
									message.map((it,key)=>{
										if(key>0){
											msg+=it+" "
										}
									})
									//console.log(message,msg)
									
									return(
										<div className={'ft-item row-ntf ft-item-'+key} key={key} style={{'backgroundColor':(data.read==0)?'aliceblue':'#fff'}} onClick={this.goToOption.bind(this,data.type,pfile.url_username,pfile.pick_code,data.notification_id,pfile.user_id,pfile.pick_user_id,pfile.parent_id)}>
											<figure className="pull-left">
													<img className="img-ntf" src={(pfile.imageurl!='' && pfile.imageurl!='null' && pfile.imageurl!=null)?pfile.imageurl:avartarDf} width="50" height="50"/>
											</figure>
											<div className="ct-user-nf">
												<a className="name-user">{pfile.tipster_name}</a>
												<span>{msg}.</span>
												<div>
													<i className="fa fa-clock-o ic-ntf"></i>
													<span className="time-ntf">{data.str_notification_time}</span>
												</div>
											</div>
											
										</div>
									)
								}):''
							}
										
							</div>
						</div>
					</div>
					<div className="footer">
						<Link to="/notifications" tabIndex={1} >{localizeKey(menu)('viewall', this.state.language)}</Link>
					</div>
					<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
				</div>
			)			
		//}
		return null
	}
}

//export default connect(mapStateToProps, mapDispatchToProps)(Notification)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));