import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
//import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
//import $ from 'jquery';
import {TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
//import languages from '../../languages'
//import translations from '../common/captionhome.json';
import HomeList from './homeList.js';
import Header from '../../components/Header';
import Pusher from 'pusher-js';

/*var pusher = new Pusher('ac53b48bde4e5e9c5a17', {
	cluster: 'ap1',
	forceTLS: true
});*/
var pusher = new Pusher('d75bec97873cadcd8a43', {
	cluster: 'ap1',
	forceTLS: true
});


	  	  
const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'RUNNING_LOADER', data})  
	},
	setHomePick: (data) => {
		dispatch({ type: 'RUNNING_HOME_PICK', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});


class Running extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},
			homebetList:[],user_id:'',PickCode:'',userInfo:{},parent_id:'',
		}
		//---
		this.getrandomad=(lang_id)=>{
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.getUserInfo=(user_id)=>{
			agent.running.userInfo({user_id:user_id}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({userInfo:data.data})
				}
			})
		}
		//---
		this.homePick=(user_id,PickCode)=>{
			agent.running.homePick({user_id:user_id,PickCode:PickCode}).then(data => {
				//console.log(data)
				if(data!=null && data.result === 'good'){
					let homebetList=this.state.homebetList
					homebetList.push(data.data)
					this.setState({homebetList:homebetList})
					this.props.setHomePick(homebetList)
				}
			})
		}
		//---
		this.checksuspend=()=>{
			try{
				let login=window.localStorage.getItem(TOKEN_NAME);
				login=JSON.parse(login)
				if(login!='' && login!='null' && login!=null && login.UserInfo.role_id!=2){
					agent.auth.checksuspend().then(data => {
						//console.log(data)
						if(data!=null && data.result === 'good' && data.suspended==1){
							window.location='/un-available' 
						}
					})
				}
			}catch(e){
				console.log(e)
			}
		}
		//---
	}
	
	componentWillMount(){	
		//-----
		this.props.onLoad('running');
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//-----
		let login=window.localStorage.getItem(TOKEN_NAME);
		
		this.getrandomad(this.state.lguages)
		let prID=this.props.match.params.id
		if(prID!='' && prID!=null && prID!='null'){
			prID=prID.split("_")
			let user_id=prID[0]
			let PickCode=prID[1]
			this.setState({user_id:user_id,PickCode:PickCode})
			this.getUserInfo(user_id)
			this.homePick(user_id,PickCode)
			let parent_id=prID[2]
			if(parent_id!='' && parent_id!=null){
				this.setState({parent_id:parent_id})
			}
			//console.log(prID)
			this.getSocket(PickCode);
			this.getSocketForNum();
		}	
		if(login!='' && login!='null' && login!=null){
			window.addEventListener('click', this.checksuspend);
			
		}
		//-----
		
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
			this.getrandomad(nextProps.languageKey)
		}
		if(nextProps.dashboardData){
			this.setState({homebetList:nextProps.dashboardData});
		}
	}
	
	componentWillUnmount() {
		window.removeEventListener('click', this.checksuspend);
	}
	shouldComponentUpdate(nextProps, nextState){
	   
	   return nextState;
	}	
	getSocket(PickCode){
		var thiss=this
		var pickcode=PickCode
		//var channelcode = 'coopbet-pick-' + pickcode;
		//var event = 'pick-changed';
		var channelcode = 'coopbet-pick';
		var event = 'pick-changed-'+pickcode;
		var channel = pusher.subscribe(channelcode);
		channel.bind(event, function(data) {
			//alert(JSON.stringify(data));
			//console.log(data)
			var newCommentList=data.CommentList
			var newTotalComments=data.TotalComments
			var homebetList=thiss.state.homebetList
			var CommentList=homebetList[0].CommentList
			var TotalComments=homebetList[0].TotalComments
			//console.log(homebetList,CommentList,TotalComments)
			let newhomeList=homebetList.map(dt=>{
				//console.log(dt.CommentList,dt.TotalComments)
				dt.CommentList=newCommentList
				dt.TotalComments=newTotalComments
				return dt;
			})
			thiss.setState({homebetList:newhomeList})
			
		});
	}
	getSocketForNum(){
		var thiss=this
		var channelcode = 'coopbet-pick';
		var event = 'pickcoming-changed';
		var channel = pusher.subscribe(channelcode);
		channel.bind(event, function(data) {
			//alert(JSON.stringify(data));
			//console.log(data)
			var homebetList=thiss.state.homebetList
			var PickCodeComingNumber=data.PickCodeComingNumber
			var PickCodeComingUsers=data.PickCodeComingUsers
			let newhomeList=homebetList.map(dt=>{
				dt.PickCodeComingNumber=PickCodeComingNumber
				dt.PickCodeComingUsers=PickCodeComingUsers
				return dt;
			})
			//console.log(newhomeList)
			thiss.setState({homebetList:newhomeList})
			thiss.props.setHomePick(newhomeList)
		});
	}
	render(){   
		//console.log(this.state.lguages);
		//console.log(languages);
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
		}			
		
		//---homebetList
		
		//---
		let login=window.localStorage.getItem(TOKEN_NAME);
		let userInfo=this.state.userInfo;
		let homebetList=this.state.homebetList;
		//console.log(homebetList)
		//---
        return (
		<div>
			<Header/>
			<div id="body">   
				<div className="list-user">
					<div className="container">
						
						<div className="items">
					
							<div className="row">
								<div className="col-md-12">
								</div>
							</div>
							{
								(qcinfo!='' && qcinfo!=null)?
								<div className="row">
									<div className="col-md-3">
										<div className="img-adv">
											<a href={url} target="_blank" className="rand-logo">
												<img alt="m88" src={imageurl} width="100" height="50"/>
											</a>
										</div>
									</div>
									<div className="col-md-9">
										<div className="desc-adv">									
										{
										ReactHtmlParser(description)
										}
										</div>
									</div>
								</div>	:''					
							}
						</div>
						{
							<HomeList homebetList={homebetList} 
								loginInfo={login} profileInfo={userInfo} user_id={this.state.user_id} parent_id={this.state.parent_id}
							/>
						}
						
					</div>
				</div>

			</div>
		</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Running)