import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/Comment.css'
import $ from 'jquery';
import {RESET_DASHBOARD, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME,UDATE_PICK_COMENT} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import ReplyList from './ReplyList'
import ModalLikes from '../common/ModalLikes'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import Lqhome from '../common/home.json'
import TextareaAutosize from 'react-textarea-autosize';
import ModalEditComment from '../Home/ModalEditComment';
import ModalEditDescr from '../Home/ModalEditDescr'
import ReactHtmlParser from 'react-html-parser';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
		
	})      
);

const mapDispatchToProps = dispatch => ({               
	onResetDashboard: (data) => {
		dispatch({ type: RESET_DASHBOARD, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	reload_pick_comment: (data) => {
		dispatch({ type: UDATE_PICK_COMENT, data})  
	},
	
});

class CommentLayout extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			//img_default:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://bootdey.com/img/Content/avatar/avatar1.png',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			TotalComments:'',CommentList:[],PickCode:'',for_pick_code:'',parent_id:'',member_name:'',
			numCM:'',backoffset:0,numi:'',PickLikedNum:'',PickLikedUserList:[],PickLikedUserIDList:[],
			isOpenModalLike:false,numlike:'',likeList:[],id_user:'',PickCodeComingNumber:0,PickCodeRunning:0,
			runLst:[],PickDatetimeSTR:'',lguages:'1005',isRunging:false,
			PickComment:'',avartaturl:'',tipsterName:'',user_id_login:'',user_id:'',pick_dot:0,
			isOpenModalEditCm:false,txt_comment:'',comment_id:'',
			isOpenModalEditDes:false,txt_description:'',txt_title:'',edit_decrpt:''
			
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,isOpenModalLike:false,isRunging:false,isOpenModalEditCm:false,isOpenModalEditDes:false})
		}
		//----
		this.listComment=this.listComment.bind(this)
		this.viewCM=this.viewCM.bind(this)
		this.listCommentAfterRely=this.listCommentAfterRely.bind(this)
		this.updateTotalRely=this.updateTotalRely.bind(this)
		//----
		this.handleClickTab = menu => e => {
			e.preventDefault()
			this.props.onTabClick(menu)
			
		}
		//----
		this.updateTotalCM=(TotalComments)=>{
			this.setState({TotalComments:TotalComments})
		}
		this.emptyPickComment=()=>{
			this.props.emptyPickComment(this.state.PickCode)
		}
		//----
		this.showModalEditCM=(comment_id,for_pick_code,comment)=>{
			this.setState({isOpenModalEditCm:true,comment_id:comment_id,isOpenModalLike:false,isOpenModalEditDes:false,
						   txt_comment:comment,for_pick_code:for_pick_code,txt_title:'Edit Comment'})
		}
		this.updateCommentList=(comment_id,comment)=>{
			let CommentList=this.state.CommentList.map(dt=>{
				if(dt.comment_id==comment_id){
					dt.comment=comment
					dt.edit_cm=1
				}
				return dt;
			})
			this.setState({CommentList:CommentList})
		}
		this.showModalEditDescr=(PickCode,txt_description)=>{
			this.setState({isOpenModal:false,isOpenModalLike:false,isOpenModalEditDes:true,isOpenModalEditCm:false,
						   txt_description:txt_description,PickCode:PickCode,txt_title:'Edit Description'})
		}
		
	}
	
	
	//---------------------------
    
	

	componentWillMount() {
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//---
		//console.log(this.props.PickCodeComingNumber)
		const url=(this.props.imageurl!='' && this.props.imageurl!=null && this.props.imageurl!='null')?this.props.imageurl:this.state.url
		const TotalComments=this.props.TotalComments;
		const CommentList=this.props.CommentList;
		const PickCode=this.props.PickCode;
		const member_name=this.props.member_name;
		const numCM=this.props.TotalComments;
		const numi=this.props.numi;
		const PickLikedNum=this.props.PickLikedNum;
		const PickLikedUserList=this.props.PickLikedUserList;
		const PickLikedUserIDList=this.props.PickLikedUserIDList;
		const id_user=this.props.id_user;
		const PickCodeComingNumber=this.props.PickCodeComingNumber;
		const PickCodeRunning=this.props.PickCodeRunning;
		const runLst=this.props.runLst;
		const PickDatetimeSTR=this.props.PickDatetimeSTR;
		const PickComment=this.props.PickComment;
		const avartaturl=this.props.avartaturl;
		const tipsterName=this.props.tipsterName;
		const parent_id_cm=this.props.parent_id_cm;
		const user_id_login=this.props.user_id_login;
		const user_id=this.props.user_id;
		const edit_decrpt=this.props.edit_decrpt;
		let Comments=CommentList.map(dt=>{
			if(parent_id_cm==dt.comment_id){
				dt.show_rely=1
			}else{
				dt.show_rely=0
			}
				
				return dt;
		})
			
		//----
		if(this.props.parent_id_cm!='' && this.props.parent_id_cm!=null){
				this.setState({parent_id:this.props.parent_id_cm})
			}
		this.setState({url:url,TotalComments:TotalComments,CommentList:Comments,PickCode:PickCode,
						member_name:member_name,numCM:numCM,numi:numi,PickLikedNum:PickLikedNum,
						PickLikedUserList:PickLikedUserList,PickLikedUserIDList:PickLikedUserIDList,
						id_user:id_user,PickCodeComingNumber:PickCodeComingNumber,PickCodeRunning:PickCodeRunning,
						runLst:runLst,PickDatetimeSTR:PickDatetimeSTR,PickComment:PickComment,user_id:user_id,
						avartaturl:avartaturl,tipsterName:tipsterName,user_id_login:user_id_login,edit_decrpt:edit_decrpt})
		
		
	}	
	componentWillReceiveProps(nextProps){
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps){
			//console.log(nextProps);
			const url=(nextProps.imageurl!='' && nextProps.imageurl!=null && nextProps.imageurl!='null')?nextProps.imageurl:this.state.url
			const TotalComments=nextProps.TotalComments;
			const CommentList=nextProps.CommentList;
			const PickCode=nextProps.PickCode;
			const member_name=nextProps.member_name;
			const numCM=nextProps.TotalComments;
			const numi=nextProps.numi;
			const PickComment=nextProps.PickComment;
			const avartaturl=nextProps.avartaturl;
			const tipsterName=nextProps.tipsterName;			
			const PickLikedNum=nextProps.PickLikedNum;
			const PickLikedUserList=nextProps.PickLikedUserList;
			const PickLikedUserIDList=nextProps.PickLikedUserIDList;
			const id_user=nextProps.id_user;
			const parent_id_cm=nextProps.parent_id_cm;
			const user_id_login=nextProps.user_id_login;
			const user_id=nextProps.user_id;
			const PickCodeComingNumber=nextProps.PickCodeComingNumber;
			const runLst=nextProps.runLst;
			const edit_decrpt=nextProps.edit_decrpt;
			let Comments=CommentList.map(dt=>{
				if(parent_id_cm==dt.comment_id){
					dt.show_rely=1
				}else{
					dt.show_rely=0
				}
				return dt;
			})
			//const PickLikedNum=this.props.PickLikedNum;
			//const PickLikedUserList=this.props.PickLikedUserList;
			//const PickLikedUserIDList=this.props.PickLikedUserIDList;
			//const id_user=this.props.id_user;
			this.setState({url:url,TotalComments:TotalComments,CommentList:Comments,PickCode:PickCode,
						member_name:member_name,numCM:numCM,numi:numi,PickLikedNum:PickLikedNum,
						PickLikedUserList:PickLikedUserList,PickLikedUserIDList:PickLikedUserIDList,
						id_user:id_user,PickComment:PickComment,user_id_login:user_id_login,user_id:user_id,
						avartaturl:avartaturl,tipsterName:tipsterName,PickCodeComingNumber:PickCodeComingNumber,
						runLst:runLst,edit_decrpt:edit_decrpt})
			if(nextProps.parent_id_cm!='' && nextProps.parent_id_cm!=null){
				this.setState({parent_id:nextProps.parent_id_cm})
			}
		}
		
	}
	componentDidMount() {
		
	}
	doKeycomment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".bg-green-haze").addClass("active")
		if(e.key=='Enter'){
			
		}
		
		//
	}
	changeCOmment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".ot-btn").show()
	}
	hideComment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".ot-btn").hide()
		$(thiss).find(".textarea-cm").val('')
		$(thiss).find(".bg-green-haze").removeClass("active")
	}
	handleRely(e){
		let thiss=e.target.closest(".ct-cmt")
		$(thiss).find(".t-reply").show();
	}
	hideRyply(e){
		let thiss=e.target.closest(".t-reply")
		$(thiss).hide();
	}
	hideRyplyChild(e){
		let thiss=e.target.closest(".t-reply-child")
		$(thiss).hide();
	}
	handleRelyChild(e){
		let thiss=e.target.closest(".ct-cm-reply")
		$(thiss).find(".t-reply-child").show();
	}
	hideCM(for_pick_code,parent_id,e){
		//let thiss=e.target.closest(".reply-List");
		//$(thiss).find(".view-cm").hide();
		//$(thiss).find(".hide-cm").show();
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt.comment_id==parent_id){
				dt.show_rely=1
			}
			return dt;
		})
		this.setState({CommentList:CommentList,for_pick_code:for_pick_code,parent_id:parent_id})
	}
	viewCM(comment_id,e){
		//let thiss=e.target.closest(".reply-List");
		//$(thiss).find(".view-cm").show();
		//$(thiss).find(".hide-cm").hide();
		//this.setState({for_pick_code:'',parent_id:''})
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt.comment_id==comment_id){
				dt.show_rely=0
			}
			return dt;
		})
		this.setState({CommentList:CommentList})
	}
	createComment(parent_id,e){
		let thiss=e.target.closest(".cm-dad")
		let comment=$(thiss).find(".textarea-cm").val();
		let for_pick_code=this.state.PickCode
		//---
		$(thiss).find('.do_cm').hide()
		$(thiss).find('.ot-btn').hide()
		$(thiss).find('.loading_dad').show()
		//---
		//comment=comment.replace(/(?:\r\n|\r|\n)/g, '<br />')
		agent.running.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.listComment(for_pick_code,0)
				$(thiss).find('.do_cm').show()
				$(thiss).find('.loading_dad').hide()
				$(thiss).find(".textarea-cm").val('');
			}else{
				$(thiss).find('.loading_dad').hide()
				$(thiss).find('.do_cm').show()
				this.props.callModalError(data.message)
			}
		})
	}
	createCommentChild(parent_id,for_pick_code,e){
		let thiss=e.target.closest(".cm-child")
		let thisss=e.target.closest(".ct-cmt")
		let comment=$(thiss).find(".textarea-cm").val();
		//---
		$(thiss).find('.do_cm').hide()
		$(thiss).find('.ot-btn').hide()
		$(thiss).find('.loading_dad').show()
		//---
		agent.running.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				//this.listComment(for_pick_code,0)
				this.listCommentAfterRely(for_pick_code,parent_id)
				$(thiss).find('.do_cm').show()
				$(thiss).find('.loading_dad').hide()
				$(thiss).find(".textarea-cm").val('');
				//this.props.reload_pick_comment({for_pick_code:for_pick_code,parent_id:parent_id})
				//this.setState({for_pick_code:for_pick_code,parent_id:parent_id})	
				let ev=this
				setTimeout(function(){
					//$(thisss).find('.view-cm').hide();
					//$(thisss).find('.hide-cm').show();
					//console.log(parent_id,ev.state.CommentList)
					
					ev.setState({for_pick_code:for_pick_code,parent_id:parent_id})
				},500)	
				
			}else{
				$(thiss).find('.loading_dad').hide()
				$(thiss).find('.do_cm').show()
				$(thisss).find(".t-reply").hide()
				$(thiss).find(".textarea-cm").val('');
				this.props.callModalError(data.message)
			}
		})
	}
	listCommentAfterRely(for_pick_code,comment_id){
		let limit=(this.state.backoffset+10);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:0,limit:limit,offset:0}).then(data => {
			
			if(data.result=="good"){
				let CommentList=data.CommentList.map(dt=>{
						if(dt.comment_id==comment_id){
							dt.show_rely=1
						}
						return dt;
					})
				this.setState({CommentList:CommentList,TotalComments:data.TotalComments,numCM:data.TotalComments})
			}
		})
	}
	listComment(for_pick_code,parent_id){
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:parent_id,limit:10,offset:0}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({CommentList:data.CommentList,TotalComments:data.TotalComments,numCM:data.TotalComments})
			}
		})
	}
	togglelike(comment_id,ev){
		agent.comment.togglelike({comment_id:comment_id,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsLiked=data.IsLiked
						item.IsDisliked=data.IsDisliked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	toggledislike(comment_id,ev){
		agent.comment.toggledislike({comment_id:comment_id,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsDisliked=data.IsDisliked
						item.IsLiked=data.IsLiked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	viewComment(for_pick_code){
		let backoffset=parseInt(this.state.backoffset+10);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:0,limit:10,offset:backoffset}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let cm_list=this.state.CommentList.concat(data.CommentList)
				let Comments=cm_list.map(dt=>{
					dt.show_rely=0
					return dt;
				})
				this.setState({CommentList:Comments,TotalComments:data.TotalComments,numCM:data.TotalComments,backoffset:backoffset})
			}
		})
	}
	docm(for_pick_code,ev){
		let thiss=ev.target.closest("#cmt-lt")
		//console.log(thiss)
		let ot=$(thiss).attr("option")
		if(ot==0){
			$(thiss).find(".sh_hd_cm").hide();
			$(thiss).attr("option",1)
		}else{
			$(thiss).find(".sh_hd_cm").show();
			$(thiss).attr("option",0)
			this.listComment(for_pick_code,0)
		}
		
	}
	togglelikepick(ev){
		let pick_code=$(ev.target).attr("pick_code")
		agent.home.togglelikepick({pick_code:pick_code,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let PickLikedNum=data.PickLikedNum;
				let PickLikedUserIDList=data.PickLikedUserIDList;
				let PickLikedUserList=data.PickLikedUserList;
				this.setState({PickLikedNum:PickLikedNum,PickLikedUserIDList:PickLikedUserIDList,PickLikedUserList:PickLikedUserList})
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	tooltip(it){
		//console.log(it)
		return(
			<Tooltip id="tooltip">
			{
				it.map((data,id)=>{
					return(
						<div key={id}>{data.tipster_name}</div>
					)
				})
			}
				
			</Tooltip>
		)
	}
	oPenModalLike(numlike,likeList,isRunging){
		this.setState({isOpenModalLike:true,isOpenModalEditCm:false,isOpenModalEditDes:false,numlike:numlike,likeList:likeList,isRunging:isRunging})
	}
	onDotToll(comment_id,e){
		//console.log(userId)
		let CommentList=this.state.CommentList.map(data=>{
			if(comment_id==data.comment_id){
				data.show_dot=1;
			}else{
				data.show_dot=0;
			}
			return data;
		})
		this.setState({CommentList:CommentList})
	}
	leaveDotTool(e){
		let CommentList=this.state.CommentList.map(data=>{
			if(data!=null){
				data.show_dot=0;
				return data;
			}
		})
		this.setState({CommentList:CommentList})
	}
	deleteInOwnTopic(comment_id,for_pick_code,e){
		agent.comment.deleteInOwnTopic({for_pick_code:for_pick_code,comment_id:comment_id,lang_id:this.state.lguages}).then(data => {
			//console.log(this.state.CommentList)
			if(data.result=="good"){
				let CommentList=this.state.CommentList.map(dt=>{
					if(dt!=null && dt.comment_id!=comment_id){
						return dt;
					}
				})
				this.listComment(for_pick_code,0)
				//this.setState({CommentList:CommentList,TotalComments:this.state.TotalComments-1})
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	deleteCm(comment_id,for_pick_code,e){
		agent.admin.deleteCm({for_pick_code:for_pick_code,comment_id:comment_id,lang_id:this.state.lguages}).then(data => {
			//console.log(this.state.CommentList)
			if(data.result=="good"){
				
				this.listComment(for_pick_code,0)
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	updateTotalRely(comment_id,e){
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt!=null && dt.comment_id==comment_id){
				dt.total_replies=dt.total_replies-1
			}
			return dt;
		})
		this.setState({CommentList:CommentList})
	}
	blockUser(blocked_user_id,for_pick_code,e){
		agent.running.blockUser({blocked_user_id:blocked_user_id,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				/*let CommentList=this.state.CommentList.map(dt=>{
					if(dt!=null && dt.comment_id!=comment_id){
						return dt;
					}
				})*/
				this.listCommentAfterRely(for_pick_code,0)
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	onDotTollDScpt(e){
		
		this.setState({pick_dot:1})
	}
	leaveDotToolDScpt(e){
		
		this.setState({pick_dot:0})
	}
	render() {
		let url=this.state.url
		let img_default=this.state.img_default
		let url_like=this.state.url_like
		let url_liked=this.state.url_liked
		let url_dislike=this.state.url_dislike
		let url_disliked=this.state.url_disliked
		let TotalComments=this.state.TotalComments
		let CommentList=this.state.CommentList
		let member_name=this.state.member_name
		let numCM=this.state.numCM
		let PickCode=this.state.PickCode
		let numi=this.state.numi
		let PickLikedNum=this.state.PickLikedNum
		let PickLikedUserList=this.state.PickLikedUserList
		let PickLikedUserIDList=this.state.PickLikedUserIDList
		let PickCodeComingNumber=this.state.PickCodeComingNumber
		let PickCodeRunning=this.state.PickCodeRunning
		let runLst=this.state.runLst
		let PickDatetimeSTR=this.state.PickDatetimeSTR
		let PickComment=this.state.PickComment
		let avartaturl=this.state.avartaturl
		let tipsterName=this.state.tipsterName
		let backoffset=this.state.backoffset
		let user_id_login=this.state.user_id_login
		let user_id=this.state.user_id
		let pick_dot=this.state.pick_dot
		let edit_decrpt=this.state.edit_decrpt
		
		//---------
		var likeLst=this.state.PickLikedUserList;
		var pesons='';
		var _status='';
		for( let i=0;i<likeLst.length;i++){
			if(i==likeLst.length-1){
				pesons+=likeLst[i].tipster_name;
			}else{
				pesons+=likeLst[i].tipster_name+', ';
			}
			if(likeLst[i].user_id==this.state.id_user){
				_status="liked"
			}
			
		}
		//---------
		var html = PickComment;
		if(html!='' && html!=null){
			html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
		}
		
		var div = document.createElement("div");
		div.innerHTML = html;
		var textDescript= html;
		//console.log(parseInt(this.state.edit_decrpt),CommentList)
			return (
				<Row id="cmt-lt" className="mg0">
					<Col  md={12} className="r-footer l-footer">
						<Col md={4} className="pd0 f-left">
							<a className={(PickLikedNum>0 && _status=='liked')?"like-bet-active":"like-bet"} title={localizeKey(Lqhome)('like', this.state.lguages)} pick_code={PickCode} onClick={(member_name!='')?this.togglelikepick.bind(this):this.handleClickTab('login')}>														
								{
									(PickLikedNum>0 && _status=='liked')?<i className="fas fa-thumbs-up" pick_code={PickCode} aria-hidden="true"></i>:<i className="far fa-thumbs-up" pick_code={PickCode} aria-hidden="true"></i>
								}
								{
									(PickLikedNum>0)?
									<OverlayTrigger placement="bottom" overlay={this.tooltip(likeLst)} onClick={this.oPenModalLike.bind(this,PickLikedNum,likeLst,false)}>
									  <span className="num-like">{localizeKey(Lqhome)('like', this.state.lguages)} ({PickLikedNum})</span>
									</OverlayTrigger>:
									<span className="num-like">{localizeKey(Lqhome)('like', this.state.lguages)} (0)</span>
								}
							</a>
						</Col>
						<Col md={4} className="runing-ct">
						{
							(PickCodeRunning==1)?
								<div>
									<a href="#"> 
										<i className="fas fa-running icon-running" title="" aria-hidden="true"></i>
									</a>
									{
										(PickCodeComingNumber>0)?
										<OverlayTrigger placement="bottom" overlay={this.tooltip(runLst)} onClick={this.oPenModalLike.bind(this,PickCodeComingNumber,runLst,true)}>
											<span className="num-running">Running ({PickCodeComingNumber})</span>
										</OverlayTrigger>:''
									}
							
								</div>:''
						}
						</Col>
						<Col md={4} className="f-right create-date">
							<span className="lbl">{localizeKey(translations)('Create', this.state.lguages)}: </span>
							<span className="ctn-dt"> {PickDatetimeSTR}</span>
							
						</Col>
					</Col>
					{
						(PickComment!='' && PickComment!=null)?
							<Col md={12} className="pd10_0">
								<Col md={1} className="pl0">
									<img src={(avartaturl!='' && avartaturl!=null)?avartaturl:this.state.img_default} width="35" height="35" className="img-icon"/>
								</Col>
								<Col md={11} className="dot-hv rm-dsc" onMouseEnter={this.onDotTollDScpt.bind(this)} onMouseLeave={this.leaveDotToolDScpt.bind(this)}>
									<Row><h4 className="name-comment">{tipsterName}</h4></Row>
									<Row>
										<div className="date-comment">
											<span>{PickDatetimeSTR}</span>
											{(edit_decrpt==1)?<span className="txt-small"> (edited)</span>:''}
											{((user_id_login==user_id || user_id_login==5) && pick_dot==1)?
											<DropdownMenu triggerType='text' trigger='...'  className="tool-of-user">
												<Row className="mg0 noti-box pd0">	
													{(user_id==user_id_login)?
														<Col md={12} className="lhi0 bdbt1">
															<a className="text-cmt-tl" onClick={this.showModalEditDescr.bind(this,PickCode,PickComment)}>
																<i className="fa fa-pencil"></i> edit
															</a>
														</Col>:''
													}
													<Col md={12} className="lhi0">
														<a className="text-cmt-tl" onClick={this.emptyPickComment.bind(this)}>
															<i className="fa fa-trash-o"></i> delete
														</a>
													</Col>																			
																											
												</Row>
											</DropdownMenu>:''
											}
										</div>
									</Row>
									<Row>
										<div className="content-comment">
											{
												//PickComment
												ReactHtmlParser(textDescript)
											}
										</div>
									</Row>
									
								</Col>
							</Col>:''
					}
					<Col md={12} className="pdt10">
						<div className="count-txt-cm" option="1" onClick={this.docm.bind(this,PickCode)}>{TotalComments} {localizeKey(Lqhome)('comments', this.state.lguages)}</div>
					</Col>
					{
						//---
					}
					<Row className="sh_hd_cm" style={{'display':(numi==1)?'':'none'}}>
					{
						(member_name!='' && member_name!='null' && member_name!=null)?
						<Col md={12} className="pd10_0 cm-dad">
							<Col md={1} className="">
								<img src={url} width="35" height="35" className="img-icon-sm"/>
							</Col>
							<Col md={11} className="pdL0 mgt10 mgbt10">
								<div className="do_cm">
									
									<TextareaAutosize className="textarea-cm pd0"
										placeholder={localizeKey(Lqhome)('add_a_comment', this.state.lguages)} 
										maxLength={10000}
										onKeyPress={this.doKeycomment.bind(this)} 
										onClick={this.changeCOmment.bind(this)}
									/>
								</div>
								<img className="loading_dad" src="https://www.coopbet.com/media/loading.gif" width="33"/>
							</Col>
							<Col md={12} className="t-right ot-btn">
								<a className="bg-grey-steel" onClick={this.hideComment.bind(this)}>cancel</a>
								<a className="bg-green-haze" onClick={this.createComment.bind(this,0)}>{localizeKey(Lqhome)('comments', this.state.lguages)}</a>
							</Col>
						</Col>:''
					}
					
					
					
					{
						//---comment List
					}
					<Row className="mg0">
					{
						(CommentList!='')?CommentList.map((data,index)=>{
							var url_username=data.url_username
							let prName='';
							let url_profile='';
							if(url_username!='' && url_username!='null' && url_username!=null){
								prName=url_username.split("/");
								url_profile=prName[prName.length-1];
							}
							var html = data.comment;
							html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
							var div = document.createElement("div");
							div.innerHTML = html;
							var textmessge = html;
							return(
								<Col md={12} className="pd10_0" key={index}>
									<Col md={1} className="">
										<Link to={"/"+url_profile} className="">
											<img src={(data.imageurl!='' && data.imageurl!=null && data.imageurl!='null')?data.imageurl:img_default} width="35" height="35" className="img-icon-sm"/>
										</Link>	
									</Col>
									<Col md={11} className="pdL0 ct-cmt">
										<Row className="mg0 dot-hv" onMouseEnter={this.onDotToll.bind(this,data.comment_id)} onMouseLeave={this.leaveDotTool.bind(this)}>
											<h4 className="name-cmt">
												<Link to={"/"+url_profile} className="">{(data.blocked==true)?<i className="fa fa-ban" style={{'color':'red','paddingRight':'3px'}}></i>:''}{data.tipster_name} </Link>
												<span className="time-cmt">{data.str_comment_time}</span>
												{(data.edit_cm==1)?<span className="txt-cm-small"> (edited)</span>:''}
												{
													((user_id==user_id_login || user_id_login==data.user_id) && data.show_dot==1)?
													<DropdownMenu triggerType='text' trigger='...'  className="tool-of-user">
														<Row className="mg0 noti-box">	
															{
																(user_id_login==data.user_id && data.show_dot==1)?
																<Col md={12}  className="lhi0"><a className="text-cmt-tl" onClick={this.showModalEditCM.bind(this,data.comment_id,data.for_pick_code,data.comment)}><i className="fa fa-pencil"></i> edit comment</a></Col>:''
															}
															<Col md={12} className="lhi0"><a className="text-cmt-tl" onClick={this.deleteInOwnTopic.bind(this,data.comment_id,data.for_pick_code)}><i className="fa fa-trash-o"></i> delete comment</a></Col>	
															{
																(user_id_login!=data.user_id && data.user_id!=5 && data.blocked!=true)?
																<Col md={12} style={{'marginTop':'7px'}} className="lhi0">
																	<a className="text-cmt-tl" onClick={this.blockUser.bind(this,data.user_id,data.for_pick_code)}><i className="fa fa-minus-circle"></i> block user</a></Col>	:''
															}																
														</Row>
													</DropdownMenu>	:(user_id_login==5 && user_id_login!=data.user_id && data.show_dot==1)?
													<DropdownMenu triggerType='text' trigger='...'  className="tool-of-user">
														<Row className="mg0 noti-box">														
															<Col md={12}><a className="text-cmt-tl" onClick={this.deleteCm.bind(this,data.comment_id,data.for_pick_code)}><i className="fa fa-trash-o"></i> delete comment</a></Col>																																
														</Row>
													</DropdownMenu>:''
													
												}
											</h4>
										</Row>
										<Row className="mg0">
										{
											//data.comment
											ReactHtmlParser(textmessge)
										}
										</Row>
										<Row className="mg0">
											<div className="r-like-reply">
												<img src={(data.IsLiked==true)?url_liked:url_like} 
													width="35" height="35" className="img-icon-like"
													onClick={(member_name!='' && member_name!=null && member_name!='null')?this.togglelike.bind(this,data.comment_id):this.handleClickTab('login')}
												/>
												<span className="count-like">{(data.LikedNum>0)?data.LikedNum:''}</span>
												<img src={(data.IsDisliked==true)?url_disliked:url_dislike}
													width="35" height="35" className="img-icon-dislike"
													onClick={(member_name!='' && member_name!=null && member_name!='null')?this.toggledislike.bind(this,data.comment_id):this.handleClickTab('login')}
												/>
												{
													(member_name!='' &&  member_name!='null' && member_name!=null)?
													<a className="reply-cmt" onClick={this.handleRely.bind(this)}>{localizeKey(Lqhome)('reply', this.state.lguages)}</a>:''
												}
												
											</div>
										</Row>
										<Row className="mg0 t-reply cm-child">
											<Col md={12} className="pd10_0">
												<Col md={1} className="pdL0 w-5p">
													<img src={url} width="24" height="24" className="img-icon-sm24"/>
												</Col>
												<Col md={11} className="pd0 mgt10 w-95p">
													<div className="do_cm">
														
														<TextareaAutosize className="textarea-cm pd0"
															placeholder="Add a public reply..." 
															maxLength={10000}
															onKeyPress={this.doKeycomment.bind(this)} 
															onClick={this.changeCOmment.bind(this)}
														/>
													</div>
													<img className="loading_dad" src="https://www.coopbet.com/media/loading.gif" width="33"/>
												</Col>
												<Col md={12} className="t-right ot-btn" style={{'display':'block','marginTop':'10px','padding':'0px'}}>
													<a className="bg-grey-steel" onClick={this.hideRyply.bind(this)}>cancel</a>
													<a className="bg-green-haze" onClick={this.createCommentChild.bind(this,data.comment_id,data.for_pick_code)}>{localizeKey(Lqhome)('reply', this.state.lguages)}</a>
													
													
												</Col>
											</Col>
										</Row>
										{
											//---Reply list
										}
										{
											(data.total_replies>0)?
											<Row className="mg0 reply-List">
											{
												(data.show_rely==1 && (data.comment_id==parseInt(this.state.parent_id)))?
												<div>
													<h4 className="name-cmt hide-cm" onClick={this.viewCM.bind(this,data.comment_id)} >
														<span>{localizeKey(Lqhome)('hideReplies', this.state.lguages)}</span>
														<i className="fa fa-chevron-up"></i>
													</h4>
													<Col md={12} className="pd0 hide-cm" >
														<ReplyList 
															imageurl={url} 
															//for_pick_code={this.state.for_pick_code} 
															for_pick_code={data.for_pick_code} 
															//parent_id={this.state.parent_id}
															parent_id={data.comment_id}
															listComment={this.listComment}
															member_name={this.state.member_name}
															updateTotalCM={this.updateTotalCM}
															listCommentAfterRely={this.listCommentAfterRely}
															user_id_login={user_id_login}
															user_id={this.state.user_id}
															updateTotalRely={this.updateTotalRely}
															callModalError={this.props.callModalError}
														/>
													</Col>
												</div>:
												<h4 className="name-cmt view-cm" style={{'display':''}} onClick={(member_name!='' && member_name!=null && member_name!='null')?this.hideCM.bind(this,data.for_pick_code,data.comment_id):this.handleClickTab('login')}>
													<span>{localizeKey(Lqhome)('view_replies', this.state.lguages)} ({data.total_replies})</span>
													<i className="fa fa-chevron-down"></i>
												</h4>
												
											}
												
												
												
											</Row>:''
										}
										
										{
											//---end
										}
									</Col>
									
								</Col>
							)
						}):''
					}
						</Row>
					{
						(numCM>10 && CommentList!='' && parseInt(numCM-CommentList.length)>0)?<Row className="mg0" style={{'display':''}}>
									<Col md={12}>
										<a className="view-more" onClick={(member_name!='' && member_name!=null)?this.viewComment.bind(this,PickCode):this.handleClickTab('login')}>{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>
									</Col> 
								</Row>:''
					}	
					</Row>
					{
							//---end comment
					}
					<ModalLikes isOpen={this.state.isOpenModalLike} close={this.close} numlike={this.state.numlike}
						idpet={this.state.idpet} id_user={this.state.id_user} likeList={this.state.likeList}
						isRunging={this.state.isRunging}
					/>
					<ModalEditComment isOpen={this.state.isOpenModalEditCm} close={this.close}
						txt_comment={this.state.txt_comment} PickCode={this.state.for_pick_code}
						txt_title={this.state.txt_title} callModalError={this.props.callModalError}
						updateCommentList={this.updateCommentList} comment_id={this.state.comment_id}/>
					<ModalEditDescr isOpen={this.state.isOpenModalEditDes} close={this.close}
								txt_description={this.state.txt_description} PickCode={this.state.PickCode}
								txt_title={this.state.txt_title} callModalError={this.props.callModalError}
								updateHomeLis={this.props.updateHomeLis} />
				</Row>
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentLayout)
