import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Table } from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Link } from 'react-router-dom'
import agent from '../../agent'


import '../../styles/modal.css'; 
import '../../styles/style-modal.css'; 
import '../../styles/App.css'
import '../../styles/Friends.css'
import ImageUploader from 'react-images-upload';
import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import { 
  //BUILDING_LOADED,
  
}from '../../constants/actionTypes'; 

const mapStateToProps = state => (
    Object.assign({}, state, {
	// customer: state.buildings.customer,
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ViewReport extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,order_report_info:{},order_id:'',report_list:[]
			
			
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};
		
		//---
		this.getOrderReportInfo=(order_id)=>{
			agent.orders.getOrderReportInfo({order_id:order_id}).then(data => {
				if(data.result=="good"){					
					this.setState({order_report_info:data.order_report_info,report_list:data.order_report_info.report_list})
				}else{
				}
			})
		}
		this.vieImg=(id,e) => {
			document.getElementById("modal-viewReport").style.zIndex = "1";
			
			let info=this.state.order_report_info
			let letms=info.report_list
			letms.map(it=>{
				if(it.buyer_id===id){
					it.isOpenIM=true
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({order_report_info:info})
			
		}
		this.hideImg=(id,e) => {
			document.getElementById("modal-viewReport").style.zIndex = "1051";
			let info=this.state.order_report_info
			let letms=info.report_list
			letms.map(it=>{
				if(it.buyer_id===id){
					it.isOpenIM=false
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({order_report_info:letms})
			
		}
		
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,order_id:nextProps.order_id
			},()=>{
				if(nextProps.isOpen===true){
					this.getOrderReportInfo(nextProps.order_id);
				}
			});
			
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		
	}
	componentDidMount() {
	}
	componentWillMount() {
		//this.getBankTypes();
	}
	
  render() {
	    let info=this.state.order_report_info
	    let report_list=this.state.report_list
        return (
			<Modal
     		  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="modal-viewReport"
			  className="w3-animate-zoom"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title">View Report
						</span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body className="">
					<div className="">
						<Row className="mg0">
							<Col md={3}>
								<label className="txt-rpt">Mã đơn hàng</label>
								<label className="txt-rpt">{info.order_number}</label>
							</Col>
							<Col md={3}>
								<label className="txt-rpt">Tipster</label>
								<label className="txt-rpt">{info.seller_tipster_name}</label>
							</Col>
							<Col md={2}>
								<label className="txt-rpt">Price</label>
								<label className="txt-rpt">{info.PriceTips}</label>
							</Col>
							<Col md={1} style={{'textAlign':'center'}}>
								<label className="txt-rpt">Report</label>
								<label className="txt-rpt">{info.Reports}</label>
							</Col>
						</Row>
						<Row className="mg0" style={{'marginTop':'10px'}}>								
							<Col md={12} className="pd0 tips-exchange">
								<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
									<thead>
										<tr>
											<th colSpan="1" style={{'width':'5%'}}>No.</th>
											<th colSpan="1" style={{'width':'15%'}}>Buyer</th>
											<th colSpan="1" style={{'width':'65%'}}>Details</th>
											<th colSpan="1" style={{'width':'15%','textAlign':'center'}}>Invoice file</th>											
										</tr>
									</thead>
									<tbody>
									{
										(report_list!=='')?report_list.map((dt,idx)=>{
											let file=dt.proof_of_payment_url
											var res = (file!==null)?''+file.substring(file.length-13):'';
											return(
											<tr key={idx}>
												<td>0{idx+1}</td>
												<td>{dt.buyer_tipster_name}</td>
												<td>{dt.comment}</td>
												<td style={{'textAlign':'center'}}>
																
													<a className="cusor" onClick={this.vieImg.bind(this,dt.buyer_id)}>{res}</a>
														{
															dt.isOpenIM && (
															  <Lightbox
																mainSrc={dt.proof_of_payment_url}
															   onCloseRequest={this.hideImg.bind(this,dt.buyer_id)}
																style={{'z-index':'1060'}}
																className="lig-box-img"
															  />
														
															)
														}
													
												</td>
											</tr>	
											)
										}):''
									}												
									</tbody>
								</Table>
							</Col>
						</Row>
					</div>
			  </Modal.Body>
			  <Modal.Footer style={{'display':''}}>
					<Col md={12} style={{'textAlign':'center'}}> 						
						<input type="button" className="btn-search btn-view" value="Ok" onClick={this.props.close}/>
					</Col>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewReport); 