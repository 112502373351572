import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox } from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Link } from 'react-router-dom'
import agent from '../../agent'


import '../../styles/modal.css'; 
import '../../styles/style-modal.css'; 
import '../../styles/App.css'
import '../../styles/Friends.css'

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  //BUILDING_LOADED,
  
}from '../../constants/actionTypes'; 

const mapStateToProps = state => (
    Object.assign({}, state, {
	// customer: state.buildings.customer,
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ModalLikes extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,
			text:'',numlike:'0 like',idpet:'',id_user:'',likeList:[],
			url:'https://www.coopbet.com/media/user_none.jpg',isRunging:false
			
			
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};
		
		
		//---
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,
				numlike:nextProps.numlike,
				idpet:nextProps.idpet,
				id_user:nextProps.id_user,
				likeList:nextProps.likeList,
				isRunging:nextProps.isRunging,
			});
			
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		
	}
	componentDidMount() {
	}
	componentWillMount() {
		//this.getLikeList();
	}
	
  createrequest(ev){		
	let thiss=ev.target.closest('.tfd-addfriend-gray');
	$(thiss).find('.loading').show();
	const to_user_id=$(thiss).attr("data-id")
	agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
		//console.log(data)
		if(data.result === 'good'){
			$(thiss).find('.loading').hide();
			const likeList=this.state.likeList.map(item=>{
				if(item.user_id==to_user_id){
					item.status='Pending'
					item.FriendStatusWithLoginUser='Pending'
				}
				return item;
			})
		this.setState({likeList:likeList})	
			
		}else{
			
			$(thiss).find('.loading').hide();
		}
	})
 }
 confirmRequest(ev){
	let thiss=ev.target.closest('.tfd-addfriend');
	let thisss=ev.target.closest('.ft-item');
	let friend_request_id=$(thiss).attr("data-id");
	agent.friends.confirmRequest({friend_request_id:friend_request_id}).then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			console.log(this.state.likeList)
			const likeList=this.state.likeList.map(item=>{
				
				if(item!='' && item!='null' && item!=null && item.friend_request_id==friend_request_id){
					item.status='Confirmed'
					item.FriendStatusWithLoginUser='Confirmed'
				}	
				return item;
			})
			this.setState({likeList:likeList})
			//$(thisss).remove();
		}else{
			this.setState({isOpenModal:true,msgLogin:datas.message})
		}
		
	  })	
 }
  render() {
	   const likeList=this.state.likeList;
	   const url=this.state.url;
	   console.log(likeList)
	
        return (
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="modal-likes"
			  className="w3-animate-zoom"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title">
						{
							(this.state.isRunging==true)?
							<span style={{'color':'#b81900'}}>Running list</span>:
							<span style={{'color':'#b81900'}}>All <span className="numlike">{(this.state.numlike>1)?this.state.numlike+' likeds':this.state.numlike+" liked"} <i className="imglike"></i></span></span>
						}
							
						</span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body className="">
					<Row id="modal-like-list">	
						<div className="scroller friens-top" onBlur={this.hideFriendsBox} tabIndex={0} id="test">
							<div className="frd-top">
								<div className="ft-items">
									<Col md={12} className="ctn">
									{
										(likeList!='' && likeList!='null')?likeList.map((data,idx)=>{
											var url_username=data.url_username
											let prName='';
											let url_user='';
											if(url_username!='' && url_username!='null' && url_username!=null){
												prName=url_username.split("/");
												url_user=prName[prName.length-1];
											}
											return(
												<div className="ft-item ft-item-7" key={idx}>
													<figure className="pull-left">
															<img src={(data.imageurl!='' && data.imageurl!='null' && data.imageurl!=null)?data.imageurl:url} width="50" height="50"/>
													</figure>
													<div className="name pull-left">
														<Link className="profile-name" to={"/"+url_user}>{data.tipster_name}</Link>
													</div>
													{
														(data.user_id != this.state.id_user)?
														<div className="g-btn pull-right">
														{
															(data.FriendStatusWithLoginUser=='')?<a className="btn-1 pull-right tfd-addfriend-gray" data-id={data.user_id} onClick={this.createrequest.bind(this)}>
																<i className="fa fa-user-plus userpl" style={{'paddingRight':'5px'}}></i>Add Friend
																<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20"/>
															</a>:
															(data.FriendStatusWithLoginUser=='PendingYou')?
															<a className="btn-1 pull-right tfd-addfriend" data-id={data.friend_request_id} onClick={this.confirmRequest.bind(this)}>
																Confirm
																<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20"/>
															</a>:
															(data.FriendStatusWithLoginUser=='Confirmed')?
															<a className="btn-1 pull-right tfd-addfriend-gray" data-id={data.user_id} >
																<i className="fa fa-angle-down fw600" style={{'paddingRight':'5px'}}></i>
																Friend
															</a>:
															<a className="btn-1 pull-right tfd-addfriend-gray" data-id={data.user_id}>
																<i className="_3-8_ img sp_88nz5MexVSt sx_7ffadc"></i>Friend Request Sent
																<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20"/>
															</a>
														}
															
													</div>:''
													}
													
												</div>
											)
										}):''
									}
									</Col>
								</div>
							</div>
						</div>
						<Col md={12} className="col-lg-12 lblMessage" id="lblMessage" style={{'color':'red'}}></Col>
					</Row>
			  </Modal.Body>
			  <Modal.Footer style={{'display':'none'}}>
					<div className="col-lg-12" style={{'textAlign':'left','padding':'0'}}> 						
						<button type="button" className="btn btn-primary" onClick={this.props.close}>Close</button>
					</div>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalLikes); 