import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import {Col, Row, FormControl, FormGroup, Radio, OverlayTrigger, Popover, Button} from 'react-bootstrap'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB} from '../../constants/actionTypes'
import $ from 'jquery';
import { Link} from 'react-router-dom'
import { localize, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import Lqhome from '../common/home.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Forgot extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			
		}
		//---
		
		//---
  }
	//---
	
	componentWillMount(){		
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	
	render(){   
		
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">{localizeKey(Lqhome)('resetpass', this.state.lguages)}</span>
								</h1>
							</div>
						</div>
					</div>
					
					<div className="container">
					{
						//---
					}
						<div className="register page">
							<div className="regis-ctn">
								<Row className="email-div">
									<Col md={12}>
										<p className="styiN">{localizeKey(Lqhome)('txtresetpass', this.state.lguages)}.</p>
										<p className="styiN"><Link to="./login" className="link">{localizeKey(Lqhome)('txtlkresetpass', this.state.lguages)}</Link>.</p>
										<Col md={3}></Col>
											<Col md={6}>
												
											</Col>
									</Col>
								</Row>
								
							</div>
						</div>
					{
						//---
					}
					</div>
				</div>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot)