import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import ReactDOM from 'react-dom';
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import '../../styles/upload.css'
import {Col, Row, FormControl, FormGroup, Radio, Checkbox, Table,Button} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,AD_USER_LOAD} from '../../constants/actionTypes'
import NumberFormat from 'react-number-format'
import $ from 'jquery';

import ModalEditAd from './ModalEditAd';

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: AD_USER_LOAD })  
	},
	
});
class Ad extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
           adList:[],isOpenModalAdd:false,isOpenModalEdit:false,ad_id:'',info:''
			
		}
		//---
		this.getadList=this.getadList.bind(this)
		this.goEditAd=this.goEditAd.bind(this)
		this.updateAdList=this.updateAdList.bind(this)
		//---
		this.close=()=>{
			this.setState({isOpenModalEdit:false,message:''})
		}
	}                                       
  
    componentWillMount(){		
		this.props.onLoad('ad-user');	
		this.getadList()
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	updateAdList(adList){
		this.setState({adList:adList})
	}
	goEditAd(ev){
		let ad_id=$(ev.target).attr("dataid")
		//console.log(ad_id)
		//this.setState({isOpenModalAdd:false,isOpenModalEdit:true,ad_id:ad_id})
		//this.getAdInfo(ad_id)
		//window.location.href='/edit-ad'
		window.location.href='/edit-ad/'+ad_id;
	}
	
	getAdInfo(ad_id){
		agent.ads.adUserInfo({ad_id:ad_id}).then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				let info=datas.info
				this.setState({info:info})
				this.props.onloadAdInfo(info)
				//console.log(info)
			}else{
				
			}
		  })	
	}
	getadList(){
		agent.ads.adUserList().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({adList:datas.list})
		}else{
			this.setState({adList:[]})
		}
	  })	
	}
	render(){   
		const adList=this.state.adList;
        return (
			<div id="body">   
				<div>
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">Coopbet Ad Management</span>
								</h1>
							</div>
								
						</div>
					</div>
					<div className="tipster-list">
						<div className="container">
							<Row>
								<Col md={12}>
									<Table className="common" id="table-ad">
											<thead>
												<tr>
													<td style={{'width':'55%'}}>BOOK MAKER</td>
													<td style={{'width':'15%','textAlign':'center'}}>DATE START</td>
													<td style={{'width':'15%','textAlign':'center'}}>DATE END</td>
													<td style={{'width':'15%','textAlign':'center'}}></td>
												</tr>
											</thead>
											<tbody>
											{
												(adList!='')?adList.map((data,idx)=>{
													if(data!='' && data!=null && data!='null'){
													return (
														<tr key={idx}>
															<td style={{'width':'55%'}}>
																<Row className="row-ad">
																	<Col md={4}><img src={data.ad_logo_url} className="logo-ad"/></Col>
																	<Col md={8} className="txtcenter">
																		<h3 className="caption-ad">{data.ad_title}</h3>
																		<a href={data.ad_url} className="lnk-note-ad">{(data.ad_url.length<=60)?data.ad_url:data.ad_url.substr(0,60)+'...'}</a>
																	</Col>
																</Row>
																<Row style={{'margin':'0px'}}>
																	<Col md={12} className="desc-ad">
																		{data.ad_description}
																	</Col>
																	<Col md={12} className="creat-by-ad">Create/Update: {data.CreatUpdateDate}</Col>
																</Row>
															</td>															
															<td className="cell-vertical" style={{'width':'15%','textAlign':'center'}}>{data.ad_startdate}</td>
															<td className="cell-vertical" style={{'width':'15%','textAlign':'center'}}>{data.ad_enddate}</td>
															<td className="cell-vertical" style={{'width':'15%','textAlign':'center'}}>
																{
																	(data.suspended==0)?<a className="running" href="#" onClick={this.goEditAd.bind(this)} dataid={data.ad_id}>EDIT</a>:<a href="#">Suspended</a> 
																}
																
															</td>
														</tr>
													)}
												}):<tr><td></td><td></td><td></td><td></td><td></td></tr>
											}
												
											</tbody>
									</Table>
								</Col>
							</Row>
						
						</div>
					</div>
				</div>
				<ModalEditAd isOpen={this.state.isOpenModalEdit} close={this.close}  getadList={this.getadList} 
							 ad_id={this.state.ad_id} info={this.state.info} updateAdList={this.updateAdList} adList={adList}/>
			</div>
        );
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(Ad)