import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/tips.css'
import $ from 'jquery';
import {Col, Row, FormControl, FormGroup, Radio, Checkbox, ControlLabel,ButtonToolbar,DropdownButton,MenuItem,Button} from 'react-bootstrap'
import NumericInput from 'react-numeric-input';
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,ADDTIPS_PAGE_LOADER} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ItemTip from './itemTip'
import menu from '../common/menu.json'
import { Cookies } from 'react-cookie'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: ADDTIPS_PAGE_LOADER })  
	},
});



 


class Addtip extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			listTips:[],
			betlist:[],randomad:{},userinfo:{},waittocreatebetlist:[],
			loading:false,odd_info:{},stake :5,is_maxbet:0,comment:'',msgLogin:'',
			isOpenModal:false,count:0,startDate: moment(),filterdate:'',leagues:[],league_id:0,selectedOption:'',
			btnTime:'Trận đấu hôm nay',today_match_number:0,tomorrow_match_number:0,Openviewboxplayer:false,key:1,
			maingroups:[],othergroups:[],currentdate:'',leagueids:'',tiplist:[],running:'',sport_tips:2,all_match_number:0,
			day_fm:'today',isLoading:false,is_charged_tip:0
			
		}
		
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.callModalError=(message)=>{
			this.setState({isOpenModal:true,msgLogin:message})
		}
		//---
		this.deletebet=this.deletebet.bind(this)
		//---
		this.createtips=this.createtips.bind(this)
		this.infotobet=this.infotobet.bind(this)
		this.setSportTip=this.setSportTip.bind(this)
		//---
		
		//---
		
	}
	timemenuData(){
		this.setState({loading:true})
		const sport_tips=this.state.sport_tips
		agent.tips.timemenuData({sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				this.setState({tomorrow_match_number:data.tomorrow_match_number,today_match_number:data.today_match_number,
							   all_match_number:data.all_match_number})
			}
		})
	}
	listtobet(){
		this.setState({loading:true})
		const leagueids=this.state.leagueids
		const filterdate=this.state.filterdate
		const sport_tips=this.state.sport_tips
		const league_id=(this.state.league_id>0)?this.state.league_id:'0'
		//console.log(filterdate,league_id)
		agent.tips.listtobet({league_id:league_id,filterdate:filterdate,leagueids:leagueids,sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				this.setState({listTips:data.list,betlist:data.betlist,randomad:data.randomad,
								userinfo:data.userinfo,waittocreatebetlist:data.waittocreatebetlist,
								loading:false,tiplist:data.tiplist})
			}else{
				
			}
		})
	}
	refesh(col){
		const filterdate=this.state.filterdate
		const league_id=this.state.league_id
		const sport_tips=this.state.sport_tips
		agent.tips.listtobet({filterdate:filterdate,league_id:league_id,sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				if(col=="waittocreatebetlist")
				this.setState({waittocreatebetlist:data.waittocreatebetlist})
			}
		})
	}
	infotobet(odd_id,bet_type){
		agent.tips.infotobet({odd_id:odd_id,bet_type:bet_type}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				this.setState({odd_info:data.odd_info})
				$("#tipster-box").show();
			}else{
				this.callModalError(data.message)
				this.setState({odd_info:{}})
				$("#tipster-box").hide();
			}
		})
	}
	choosetobet(ev){
		let thiss=ev.target.closest(".bet-item")
		let odd_id=$(thiss).find('.match-name').attr("dataid")
		let bet_type=$(thiss).find('.match-name').attr("type")
		let amount=this.state.stake
		let is_maxbet=this.state.is_maxbet
		let comment=this.state.comment
		let is_charged_tip=(this.state.is_charged_tip>0)?this.state.is_charged_tip:'0'
		const sport_tips=this.state.sport_tips
		agent.tips.choosetobet({odd_id:odd_id,
								bet_type:bet_type,
								amount:amount,
								is_maxbet:is_maxbet,
								comment:comment,
								sport_tips:sport_tips,
								is_charged_tip:is_charged_tip}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				$("#tipster-box").hide();
				this.setState({isOpenModal:false,msgLogin:'',waittocreatebetlist:data.waittocreatebetlist,randomad:data.randomad})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
			}
		})
	}
	createtips(){
		this.setState({isLoading: true});
		let betids=''
		$("#mybet-list").find(".remove").each(function(){
			//console.log($(this).attr("dataid"))
			betids=betids+$(this).attr("dataid")+"|"
		})
		betids=betids.substring(0,betids.length-1)
		let comment=this.state.comment
		let running=this.state.running
		const sport_tips=this.state.sport_tips
		//console.log(running)
		agent.tips.createtips({betids:betids,comment:comment,running:running,sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				$("#tipster-box").hide();
				this.setState({isOpenModal:false,msgLogin:'',waittocreatebetlist:data.waittocreatebetlist,randomad:data.randomad,betlist:data.betlist,tiplist:data.tiplist,comment:''})
				this.setState({isLoading: false});
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message,comment:''})
			}
		})
	}
	deletebet(ev){
		//console.log(ev.target)
		let thiss=ev.target.closest(".remove")
		$(thiss).find(".remove-tip").hide();
		$(thiss).find(".loadern").show();
		let bet_id=$(thiss).attr("dataid")
		agent.tips.deletebet({bet_id:bet_id}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				this.setState({isOpenModal:false,msgLogin:''})
				this.refesh('waittocreatebetlist')
				$(".remove-tip").show();
				$(".loadern").hide();
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
			}
			
		})
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(this.state.day_fm);
		let day_fm=this.state.day_fm
		//let btnTime=(this.state.btnTime=='trận đấu ngày mai')?localizeKey(menu)('tomorrowmatch', nextProps.languageKey):localizeKey(menu)('todatmatch', nextProps.languageKey)
		let btnTime=(day_fm=='tomorrow')?localizeKey(menu)('tomorrowmatch', nextProps.languageKey):(day_fm=='all')?localizeKey(menu)('allmatch', nextProps.languageKey):localizeKey(menu)('todatmatch', nextProps.languageKey)
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey,btnTime:btnTime});
		}
	}
	componentWillMount(){	
		//console.log(this.state.day_fm)
		this.props.onLoad('addtips')
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		let day_fm=this.state.day_fm
		let btnTime=(day_fm=='tomorrow')?localizeKey(menu)('tomorrowmatch', language):(day_fm=='all')?localizeKey(menu)('allmatch', language):localizeKey(menu)('todatmatch', language)
		
		this.setState({'lguages':language,btnTime:btnTime})
		//--
		//const queryString = require('query-string');
		//var parsed = queryString.parse(this.props.location.search);
		//console.log(parsed.leagueids)
		//let leagueids=parsed.leagueids
		//--
		this.timemenuData();
		let date = new Date();
		let dateFormat=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
		this.setState({filterdate:'today',currentdate:dateFormat},()=>{
			this.listtobet();
		})
		//--
		
		
	}
	CollapseList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').hide();
		$(thiss).find('.collapse').hide();
		$(thiss).find('.expand').show();
	}
	ExpandList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').show();
		$(thiss).find('.collapse').show();
		$(thiss).find('.expand').hide();
	}
	
	handleChangeField(field,ev){
		//console.log(field,ev)
		let val=''
		if(field=='is_maxbet'){
			val=(ev.target.checked)?1:0	
			if(ev.target.checked){
				this.setState({stake:10})
			}else{
				this.setState({stake:5})
			}
		}else if(field=="comment"){
			val=ev.target.value
		}else if(field=="stake"){
			val=ev
			if(val==10){
				this.setState({is_maxbet:1})
			}else{
				this.setState({is_maxbet:0})
			}
		}else if(field=="running"){
			val=(ev.target.checked)?1:0	
			
			
		}
		this.setState({[field]:val})
		//console.log(this.state.stake)
	}
	leagueFiler(ev){
		let val=ev.target.value
		this.setState({league_id:val},()=>{
			this.listtobet();
		})
	}
	gotopet(ev){
		this.setState({stake:5})
		let thiss=ev.target.closest('div')
		//console.log($(thiss).attr("dataid"),$(thiss).attr("type"))
		this.infotobet($(thiss).attr("dataid"),$(thiss).attr("type"))
		//$("#tipster-box").show();
	}
	closepet(ev){
		$("#tipster-box").hide();
	}
	showcomment(ev){
		
		let count=parseInt(this.state.count)+1;	
		this.setState({count:count})
		let thiss=ev.target.closest(".comments")
		$(thiss).find(".note-comment").show();
		$(thiss).find(".fa-power-off").show();
		$(thiss).find(".fa-list-alt").hide();
	}
	hidecomment(ev){
		let thiss=ev.target.closest(".comments")
		$(thiss).find(".note-comment").hide();
		$(thiss).find(".fa-power-off").hide();
		$(thiss).find(".fa-list-alt").show();
		
	}
	handletimeplay(date,ev){
		let thiss=ev.target.closest("li")
		let title=$(thiss).find(".left").text();
		$('#time-play li').removeClass('active');
		$(thiss).addClass('active');
		let filterdate='';
		let day_fm='today';
		if(date=='today'){
			let date = new Date();
			//filterdate=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
			filterdate='today'
			day_fm='today'
		}else if(date=='tomorow'){
			var d = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
			//filterdate=parseInt(d.getFullYear())+'-'+parseInt(d.getMonth()+1)+"-"+parseInt(d.getDate())
			filterdate='tomorrow'
			day_fm='tomorrow'
		}else if(date=='all'){
			filterdate='all'
			day_fm='all'
		}
		this.setState({btnTime:title,filterdate:filterdate,day_fm:day_fm},()=>{
			this.listtobet();
		})
	}
	changeOption(sport_tips,e){
		//console.log(sport_tips)
		$(".tool-ball").removeClass("active")
		this.setState({sport_tips:sport_tips},()=>{
			this.listtobet();
			this.timemenuData();
		})
		$(e.target).closest(".tool-ball").addClass("active")
		window.localStorage.setItem("SportTIP",sport_tips)
	}
	setSportTip(){
		window.localStorage.setItem("SportTIP",this.state.sport_tips)
	}		
	noEnterkey(e){
		e.preventDefault()
	}
	changeOption1(){
	}
	handleCharged(e){
		this.setState({is_charged_tip:e.target.value},()=>{
			//console.log(this.state.is_charged_tip)
		})
	}
	render(){   
		
		const isLoading=this.state.isLoading
		const listTips=this.state.listTips
		const userinfo=this.state.userinfo
		const randomad=this.state.randomad
		const betlist=this.state.betlist
		const waittocreatebetlist=this.state.waittocreatebetlist
		const loading=this.state.loading
		const odd_info=this.state.odd_info
		const leagues=this.state.leagues
		const tiplist=this.state.tiplist
		let payout=0
		if(odd_info.odds>0){
			payout=(parseFloat(this.state.stake))*parseFloat(odd_info.odds)
		}else{
			payout= (parseInt(this.state.stake))*1
		}
		payout= Math.round(payout* 100) / 100; 
		const maingroups=this.state.maingroups;
		const othergroups=this.state.othergroups;
		const sport_tips=this.state.sport_tips
		//console.log(randomad)

		
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">{localizeKey(menu)('btndt', this.state.lguages)}</span>
								</h1>
							</div>
							
							
						</div>
					</div>
					
					<div className="container">
					{
						//---
					}
					<Row className="mgbt10">
						<Col md={7}>
							<Row className="mg0">
								<Col md={4} xs={4} sm={4} className={(sport_tips==2)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,2)}>
									<i className="far fa-futbol icon-tool" ></i>
									<div className="tool-ball-title">{localizeKey(menu)('soccer', this.state.lguages)}</div>
								</Col>
								<Col md={4} xs={4} sm={4} className={(sport_tips==1)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,1)}>
									<i className="fas fa-basketball-ball icon-tool"></i>
									<div className="tool-ball-title">{localizeKey(menu)('basketball', this.state.lguages)}</div>
								</Col>
								<Col md={4} xs={4} sm={4} className={(sport_tips==3)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,3)}>
									<img src="https://m.coopbet.com/img/tennis_ball.png" className="img_tennis" style={{'display':'none'}}/>
									<i className="fas fa-baseball-ball icon-tool"></i>
									<div className="tool-ball-title">{localizeKey(menu)('tennis', this.state.lguages)}</div>
								</Col>
							</Row>
						</Col>
						<Col md={5} className="mgt10">
							<div className="tool-r">	
								<div className="competitions">
									<Link className="btn-gd" to='/select-competition' onClick={this.setSportTip}>{localizeKey(menu)('leagues', this.state.lguages)}</Link>
								</div>
								
								<div id="time-play">
									<ButtonToolbar>
										<DropdownButton
										  bsSize="large"
										  title={this.state.btnTime}
										  id="dropdown-size-large"
										>
										  <MenuItem eventKey="1" className="menu-c1 active" onClick={this.handletimeplay.bind(this,'today')}><div className="left">{localizeKey(menu)('todatmatch', this.state.lguages)}</div><div className="right"><span>{this.state.today_match_number}</span></div></MenuItem>
										  <MenuItem eventKey="2" className="menu-c2" onClick={this.handletimeplay.bind(this,'tomorow')}><div className="left">{localizeKey(menu)('tomorrowmatch', this.state.lguages)}</div><div className="right"><span>{this.state.tomorrow_match_number}</span></div></MenuItem>
										  <MenuItem eventKey="3" className="menu-c3" onClick={this.handletimeplay.bind(this,'all')}><div className="left">{localizeKey(menu)('allmatch', this.state.lguages)}</div><div className="right"><span>{this.state.all_match_number}</span></div></MenuItem>
										 
										</DropdownButton>
									</ButtonToolbar>
								</div>
                            </div>
						</Col>
					</Row>
					{										
						loading ? (
							<div className="loader"></div>
						) : null
					}  
					{
						(!loading)?
						<Row>
						<Col md={9}>
							<div  id="bet-list" className="clear-fix">
								<div className="bet-list">
								{
									(listTips!="")?listTips.map((data,index)=>{										
										const match_list=data.match_list;
										
										return (
											<ItemTip data={data} index={index} sport_tips={sport_tips} key={index} infotobet={this.infotobet}
													 filterdate={this.state.filterdate} callModalError={this.callModalError}
											/>
											
										)
									}):<div className="bet-items">{localizeKey(menu)('oddotfound', this.state.lguages)}</div>
								}
									
								</div>
							</div>
						</Col>
						<Col md={3} className="parent fixed">
							<div className="my-bet-list">
								<h3 className="heading">Tipster: {userinfo.tipster_name}</h3>
								<div id="form-bet">
								</div>
								<div id="tipster-box" style={{'display':'none'}}>
									<div className="box-header">
										<div className="float-left">{odd_info.hometeam_name} - {odd_info.awayteam_name}</div>										
									</div>
									<div className="box-content clear-fix">
										<div id="tips-list" >
											<div className="bet-item clear-fix"> 
												<div className="match"> 
													<span className="match-name" dataid={odd_info.odd_id} type={odd_info.bet_type}>{odd_info.odd_name}</span>
													<span className="tips-type"><span className="tip-name">{odd_info.tipDesc1}</span> @ <strong className="odd">{odd_info.tipDesc2}</strong></span>
												</div>
												<Row>
													<Col md={6} style={{'paddingLeft':'18px','paddingRight':'0px'}}>
														<FormGroup style={{'marginBottom':'0px'}}>
															<ControlLabel className="lb-tips">{localizeKey(menu)('stake', this.state.lguages)}:</ControlLabel>
															<NumericInput min={1} max={10} value={this.state.stake} className="state-tips" onChange={this.handleChangeField.bind(this,'stake')} onKeyDown={this.noEnterkey.bind(this)}/>
															
														</FormGroup>
													</Col>
													<Col md={6} style={{'padding':'0px'}}>
														<FormGroup style={{'marginBottom':'0px'}}>
															<ControlLabel className="lb-tips">Payout:</ControlLabel>
															<FormControl className="ip-tips rt" type="text" value={payout} onChange={this.handleChangeField.bind(this,'payout')}/>
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col md={12} style={{'paddingLeft':'18px'}}>
														<Checkbox className="ckt" onChange={this.handleChangeField.bind(this,'is_maxbet')} checked={(this.state.is_maxbet==1)?true:false}>Max Bet(10)</Checkbox>
													</Col>
												</Row>
												<Row style={{'display':'none'}}>
													<Col md={12} style={{'paddingLeft':'18px'}}>
														<ControlLabel className="lb-tips">Remain: Max Bet(10) Nomal Met(5)</ControlLabel>
													</Col>
												</Row>
												<Row style={{'margin':'15px -10px'}}>
													<Col md={12} style={{'backgroundColor':'#e4e4e4'}}>
														<FormGroup style={{'marginBottom':'0px'}}> 
														  <Radio className="rdg" name="radioGroup" value={0} onChange={this.handleCharged.bind(this)} checked={(this.state.is_charged_tip==0)?true:false}>
															FREE TIPS
														  </Radio>
														  <Radio className="rdg" name="radioGroup" value={1} onChange={this.handleCharged.bind(this)} checked={(this.state.is_charged_tip==1)?true:false}>
															CHARGE TIPS (ONLY TIPSTER)
														  </Radio>
														  
														</FormGroup>

													</Col>
												</Row>
												<Row>
													
													<Col md={12}>
														<button type="button" className="btn btn-choose" onClick={this.choosetobet.bind(this)}>{localizeKey(menu)('choose', this.state.lguages)}</button>
														<button type="button" className="btn btn-close" onClick={this.closepet.bind(this)}>{localizeKey(menu)('close', this.state.lguages)}</button>
													</Col>
												</Row>
											</div>
										</div>
									</div>
								</div>
								<div id="mybet-list-box" className="box clear-fix">
									<div className="box-header">
										<div className="float-left">
										{
											(sport_tips==1)?<i className="fas fa-basketball-ball icon-tool-sm" aria-hidden="true"></i>:(sport_tips==2)?<i className="far fa-futbol icon-tool-sm" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-tool-sm" aria-hidden="true"></i>
										}
											{localizeKey(menu)('bestlist', this.state.lguages)}
										</div>
										<div className="float-right" style={{display:'none'}}><Link to={'/list-all-bets'}>View all</Link></div>
									</div>
									<div className="box-content clear-fix">
										<div id="mybet-list">
										{
											(waittocreatebetlist)?waittocreatebetlist.map((data,index)=>{
												let btype=data.tip_desc
												btype=btype.split("@")
												
												return(
													<div className="bet-item clear-fix" key={index}> 
														<div>
															<div className="match"> 
																<span className="match-name">{data.hometeam_name} - {data.awayteam_name}</span>
																<span className="bet-type">{btype[0]} @ <strong className="odd">{btype[1]}</strong></span>
															</div>
															<span className="amount">{data.amount}</span>
															<span className="remove" dataid={data.bet_id} onClick={this.deletebet}><i className="fa fa-times remove-tip" title="click to remove"></i><div className="loadern" style={{'display':'none'}}></div></span>
														</div>
														{
															(data.comment!=" "&& data.comment!='' && data.comment!=null)?
															<div className="comments">
																<i className="fa fa-list-alt" style={{'paddingLeft':'3px','cursor':'pointer'}} onClick={this.showcomment.bind(this)}></i>
																<div className="note-comment" style={{'display':'none'}}>{data.comment}</div>
															</div>:''
														}
														
													</div>
												)
											}):''
										}
											
										</div>
										<div id="create_pick" className="create-pick">
										{
											(waittocreatebetlist!='')?
											<Col md={12}>
												<FormGroup className="mgbt7">
													<FormControl componentClass="textarea" maxLength={250} placeholder={localizeKey(menu)('txtdecript', this.state.lguages)} rows="5" onChange={this.handleChangeField.bind(this,'comment')} value={this.state.comment}/>
												</FormGroup>
												<Row className="mg0 mgbt10">
													<Col md={6} className="pd0 pdt3">
														<Checkbox className="ckt" onChange={this.handleChangeField.bind(this,'running')} style={{'float':'left','marginTop':'0'}}>Running</Checkbox>
													</Col>
													<Col md={6} className="pd0 txtr">
														{
															//<a className="btn" onClick={this.createtips}>{localizeKey(menu)('createtip', this.state.lguages)}</a>
														}
														<Button
															id="btn-create"
															//bsStyle=""
															disabled={isLoading}
															onClick={!isLoading ? this.createtips : null}>
															{isLoading ? <span style={{'fontSize':'10px'}}><i className='fa fa-spinner fa-spin '></i> {localizeKey(menu)('createtip', this.state.lguages)} ...</span> :<span>{localizeKey(menu)('createtip', this.state.lguages)} </span>}
														 </Button>
													</Col>
												</Row>
												
											</Col>:''
										}
											
										{
											(randomad && randomad!=null && randomad!='')?
											<a href={randomad.url} target="_blank" className="rand-logo">
												<span>Power by: </span>
												<img alt={randomad.title} src={randomad.imageurl} width="80" height="30"/>
											</a>:''
										}
											
											
										</div>
										<div id="mybet-list-success">
											{
												(tiplist)?tiplist.map((data,index)=>{
													let BetList=data.BetList
													return (
														<div className="clear-fix" key={index} id="tip-item"> 
															{
																(BetList!='')?BetList.map((dt,i)=>{
																		let btype=dt.tip_desc
																		btype=btype.split("@")
																		//console.log(BetList.length-1,i%2)
																		return(
																			<div className={((i%2)==1)?'bet-item clear-fix br-bt':'bet-item clear-fix'} key={i}> 
																				<div>
																					<div className="match"> 
																						<span className="match-name">{dt.hometeam_name} - {dt.awayteam_name}</span>
																						<span className="bet-type">{btype[0]} @ <strong className="odd">{btype[1]}</strong></span>
																					</div>
																					<span className="am-at amount">{dt.amount}</span>
																					
																				</div>
																				
																			</div>
																		)
																}):''
															}
															{
																(data.PickComment!=" " && data.PickComment!='' && data.PickComment!=null)?
																<div className="comments">
																	<i className="fa fa-list-alt" style={{'paddingLeft':'3px','cursor':'pointer'}} onClick={this.showcomment.bind(this)}></i>
																	<i className="fa fa-power-off" style={{'paddingLeft':'3px','cursor':'pointer','display':'none'}} onClick={this.hidecomment.bind(this)}></i>
																	<div className="note-comment" style={{'display':'none'}}>{data.PickComment}</div>
																</div>:''
															}
														</div>
													)
													
													
													
												}):''
											}
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>:''
					}
						
					{
						//---
					}
					</div>
					
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Addtip)