import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import {Col, Row, FormControl, FormGroup, Radio, OverlayTrigger, Popover, Button} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB} from '../../constants/actionTypes'
import $ from 'jquery';
import Datetime from "react-datetime"
import ModalErorr from '../common/ModalErorr'
import Captcha from './formCaptcha.js'
import { Cookies } from 'react-cookie' 
import menu from '../common/menu.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Register extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			old_avatar: 'https://www.coopbet.com/media/no_avatar.png',
			avatar: 'https://www.coopbet.com/media/no_avatar.png',
			years:[],	
			sport_tips:2,sporttipList:[],timezoneList:[],countryList:[],
			country_id:'',time_zone:4,odds_formart:1,member_name:'',tipster_name:'',
			email:'',password_confirmation:'',password:'',user_name:'',name:'',sex:2,
			date:'',month:'',year:'',first_name:'',last_name:'',imgCheck:'',textCheck:'',
			isOpenModal:false,msgRigister:'',dorepas:true,
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		this.handleGetFileUpload = (imageURL) => { 
			console.log(imageURL)
			const avatar = (imageURL!='') ? imageURL : this.state.old_avatar				
			const newState = Object.assign({}, this.state,{avatar:avatar})
			this.setState(newState)	
		}
		//---
		this.signup=this.signup.bind(this) 
		this.check_url=this.check_url.bind(this) 
		this.updateCaptcha=this.updateCaptcha.bind(this) 
		//---
		this.handleClickTab = menu => e => {
			e.preventDefault()
			this.props.onTabClick(menu)
			
		}
		//---
  }
	//---
	getSporttip(){
		agent.auth.sporttip().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({sporttipList:datas.list})
		}
	  })	
	}
	//---
	getTimezone(){
		agent.auth.timezone().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({timezoneList:datas.list})
		}
	  })	
	}
	//---
	getCountry(){
		agent.auth.country().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({countryList:datas.list})
		}
	  })	
	}
	//---
	selectCountry(ev){
		this.setState({country_id:ev.target.value})
	}
	//---
	selectTimeZone(ev){
		this.setState({time_zone:ev.target.value})
	}
	//---
	selectSportTips(ev){
		this.setState({sport_tips:ev.target.value})
	}
	//---
	selectOddFormat(ev){
		this.setState({odds_formart:ev.target.value})
	}
	//---
	handleChangeFiled(feild,ev){
		//console.log(feild)
		this.setState({[feild]:ev.target.value})
		if(feild=='tipster_name'){
			//console.log($(".url-user").text(),ev.target.value.toLowerCase())
			var url="coopbet.com/"+ev.target.value.toLowerCase();
			//this.check_url(url);
			
		}
		if(feild=='password'){
			//this.checkPassword(ev.target.value)
		}
	}
	selectBirthday(feild,ev){
		//console.log(ev.target.value)
		this.setState({[feild]:ev.target.value})
	}
	//---
	check_url(url_username){
		$(".ch_U").show()
		agent.profile.check_url({url_username:url_username}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.setState({imgCheck:'fa fa-check',textCheck:'Username url is available.'})
			}else{
				this.setState({imgCheck:'fa fa-times',textCheck:'Username url is not available.'})
			}
			$(".ch_U").hide()
	  })
	}
	//---
	signup(){
		let state=this.state;
		let date=state.date;
		date=(date!='')?date:1;
		let month=state.month
		month=(month!='')?month:1
		let year=state.year
		year=(year!='')?year:1987
		let first_name=state.first_name
		let last_name=state.last_name
		let birthday=date+"/"+month+"/"+year
		let sex=state.sex
		let email=state.email
		let tipster_name=state.tipster_name
		let imageurl=state.avatar
		let sport_tips=state.sport_tips
		let time_zone=state.time_zone
		let odds_formart=state.odds_formart
		let country_id=state.country_id
		let password=state.password
		let password_confirmation=state.password_confirmation
		let checkPassword=this.checkPassword(password);
		agent.auth.signup({
			first_name:first_name,last_name:last_name,birthday:birthday,
			sex:sex,email:email,tipster_name:tipster_name,imageurl:imageurl,
			sport_tips:sport_tips,time_zone:time_zone,odds_formart:odds_formart,
			country_id:country_id,password:password,password_confirmation:password_confirmation,
			lang_id:this.state.lguages
		}).then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				//window.localStorage.setItem( TOKEN_NAME, JSON.stringify(datas.data) );					
				//this.props.setToken(JSON.stringify(datas.data))
				let thiss=this
				//let msg='Thank you for signing up with Coopbet.  Please check your email before logging in to Coopbet.'
				let msg='Thank you for signing up with Coopbet. Please check email to active your account.'
				this.setState({msgRigister:msg,isOpenModal:true},()=>{
					//this.props.onTabClick('home')
					setTimeout(function(){
						thiss.setState({msgRigister:'',isOpenModal:false})
						thiss.props.onTabClick('home')
					},5000);
				})
				
			}else{
				//$("#msg-error").html(datas.message)
				this.setState({msgRigister:datas.message,isOpenModal:true})
				setTimeout(function(){
					//ReactDOM.render('', document.getElementById('msg-error'));
					$("#msg-error").html('')
				},9000);
			}
	   })	

	
		
	}
	//---
	
	handleChange(ev){
		console.log(ev)
	}
	popoverTop(){
		return (
			<Popover id="popover-positioned-top" title="" className="overView-birthday">
				<div className="body-ct">
					<p style={{'display':'none'}}>
						<b>Providing your birthday</b> help make sure you get the right Facebook experience for your age.
						If you want to change to see this, go to the About section of your profile.
						For more details, please visit out <a style={{'color':'blue'}}>Data policy</a>.
					</p>
					<p>{localizeKey(menu)('txtbirth', this.state.lguages)}</p>
				</div>
				<Button bsStyle="primary" onClick={this.hidePopover}>Okay</Button>
		    </Popover>
		)
	}
	popoverTopTerm(){
		return (
			<Popover id="popover-positioned-top1" title="" className="overView-term">
				<div className="body-ct scroll-body">
					<h3><b>Terms and Conditions</b></h3>
					<p><b>Introduction</b></p>
					<p>Hi, we’re Coopbet.com and welcome! We’re happy to have you here and we hope you enjoy your stay on our website.</p>
					<p><b>I. Acceptance of Terms</b></p>
					<p>The following terms and conditions (‘Terms’) have been created to inform you (the ‘User’) about certain conditions and guidelines that you must follow in order to access the Site and any related content, including but not limited to opinions, information, data, files, text, images, photos, audio and video files (‘Content’). Using or interacting with this Site means that you agree to these Terms. If you do not completely consent to these Terms, you must stop using this Site immediately. ‘Using’ this Site includes, but is not limited to, registering an account, browsing web pages or viewing any Content, whether via personal computers, smartphones, mobile apps, mobile devices, or any other devices.</p>
					<p>These Terms incorporate a Privacy Policy, which you are also deemed to agree with. </p>
					<p>From time to time, we may update these Terms, the Privacy Policy, our rates, or other aspects of our business without prior notice. It is your responsibility to review these Terms from time to time, and to become aware of and understand any changes. After any such update, your continued use of this Site or any of our services means that you agree with the updated Terms.</p>
					<p>Who We Are and What We Do</p>
					<p>Coopbet.com is a website that provides access to sports information, specializing in expert opinions on the possible outcomes of sports events (the ‘Tip’ or ‘Tips’). A Tip may include (1) the appropriate types of bets available from independent gaming sites (simple bet home/draw/away, over/under, asian handicap etc.); and (2) the best odds available from such gaming sites.</p>
					<p>Not a Gambling Site</p>
					<p>In connection with the Tips, we do not accept any wages or stakes. You cannot bet on our Site. While you can buy Tips on this Site, the purchase price is only for the opinion presented in the Tip, and does not depend on the odds or actual outcome of the sporting event. We do not pay out any winnings and take no risk or position on the results of the event. It is your sole responsibility to act on the Tips, and any wins or losses that you may obtain from using such Tips to bet with independent gaming sites is for your sole account. We have no affiliation with any gaming site or any gambling operator.</p>
					<p><b>II. Membership</b></p>
					<p>Registration</p>
					<p>Membership is free. When you create an account and accept these terms, you become a member of our site. Membership allows you to view and buy Tips.</p>
					<p>A Tip represents a specific selection from a sporting event provided by our professional tipsters. You may purchase the Tips via our designated payment solution. Any transactions are logged on your member’s statement.</p>
					<p>Membership Requirements</p>
					<p>You must be 18 years of age or older. If you do not meet this specific requirement you are not eligible to use any of our services. If we find out or suspect that you are below 18 years of age, we may terminate your account at any time without notice. Your membership is not transferrable.</p>
					<p>For purposes of registering your membership account, you must provide your real name, email, and other relevant information that we may request. We reserve the right, in our discretion, to approve or disapprove of any account. You must update your account immediately in case of any change of information, such as contact information or address. </p>
					<p>You must keep your password and other account information confidential. You are responsible for all activities under your account. We may treat any person accessing your account with your password exactly as we would treat you, without any liability for doing so. You must immediately let us know of any unauthorized use of your password or account or any other breach of security.</p>
					<p>Compliance with Law</p>
					<p>While we are not a gambling website, we recognize that Tips may be used by Buyers for purposes of gambling. Gambling-related information may be subject to various laws and limitations depending on each country. Users of the Site are individually responsible for knowing their own country’s applicable law, and acting accordingly.</p>
					<p><b>III. Specific Disclaimer</b></p>
					<p>Assumption of Risk</p>
					<p>You use this Site and buy Tips at your sole risk and liability. We make no guarantees, promises, warranties or representations of any kind regarding Tips. You accept that Tips are the subjective opinions, and are inherently speculative. If you use the Tips to gamble on other websites or with any gambling operators, you accept the possibility that you may lose all of the amounts wagered, and that you may otherwise suffer financial losses, distress, trauma and emotional harm.</p>
					<p>It is your duty to verify that the information presented in a Tip is accurate. You assume responsibility to conduct your own verification of any Tip that you consider buying. You have the sole obligation to determine, among other things, whether a Tip appears valid or feasible, or whether the subject sporting event exists and offers the stated betting types and odds.</p>
					<p>We do not promise or guarantee we can provide successful Tips, or that any particular Tips will be available at any particular time.</p>
					<p><b>IV. Limitations</b></p>
					<p>Using Tips for Gambling</p>
					<p>Any action on the basis of the purchased Tip is separately performed by the buyer to the exclusion of Site. If you use the Tip on third party gambling websites or providers, you shall look solely to such third parties in respect of any relevant liability or obligation arising from the use of the Tip.</p>
					<p>Tips are Opinions, Not Promises</p>
					<p>A Tips is only an opinion, and not a promise, stipulation, contractual provision, representation, warranty or guaranty of any expected result. It is solely your decision and at your own risk if you choose to rely on such advice, and the Site may not become liable for any outcome.</p>
					<p>No Resale of Tips</p>
					<p>Buyer may not resell, assign, and transfer for commercial purposes any Tips found on the Site. You may use the Site for personal purposes only, and not for commercial purposes.</p>
					<p>Liability Limitation</p>
					<p>In exchange for the services provided by our Site, and other good and valuable consideration, you release the Site, its owner, directors, officers, affiliates, associates, and employees from any claims, demands, causes of action, losses and damages (direct and consequential) of every kind and nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, in any way connected with your use of this Site, any Tips and any other Content or information that you may find on the Site. You waive any limitations on general releases under applicable law. </p>
					<p>Without limiting the generality of the foregoing, you release and absolve the Site from any liability arising from: inaccurate, false or misleading Tips; or Tips that lead to losing bets or inappropriate bets.</p>
					<p><b>V. Transacting as a Buyer</b></p>
					<p>Subscriptions</p>
					<p>We may change the subscription prices at any time.</p>
					<p>If the minimum is not reached by the time the subscription expires, we may extend the subscription until you receive the minimum.</p>
					<p>Rebilling</p>
					<p>Some subscriptions may be automatically renewed by Coopbet.com. If you want to cancel the automatically renewal of a subscription, you must contact us through our contact details provided on the Site. Alternatively, if you use payment solutions like PayPal or HiPay to pay for your subscription, you can log in to the payment solution and cancel any recurring payments from there.</p>
					<p>Currency Conversion</p>
					<p>You are responsible for all costs of currency conversion relating to your account. We don’t control either the conversion rates or your financial institution’s fees. Your financial institution handles the currency conversion and may charge you additional fees in this regard. This means that you may incur additional costs when purchasing from us, which we have no control over.</p>
					<p>Order Cancellation</p>
					<p>We reserve the right to refuse, without being liable for any damages or costs, an order already accepted by us in the following situations, without limitation:</p>
					<div className="tip-heading">
						<ul className="tip-list">
							<li>your billing information is not correct or not verifiable;</li>
							<li>your order is flagged up by our security systems as an unusual order or an order susceptible to fraud;</li>
							<li>we have reason to believe you are under 18;</li>
							<li>we have reason to believe that you resell or make available tips to third parties.</li>
						</ul>
					</div>
					<p>No Refunds</p>
					<p>All purchases are final. We do not offer any kind of refund to payments already made, regardless of the reason, expect in our sole and absolute discretion.</p>
					<p>Disputes Lodged with Payment Agents</p>
					<p>If you lodge a dispute with a payment solution or payment agent for any reason, we reserve the right to freeze your account until the dispute raised with the payment agent has been closed.</p>
					<p><b>VI. Intellectual Property</b></p>
					<p>Exclusive Ownership</p>
					<p>Our Site, the Coopbet.com name, the Site’s Content and any related software, networks, platforms, database, account information, interface, concepts, illustrations, articles, trade secrets, designs, research, technology, developments, know-how, technologies, look and feel, methodology, trademarks, copyrights, trade dress, logos, and any other information that we regard as proprietary, constitutes our exclusive intellectual property (‘Intellectual Property’).</p>
					<p>No Right Granted</p>
					<p>Nothing on this Site should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Intellectual Property displayed on the Site. Viewing this Site may download a copy of the Site’s materials in your device. You acknowledge that such downloaded materials remain the property of the Site, and you will delete or transfer such materials in accordance with our instructions, if any.</p>
					<p>Limited Permission</p>
					<p>We grant you a limited permission to use the Site’s materials solely for informational, non-commercial and personal use only and will not be copied or posted on any network computer or broadcast in any media, and no modifications of any documents are made. We expressly prohibit use for any other purpose. For avoidance of doubt, no logo, graphic, sound or image from any the Site may be copied or retransmitted unless expressly permitted by the Site.</p>
					<p><b>VII. Confidentiality and Privacy</b></p>
					<p>Confidentiality</p>
					<p>You must treat all Tips as strictly confidential as soon as they are provided to you. If you fail to comply and breach this confidentiality, we are entitled to claim damages and seek legal action. While using the Site, you may also become aware of confidential information about us or another member. You promise to not disclose any confidential information made available to you through the Site to any other person.</p>
					<p>Privacy</p>
					<p>We respect your privacy and take reasonable precautions to protect it. When you become a member you will be asked to provide some information about yourself. This information may be used by us and contractors in connection with providing the Site and its services, including marketing activities. Our Privacy Policy explains how we deal with your information and it forms part of these Terms. While we take reasonable steps to preserve the security of your information, please be aware that we can’t promise that your use of the Site will be absolutely confidential, and we can’t promise that any information you provide to us is perfectly secure.</p>
					<p><b>VIII. General User Obligations</b></p>
					<p>Prohibited Conduct</p>
					<p>You promise that you will not: </p>
					<div className="tip-heading">
						<ul className="tip-list">
							<li>Use a false email address, impersonate others, or misrepresent your affiliation with others;</li>
							<li>Attempt to gain unauthorised access to computer systems or content through the Site;</li>
							<li>Enter any arrangements that are not consistent with these Terms;</li>
							<li>Use information gathered from or through the Site in a way that may confuse or mislead consumers (for example, automatically gathering information to set up a site that looks like one of our sites); </li>
							<li>Attempt to or actually interrupt, negatively impact or alter the Site operation in any way;</li>
							<li>Use the Site or any of the content in a way that violates applicable law, that violates the intellectual property or other rights of us or others, or that is fraudulent, obscene, unprofessional, offensive, misleading or defamatory;</li>
							<li>Modify, reproduce, display, publish, distribute, copy, transmit, perform, create derivative works from, transfer, or sell or re-sell any information, content, software, or item obtained from or through the Site, other than in accordance with these terms. </li>
							<li>Creating multiple accounts - to prevent fraud and abuse, Users are limited to one active account. Any additional account determined to be created to circumvent guidelines will be disabled. Mass account creation may result in disabling of all related accounts.</li>
						</ul>
					</div>
					<p>User Content</p>
					<p>We can look at or remove any of your content for any reason at our discretion. Reasons might include quality assurance, if we think that the use of your content is unauthorised, misleading, incorrect, offensive, or in breach of anyone else’s rights, or if we think that your use of the Site and any content might result in liability to us or anyone else.</p>
					<p><b>IX. General Disclaimer</b></p>
					<p>No Warranty</p>
					<p>We provide our services on an ‘as is’ and ‘as available’ basis. While we make our best efforts to provide high-quality service, we disclaim all warranties of any kind with respect to our Site or services, whether express or implied, including but not limited to merchantability, fitness for a particular purpose, title and non-infringement, or with respect to the completeness, security, reliability, quality, accuracy or availability of the website. In addition, we make no warranty that our Site’s services will be uninterrupted, timely or secure; that our Site will be accessible at any time; that any Content is error-free; that our services will meet your expectations; and that any bugs or glitches in our software will be corrected. The foregoing does not affect any warranties which cannot be excluded or limited under applicable law.</p>
					<p>Third Party Vendors</p>
					<p>Our Site may also include access to products and services of third parties, either directly or via links to sites operated by such third parties. In some cases, we may get a commission if you subsequently make a purchase from such third parties.</p>
					<p>While we endeavor to provide access only to third party products or services that we reasonably believe to be useful, we have no control over such third parties. Thus, any link or access on our site to any third party shall not be deemed as an endorsement of the same. Any transaction between you and such third party is solely between the two of you. We shall not be liable for any loss or damage that you suffer as a result of dealing with such third parties.</p>
					<p><b>X. Suspension or Termination</b></p>
					<p>Reserved Rights</p>
					<p>Membership is not an automatic right; it is a benefit for members who follow our reasonable rules. We hope that you will be with us for a long time. But we do reserve the right, based on our reasonable discretion, to determine whether or not your use of Site complies with these Terms. We reserve the right to modify or terminate the Site or any services for any reason, without notice at any time. We can suspend or terminate your account at any time for any reason (acting reasonably of course), including but not limited to suspicion of fraudulent activity, or non-compliance with these Terms. If we do decide to permanently terminate your account you must not apply for a new account as unfortunately you will no longer be welcome on our Site.</p>
					<p>Cancellation</p>
					<p>You can cancel your subscription at any time by emailing us at contact@coopbet.com. However, cancellation shall not result in refunds for any payments already made. Once your account is cancelled, we may permanently delete all of your content and personal data from the Site.</p>
					<p><b>XI. Miscellaneous</b></p>
					<p>Support</p>
					<p>Users may send an email to <a>support@coopbet.com</a> if they have any questions or require any information about the charged service and the status of their registration. Coopbet.com undertakes to answer these questions or requests for information as soon as reasonably possible. When communicating with our support staff, whether directly or through social media, you may not be abusive, obscene, profane, offensive, sexist, threatening, harassing, and racially offensive or otherwise behave inappropriately. If we feel that your behavior toward any of our customer care representatives or other employees is at any time threatening or offensive, we reserve the right to immediately terminate your account. You acknowledge and agree that the Site may record any communication between you and the Site.</p>
					<p>Relationship between the Parties</p>
					<p>Nothing in these terms is to be construed as constituting a partnership, joint venture, employment or agency relationship between you and us, or between you and any other member. Neither you nor us can bind each other in any way.</p>
					<p>Interpretation</p>
					<p>Words like ‘include’ and ‘including’ are not words of limitation and where anything is within our discretion we mean our sole discretion.</p>
					<p>Dispute Resolution</p>
					<p>In case of any dispute between a user and this Site, the parties shall first exert best efforts to resolve any dispute by amicable discussions. If such discussions fail to resolve the dispute within 14 days, either party may refer the dispute to arbitration in Belize. For purposes of any legal action related to these Terms, the parties irrevocably submit to the exclusive jurisdiction of the courts of Belize. You agree that any claim arising out of your use of the Site must be filed within one (1) year after such claim arose, or be forever barred.</p>
					<p>Responsible Gambling</p>
					<p>While we are not a gambling website, we are aware that you might use our Tips for gambling, and therefore encourage you to gamble responsibly. We support the following association: www.gambleaware.co.uk.</p>
				</div>
				<div className="f-ter">
					<Button bsStyle="primary" onClick={this.hidePopover}>Okay</Button>
				</div>
			</Popover>
		)
	}
	popoverTopPolicy(){
		return (
			<Popover id="popover-positioned-top1" title="" className="overView-term">
				<div className="body-ct scroll-body">
					<h3><b>Coopbet.com Privacy Policy</b></h3>
					<p>
						This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
					</p>
					<p>
						<b>What personal information do we collect from the people that visit our blog, website or app?</b>
					</p>
					<p>
						When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, credit card information or other details to help you with your experience.
					</p>
					<p>
						<b>When do we collect information?</b>
					</p>
					<p>
						We collect information from you when you register on our site, fill out a form or enter information on our site.
					</p>
					<p>
						<b>How do we use your information?</b>
					</p>
					<p>
						We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
						
					</p>
					<div className="tip-heading">
						<ul className="tip-list">
							<li>To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
							<li>To improve our website in order to better serve you.</li>
							<li>To allow us to better service you in responding to your customer service requests.</li>
							<li>To quickly process your transactions.</li>
							<li>To send periodic emails regarding your order or other products and services.</li>
							<li>To follow up with them after correspondence (live chat, email or phone inquiries)</li>
						</ul>
					</div>
					<p>
						<b>How do we protect visitor information?</b>
					</p>
					<p>We do not use vulnerability scanning and/or scanning to PCI standards.</p>
					<p>We do collect credit card information, but did not know PCI compliant scans are now required.</p>
					<p>We do not use Malware Scanning.</p>
					<p>
						Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
					</p>
					<p>
						We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.
					</p>
					<p>
						All transactions are processed through a gateway provider and are not stored or processed on our servers.
					</p>
					<p><b>Do we use 'cookies'?</b></p>
					<p>
						Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
					</p>
					<p><b>We use cookies to:</b></p>
					<div className="tip-heading">
						<ul className="tip-list">
							<li>Understand and save user's preferences for future visits.</li>
							<li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</li>							
						</ul>
					</div>
					<p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.</p>
					<p><b>If users disable cookies in their browser:</b></p>
					<p>If you disable cookies off, some features will be disabled It will turn off some of the features that make your site experience more efficient and some of our services will not function properly.</p>
					<p>However, you can still place orders</p>
					<p>Login, API response</p>
					<p><b>Third-party disclosure</b></p>
					<p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.</p>
					<p>Third-party links</p>
					<p>We do not include or offer third-party products or services on our website.</p>
					<p><b>COPPA (Children Online Privacy Protection Act)</b></p>
					<p>When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.</p>
					<p>We do not specifically market to children under 13.</p>
					<p><b>Fair Information Practices</b></p>
					<p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>
					<p><b>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</b></p>
					<p>We will notify the users via email</p>
					<div className="tip-heading">
						<ul className="tip-list">
							<li>Within 7 business days</li>
						</ul>
					</div>
					<p>We also agree to the Individual Redress Principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>
					<p><b>CAN SPAM Act</b></p>
					<p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>
					<p><b>We collect your email address in order to:</b></p>
					<div className="tip-heading">
						<ul className="tip-list">
							<li>Send information, respond to inquiries, and/or other requests or questions.</li>
						</ul>
					</div>
					<p><b>To be in accordance with CANSPAM we agree to the following:</b></p>
					<div className="tip-heading">
						<ul className="tip-list">
							<li>NOT use false or misleading subjects or email addresses.</li>
							<li>Identify the message as an advertisement in some reasonable way.</li>
							<li>Include the physical address of our business or site headquarters.</li>
							<li>Monitor third-party email marketing services for compliance, if one is used.</li>
							<li>Honor opt-out/unsubscribe requests quickly.</li>
							<li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
						</ul>
					</div>
					<p><b>If at any time you would like to unsubscribe from receiving future emails, you can email us at</b></p>
					<p><a>info@coopbet.com</a> and we will promptly remove you from ALL correspondence.</p>
					<p><b>Contacting Us</b></p>
					<p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>
					<p>coopbet.com</p>
					<p>info@coopbet.com</p>
					<p>Last Edited on 2017-08-05</p>
				</div>
				<div className="f-ter">
					<Button bsStyle="primary" onClick={this.hidePopover}>Okay</Button>
				</div>
			</Popover>
		)
	}
	popoverTopGambling(){
		return (
			<Popover id="popover-positioned-top1" title="" className="overView-term">
				<div className="body-ct scroll-body">
					<h3><b>Gambling Addiction</b></h3>
					<p>At coopbet, we want to ensure that your online betting experience is exactly what it's intended to be: an enjoyable, engaging and potentially profitable experience.</p>
					<p><b>Enjoy betting sensibly</b></p>
					<p>If performed correctly betting can be fun, however, it can easily turn into a negative experience. We know it sounds boring but gambling addiction is a serious problem that affects many people. Our role at coopbet is to provide you with as much information as possible about online betting so you are best equipped to have a positive betting experience. Our advice cannot guarantee to that you will win however it will maximise your chances of success. It is important to recognize that a positive betting experience is not just about winning but that losing is also an inevitable part of betting.</p>
					<p>It is important to recognize your level of betting ability as many gambling problems derive from people thinking they are better than they are. Unless you are a truly experienced or a professional gambler we don't think earning money should be your main priority when betting. For the regular/casual gamer, your main priority should be to have fun. Money certainly adds to the enjoyment and the higher the stakes the higher the thrill but it is easy to get addicted to this thrill and allow your betting to get out of control. Be honest to yourself about your betting and make sure that your betting behavior matches your experience and ability. Being open about your betting experiences to others can help keep your betting under control.</p>
					<p>Remember: you should never bet what you can't afford to lose. Make sure you carefully monitor how much you are spending on your betting and adjust your stakes accordingly. Bankroll management is a very important aspect of betting .</p>
					<p><b>Definition of compulsive gambling</b></p>
					<p>Gambling addictions, also known as compulsive gambling, are characterized as an impulse-control disorder. Those diagnosed with gambling addictions cannot refrain from gambling, regardless of the financial consequences and strains on their relationships. Their behavior results from the impulse to gamble, without taking into consideration the negative impacts of their behavior. The intoxication of a "big win" produces a high which the gambler tries to obtain again. Compulsive gamblers also fall victim to the "gambler's fallacy" in which they believe they can predict the future chance events based on past events, fueling their compulsion and false-belief in future successes with gambling.</p>
					<p>Gambling addictions can be sub-typed into two distinct categories:</p>
					<p>Type one is classified as Action Gamblers. Action Gamblers are drawn to compulsive gambling because of the "risk" involved, and usually prefer gambling around others and prefer games of skill.</p>
					<p>The second type of compulsive gambling is an Escape Gambler. These gamblers are compelled to gamble in order to escape some sort of pain, where they can numb their worries, loneliness and pain.</p>
					<p><b>Early warning signs </b></p>
					<p>Like most mental illnesses, sure signs of gambling addictions appear when one's gambling behaviors begin to interfere with their ordinary day-to-day life and relationships. Since compulsive gambling is not a physical illness, the symptoms of gambling addictions are sometimes more difficult to detect. Furthermore, like most illnesses, the gambler tries to conceal their behavior and keep their impulses secretive, in order to alleviate suspicions and concerns. However, some signs of compulsive gambling problems include: lack of money without income change, overdue bills, secrecy over finances, stealing, borrowing or selling things for extra dispensable cash, secrecy over time spent gambling, neglecting family and personal obligations, using gambling to celebrate, as well as deal with stress.</p>
					<p>Addicted gamblers tend to go through a four phase process of addiction:</p>
					<p>Phase I is the Winning Phase where the gambler experiences "big win" which causes the gambler to attribute his/her win to their own personal talent, and strive to continue their success.</p>
					<p>Phase II is the Losing Phase where they, despite losing large sums, become more concerned with gambling and begin to ignore family, friends, work and the realization that they are losing.</p>
					<p>Phase III is the Desperation Phase where they lose all control over their gambling habits. Continually losing money, and hoping to win it back, causes them to realize their problem, but are unable to stop. Often times, their neglect of reality causes job loss, divorce, ruined relationships and financial and criminal troubles.</p>
					<p>Phase IV is the Hopeless Phase where the gambler has lost everything, even hope. They often resort to drugs or alcohol to deal with their losses and some even go as far as suicide or suicidal attempts.</p>
					<p><b>Treatment and getting help</b></p>
					<p>There are several methods to help an individual deal with their gambling addiction. All treatments however require the individual to first admit that they do in fact have a problem. Methods such as Cognitive-Behavioral Therapy and participation in recovery programs are the first steps to getting treatment and help compulsive gamblers rebuild their personal lives, finances and stability. For more information on treatment programs, as well as hotlines, diagnoses, and self-help programs, please see the following websites.</p>
					<div className="tip-heading">
						<ul className="tip-list">
							<li className="cl-red">Gamblers Anonymous</li>
							<li className="cl-red">Self-Evaluation</li>
							<li className="cl-red">Compulsive Gambling Help</li>
							<li className="cl-red">The National Council on Problem Gambling</li>
							<li className="cl-red">Gambling Therapy</li>
						</ul>
					</div>
					<p>Another suggestion to help treat compulsive gambling problems is to install programs which won't allow gambling software to run on your computer. This removes the temptation to participate in online gambling and removes a significant contributor to secretive gambling. If you are worried that your betting is becoming a serious problem you should consider using this kind of program to help reduce the opportunity to bet. We recommend the software called <span className="cl-red">Gamblock</span>.</p>
					<p>Remember to enjoy the excitement that betting incites and make positive choices in your gambling behavior.</p>
					<p>May you have happy, safe and enjoyable betting experiences!</p>
					<p>coopbet</p>
				</div>
				<div className="f-ter">
					<Button bsStyle="primary" onClick={this.hidePopover}>Okay</Button>
				</div>
			</Popover>
		)
	}
	popoverTopDisclaimer(){
		return (
			<Popover id="popover-positioned-top1" title="" className="overView-term">
				<div className="body-ct scroll-body">
					<h3><b>Disclaimer</b></h3>
					<p>Data and information is provided for informational purposes only, and is not intended for any other commercial or non-commercial purposes. Neither we nor any of our registered tipsters shall be liable for any errors or delays in the content, for any actions taken in reliance thereon. By accessing out website, a user agrees not to redistribute the information found therein. We shall not be liable for any damages of costs of any type arising out of or in any way connected with you're used of our services. </p>
					<p>UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE COOPBET.COM SITE OR MATERIALS OR FUNCTIONS ON ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY ON INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU, IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING COOPBET.COM </p>
					<p>Disclaimer information is subject to change without prior notice and at the discretion of Coopbet.com.</p>
					<p><b>Notes:</b></p>
					<div className="bd-p">
						<p>We provide Sports information only, and do not condone any illegal gambling of any nature. Further, we do not encourage, support or condone any prohibited, illegal or restricted gambling in any shape, form or manner.</p>
						<p>Best tipsters picks and betting predictions from our site are only propositions. We do not take responsibility for using this predictions. Just sign in and put your betting predictions on site</p>
						<p><b>THIS WEBSITE IS FOR ENTERTAINMENT AND INFORMATIVE PURPOSES ONLY. WE DO NOT ACCEPT ANY WAGERING, BETTING, GAMING OR GAMBLING IN ANY FORM OR MANNER.</b></p>
					</div>
				</div>
				<div className="f-ter">
					<Button bsStyle="primary" onClick={this.hidePopover}>Okay</Button>
				</div>
			</Popover>
		)
	}
	hidePopover(){
		document.body.click()
	}
	defaultcountryandtimezone(){
		agent.auth.defaultcountryandtimezone().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			let data=datas.data
			let country_id=data.country_id
			let timezone_id=data.timezone_id
			this.setState({time_zone:timezone_id,country_id:country_id})
		}
	  })	
	}
	componentWillMount(){	
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		
		this.getSporttip();
		this.getTimezone();
		this.getCountry();
		this.defaultcountryandtimezone();
		//----------------------------------
		let dates=new Date();
		let year=dates.getFullYear();
		let years=this.state.years;
		//console.log(year)
		for(let i=0;i<100;i++){
			let y=year-parseInt(i+8)
			years.push(y)
			
		}
		this.setState({years:years})
		//----------------------------------
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	handleSex(e){
		//console.log(e.target.value)
		this.setState({sex:e.target.value})
	}
	updateCaptcha(vale){
		//console.log(vale)  
		this.setState({captcharValue:vale})
		if(vale!='' && vale!=null && vale!="null"){
			this.setState({dorepas:false})
		}else{
			this.setState({dorepas:true})
		}
	}
	checkPassword(key){
		let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
		//var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
		return regex.test(key);
	}
	
	render(){   
		const countryList=this.state.countryList;
		const timezoneList=this.state.timezoneList;
		const sporttipList=this.state.sporttipList;
		//console.log(this.state.sex);
		let urlname=this.state.tipster_name.split(' ').join('');
		let days=['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
		let month=[{'key':'1','value':'Jan'},{'key':'2','value':'Feb'},{'key':'3','value':'Mar'},
				   {'key':'4','value':'Apr'},{'key':'5','value':'May'},{'key':'6','value':'Jun'},
				   {'key':'7','value':'Jul'},{'key':'8','value':'Aug'},{'key':'9','value':'Sep'},
				   {'key':'10','value':'Oct'},{'key':'11','value':'Nov'},{'key':'12','value':'Dec'}]
		let years=this.state.years
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">{localizeKey(menu)('sigUp', this.state.lguages)}</span>
								</h1>
							</div>
						</div>
					</div>
					
					<div className="container">
					{
						//---
					}
						<div className="register page">
							<div className="regis-ctn">
								<Col md={12} className='items-1'>
									<Col md={6} className="item-l-1">
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('firstName', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<input id="FName" name="FName" type="text" value="" placeholder={localizeKey(menu)('firstName', this.state.lguages)} className="form-control" value={this.state.first_name} onChange={this.handleChangeFiled.bind(this,'first_name')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('lastName', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<input id="LName" name="UserName" type="text" value="" placeholder={localizeKey(menu)('lastName', this.state.lguages)} className="form-control" value={this.state.last_name} onChange={this.handleChangeFiled.bind(this,'last_name')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('emailTips', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<input id="Email_Tip" name="Email_Tip" type="text" value="" placeholder={localizeKey(menu)('emailTips', this.state.lguages)} className="form-control" value={this.state.email} onChange={this.handleChangeFiled.bind(this,'email')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('password', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<input name="Password" type="password" value="" placeholder={localizeKey(menu)('password', this.state.lguages)} className="form-control" value={this.state.password} onChange={this.handleChangeFiled.bind(this,'password')}/>
												<div className="note-text">{localizeKey(menu)('txt_password', this.state.lguages)}</div>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('confirmPass', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<input  name="confirm_password" type="password" value="" placeholder={localizeKey(menu)('confirmPass', this.state.lguages)} className="form-control" value={this.state.password_confirmation} onChange={this.handleChangeFiled.bind(this,'password_confirmation')}/>
											</Col>
										</Row>
									</Col>
									<Col md={1}></Col>
									<Col md={5}>
										<Row>
											<Col md={12}>
												<Row>
													<Col md={12} className="form-group" style={{'lineHeight':'34px'}}>
														<span className="lbl">Birthday</span>
													</Col>
												</Row>
												<Row>
													<Col md={12} className="form-group" style={{'display':'none'}}>
														<Datetime
															defaultValue={new Date('2000-01-01')}
															dateFormat={"DD/MM/YYYY"}
															timeFormat={false} 
															onChange={this.handleChange}
														/>
													</Col>
													<Col md={6}>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
															<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} onChange={this.selectBirthday.bind(this,'date')}>
															{
																month.map((data,i)=>{
																	return (
																		<option value={data.key} key={i}>{data.value}</option>
																	)
																})
															}
															</FormControl>		
														</Col>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
																<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} onChange={this.selectBirthday.bind(this,'month')}>
																{
																	days.map((data,i)=>{
																		return(<option value={data} key={i}>{data}</option>)
																	})
																}
																</FormControl>		
														</Col>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
																<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} onChange={this.selectBirthday.bind(this,'year')}>
																{
																	years.map((data,i)=>{
																		return(<option value={data} key={i}>{data}</option>)
																	})
																}
																</FormControl>		
														</Col>														
													</Col>
													<Col md={6} style={{'lineHeight':'','padding':'5px 0px'}}>
														
														 <OverlayTrigger trigger="click" placement="top" overlay={this.popoverTop()} rootClose={true}>
														  <a className="overlay-bt" style={{'color':'blue'}}>{localizeKey(menu)('whydo', this.state.lguages)}</a>
														</OverlayTrigger>
													</Col>
												</Row>
												<Row>
													<Col md={6} className="form-group male" style={{'marginBottom':'0px'}}>
														<FormGroup style={{'paddingTop':'10px','textAlign':'center'}}>
														  <Radio name="gt" inline value={1} onChange={this.handleSex.bind(this)} checked={(this.state.sex==1)?true:false}>
															{localizeKey(menu)('female', this.state.lguages)}
														  </Radio>{' '}
														  <Radio name="gt" inline value={2} onChange={this.handleSex.bind(this)} checked={(this.state.sex==2)?true:false}>
															{localizeKey(menu)('male', this.state.lguages)}
														  </Radio>{' '}
														  
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<label className="col-md-2 font-normal" style={{'padding':'9 0'}}></label>
															<div className="avatar-container">
																<figure>
																	<img className="avatar1" src={this.state.avatar} alt="" />
																</figure>
															</div>	
														</div>
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<label className="col-md-2 font-normal" style={{'padding':'9 0'}}></label>
															<div className="ctnavatar-container" style={{'padding':'0'}}>												
																<UploadImage text={localizeKey(menu)('choseAv', this.state.lguages)}  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetFileUpload} />																								
															</div>	
														</div>
													</Col>
												</Row>
											</Col>
											
										</Row>
									</Col>
								</Col>
								<Col md={12} className='items-2'>
									<Col md={6}>
										<Row className="form-group item" style={{'marginBottom':'3px'}}>
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('tipname', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<input id="Name_Tip" name="Name_Tip" type="text" maxLength="50" placeholder={localizeKey(menu)('tipname', this.state.lguages)} className="form-control" value={this.state.tipster_name} onChange={this.handleChangeFiled.bind(this,'tipster_name')}/>
											</Col>
										</Row>										
									</Col>
									<Col md={12}>
										<Row className="form-group item">
											<span className="note">{localizeKey(menu)('textTipname', this.state.lguages)}</span>
										</Row>
									</Col>
									<Col md={12} style={{'display':'none'}}>
										<Row className="form-group item" style={{'marginBottom':'0px'}}>
											<Col md={4} className="pdr0" style={{'width':'230px'}}>
												<span className="lbl">Url Username:</span>
											</Col>
											{
											(this.state.tipster_name!='')?
											<Col md={7} style={{'paddingRight':'0','lineHeight':'34px'}}>
												<span className="url-user">coopbet.com/{urlname.toLowerCase()}</span>
												<img className="ch_U" src="https://www.coopbet.com/media/loading_dots.gif" style={{'width':'35px'}}/>
												<i className={this.state.imgCheck}></i>
												<span>{this.state.textCheck}</span>
											</Col>:
											<Col md={7} style={{'paddingRight':'0','lineHeight':'34px'}}>
											</Col>
											}
											
										</Row>	
									</Col>
									{
										(this.state.tipster_name!='')?
										<Col md={12} style={{'display':'none'}}>
											<Row className="form-group item" style={{'marginBottom':'0px','marginTop':'-7px'}}>
												<span className="note">Must verify by your mobile number in setting page to get url username</span>
											</Row>
										</Col>:''
									}
								</Col>
								<Col md={12} className='items-3'>
									<Col md={6}>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('porttip', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<FormControl componentClass="select" placeholder="select" value={this.state.sport_tips} onChange={this.selectSportTips.bind(this)}>
													
													{
														(sporttipList)?sporttipList.map(data=>{
															return( <option value={data.id} key={data.id}> {(data.id==1)?localizeKey(menu)('basketball', this.state.lguages):(data.id==2)?localizeKey(menu)('soccer', this.state.lguages):localizeKey(menu)('tennis', this.state.lguages)} </option> )
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('oddFm', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<FormGroup className="sub-item oddsF">
												  <Radio name="radioGroup" checked={true} value={1} onChange={this.selectOddFormat.bind(this)}>
													Decimal 2.5
												  </Radio>
												  <Radio name="radioGroup" value={2} onChange={this.selectOddFormat.bind(this)} disabled = {true}>
													FRACTIONAL 3/2
												  </Radio>
												  
												</FormGroup>
											</Col>
										</Row>
									</Col>
									<Col md={6}>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('timezone', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<FormControl componentClass="select" placeholder="select" value={this.state.time_zone} onChange={this.selectTimeZone.bind(this)}>
													
													{
														(timezoneList)?timezoneList.map(data=>{
															return (<option value={data.id} key={data.id}> {data.desc} </option>)
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">{localizeKey(menu)('selectCtr', this.state.lguages)}:</span>
											</Col>
											<Col md={7}>
												<FormControl componentClass="select" placeholder="select" value={this.state.country_id} onChange={this.selectCountry.bind(this)}>
													<option value=""> {localizeKey(menu)('selectCtr', this.state.lguages)} </option>
													{
														(countryList)?countryList.map(data=>{
															return(<option value={data.id} key={data.id}> {data.title} </option>)
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
									</Col>
								</Col>
								<Col md={12} className="tex-center" style={{'marginBottom':'10px'}}>
									
									By clicking Sign Up, you agree to our 
									<OverlayTrigger trigger="click" placement="top" overlay={this.popoverTopTerm()} rootClose={true}>
									  <a className="overlay-bt" style={{'color':'blue'}}> Terms</a>
									</OverlayTrigger>, 
									<OverlayTrigger trigger="click" placement="top" overlay={this.popoverTopPolicy()} rootClose={true}>
									  <a className="overlay-bt" style={{'color':'blue'}}> Privacy Policy</a>
									</OverlayTrigger>,
									<OverlayTrigger trigger="click" placement="top" overlay={this.popoverTopGambling()} rootClose={true}>
									  <a className="overlay-bt" style={{'color':'blue'}}> Gambling Addiction</a>
									</OverlayTrigger> and
									<OverlayTrigger trigger="click" placement="top" overlay={this.popoverTopDisclaimer()} rootClose={true}>
									  <a className="overlay-bt" style={{'color':'blue'}}> Disclaimer</a>
									</OverlayTrigger>.
								</Col>
								<Col md={12} className="tex-center">
									<Col md={4}></Col>
									<Col md={4}>
										<Col md={1}></Col>
										<Col md={10}><Captcha captcha={this.updateCaptcha} /></Col>
										<Col md={1}></Col>
									</Col>
									<Col md={4}></Col>
									
								</Col>
								<Col md={12} className="tex-center">
									<input type="submit" className="btn-sigin" value={localizeKey(menu)('sigUp', this.state.lguages)} disabled={this.state.dorepas} onClick={this.signup} style={{'fontSize':'14px'}}/>
								</Col>
								
								<Col md={12} className="msg-error" id="msg-error">
									
								</Col>
							</div>
						</div>
					{
						//---
					}
					</div>
					
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)