import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button,Tabs,Tab,Table,Radio} from 'react-bootstrap'
import {TIPSTERS_LOADER,LANGUAGE_KEY,CHANGE_TAB,TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'
import $ from 'jquery';
//import { Link } from 'react-router-dom'
//import ModalErorr from '../common/ModalErorr'
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: TIPSTERS_LOADER })  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class ItemSumary extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',startDate:new Date(),month:'',seller_id:'',seller_list:[],betlist:[],summary:{}
		}
		
		
  }
	getSummaryForBuyer(){
		let month=this.state.month
		let seller_id=this.state.seller_id
		agent.orders.getSummaryForBuyer({seller_id:seller_id,month:month}).then(data => {
			if(data.result === "good"){					
				this.setState({seller_list:data.seller_list,betlist:data.betlist,summary:data.summary})
			}else{
				
			}
		})
	}
	componentWillMount(){		
		this.props.onLoad('tipters')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		this.setState({lguages:language},()=>{
			//this.getrandomad();
		})
		let login=window.localStorage.getItem(TOKEN_NAME);
		//console.log(login)
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			//this.setState({idUser:info.id})
		}
		let dates=new Date();
		let mth=dates.getMonth()+1
		let year=dates.getFullYear()
		let month=mth+"/"+year
		this.setState({month:month},()=>{
			this.getSummaryForBuyer();
		})
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	  //document.getElementById("text1").innerText =''
    }

    componentWillUnmount() {
       
    }
	handleChangeDate(date){
		let dates=date.getDate();
		var month = date.getMonth() + 1;
		let year=date.getFullYear();
		let mth=month+"/"+year
		this.setState({startDate:date,month:mth},()=>{
			this.getSummaryForBuyer();
		})
	}
	handleExpandOrCollapan(show,ev){
		let thiss=ev.target.closest('.bet-items')
		if(show>0){
			$(thiss).find('.sh-snap-menu').show();
			$(thiss).find('.collapse').show();
			$(thiss).find('.expand').hide();			
			
		}else{
			
			$(thiss).find('.sh-snap-menu').hide();
			$(thiss).find('.collapse').hide();
			$(thiss).find('.expand').show();
		}
	}
	selectHandle(field,ev){
		let value=ev.target.value
		this.setState({[field]:value},()=>{
			this.getSummaryForBuyer();
			
		})
	}
	render(){   
		
		//console.log(keyTab)
		let seller_list=this.state.seller_list
		let BetList=this.state.betlist
		let summary=this.state.summary
		let i=0;let ii=0;
        return (
			<Row>										
				<Col md={12} className="pd0">
					<div className="bet-items">
						<div className="bet-league sh-item">
							<div className="flr-date">
								<span className="lb-date">Tháng:</span>
								<DatePicker
								  selected={this.state.startDate}
								  onChange={this.handleChangeDate.bind(this)}
								  dateFormat="MM/yyyy"
								  showMonthYearPicker
								  
								/>
							</div>
							<div className="flt-ct">
								<span className="lb-date">TIPSTER:</span>
								<FormControl componentClass="select" className="slct-seler" value={this.state.seller_id} onChange={this.selectHandle.bind(this,'seller_id')}>
									<option value={''}>All</option>
								{
									(seller_list!=='')?seller_list.map((data,idx)=>{
										return(
										<option value={data.seller_id} key={idx}>{data.seller_tipster_name}</option>
										)
									}):''
								}
								</FormControl>
							</div>
							<div className="ic-rig">
								<i className="collapse focus-mb" onClick={this.handleExpandOrCollapan.bind(this,0)}>&nbsp; </i>
								<i className="expand focus-mb" onClick={this.handleExpandOrCollapan.bind(this,1)} style={{'display':'none'}}>&nbsp; </i>
							</div>
						</div>
						<div className="table_bound sh-snap-menu">
							<Table className="tbl-tipters" id="tbl-summary">
								<thead>
									<tr>
										<td style={{'textAlign':'center'}}><b>Số tips</b></td>
										<td style={{'textAlign':'center'}}><b>WIN</b></td>
										<td style={{'textAlign':'center'}}><b>DRAW</b></td>
										<td style={{'textAlign':'center'}}><b>LOSE</b></td>
										<td style={{'textAlign':'center'}}><b>% Lợi nhuận</b></td>
										<td style={{'textAlign':'center'}}><b>Lợi nhuận</b></td>
									</tr>
								</thead>
								<tbody>
								
									<tr className="boder_none odd-row ">
										<td style={{'textAlign':'center'}}>{summary.num_of_tips}</td>
										<td style={{'textAlign':'center'}}>{summary.win}</td>
										<td style={{'textAlign':'center'}}>{summary.draw}</td>
										<td style={{'textAlign':'center'}}>{summary.lost}</td>
										<td style={{'textAlign':'center'}}>{summary.ProfitPercentage2}</td>
										<td style={{'textAlign':'center'}}>{summary.Profit}</td>
										
									</tr>
									
								</tbody>
							</Table>
						</div>
					</div>
				</Col>
				<Col md={12} id="tips-sumary">
					<div className="header">
						
						<div className="w-0" style={{'width':'85px'}}><span>{localizeKey(translations)('title1', this.state.lguages)}</span></div>
						<div className="w-1" style={{'width':'115px'}}><span>{localizeKey(translations)('title2', this.state.lguages)}</span></div>
						<div className="w-2" style={{'width':'250px','textAlign':'left'}}><span>{localizeKey(translations)('title3', this.state.lguages)}</span></div>
						<div className="w-3" style={{'width':'200px'}}><span>{localizeKey(translations)('title4', this.state.lguages)}</span></div>
						<div className="w-1"><span>{localizeKey(translations)('title5', this.state.lguages)}</span></div>
						
						<div className="w-1"><span>{localizeKey(translations)('title6', this.state.lguages)}</span></div>
						<div className="w-1" style={{'width':'160px'}}><span>TIPSTER</span></div>
						<div className="w-1" style={{'width':'90px'}}><span>{localizeKey(translations)('title7', this.state.lguages)}</span></div>
												
					</div>
					<div className="ctn">
						{
							(BetList.length>0)?
							BetList.map((item,idx)=>{
								
								return (
									<div className="sitem" key={idx} >
										
										<div className="w-0" style={{'width':'85px'}}>
											<img src={item.sporttip_imageurl} style={{display:'none'}}/>
										{
											(item.sportid==1)?<i className="fas fa-basketball-ball icon-sport fnt26px" aria-hidden="true"></i>:(item.sportid==2)?<i className="far fa-futbol icon-sport fnt26px" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport fnt26px" aria-hidden="true"></i>
										}
										</div>
										<div className="w-1" style={{'width':'115px'}}>
											<span>
											{item.start_date} {item.start_time}
											</span>
										</div>
										<div className="w-2">
											<span>
												[{item.league_name}]<br/>
												{item.hometeam_name} - {item.awayteam_name}
											</span>
										</div>
										<div className="w-3" style={{'width':'200px'}}>
												<span className="bet-type" style={{'color':(item.is_charged_tip===1)?'#42b1a9':''}}>{item.tip_desc}</span>
										</div>
										<div className="w-1"><span>{parseInt(item.amount)}/10</span></div>
										
										<div className="w-1" style={{'padding':'inherit'}}>
											{
												(item.home_goal!=null && item.home_goal!='null')?
												<span>{item.home_goal} - {item.away_goal}</span>:
												<span> ? </span>
											}
												
										</div>
										<div className="w-1" style={{'width':'160px'}}>
											{item.tipster_name}
										</div>
										<div className="w-1" style={{'padding':'inherit','width':'90px'}}>
											{
												(item.bet_status=='WIN')?
												<span className="status won" title="Won">
													{localizeKey(translations)('Win', this.state.lguages)}
												</span>:(item.bet_status=='DRAW')?
												<span className="status draw" title="Draw">
													{localizeKey(translations)('Draw', this.state.lguages)}
												</span>:(item.bet_status=='WAIT')?
												<span className="status pending" title="Pending">
													{localizeKey(translations)('wait', this.state.lguages)}
												</span>:(item.bet_status=='LOST')?
												<span className="status lost" title="Lost">
													{localizeKey(translations)('lost', this.state.lguages)}
												</span>:''
											}
											
										</div>
									</div>
								)
							}):''
						}
							
							
					</div>											
				</Col>
			</Row>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemSumary)