import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox } from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';

import agent from '../../agent'


import '../../styles/modal.css'; 

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  //BUILDING_LOADED,
  
}from '../../constants/actionTypes'; 
import { Cookies } from 'react-cookie' 

const mapStateToProps = state => (
    Object.assign({}, state, {
		languageKey: state.common.languageKey
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ModalConfirmDelete extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,
			ad_id:'',ad_title:'',
			lguages:'1005'
			
        }
		
		this.hideModal = ev => {	
		  $(".lblMessage").text('');
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			$(".lblMessage").text('');
		  //this.props.refeshorder();
		};
		
		this.remove=()=>{
			this.props.hideModal();
			agent.ads.deleteAd({ad_id:this.state.ad_id,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
				
				if(data.result == 'good'){
					this.hideModal();
					this.props.getadList();
				}else{
					$(".eror-saved").html(data.message)
				}
				setTimeout(function(){
						$(".eror-saved").html('')
					},9000);
			})
			
		}
		//---
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,ad_id:nextProps.ad_id,ad_title:nextProps.ad_title
			});
			//$("#lblMessage").html('');
		}
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		
    }	
	//componentWillUpdate(nextProps, nextState){
		//const idb=nextState.valueBuild;
		//console.log(nextState)
		//return nextState;
		
		
	//}
	componentDidMount() {
		
		
		
		
	}
	componentWillMount() {
		
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		
	}
 
  render() {
	  // const oderInfo=this.props.orderParams;//console.log(oderInfo)
	
        return (
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="small"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title"><span style={{'color':'#b81900'}}>Confirm</span></span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body>
					<div className="row">		
						<div className="col-md-12 mg5">Do you want to delete the {this.state.ad_title}?</div>
						
						<div className="col-lg-12 lblMessage" id="lblMessage" style={{'color':'red'}}></div>
					</div>
			  </Modal.Body>
			  <Modal.Footer>
					<div className="col-lg-12" style={{'textAlign':'left','padding':'0'}}> 
						<button type="button btY" id="qst-cfRemove" className="btn btn-primary btYes" onClick={this.remove}>Yes</button>
						<button type="button btN" className="btn btn-default btNo" onClick={this.props.close}>No</button>
					</div>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmDelete); 
//export default ModalComfirmOrder