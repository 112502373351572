import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { Cookies } from 'react-cookie' 
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/admin.css'
import {Col, Row, FormControl, FormGroup, Radio, Checkbox, Table} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,AD_PAGE_LOAD} from '../../constants/actionTypes'
import ModalCreatAd from './ModalCreatAd';
import ModalEditAd from './ModalEditAd';
import $ from 'jquery';
import ModalConfirmDelete from './ModalConfirmDelete'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey,
		adData: state.common.adData
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: AD_PAGE_LOAD })  
	},
});



 


class Ads extends Component{ 	
	constructor(){
		super()
		this.state = {
			language:'1005',adList:[],isOpenModalAdd:false,isOpenModalEdit:false,ad_id:'',info:'',isOpenModalconfirm:false
			
		}
		//-----
		this.close=()=>{
			this.setState({isOpenModalAdd:false,isOpenModalEdit:false,isOpenModalconfirm:false})
		}
		//------
		this.remove=(ev)=>{
			//let ad_id=$(this.ev.target).attr("dataid")
			let ad_id=this.state.ad_id
			agent.ads.deleteAd({ad_id:ad_id}).then(data => {
			//console.log(data)
				
				if(data.result == 'good'){
					let adList=this.state.adList
					let newList=adList.map(dt=>{
						if(dt.ad_id==this.state.ad_id){
							return null
						}else{
							return dt;
						}
					})
					this.getadList(newList);
				}else{
					$(".eror-saved").html(data.message)
				}
				setTimeout(function(){
						$(".eror-saved").html('')
					},9000);
			})
			
		}
		//------
		this.goCreateAd=this.goCreateAd.bind(this)
		this.getadList=this.getadList.bind(this)
		this.goEditAd=this.goEditAd.bind(this)
		this.updateAdList=this.updateAdList.bind(this)
	}
	
	getadList(){
		agent.ads.adList().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({adList:datas.list})
		}else{
			this.setState({adList:[]})
		}
	  })	
	}
	goCreateAd(){
		this.setState({isOpenModalAdd:true,isOpenModalEdit:false,isOpenModalconfirm:false})
	}
	goEditAd(ev){
		let ad_id=$(ev.target).attr("dataid")
		//console.log(ad_id)
		this.setState({isOpenModalAdd:false,isOpenModalEdit:true,isOpenModalconfirm:false,ad_id:ad_id})
		this.getAdInfo(ad_id)
	}
	
	getAdInfo(ad_id){
		agent.ads.adInfo({ad_id:ad_id}).then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				let info=datas.info
				this.setState({info:info})
			}else{
				
			}
		  })	
	}
	componentWillMount(){		
		this.props.onLoad('ads');	
		this.getadList()
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({language:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	updateAdList(adList){
		this.setState({adList:adList})
	}
	deleteAd(ev){
		let ad_id=$(ev.target).attr("dataid")
		let ad_title=$(ev.target).attr("ad_title")
		this.setState({isOpenModalAdd:false,isOpenModalEdit:false,isOpenModalconfirm:true,ad_id:ad_id,ad_title:ad_title})
	}
	render(){   
		
		const adList=this.state.adList;
        return (
			<div id="body">   
				<div>
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">Coopbet Ad Management</span>
								</h1>
							</div>
							<div className="portlet-input input-inline input-small div-search-user" style={{'textAlign':'right'}}>
								<a href="#" className="running" onClick={this.goCreateAd}>Create ad</a>
							</div>		
						</div>
					</div>
					<div className="tipster-list">
						<div className="container">
							<Row>
								<Col md={12}>
									<Table className="common" id="table-ad">
											<thead>
												<tr>
													<td style={{'width':'45%'}}>BOOK MAKER</td>
													<td style={{'width':'21%'}}>AD USER</td>
													<td style={{'width':'12%','textAlign':'center'}}>Hiring Duration</td>
													<td style={{'width':'12%','textAlign':'center'}}>Ad Duration</td>
													<td style={{'width':'10%','textAlign':'center'}}></td>
												</tr>
											</thead>
											<tbody>
											{
												(adList!='')?adList.map((data,idx)=>{
													if(data!='' && data!=null && data!='null'){
													return (
														<tr key={idx}>
															<td style={{'width':'45%'}}>
																<Row className="row-ad">
																	<Col md={4}><img src={data.ad_logo_url} className="logo-ad"/></Col>
																	<Col md={8} className="txtcenter">
																		<h3 className="caption-ad">{data.ad_title}</h3>
																		<a href={data.ad_url} className="lnk-note-ad">{(data.ad_url.length<=60)?data.ad_url:data.ad_url.substr(0,60)+'...'}</a>
																	</Col>
																</Row>
																<Row style={{'margin':'0px'}}>
																	<Col md={12} className="desc-ad">
																		{data.ad_description}
																	</Col>
																	<Col md={12} className="creat-by-ad">Create/Update: {data.CreatUpdateDate}</Col>
																</Row>
															</td>
															<td style={{'width':'21%'}}>
																<div>- {data.first_name+" "+data.last_name} ({data.tipster_name})</div>
																<div>- Ad Language: {data.ad_lang_display_name}</div>
															</td>
															<td className="cell-vertical" style={{'width':'12%','textAlign':'center'}}><span className="pdl15">{data.adhiring_startdate}</span><br/> -> <span>{data.adhiring_enddate}</span></td>
															<td className="cell-vertical" style={{'width':'12%','textAlign':'center'}}><span className="pdl15">{data.ad_startdate}</span><br/> -> <span>{data.ad_enddate}</span></td>
															<td className="cell-vertical" style={{'width':'10%','textAlign':'center'}}>
																<div>
																	<a className="running" href="#" onClick={this.goEditAd.bind(this)} dataid={data.ad_id}>EDIT</a>
																	<a className="icon-remove" href="#" dataid={data.ad_id} ad_title={data.ad_title} onClick={this.deleteAd.bind(this)}>DELETE</a>
																	
																</div>
																{
																	(data.suspended==0)?'':<a href="#">Suspended</a> 
																}
																
															</td>
														</tr>
													)}
												}):<tr><td></td><td></td><td></td><td></td><td></td></tr>
											}
												
											</tbody>
									</Table>
								</Col>
							</Row>
						
						</div>
					</div>
				</div>
				<ModalCreatAd isOpen={this.state.isOpenModalAdd} close={this.close}  getadList={this.getadList}/>
				<ModalEditAd isOpen={this.state.isOpenModalEdit} close={this.close}  getadList={this.getadList} 
							 ad_id={this.state.ad_id} info={this.state.info} updateAdList={this.updateAdList} adList={adList}/>
				<ModalConfirmDelete isOpen={this.state.isOpenModalconfirm} close={this.close} ad_id={this.state.ad_id}
						remove={this.remove} ad_title={this.state.ad_title}
						/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ads)