import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import '../../styles/App.css'
import '../../styles/Comment.css'
import $ from 'jquery';
import {RESET_DASHBOARD, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import { localize, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import Lqhome from '../common/home.json'
import TextareaAutosize from 'react-textarea-autosize';
import ModalEditComment from '../Home/ModalEditComment';
import ReactHtmlParser from 'react-html-parser';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({               
	onResetDashboard: (data) => {
		dispatch({ type: RESET_DASHBOARD, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class ReplyList extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://www.coopbet.com/media/avartar_cm.jpg',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			CommentList:[],parent_id:'',for_pick_code:'',member_name:'',
			limit:5,backoffset:'0',numCM:0,user_id:'',user_id_login:'',lguages:'1005',
			isOpenModalEditCm:false,txt_comment:'',
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,isOpenModalEditCm:false})
		}
		//----
		this.handleClickTab = menu => e => {
			e.preventDefault()
			this.props.onTabClick(menu)
			
		}
		//----
		this.showModalEditCM=(comment_id,for_pick_code,comment)=>{
			this.setState({isOpenModalEditCm:true,comment_id:comment_id,
						   txt_comment:comment,for_pick_code:for_pick_code,txt_title:'Edit Comment'})
		}
		this.updateCommentList=(comment_id,comment)=>{
			let CommentList=this.state.CommentList.map(dt=>{
				if(dt.comment_id==comment_id){
					dt.comment=comment
					dt.edit_cm=1
				}
				return dt;
			})
			this.setState({CommentList:CommentList})
		}
		
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		//console.log(nextProps)
		/*this.setState({url:nextProps.imageurl,
			for_pick_code:nextProps.for_pick_code,
			member_name:nextProps.member_name,
			user_id:nextProps.user_id,
			user_id_login:nextProps.user_id_login,
			parent_id:nextProps.parent_id},()=>{
				this.listComment(this.state.for_pick_code,this.state.parent_id)
			})*/
	}	

	componentWillMount() {
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//---
		//console.log(this.props.imageurl,this.props.for_pick_code,this.props.parent_id)
		this.setState({url:this.props.imageurl,
		for_pick_code:this.props.for_pick_code,
		user_id_login:this.props.user_id_login,
		user_id:this.props.user_id,
		parent_id:this.props.parent_id},()=>{
			this.listComment(this.state.for_pick_code,this.state.parent_id)
		})
		//----
		
		
	}	
	componentDidMount() {
		
	}
	doKeycomment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".bg-green-haze").addClass("active")
		if(e.key=='Enter'){
			
		}
		
		//
	}
	changeCOmment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".ot-btn").show()
	}
	hideComment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".ot-btn").hide()
		$(thiss).find(".textarea-cm").val('')
		$(thiss).find(".bg-green-haze").removeClass("active")
	}
	handleRely(e){
		let thiss=e.target.closest(".ct-cmt")
		$(thiss).find(".t-reply").show();
	}
	hideRyply(e){
		let thiss=e.target.closest(".t-reply")
		$(thiss).hide();
	}
	hideRyplyChild(e){
		let thiss=e.target.closest(".t-reply-child")
		$(thiss).hide();
	}
	handleRelyChild(e){
		let thiss=e.target.closest(".ct-cm-reply")
		$(thiss).find(".t-reply-child").show();
	}
	createCommentChild(parent_id,for_pick_code,e){
		let thiss=e.target.closest(".cm-child")
		let comment=$(thiss).find(".textarea-cm").val();
		//---
		$(thiss).find('.do_cm').hide()
		$(thiss).find('.ot-btn').hide()
		$(thiss).find('.loading_dad').show()
		//---
		agent.comment.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id,lang_id:this.state.lguages}).then(data => {
			console.log(data)
			if(data.result=="good"){
				this.props.listComment(for_pick_code,0)
				//this.listComment(for_pick_code,parent_id)
				this.listComment(this.state.for_pick_code,this.state.parent_id)
				$(thiss).find('.do_cm').show()
				$(thiss).find('.loading_dad').hide()
				$(thiss).find(".textarea-cm").val('');
				window.location="/topic/"+this.state.user_id+"_"+for_pick_code+"_"+this.state.parent_id;
			}else{
				$(thiss).find('.loading_dad').hide()
				$(thiss).hide();
				$(thiss).find('.do_cm').show();
				$(thiss).find(".textarea-cm").val('');
				this.props.callModalError(data.message)
			}
		})
	}
	listComment(for_pick_code,parent_id){
		const limit=this.state.limit
		let backoffset=this.state.backoffset
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:parent_id,backoffset:backoffset,limit:limit}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({CommentList:data.CommentList,numCM:data.TotalComments})
				//this.props.updateTotalCM(data.TotalComments)
			}
		})
	}
	togglelike(comment_id,ev){
		agent.comment.togglelike({comment_id:comment_id,lang_id:this.state.lguages}).then(data => {
			console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item!=null && item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsLiked=data.IsLiked
						item.IsDisliked=data.IsDisliked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	toggledislike(comment_id,ev){
		agent.comment.toggledislike({comment_id:comment_id,lang_id:this.state.lguages}).then(data => {
			console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item!=null && item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsDisliked=data.IsDisliked
						item.IsLiked=data.IsLiked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	viewReply(for_pick_code){
		const limit=this.state.limit
		let backoffset=(parseInt(this.state.backoffset+5))
		let parent_id=this.state.parent_id
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:parent_id,backoffset:backoffset,limit:limit}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({CommentList:data.CommentList,numCM:data.TotalComments,backoffset:backoffset})
				this.props.updateTotalCM(data.TotalComments)
			}
		})
	}
	onDotToll(comment_id,e){
		//console.log(comment_id,this.state.CommentList)
		let CommentList=this.state.CommentList.map(data=>{
			if(data!=null && comment_id==data.comment_id){
				data.show_dot=1;
			}else if(data!=null && comment_id!=data.comment_id){
				data.show_dot=0;
			}
			return data;
		})
		this.setState({CommentList:CommentList})
	}
	leaveDotTool(e){
		let CommentList=this.state.CommentList.map(data=>{
			if(data!=null){
				data.show_dot=0;
				return data;
			}
		})
		this.setState({CommentList:CommentList})
	}
	deleteInOwnTopic(comment_id,for_pick_code,e){
		agent.comment.deleteInOwnTopic({for_pick_code:for_pick_code,comment_id:comment_id,lang_id:this.state.lguages}).then(data => {
			//console.log(this.state.CommentList)
			if(data.result=="good"){
				let CommentList=this.state.CommentList.map(dt=>{
					if(dt!=null && dt.comment_id!=comment_id){
						return dt;
					}
				})
				this.setState({CommentList:CommentList})
				this.props.updateTotalRely(this.state.parent_id)
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	deleteCm(comment_id,for_pick_code,e){
		agent.admin.deleteCm({for_pick_code:for_pick_code,comment_id:comment_id,lang_id:this.state.lguages}).then(data => {
			//console.log(this.state.CommentList)
			if(data.result=="good"){
				
				let CommentList=this.state.CommentList.map(dt=>{
					if(dt!=null && dt.comment_id!=comment_id){
						return dt;
					}
				})
				this.setState({CommentList:CommentList})
				this.props.updateTotalRely(this.state.parent_id)
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	blockUser(blocked_user_id,for_pick_code,e){
		agent.running.blockUser({blocked_user_id:blocked_user_id,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				/*let CommentList=this.state.CommentList.map(dt=>{
					if(dt!=null && dt.comment_id!=comment_id){
						return dt;
					}
				})*/
				this.listCommentAfterRely(for_pick_code,0)
			}else{
				this.props.callModalError(data.message)
			}
		})
	}
	render() {
		let url=this.state.url
		let url_like=this.state.url_like
		let url_liked=this.state.url_liked
		let url_dislike=this.state.url_dislike
		let url_disliked=this.state.url_disliked
		let member_name=this.state.member_name
		let CommentList=this.state.CommentList
		let for_pick_code=this.state.for_pick_code
		let numCM=this.state.numCM
		let user_id_login=this.state.user_id_login
		let user_id=this.state.user_id
		//console.log(user_id_login,CommentList)
		//---------
			return (
				<Row className="mg0">
				{
					(numCM>5 && CommentList!='' && parseInt(CommentList.length)>4)?
						<Col md={12} className="pd0">
							<a className="view-more" onClick={(member_name!='' && member_name!=null)?this.viewReply.bind(this,for_pick_code):this.handleClickTab('login')}>View previous reply</a>
						</Col>:''
				}
				{
					(CommentList!='')?CommentList.map((data,index)=>{
						//console.log(user_id,",",user_id_login,",",data.user_id)
						if(data!=null){
							var html = data.comment;
							html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
							var div = document.createElement("div");
							div.innerHTML = html;
							var textmessge = html;
							return(
								<Col md={12} className="pd0" key={index}>
									<Col md={1} className="pdL0 w-5p">
										<img src={(data.imageurl!='' && data.imageurl!=null && data.imageurl!='null')?data.imageurl:this.state.img_default} width="24" height="24" className="img-icon-sm24"/>
									</Col>
									<Col md={11} className="pd0 w-95p ct-cm-reply">
										<Row className="mg0 dot-hv" onMouseEnter={this.onDotToll.bind(this,data.comment_id)} onMouseLeave={this.leaveDotTool.bind(this)}>
											<h4 className="name-cmt">
												<span>{(data.blocked==true)?<i className="fa fa-ban" style={{'color':'red','paddingRight':'3px'}}></i>:''}{data.tipster_name} </span>
												<span className="time-cmt">{data.str_comment_time}</span>
												{(data.edit_cm==1)?<span className="txt-cm-small"> (edited)</span>:''}
												{
													((user_id==user_id_login || user_id_login==data.user_id) && data.show_dot==1)?
													<DropdownMenu triggerType='text' trigger='...'  className="tool-of-user">
														<Row className="mg0 noti-box">		
															{
																(user_id_login==data.user_id && data.show_dot==1)?
																<Col md={12}  className="lhi0"><a className="text-cmt-tl" onClick={this.showModalEditCM.bind(this,data.comment_id,data.for_pick_code,data.comment)}><i className="fa fa-pencil"></i> edit comment</a></Col>:''
															}
															<Col md={12} className="lhi0"><a className="text-cmt-tl" onClick={this.deleteInOwnTopic.bind(this,data.comment_id,for_pick_code)}><i className="fa fa-trash-o"></i> delete comment</a></Col>	
															{
																(user_id==user_id_login && user_id_login!=data.user_id && data.user_id!=5 && data.blocked!=true)?
																	<Col md={12} style={{'marginTop':'7px'}} className="lhi0">
																		<a className="text-cmt-tl" onClick={this.blockUser.bind(this,data.user_id,data.for_pick_code)}><i className="fa fa-minus-circle"></i> block user</a>
																	</Col>	:''
															}																
														</Row>
													</DropdownMenu>	:(user_id_login==5 && user_id_login!=data.user_id && data.show_dot==1)?
													<DropdownMenu triggerType='text' trigger='...'  className="tool-of-user">
														<Row className="mg0 noti-box">														
															<Col md={12}><a className="text-cmt-tl" onClick={this.deleteCm.bind(this,data.comment_id,data.for_pick_code)}><i className="fa fa-trash-o"></i> delete comment</a></Col>																																
														</Row>
													</DropdownMenu>:''
													
												}
											</h4>
										</Row>
										<Row className="mg0">
											{
												//data.comment
												ReactHtmlParser(textmessge)
											}
										</Row>
										<Row className="mg0">
											<div className="r-like-reply">
												<img src={(data.IsLiked==true)?url_liked:url_like}
													 width="35" height="35" className="img-icon-like"
													 onClick={(member_name!='' && member_name!=null && member_name!='null')?this.togglelike.bind(this,data.comment_id):this.handleClickTab('login')}
													 />
												<span className="count-like">{(data.LikedNum>0)?data.LikedNum:''}</span>
												<img src={(data.IsDisliked==true)?url_disliked:url_dislike}
													 width="35" height="35" className="img-icon-dislike"
													 onClick={(member_name!='' && member_name!=null && member_name!='null')?this.toggledislike.bind(this,data.comment_id):this.handleClickTab('login')}
													 />
												<a className="reply-cmt" onClick={this.handleRelyChild.bind(this)}>{localizeKey(Lqhome)('reply', this.state.lguages)}</a>
											</div>
										</Row>
										{
											//---reply form
										}
										<Row className="mg0 t-reply-child cm-child">
											<Col md={12} className="pd10_0">
												<Col md={1} className="pdL0 w-5p">
													<img src={url} width="24" height="24" className="img-icon-sm24"/>
												</Col>
												<Col md={11} className="pd0 mgt10 w-95p">
													<div className="do_cm">
													{/*<FormControl className="textarea-cm pd0"
															componentClass="textarea"
															placeholder="Add a public reply..." rows={1}
															maxLength={10000}
															onKeyPress={this.doKeycomment.bind(this)} 
															onClick={this.changeCOmment.bind(this)}
														/>
													*/}
														<TextareaAutosize className="textarea-cm pd0"
															placeholder="Add a public reply..." 
															maxLength={10000}
															onKeyPress={this.doKeycomment.bind(this)} 
															onClick={this.changeCOmment.bind(this)}
														/>
													</div>
												</Col>
												<Col md={12} className="t-right ot-btn" style={{'display':'block','marginTop':'10px','padding':'0px'}}>
													<a className="bg-grey-steel" onClick={this.hideRyplyChild.bind(this)}>cancel</a>
													<a className="bg-green-haze" onClick={this.createCommentChild.bind(this,data.comment_id,data.for_pick_code)}>{localizeKey(Lqhome)('reply', this.state.lguages)}</a>
												</Col>
											</Col>
										</Row>
										{
											//--end
										}
									</Col>
									
								</Col>
							)
						}
					}):''
				}
					<ModalEditComment isOpen={this.state.isOpenModalEditCm} close={this.close}
						txt_comment={this.state.txt_comment} PickCode={this.state.for_pick_code}
						txt_title={this.state.txt_title} callModalError={this.props.callModalError}
						updateCommentList={this.updateCommentList} comment_id={this.state.comment_id}/>	
					
				</Row>
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyList)
