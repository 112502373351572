import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import {Col, Row, FormControl, FormGroup, Radio, OverlayTrigger, Popover, Button} from 'react-bootstrap'
import UploadImage from '../common/UploadImage'
import UploadBanner from '../common/UploadBanner'
import UploadAdUserLogo from '../common/UploadAdUserLogo'
import UploadBannerMobile from '../common/UploadBannerMobile'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB, CREATE_AD_USER} from '../../constants/actionTypes'
import $ from 'jquery';
import Datetime from "react-datetime"
import ModalErorr from '../common/ModalErorr'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
//import languages from '../../languages'
//import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie' 

const mapStateToProps = state => (
    Object.assign({}, state, {		
		languageKey: state.common.languageKey
	}) 
);

const mapDispatchToProps = dispatch => ({               
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	onLoad: (data) => {
		dispatch({ type: CREATE_AD_USER })  
	},
});

class CreateAdUser extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			old_avatar: 'https://www.coopbet.com/media/no_avatar.png',
			avatar: 'https://www.coopbet.com/media/no_avatar.png',
			years:[],	
			sport_tips:1,
			sporttipList:[],
			timezoneList:[],
			countryList:[],
			languageList:[],
			country_id:'',
			time_zone:4,
			odds_formart:1,
			member_name:'',
			tipster_name:'',
			email:'',
			password_confirmation:'',
			password:'',
			user_name:'',
			name:'',
			sex:'',aduser_number_posttips_perday:1,
			date:'',month:'',year:'',
			first_name:'',last_name:'',
			imgCheck:'',
			textCheck:'',
			isOpenModal:false,
			msgRigister:'',
			old_banneradurl:'https://back-end.coopbet.com/gf/iPAcUstMKm2436_no-banner.png',
			banneradurl:'https://back-end.coopbet.com/gf/iPAcUstMKm2436_no-banner.png',
			bannermobileadurl:'https://back-end.coopbet.com/gf/iPAcUstMKm2436_no-banner.png',
			ad_lang_id:'1005',
			old_aduser_logo_url: 'https://back-end.coopbet.com/gf/e2IogaxeSt2826_no-logo.png',
			aduser_logo_url:'https://back-end.coopbet.com/gf/e2IogaxeSt2826_no-logo.png',
			aduser_url:'',
			aduser_introduction:'',
			adhiring_startdate:'',
			//adhiring_startdateobj:moment(),
			adhiring_startdateobj:new Date(),
			adhiring_enddate:'',
			//adhiring_enddateobj:moment(),
			adhiring_enddateobj:new Date(),
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		this.handleGetFileUpload = (imageURL) => { 
			//console.log(imageURL)
			const avatar = (imageURL!='') ? imageURL : this.state.old_avatar				
			const newState = Object.assign({}, this.state,{avatar:avatar})
			this.setState(newState)	
		}
		//---
		this.handleGetBannerUpload = (imageURL) => { 
			//console.log("handleGetBannerUpload")
			const banneradurl	= (imageURL!='') ? imageURL : this.state.old_banneradurl			
			const newState = Object.assign({}, this.state,{banneradurl:banneradurl})
			this.setState(newState)	
		}
		//---
		this.handleGetAduserLogoUpload = (imageURL) => { 
			//console.log("handleGetBannerUpload")
			const aduser_logo_url	= (imageURL!='') ? imageURL : this.state.old_aduser_logo_url			
			const newState = Object.assign({}, this.state,{aduser_logo_url:aduser_logo_url})
			this.setState(newState)
		}
		this.handleGetMobileBannerUpload = (imageURL) => { 
			//console.log("handleGetBannerUpload")
			const bannermobileadurl 	= (imageURL!='') ? imageURL : this.state.old_banneradurl			
			const newState = Object.assign({}, this.state,{bannermobileadurl :bannermobileadurl })
			this.setState(newState)	
		}
		//---
		this.createaduser=this.createaduser.bind(this) 
		this.check_url=this.check_url.bind(this) 
		//---
		this.handleClickTab = menu => e => {
			e.preventDefault()
			this.props.onTabClick(menu)
		}
		//---
  }
	//---
	getSporttip(){
		agent.auth.sporttip().then((datas) => {
			if(datas.result=='good'){
				this.setState({sporttipList:datas.list})
			}
	  })	
	}
	//---
	getTimezone(){
		agent.auth.timezone().then((datas) => {
			if(datas.result=='good'){
				this.setState({timezoneList:datas.list})
			}
	  })	
	}
	//---
	getCountry(){
		agent.auth.country().then((datas) => {
			if(datas.result=='good'){
				this.setState({countryList:datas.list})
			}
	  })	
	}
	//---
	selectCountry(ev){
		this.setState({country_id:ev.target.value})
	}
	//---
	getLanguage(){
		agent.header.getlangs().then((datas) => {
			if(datas.result==='good'){
				this.setState({languageList:datas.list})
			}
		})
	}
	//---
	selectLanguage(ev){
		this.setState({ad_lang_id:ev.target.value})
	}
	//---
	selectTimeZone(ev){
		this.setState({time_zone:ev.target.value})
	}
	//---
	selectSportTips(ev){
		this.setState({sport_tips:ev.target.value})
	}
	//---
	selectOddFormat(ev){
		this.setState({odds_formart:ev.target.value})
	}
	//---
	handleChangeFiled(feild,ev){
		//console.log(ev.target.value)
		this.setState({[feild]:ev.target.value})
		if(feild=='tipster_name'){
			//console.log($(".url-user").text(),ev.target.value.toLowerCase())
			var url="coopbet.com/"+ev.target.value.toLowerCase();
			this.check_url(url);
			
		}
	}

	handleDateChange(field,dates) {
		//console.log(field,date);
		let date=new Date(dates)
		//let day=date.date();
		let day=date.getDate();
		//let month=date.month()+1;
		let month=date.getMonth()+1;
		//let year=date.year();
		let year=date.getFullYear();
		let newdate=year+"-"+month+"-"+day
//console.log(newdate)	
		if(field=='adhiring_startdate'){
			this.setState({
				adhiring_startdate: newdate,
				adhiring_startdateobj: date
			});
		}
		if(field=='adhiring_enddate'){
			this.setState({
				adhiring_enddate: newdate,
				adhiring_enddateobj: date
			});
		}
	}

	selectBirthday(feild,ev){
		this.setState({[feild]:ev.target.value})
	}
	//---
	check_url(url_username){
		$(".ch_U").show()
		agent.profile.check_url({url_username:url_username}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.setState({imgCheck:'fa fa-check',textCheck:'Username url is available.'})
			}else{
				this.setState({imgCheck:'fa fa-times',textCheck:'Username url is not available.'})
			}
			$(".ch_U").hide()
	  })
	}
	//---admincreataduser
	createaduser(){
		let state=this.state;
		let date=state.date;
		date=(date!='')?date:1;
		let month=state.month
		month=(month!='')?month:1
		let year=state.year
		year=(year!='')?year:1987
		let birthday=date+"/"+month+"/"+year

		agent.admin.admincreataduser({
			first_name:state.first_name,
			last_name:state.last_name,
			birthday:birthday,
			sex:state.sex,
			email:state.email,
			tipster_name:state.tipster_name,
			imageurl:state.imageurl,
			banneradurl:state.banneradurl,
			bannermobileadurl:state.bannermobileadurl,
			sport_tips:state.sport_tips,
			time_zone:state.time_zone,
			odds_formart:state.odds_formart,
			country_id:state.country_id,
			ad_lang_id:state.ad_lang_id,
			aduser_logo_url:state.aduser_logo_url,
			aduser_url:state.aduser_url,
			aduser_introduction:state.aduser_introduction,
			adhiring_startdate:state.adhiring_startdate,
			adhiring_enddate:state.adhiring_enddate,
			password:state.password,
			password_confirmation:state.password_confirmation,
			aduser_number_posttips_perday:state.aduser_number_posttips_perday,
			lang_id:this.state.lguages
		}).then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				let thiss=this
				let msg='Created the sportsbook representative successfully.'
				this.setState({msgRigister:msg,isOpenModal:true},()=>{
					//this.props.onTabClick('home')
					setTimeout(function(){
						thiss.setState({msgRigister:'',isOpenModal:false})
						thiss.props.onTabClick('userlist')
					},5000);
				})
			} else {
				//$("#msg-error").html(datas.message)
				this.setState({msgRigister:datas.message,isOpenModal:true})
				setTimeout(function(){
					$("#msg-error").html('')
				},9000);
			}
	   })	
	}
	//---	
	handleChange(ev){
		console.log(ev)
	}
	popoverTop(){
		return (
			<Popover id="popover-positioned-top" title="" className="overView-birthday">
				<div className="body-ct">
					<p>
						<b>Providing your birthday</b> help make sure you get the right Facebook experience for your age.
						If you want to change to see this, go to the About section of your profile.
						For more details, please visit out <a style={{'color':'blue'}}>Data policy</a>.
					</p>
					
				</div>
				<Button bsStyle="primary" onClick={this.hidePopover}>Okay</Button>
		    </Popover>
		)
	}
	hidePopover(){
		document.body.click()
	}
	componentWillMount(){	
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		
		this.getSporttip();
		this.getTimezone();
		this.getCountry();
		this.getLanguage();
		//----------------------------------
		let dates=new Date();
		let year=dates.getFullYear();
		let years=this.state.years;
		//console.log(year)
		for(let i=0;i<100;i++){
			let y=year-parseInt(i+8)
			years.push(y)			
		}
		this.setState({years:years})
		//----------------------------------
		let date=new Date()
		//date.setMonth(date.getMonth() + 9);
		let month=parseInt(date.getMonth()+1)
		let newdate=date.getFullYear()+"-"+month+"-"+date.getDate()
		let date3months = new Date();
		date3months.setMonth(date3months.getMonth() + 3);
		let month3=parseInt(date3months.getMonth()+1)
		let newdate3=date3months.getFullYear()+"-"+month3+"-"+date3months.getDate()
		//console.log(newdate);
		this.setState({
			adhiring_startdate:newdate,
			//adhiring_startdateobj: moment(newdate),
			adhiring_startdateobj: new Date(newdate),
			adhiring_enddate:newdate3,
			//adhiring_enddateobj: moment(newdate3),
			adhiring_enddateobj: new Date(newdate3),
		});
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	handleSex(e){
		//console.log(e.target.value)
		this.setState({sex:e.target.value})
	}
	render(){   
		const countryList=this.state.countryList;
		const timezoneList=this.state.timezoneList;
		const sporttipList=this.state.sporttipList;
		const languageList=this.state.languageList;

		//console.log(this.state.sporttipList);
		let urlname=this.state.tipster_name.split(' ').join('');
		let days=['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
		let month=[{'key':'1','value':'Jan'},{'key':'2','value':'Feb'},{'key':'3','value':'Mar'},
				   {'key':'4','value':'Apr'},{'key':'5','value':'May'},{'key':'6','value':'Jun'},
				   {'key':'7','value':'Jul'},{'key':'8','value':'Aug'},{'key':'9','value':'Sep'},
				   {'key':'10','value':'Oct'},{'key':'11','value':'Nov'},{'key':'12','value':'Dec'}]
		let years=this.state.years
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">Create Ad User</span>
								</h1>
							</div>	
							<div className="portlet-input input-inline input-small div-search-user">
								<div style={{float:'right'}}>
									<a className="running" href="./userlist">Back</a>
								</div>
							</div>	
						</div>

					</div>
					
					<div className="container">
					{
						//---
					}
						<div className="register page">
							<div className="regis-ctn">
								<Col md={12} className='items-1'>
									<Col md={6} className="item-l-1">
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">First Name:</span>
											</Col>
											<Col md={7}>
												<input id="FName" name="FName" type="text" value="" placeholder="First Name" className="form-control" value={this.state.first_name} onChange={this.handleChangeFiled.bind(this,'first_name')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">Last Name:</span>
											</Col>
											<Col md={7}>
												<input id="LName" name="UserName" type="text" value="" placeholder="Last Name" className="form-control" value={this.state.last_name} onChange={this.handleChangeFiled.bind(this,'last_name')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">Email receive Tips and News:</span>
											</Col>
											<Col md={7}>
												<input id="Email_Tip" name="Email_Tip" type="text" value="" placeholder="Tipster Email" className="form-control" value={this.state.email} onChange={this.handleChangeFiled.bind(this,'email')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">Password:</span>
											</Col>
											<Col md={7}>
												<input name="Password" type="password" value="" placeholder="Password" className="form-control" value={this.state.password} onChange={this.handleChangeFiled.bind(this,'password')}/>
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">Confirm Password:</span>
											</Col>
											<Col md={7}>
												<input  name="confirm_password" type="password" value="" placeholder="Confirm Password" className="form-control" value={this.state.password_confirmation} onChange={this.handleChangeFiled.bind(this,'password_confirmation')}/>
											</Col>
										</Row>
									</Col>
									<Col md={1}></Col>
									<Col md={5}>
										<Row>
											<Col md={12}>
												<Row>
													<Col md={12} className="form-group" style={{'lineHeight':'34px'}}>
														<span className="lbl">Birthday</span>
													</Col>
												</Row>
												<Row>
													<Col md={12} className="form-group" style={{'display':'none'}}>
														<Datetime
															defaultValue={new Date('2000-01-01')}
															dateFormat={"DD/MM/YYYY"}
															timeFormat={false} 
															onChange={this.handleChange}
														/>
													</Col>
													<Col md={6}>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
															<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} onChange={this.selectBirthday.bind(this,'date')}>
															{
																month.map((data,i)=>{
																	return (
																		<option value={data.key} key={i}>{data.value}</option>
																	)
																})
															}
															</FormControl>		
														</Col>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
																<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} onChange={this.selectBirthday.bind(this,'month')}>
																{
																	days.map((data,i)=>{
																		return(<option value={data} key={i}>{data}</option>)
																	})
																}
																</FormControl>		
														</Col>
														<Col md={4} className="form-group" style={{'padding':'0'}}>
																<FormControl componentClass="select" style={{'borderRadius':'0','padding':'3px'}} onChange={this.selectBirthday.bind(this,'year')}>
																{
																	years.map((data,i)=>{
																		return(<option value={data} key={i}>{data}</option>)
																	})
																}
																</FormControl>		
														</Col>														
													</Col>
													<Col md={6} style={{'lineHeight':'34px','padding':'0px'}}>
														
														 <OverlayTrigger trigger="click" placement="top" overlay={this.popoverTop()} rootClose={true}>
														  <a className="overlay-bt" style={{'color':'blue'}}>why do I need to provide my birthday?</a>
														</OverlayTrigger>
													</Col>
												</Row>
												<Row>
													<Col md={6} className="form-group male" style={{'marginBottom':'0px'}}>
														<FormGroup style={{'paddingTop':'10px','textAlign':'center'}}>
														  <Radio name="gt" inline value={1} onChange={this.handleSex.bind(this)}>
															Female
														  </Radio>{' '}
														  <Radio name="gt" inline value={2} onChange={this.handleSex.bind(this)}>
															Male
														  </Radio>{' '}
														  
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<label className="col-md-2 font-normal" style={{'padding':'9 0'}}></label>
															<div className="avatar-container">
																<figure>
																	<img className="avatar1" src={this.state.avatar} alt="" />
																</figure>
															</div>	
														</div>
														<div className="col-md-12 form-group" style={{'padding':'0'}}>
															<label className="col-md-2 font-normal" style={{'padding':'9 0'}}></label>
															<div className="ctnavatar-container" style={{'padding':'0'}}>												
																<UploadImage text='Choose Avatar'  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetFileUpload} />																								
															</div>	
														</div>
													</Col>
												</Row>
											</Col>
											
										</Row>
									</Col>
								</Col>
								<Col md={12} className='items-1b'>
									<Col md={6}>
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Language for ads:</span>
												</Col>
												<Col md={7}>
													<FormControl componentClass="select" placeholder="select" value={this.state.ad_lang_id} onChange={this.selectLanguage.bind(this)}>														
														{
															(languageList)?languageList.map(data=>{
																return( <option value={data.id} key={data.id}> {data.display_name} </option> )
															}):''
														}
													</FormControl>																	
												</Col>
											</Row>			
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Introduction URL:</span>
												</Col>
												<Col md={7}>
													<input id="aduser_url" name="aduser_url" type="text" value="" placeholder="ad user url" className="form-control" value={this.state.aduser_url} onChange={this.handleChangeFiled.bind(this,'aduser_url')}/>
												</Col>
											</Row>			
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Introduction:</span>
												</Col>
												<Col md={7}>												
													<textarea
														className="form-control admin-input-introduction"
														id="txt-introduction"
														rows="5"
														value={this.state.aduser_introduction} onChange={this.handleChangeFiled.bind(this,'aduser_introduction')}
													/>
												</Col>
											</Row>
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Hiring start date:</span>
												</Col>
												<Col md={7}>												
													<DatePicker className="input-date"
														selected={this.state.adhiring_startdateobj}
														onChange={this.handleDateChange.bind(this,'adhiring_startdate')}										
													/>
												</Col>
											</Row>
											<Row className="form-group item">
												<Col md={5} className="pdr0">
													<span className="lbl">Hiring end date:</span>
												</Col>
												<Col md={7}>												
													<DatePicker className="input-date"
														selected={this.state.adhiring_enddateobj}
														onChange={this.handleDateChange.bind(this,'adhiring_enddate')}										
													/>
												</Col>
											</Row>
									</Col>
									<Col md={6}>
										<Row>
											<Col md={12} >
												<Col md={1} ></Col>
												<Col md={5} className="" id="img-aduserlogo" >
													<div className="col-md-12 form-group" style={{'padding':'0'}}>
													
														<label className="col-md-2 font-normal" style={{'padding':'9 0','display':'none'}}></label>
															<div className="avatar-container">
																<figure>
																	<img className="avatar1" src={this.state.aduser_logo_url} alt="" />
																</figure>
															</div>	
													</div>
													<div className="col-md-12 form-group" style={{'padding':'0'}}>
														<label className="col-md-2 font-normal" style={{'padding':'9 0','display':'none'}}></label>
														<div className="ctnavatar-container" style={{'padding':'0'}}>												
															<UploadAdUserLogo text='Choose ad user logo (100x50)'  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetAduserLogoUpload} />																								
														</div>	
													</div>
												</Col>
												<Col md={6} className="pd0" id="img-banner-mobile">
													<div className="col-md-12 form-group" style={{'padding':'0'}}>
														<div className="avatar-container">
															<figure>
																<img className="avatar1" src={this.state.bannermobileadurl} alt="" />
															</figure>
														</div>	
													</div>
													<div className="col-md-12 form-group" style={{'padding':'0'}}>
														<div className="ctnavatar-container" style={{'padding':'0'}}>												
															<UploadBannerMobile text='Choose Mobile Banner (150x50)'  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetMobileBannerUpload} />																								
														</div>
													</div>
												</Col>
											</Col>
										</Row>
										<Row>
												<Col md={1}></Col>
												<Col md={11} id="img-banner">
													<div className="col-md-12 form-group" style={{'padding':''}}>
														<label className="col-md-2 font-normal" style={{'padding':'9 0','display':'none'}}></label>
														<div className="avatar-container">
															<figure>
																<img className="avatar1" src={this.state.banneradurl} alt="" />
															</figure>
														</div>	
													</div>
													<div className="col-md-12 form-group" style={{'padding':''}}>
														<label className="col-md-2 font-normal" style={{'padding':'9 0','display':'none'}}></label>
														<div className="ctnavatar-container" style={{'padding':'0'}}>												
															<UploadBanner text='Choose Web Banner (260x160)'  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetBannerUpload} />																								
														</div>	
													</div>
												</Col>
										</Row>
										<Row className="form-group item" style={{'margin':'10px 0'}}>
											<Col md={5} className="pdr0">
												<span className="lbl">Number post tips /day:</span>
											</Col>
											<Col md={2}>												
												<input  name="number_post_tip" type="text" value={this.state.aduser_number_posttips_perday} placeholder="Number post tips /day" className="form-control" onChange={this.handleChangeFiled.bind(this,'aduser_number_posttips_perday')}/>
											</Col>
										</Row>
									</Col>
								</Col>	
								<Col md={12} className='items-2'>
									<Col md={6}>
										<Row className="form-group item" style={{'marginBottom':'3px'}}>
											<Col md={5} className="pdr0">
												<span className="lbl">Tipster Name:</span>
											</Col>
											<Col md={7}>
												<input id="Name_Tip" name="Name_Tip" type="text" placeholder="Tipster Name" className="form-control" value={this.state.tipster_name} onChange={this.handleChangeFiled.bind(this,'tipster_name')}/>
											</Col>
										</Row>										
									</Col>
									<Col md={12}>
										<Row className="form-group item">
											<span className="note">This tipster name use for display on your tips (max 100 characters)</span>
										</Row>
									</Col>
									<Col md={12}>
										<Row className="form-group item" style={{'marginBottom':'3px'}}>
											<Col md={4} className="pdr0" style={{'width':'230px'}}>
												<span className="lbl">Url Username:</span>
											</Col>
											{
											(this.state.tipster_name!='')?
											<Col md={7} style={{'paddingRight':'0','lineHeight':'34px'}}>
												<span className="url-user">coopbet.com/{urlname.toLowerCase()}</span>
												<img className="ch_U" src="https://www.coopbet.com/media/loading_dots.gif" style={{'width':'35px'}}/>
												<i className={this.state.imgCheck}></i>
												<span>{this.state.textCheck}</span>
											</Col>:
											<Col md={7} style={{'paddingRight':'0','lineHeight':'34px'}}>
											</Col>
											}
											
										</Row>	
									</Col>
								</Col>
								<Col md={12} className='items-3'>
									<Col md={6}>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">Sport Tips:</span>
											</Col>
											<Col md={7}>
												<FormControl componentClass="select" placeholder="select" value={this.state.sport_tips} onChange={this.selectSportTips.bind(this)}>
													
													{
														(sporttipList)?sporttipList.map(data=>{
															return( <option value={data.id} key={data.id}> {data.title} </option> )
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">Odds Format:</span>
											</Col>
											<Col md={7}>
												<FormGroup className="sub-item oddsF">
												  <Radio name="radioGroup" checked={true} value={1} onChange={this.selectOddFormat.bind(this)}>
													Decimal 2.5
												  </Radio>
												  <Radio name="radioGroup" value={2} onChange={this.selectOddFormat.bind(this)}>
													FRACTIONAL 3/2
												  </Radio>
												  
												</FormGroup>
											</Col>
										</Row>
									</Col>
									<Col md={6}>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">Time Zone:</span>
											</Col>
											<Col md={7}>
												<FormControl componentClass="select" placeholder="select" value={this.state.time_zone} onChange={this.selectTimeZone.bind(this)}>
													
													{
														(timezoneList)?timezoneList.map(data=>{
															return (<option value={data.id} key={data.id}> {data.desc} </option>)
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
										<Row className="form-group item">
											<Col md={5} className="pdr0">
												<span className="lbl">Select country:</span>
											</Col>
											<Col md={7}>
												<FormControl componentClass="select" placeholder="select" value={this.state.country_id} onChange={this.selectCountry.bind(this)}>
													<option value=""> Select Country </option>
													{
														(countryList)?countryList.map(data=>{
															return(<option value={data.id} key={data.id}> {data.title} </option>)
														}):''
													}
												</FormControl>	
											</Col>
										</Row>
									</Col>
								</Col>
								<Col md={12} className="tex-center">
									<input type="submit" className="btn-sigin" value="Create" onClick={this.createaduser} style={{'fontSize':'14px'}}/>
								</Col>
								<Col md={12} className="msg-error" id="msg-error">
									
								</Col>
							</div>
						</div>
					{
						//---
					}
					</div>
					
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdUser)