import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox, Form, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format'
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Link } from 'react-router-dom'
import agent from '../../agent'
import '../../styles/modal.css'; 

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  //BUILDING_LOADED,
  
}from '../../constants/actionTypes'; 

const mapStateToProps = state => (
    Object.assign({}, state, {
	// customer: state.buildings.customer,
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ModalEror extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,message:'',
			
        }
		
		this.hideModal = ev => {	
		  $(".lblMessage").text('');
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			$(".lblMessage").text('');
		  //this.props.refeshorder();
		};
		//---
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,message:nextProps.message});
			//$("#lblMessage").html('');
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		//const idb=nextState.valueBuild;
		//console.log(nextState)
		return nextState
		
	}
	componentDidMount() {
		
		
		
		
	}
	componentWillMount() {
		
		
		
	}
	
  render() {
	   const AssignedTeams=this.state.AssignedTeams;
	  //console.log(this.state.length_of_time)
	
        return (
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="modal-team"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title" style={{'padding':'0px 15px'}}>
						<span className="modal-detail-order-title " ><span className="title-stage">Message</span></span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body>
					<Row>		
						<Col md={12}><p>{this.state.message}</p></Col>	
					</Row>
			  </Modal.Body>
			  <Modal.Footer>
					<Row className="rct textr" style={{'padding':'0px 15px'}}>
						<Link to="#" className="btn btn-default" onClick={this.hideModal}>Ok</Link>
					</Row>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalEror); 
//export default ModalEror