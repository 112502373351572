import React, {Component} from 'react';
import { connect } from 'react-redux';
import '../../styles/main.css';  
//import { Col } from 'react-bootstrap'
//import FileUpload from 'react-fileupload'
import agent from '../../agent'
import $ from 'jquery';
import ModalErorr from './ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
	// customer: state.buildings.customer,
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});
const initialState = {
		totalFiles: 1,
		files: [],
		multiFiles: false,
		text: 'Choose Logo',
		imageURL: '',
		selectedFile:'',
		isOpenModal:false,
		message:'',
	}
class UploadImage extends Component{ 
	constructor(){
		super()
		this.state = initialState
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.handleUploading = (progress) => {
			let loaded = progress.loaded 
			const fileList = this.state.files.map(item => {
				if(item.uploading) {
					const percentage = 100 - (loaded / (item.size * 1000))
					item.percentage = percentage
					return item
				}
				return item
			})
			const newState = Object.assign({}, this.state, {files: fileList })
			this.setState(newState)			

		}
		
		this.handleUploadSuccess = resp => {
			if(resp.result === 'good'){
				this.props.updateFiles(resp.fileurl)
				const fileList = this.state.files.map(item => {
					if(item.uploading) {
						item.percentage = 100
						item.uploading = false
						item.new_file_name = resp.data[0]
						return item
					}
					return item
				})
				const newState = Object.assign({}, this.state, {files: fileList, imageURL: resp.fileurl })
				this.setState(newState, () => {
					this.updateFiles()
				})
				
				
			} else {
				const fileList = this.state.files.map(item => {
					if(item.uploading) {
						item.filename = resp.message
						item.size = ''
						item.title = ''
						item.error = true
						item.uploading = false
						return item
					}
					return item
				})
				const newState = Object.assign({}, this.state, {files: fileList })			
				this.setState(newState)					
			}			
		}
		
		this.handleChooseFile = files => { 
			const size = Math.ceil(files[0].size / 1000)
			const fname = files[0].name
			let filename =  fname
			if(fname.length > 26){
				filename = fname.substring(0, 26) + "..."
			}
			let totalFiles = this.state.multiFiles ? this.state.totalFiles : 1
			let filesList = this.state.multiFiles ? this.state.files : []
			const newItem = {
				index: totalFiles++,
				filename: filename,
				file: files,
				percentage: 0,
				uploading: true,
				title: files[0].name,
				size: size,
				error: ''
			}

			filesList.push(newItem)
			const newState = Object.assign({}, this.state, {
					totalFiles: totalFiles,
					files: filesList
				})
			this.setState(newState)
		}
		
		this.handleUploadError = err => {
			const fileList = this.state.files.map(item => {
				if(item.uploading) {
					item.filename = err.message
					item.size = ''
					item.title = ''
					item.error = true
					item.uploading = false
					return item
				}
				return item
			})
			const newState = Object.assign({}, this.state, {files: fileList })			
			this.setState(newState)				
		}
		
		this.handleRemove = (index) => {
			if(index > 0){
				
				const files = this.state.files
				const newList = files.filter(item => item.index !== index)
				
				const newState = Object.assign({}, this.state, {
					files: newList
				})
				this.setState(newState, () => {
					this.props.updateFiles('')
				})				
			} else {				
				const newState = Object.assign({}, this.state, {
					filename: '',
					size: '',
					percentage: 0,
					title: ''
				})
				this.setState(newState, () => {
					this.updateFiles()
				})							
			}

		}
		
		this.updateFiles = () => {
			let files = []
			let pfiles = []
			this.state.files.map(item => {
				if(item.filename && !item.error){
					files.push(item.filename)
				}
				if(item.new_file_name && !item.error){
					pfiles.push(item.new_file_name)
				}	
				return item
			})
			//console.log(files)
			//this.props.updateFiles(files, pfiles)
			
		}
	}
	fileChangedHandler = (event) => {
		console.log(event.target.files)
	  this.handleChooseFile(event.target.files);
	  this.setState({selectedFile: event.target.files})
	  agent.auth.uploadLogoAd({picture:event.target.files[0]}).then((datas) => {
		//console.log(datas)
		if(datas.result==='good'){
			this.props.updateFiles(datas.fileurl)
		}else{
			this.setState({message:datas.message,isOpenModal:true})
		}
	  })
	  
	}
	handleClickUl(){
		$('#uploadAvartar').click()
	}
	componentWillReceiveProps(nextProps){
		console.log(nextProps)
		try{
			const files = nextProps.resetFile ? [] : this.state.files
			const totalFiles = nextProps.resetFile ? 1 : this.state.totalFiles
			const newState = Object.assign({}, this.state, {files: files, totalFiles: totalFiles, text: nextProps.text, multiFiles: nextProps.multiFiles })
			this.setState(newState)				
		} catch(e){}
	}
	
	componentWillMount(){
		const newState = Object.assign({}, this.state, {files: [], totalFiles: 1 })
		this.setState(newState)		
		
	}

  render(){      
		//const assess_token = this.props.token

		const files = this.state.files

        return (
				<div className="file-upload-container">
					{
						files.map( file => {			
							const handleRemove = index => e => {
								e.preventDefault()
								this.handleRemove(index)
							}
							const fileStyle = file.error ? 'filename error' : 'filename'
							const styleLoaded = {
								width: file.percentage + "%",		
							}							
							return (
								<div id="process-container" key={file.index}>
									{
										file.uploading ?
											<div className="container-uploading">
												<div className="uploading" style={styleLoaded}></div>
											</div>	
										: null
									}
							
									<span className={fileStyle} title={file.title}>{file.filename}</span>
									<span className="file-size">{"(" + file.size + "K)"}</span>
									<div className="remove" onClick={handleRemove(file.index)}><i className="fa fa-close"></i></div>
								</div>								
							)
						})
					}
					{
						!this.state.multiFiles && files.length > 0 ? null : (
							<div>
								<input id="uploadAvartar" type="file" onChange={this.fileChangedHandler} name='picture'/>
								<a id="buttonUpload" onClick={this.handleClickUl} style={{'color':'#F6970C','cursor':'pointer'}}>{this.state.text}</a>
							</div>
													
						)
					}
					<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.message}/>
				</div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);
//export default UploadImage