import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import {TIPMAKET_PAGE_LOADER,LANGUAGE_KEY,AD_LONG_DESCPT} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import { Cookies } from 'react-cookie'


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey,
		ad_long_description: state.common.ad_long_description
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: TIPMAKET_PAGE_LOADER })  
	},
	onLoaddecript: (data) => {
		dispatch({ type: AD_LONG_DESCPT, data })
	},
});



 


class AdDetail extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',List:[],ad_user_id:'',ad_title:'',msg:''
		}
		//---
		this.goNewInfo=(ad_long_description,ad_title,ad_id,e)=>{
			//console.log(ad_long_description)
			this.props.onLoaddecript(JSON.stringify({text:ad_long_description}))
			//this.props.onLoaddecript(123)
			window.localStorage.setItem('ad_long_description', JSON.stringify({text:ad_long_description,'title':ad_title}))
			setTimeout(function(){
				window.location.href='/new-info/'+ad_id
				
			},9);
		}
  }
		//---
	componentWillMount(){		
		this.props.onLoad('bookmakers')
		const cookies = new Cookies()		
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!= 'undefined')?cookies.get('language'):'1005';
		
		let ad_user_id=window.localStorage.getItem( 'ad_user_id');
		let ad_title=window.localStorage.getItem( 'ad_title');
		this.setState({lguages:language,ad_title:ad_title,ad_user_id:ad_user_id})
		//this.setState({lguages:window.localStorage.getItem(LANGUAGE_KEY)});
		//this.bookMakerAd(language)
		
		
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey});
			//this.bookMakerAd(nextProps.languageKey)
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	   //console.log(this.state.lguages)
	   this.bookMakerAd(this.state.ad_user_id)
    }

    componentWillUnmount() {
       // document.removeEventListener("click", this.closeMenu);
	   //console.log("test")
	   //clearInterval(this.bookMakerAd);
    }

	bookMakerAd(ad_user_id){
		agent.ads.bookMakerAd({ad_user_id:ad_user_id}).then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({List:datas.list})
		}else{
			this.setState({List:[],msg:datas.message})
		}
	  })	
	}
	render(){   
		//console.log(this.state.List)
		let list=this.state.List
        return (
			<div id="body">   
				<div className="heder-page">
					<div className="container">
						<div className="item lbl-1">
							<h1>
								<span className="txt">{this.state.ad_title} NEWS</span>
							</h1>
						</div>	
							
					</div>
				</div>
				<div className="tipster-list">
					<div className="container">
						<Row>
							<Col md={12}>
							{
								(list!='')?list.map((it,idx)=>{
									let ad_logo_url=(it.ad_logo_url!='' && it.ad_logo_url!=null && it.ad_logo_url!='null')?it.ad_logo_url:''
									return(
										<Col md={6} className="brth-box" key={idx}>
											<Row className="row-ad">
												<Col md={3}>
													<img src={it.ad_logo_url} className="logo-ad"/>
												</Col>
												<Col md={9} className="txtcenter">
													<h3 className="caption-ad">{it.ad_title}</h3>
													<a href={it.ad_logo_url} className="lnk-note-ad">{(ad_logo_url.length<=60)?ad_logo_url:ad_logo_url.substr(0,60)+'...'} </a>
												</Col>
											</Row>
											<Row style={{'margin':'0'}}>
												<Col md={12} className="desc-ad">{it.ad_description}</Col>
												<Col md={12} style={{'padding':'0px'}}>
													<Col md={4} className="creat-by-ad">Create/Update: {it.CreatUpdateDate}</Col>
													<Col md={8} className="note-to" >
														<a onClick={this.goNewInfo.bind(this,it.ad_long_description,it.ad_title,it.ad_id)}>view more</a>
													</Col>
												</Col>
											</Row>
										</Col>
									)
								}):this.state.msg
							}
								
							</Col>
						</Row>
					</div>
				</div>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdDetail)