import {
  UPDATE_VERTICAL,
  UPDATE_LINE_CHART,
  UPDATE_DRAG_AREA_CHART,
  UPDATE_STATUS,
  UPDATE_KPI_SIMULATION,
  UPDATE_KPI_TOP_PERCENT
} from '../constants/actionTypes';

export default (state = {}, action) => {
	switch (action.type) {
		case UPDATE_VERTICAL: 
			return Object.assign({}, state, {
				valueLeadMax: action.params.valueLeadMax,
				valueLeadMin: action.params.valueLeadMin,
				valueLead: action.params.valueLead,
				valueConver: action.params.valueConver
			});
		case UPDATE_LINE_CHART:
			return Object.assign({}, state, {
				SalesSimulationData: action.SalesSimulationData,
				CostsSimulationData: action.CostsSimulationData,
				ROISimulationData: action.ROISimulationData
			});		
		case UPDATE_DRAG_AREA_CHART:
			return Object.assign({}, state, {
				Deal2Data: action.Deal2Data,
				DealStageDayData: action.DealStageDayData
			});	
		case UPDATE_STATUS:
			return Object.assign({}, state, {
				WorkflowNumHeadCountData: action.WorkflowNumHeadCountData,
				WorkflowPercentageOfTimeData: action.WorkflowPercentageOfTimeData
			});	
		case UPDATE_KPI_SIMULATION:
			return Object.assign({}, state, {
				KPISimulationData: action.KPISimulationData
			});	
		case UPDATE_KPI_TOP_PERCENT:
			return Object.assign({}, state, {
				KPITopPercentage: action.KPITopPercentage
			});			
		default: 
			return state
	}
};
