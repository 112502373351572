export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED'
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED'
//export const BASE_API = 'http://198.58.126.254/apip3rceive/public/api/'
export const BASE_API_IP = 'http://139.162.119.227/apicoopbet/public/api/'
//export const BASE_API = 'https://back-end.coopbet.com/api/'
//export const BASE_API = 'https://back1.coopbet.com/api/'
export const BASE_API = 'https://back-end.coopbet.com/api/'
export const LOGOUT = 'LOGOUT'
export const TOKEN_NAME = 'P3RCEIVE_TOKEN'
export const RESET_DASHBOARD = 'RESET_DASHBOARD'
export const UPDATE_VERTICAL = 'UPDATE_VERTICAL'
export const UPDATE_LINE_CHART = 'UPDATE_LINE_CHART'
export const UPDATE_DRAG_AREA_CHART = 'UPDATE_DRAG_AREA_CHART'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_KPI_SIMULATION = 'UPDATE_KPI_SIMULATION'
export const UPDATE_KPI_TOP_PERCENT = 'UPDATE_KPI_TOP_PERCENT'
export const LOGIN = 'LOGIN'
export const BASELINE_LOADED = 'BASELINE_LOADED'
export const PAGE_NOT_FOUND_LOADED = 'PAGE_NOT_FOUND_LOADED'
export const LANGUAGE_KEY = 'LANGUAGE_KEY'
export const CHANGE_TAB = 'CHANGE_TAB'
export const LANGUAGE_LIST = 'LANGUAGE_LIST'
export const TIPMAKET_PAGE_LOADER = 'TIPMAKET_PAGE_LOADER'
export const TODAYTIP_PAGE_LOADER = 'TODAYTIP_PAGE_LOADER'
export const TIPSTERLIST_PAGE_LOADER = 'TIPSTERLIST_PAGE_LOADER'
export const JACKPOT_PAGE_LOADER = 'JACKPOT_PAGE_LOADER'
export const RESULTS_PAGE_LOADER = 'RESULTS_PAGE_LOADER'
export const MYSETTING_PAGE_LOADER = 'MYSETTING_PAGE_LOADER'
export const USERLIST_PAGE_LOADER = 'USERLIST_PAGE_LOADER'
export const MATCHS_PAGE_LOADER = 'MATCHS_PAGE_LOADER'
export const ADDTIPS_PAGE_LOADER = 'ADDTIPS_PAGE_LOADER'
export const ADD_TIPS_PAGE_LOADER = 'ADD_TIPS_PAGE_LOADER'
export const PROFILE_LOADER = 'PROFILE_LOADER'
export const MEBER_NAME = 'MEBER_NAME'
export const USERID_NF = 'USERID_NF'
export const USER_TIMELINE = 'USER_TIMELINE'
export const USER_STATISTIC = 'USER_STATISTIC'
export const LIKE_DATA = 'LIKE_DATA'
export const IMG_USER = 'IMG_USER'
export const RUNNING_LOADER = 'RUNNING_LOADER'
export const RUNNING_HOME_PICK = 'RUNNING_HOME_PICK'
export const UPLOAD_PAGE_LOAD = 'UPLOAD_PAGE_LOAD'
export const AD_PAGE_LOAD = 'AD_PAGE_LOAD'
export const LOAD_AD_DATA = 'LOAD_AD_DATA'
export const CREATE_AD_USER = 'CREATE_AD_USER'
export const ADD_MATCH = 'ADD_MATCH'									
export const ADMIN_EDIT_USER = 'ADMIN_EDIT_USER'
export const AD_USER_LOAD = 'AD_USER_LOAD'
export const MATCH_ID = 'MATCH_ID'
export const ONLY_FIENDS = 'ONLY_FIENDS'
export const REQUEST_LOADER = 'REQUEST_LOADER'
export const UDATE_PICK_COMENT = 'UDATE_PICK_COMENT'
export const ACTIVE_LOADER = 'ACTIVE_LOADER'
export const CONTACT_LOADER = 'CONTACT_LOADER'
export const FRIEND_REQUEST_LOADER = 'FRIEND_REQUEST_LOADER'
export const NOTIFICATION_LOADER = 'NOTIFICATION_LOADER'
export const AD_EDIT_LOAD = 'AD_EDIT_LOAD'
export const AD_LONG_DESCPT = 'AD_LONG_DESCPT'
export const TIPSTERS_LOADER = 'TIPSTERS_LOADER'
export const USERINFO_PAGE_LOADER = 'USERINFO_PAGE_LOADER'
export const TIPS_PAGE_LOADER = 'TIPS_PAGE_LOADER'
export const TIPS_EXCHANGE_LOADER = 'TIPS_EXCHANGE_LOADER'

export const BASE_IMAGE = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

function importAll(r) {
 let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}