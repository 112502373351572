import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
///import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import {CHANGE_TAB,TOKEN_NAME,MEBER_NAME} from '../../constants/actionTypes'
import { Link } from 'react-router-dom'
import agent from '../../agent'
//import languages from '../../languages'
//import translations from '../common/captionhome.json';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import $ from 'jquery';
import ModalErorr from '../common/ModalErorr'
import { Cookies } from 'react-cookie' 
import menu from '../common/menu.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		member_name: state.common.member_name,
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	setMember: (data)=>{ 
		dispatch({ type: MEBER_NAME, data })
	}
});



 


class Login extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			user_name:'',password:'',isOpenModal:false,msgRigister:''
		}
		//---
		this.login=this.login.bind(this)
		//---
		this.signinF = this.signinF.bind(this);
		//---
		this.signinG = this.signinG.bind(this);
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		} 
		this.handleLogin=(e)=>{
		if (e.key == 'Enter') {
				this.login()
			}
		}
  }
	handleChangeFiled(field,ev){
		this.setState({[field]:ev.target.value})
	}
	//---
	
	login(){
		agent.auth.doLogin({user_name:this.state.user_name,password:this.state.password,lang_id:this.state.lguages}).then(data => {
			//console.log(data)
			if(data.result==='good'){
				window.localStorage.setItem( TOKEN_NAME, JSON.stringify(data) );
				let UserInfo=data.UserInfo;
				this.props.setMember(UserInfo.member_name)
				this.props.setToken(JSON.stringify(data))
				window.location.href = 'home'
				this.props.onTabClick('home')
			}else{
				window.localStorage.setItem( TOKEN_NAME, '');
				this.setState({showlogout:false,msgRigister:data.message,isOpenModal:true}) 
				//$(".msg-error").html(data.message)
				setTimeout(function(){
					$(".msg-error").html('')
				},9000);
			}
		})
		//
	}
	//---
	signinF(res) {
		let postData;
		if (res.email) {
            postData = {
                name: res.name,
                provider: 'facebook',
                email: res.email,
                provider_id: res.id,
                provider_token: res.accessToken,
                provider_pic: res.picture.data.url
           };
		}

		if (postData) {
            console.log(postData);            
			agent.auth.fsignin(postData).then(data => {
                //console.log(data);                
				if(data.result==='good'){
					window.localStorage.setItem(TOKEN_NAME, JSON.stringify(data));
					let UserInfo=data.UserInfo;
					this.props.setToken(JSON.stringify(data))
					this.props.setMember(UserInfo.member_name)
					this.props.onTabClick('home')
				} else{
					window.localStorage.setItem(TOKEN_NAME, '');
				}
            })
            
		} else {

        }
		
    }
	//------
	signinG(res) {
		let postData;
		/*if (res.w3.U3) {
		    postData = {
			    name: res.w3.ig,
			    email: res.w3.U3,
			    provider: 'google',
			    provider_id: res.El,
			    provider_token: res.Zi.access_token,
			    provider_pic: res.w3.Paa
		    };
		}*/
		//if (!res.error && res.tc) {
		if (!res.error) {
		    postData = {
			    name: res.profileObj.name,
			    email: res.profileObj.email,
			    provider: 'google',
			    provider_id: res.profileObj.googleId,
			   // provider_token: res.tc.access_token,
			    provider_token: res.accessToken,
			    provider_pic: res.Ca
		    };
		}

		if (postData) {
            //console.log(postData);
			agent.auth.gsignin(postData).then(data => {
                //console.log(data);                
				if(data.result==='good'){
					window.localStorage.setItem(TOKEN_NAME, JSON.stringify(data));					
					this.props.setToken(JSON.stringify(data))
					let UserInfo=data.UserInfo;
					this.props.setMember(UserInfo.member_name)
					this.props.onTabClick('home')
				} else{
					window.localStorage.setItem(TOKEN_NAME, '');
				}
			})
		} else {

        }
		
    }
	//-----
	componentWillMount(){		
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	
	render(){   
		//console.log(this.state.lguages);
		//--------
		const responseFacebook = (response) => {
		    //console.log(response);
		    this.signinF(response);
		}
		//---------
		const responseGoogle = (response) => {
		    //console.log(response);
		    this.signinG(response);
		}
		//---------
        return (
			<div id="body">   
				<div className="login">
					<div className="heder-page">
						<div className="container">
							<div className="item lbl-1">
								<h1>
									<span className="txt">{localizeKey(menu)('login', this.state.lguages)}</span>
								</h1>
							</div>
						</div>
					</div>
					{
						//----
					}
					<div className="container">
						<section id="loginForm">
							<h2>{localizeKey(menu)('txtitlelogin', this.state.lguages)}</h2>
							<form  method="post">
								<fieldset>
									<legend>Log in Form</legend>
									<ol>
										<li>
											<label>User name</label>
											
											<input  id="UserName" name="UserName" type="text" value="" value={this.state.user_name} onChange={this.handleChangeFiled.bind(this,'user_name')}/>
											
										</li>
										<li>
											<label>{localizeKey(menu)('password', this.state.lguages)}</label>
											
											<input id="Password" name="Password" type="password" value={this.state.password} onChange={this.handleChangeFiled.bind(this,'password')} onKeyPress={this.handleLogin}/>
											
										</li>
										<li>
											<div className="div-left">
												<input data-val="true" data-val-required="The Remember me? field is required." id="RememberMe" name="RememberMe" type="checkbox" value="true"/>
												<input name="RememberMe" type="hidden" value="false"/>
												<label className="checkbox">{localizeKey(menu)('rememnerMe', this.state.lguages)}</label>
											</div>
											<div className="div-right">
												<a href="/forgot-password">{localizeKey(menu)('forgotpass', this.state.lguages)}</a>
											</div>
										</li>
									</ol>
									<input type="button" value={localizeKey(menu)('login', this.state.lguages)} className="btn-login" style={{'marginLeft':'145px'}} onClick={this.login}/>
									<div className="msg-error" id="msg-error" style={{'marginLeft':'145px'}}></div>
								</fieldset>
								<p className="return-register">
									<Link to="/register"><span className="rg">{localizeKey(menu)('txtregister', this.state.lguages)}</span></Link>
								</p>
							</form>     
						</section>
						<section className="social" id="socialLoginForm">
							<h2>{localizeKey(menu)('logwithother', this.state.lguages)}</h2>
							<div className="externall-login">
								<div className="item-ex" style={{'display':'none'}}>
								{
									//<a className="login-facebook">
									//	<span className="fa fa-face">f</span><span>Login with Facebook account</span>
									//</a>
								}
									
									<a className="login-facebook">
										<span className="fa fa-face">f</span>
										<FacebookLogin
											appId="280091142552634"
											autoLoad={false}
											fields="name,email,picture"
											callback={responseFacebook}
										/>
									</a>
								</div>
								<div className="item-ex">
								{
									//<a className="login-google">
										//<span className="fa fa-face">g<b>+</b> </span><span>Login with Google account</span>
									//</a>
								}
									<a className="login-google">
										<span className="fa fa-face">g<b>+</b> </span>
										<GoogleLogin
											clientId="1097469557398-2jj3g8bb3qku1l98qvrs6p5o02bo22bl.apps.googleusercontent.com"
											buttonText={localizeKey(menu)('lgGg', this.state.lguages)}
											onSuccess={responseGoogle}
											onFailure={responseGoogle}
										/>
									</a>
								</div>
							</div>
						</section>
					</div>
					{
						//----
					}
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)