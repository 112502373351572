import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {RESET_DASHBOARD, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({               
	onResetDashboard: (data) => {
		dispatch({ type: RESET_DASHBOARD, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class MyFriends extends React.Component {
	constructor(){
		super()
		this.state = {
			url:'https://www.coopbet.com/media/avatar_none.png',
			list:[],isOpenModal:false,msgLogin:''
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		
		//----
		
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		
	}	

	componentWillMount() {
		//---
		
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		 if(login!='' && login!='null' && login!=null){
			 this.friendlist()
		 }else{
			
		 }
		
	}	
	componentDidMount() {
		
	}
	friendlist(){
		agent.friends.friendList({}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.setState({list:datas.list})
			}
			
		  })	
	}
	
	showBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").show();
		//console.log($this)
	}
	hideBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").hide();
	}
	deleteFriend(ev){
		let $this=ev.target
		let id=$($this).attr("data-id")
		agent.friends.deleteFriend({friend_user_id:id}).then((datas) => {
			console.log(datas)
			if(datas.result=='good'){
				let newList=this.state.list.map(item=>{
					if(item!='' && item!='null' && item!=null && item.user_id != id){
						return item;
					}else{
						return null;
					}
				})
				this.setState({list:newList})
			}
			
		  })	
	}
	render() {
		$(".body").css({'background':'#e9ebee'})
		$(window).hover(function(e){
			if($(e.target).closest(".g-btn").length==0 && $(e.target).closest(".dr-fr").length==0 ){
				$(".dr-fr").hide();
			}
		})
		let url=this.state.url
		const list=this.state.list
		//console.log(list)
		//---------
			return (
				<div className="body">
					<div className="container">
						<Row>
							<Col md={9} className="friend-body">
								<div className="fiend-box-top" id="friend-request">
									<div className="frd-top">
										<div className="ft-items">
											<h3 className="header _hdf">Friends</h3>
											<Col md={12} className="pd0 ctn mg10">
												{
													(list!='' && list!='null' && list!=null)?list.map((data,index)=>{
														if(data!='' && data!='null' && data!=null){
															return(
																<Col md={6} className="ft-item ft-item-0 ft-item-bst" key={index}>
																	<figure className="pull-left"><img src={(data.imageurl!='')?data.imageurl:url} width="50" height="50" className="_rv"/></figure>
																	<div className="fr-it-body">	
																		<div className="name name-fr pull-left">
																			<span className="fwb">{data.tipster_name}</span>
																			<div className="count-fr">{data.friendcount} {(data.friendcount>1)?'friends':'friend'}</div>
																		</div>
																		<div className="g-btn pull-right">
																			<a className="btn-2 pull-right tfd-delete" onMouseOver={this.showBoxFriend.bind(this)}><i className="fa fa-angle-down fa-it"></i>Friends</a>
																			<ul role="menu" className="dropdown-menu dr-fr" onMouseLeave={this.hideBoxFriend.bind(this)}>
																				<li><a data-id={data.user_id} onClick={this.deleteFriend.bind(this)}>Unfriend</a></li>
																			</ul>
																		</div>
																	</div>
																</Col>
															)
														}
														
													}):<div></div>
												}
												
												
													
											</Col>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						
						<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
					</div>
				</div>
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyFriends)
