import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
//import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import {RESULTS_PAGE_LOADER,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onLoad: (data) => {
		dispatch({ type: RESULTS_PAGE_LOADER })  
	},
});



 


class Results extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005'
		}
		//---
		
  }
		//---
	componentWillMount(){		
		this.props.onLoad('results')
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	
	render(){   
		
        return (
			<div id="body">   
				Results
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results)