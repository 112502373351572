import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/main.css'
import '../../styles/home.css'
import '../../styles/App.css'
import {Col, Row, Button} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY,CHANGE_TAB} from '../../constants/actionTypes'
import agent from '../../agent'
import languages from '../../languages'
import translations from '../common/captionhome.json';
import HomeList from './homeList.js';
import Pusher from 'pusher-js';
import Lqhome from '../common/home.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey,
		only_friend: state.common.only_friend,
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 

var pusher = new Pusher('d75bec97873cadcd8a43', {
	cluster: 'ap1',
	forceTLS: true
});
class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},textlink:'',
			homebetList:[],only_friend:'0',limit:30,offset:0,scrollM:false,list:[],loadedAll:false,isLoading:false,
			filelist:[],
		}
		//---
		this.getrandomad=(lang_id)=>{
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info,textlink:data.textlink})
				}
			})
		}
		//---
		this.gethomebetlist=()=>{
			$(".imgload").show()
			let search=$(".txt-search").val();
			let limit=this.state.limit
			let offset=this.state.offset
			let lang_id=this.state.lguages
			
			agent.home.gethomebetlist({search:search,limit:limit,offset:offset,lang_id:lang_id}).then(data => {
				//console.log(data)
				$(".imgload").hide()
				if(data.result === 'good'){
					//this.setState({homebetList:data.list})
					$(".imgload").hide()	
					const loadedAll = data.list.length < this.state.limit ? true: false	
					//const list = this.state.scrollM ? this.state.list.concat(data.list):data.list
					const list = this.state.list.concat(data.list)
					const newState = Object.assign({}, this.state, { list: list,scrollM: false,loadedAll:loadedAll,isLoading:false})
					this.setState(newState)	
					this.props.onLoad(list);
				}else{
					this.setState({list:this.state.list,isLoading:false})
				}
			})
		}
		//---
		this.gethomebetlistNew=()=>{
			$(".imgload").show()
			let search=$(".txt-search").val();
			let only_friend=this.state.only_friend
			let limit=this.state.limit
			let offset=this.state.offset
			let lang_id=this.state.lguages
			agent.home.gethomebetlistNew({search:search,only_friends:only_friend,limit:limit,offset:offset,lang_id:lang_id}).then(data => {
				//console.log(data)
				$(".imgload").hide()
				if(data && data!=null && data.result === 'good'){
					//this.setState({homebetList:data.list})
					if(data.list!='' && data.list!=null){
						const loadedAll = data.list.length < this.state.limit-1 ? true: false	
						//const list = this.state.scrollM ? this.state.list.concat(data.list):data.list
						const list = this.state.list.concat(data.list)
						const newState = Object.assign({}, this.state, { list: list,scrollM: false,loadedAll:loadedAll,isLoading:false })
						this.setState(newState)	
						this.props.onLoad(list);
					}else{
						this.setState({list:this.state.list,isLoading:false})
					}
					
				}
			})
		}
		this.onScrollTip = (e) => {
			const loadedAll = this.state.loadedAll
			const tab = this.props.tab
			//console.log(loadedAll,$(window).scrollTop() + $(window).height() , $(document).height())
			if(!loadedAll && $(window).scrollTop() + $(window).height() == $(document).height()) {
				   let offset = parseInt(this.state.offset) + this.state.limit
					//let pageno = parseInt(this.state.pageno) + this.state.pagelength
					const newState = Object.assign({}, this.state, { offset: offset, scrollM: true })
					this.setState(newState, () => {
						let login=window.localStorage.getItem(TOKEN_NAME);
						if(login!='' && login!='null' && login!=null){
							this.gethomebetlistNew()
							
						}else{
							this.gethomebetlist()
						}
					})	
			   }
			/*const messgeContainer = document.getElementById("home-list")
			

			if( !loadedAll && messgeContainer.scrollTop === (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
				let offset = parseInt(this.state.offset) + this.state.limit
				//let pageno = parseInt(this.state.pageno) + this.state.pagelength
				const newState = Object.assign({}, this.state, { offset: offset, scrollM: true })
				this.setState(newState, () => {
					let login=window.localStorage.getItem(TOKEN_NAME);
					if(login!='' && login!='null' && login!=null){
						this.gethomebetlistNew()
						
					}else{
						this.gethomebetlist()
					}
				})				
			}*/
		}
		//---
		this.checksuspend=()=>{
			try{
				let login=window.localStorage.getItem(TOKEN_NAME);
				login=JSON.parse(login)
				if(login!='' && login!='null' && login!=null && login.UserInfo.role_id!=2){
					agent.auth.checksuspend().then(data => {
						//console.log(data)
						if(data!=null && data.result === 'good' && data.suspended==1){
							window.location='/un-available' 
						}
					})
				}
			}catch(e){
				console.log(e)
			}
		}
		this.handleClickTab = menu => e => {
			e.preventDefault()
			this.props.onTabClick(menu)
			
		}
		this.loadMore=()=>{
			let height=$("#all-list-home").height();
			let offset = parseInt(this.state.offset) + this.state.limit
			this.setState({isLoading:true,offset},()=>{
				//$("html,body").animate({ scrollTop: 0 }, "slow");
				let login=window.localStorage.getItem(TOKEN_NAME);
				if(login!='' && login!='null' && login!=null){
					this.gethomebetlistNew()
				}else{
					this.gethomebetlist()
				}
			})
			setTimeout(function(){
				$('html,body').animate({
							scrollTop: height
						}, 15);
				
			},8000);
			
		}
		
		this.goNewInfo=(ad_long_description,ad_title,id,e)=>{
			window.localStorage.setItem('ad_long_description', JSON.stringify({text:ad_long_description,'title':ad_title}))
			setTimeout(function(){
				window.location.href='/new-info/'+id
				
			},9);
		}
		this.getaddlogos=()=>{
			agent.auth.getaddlogos({}).then(datas => {
				if(datas.result==="good"){
					let filelist=datas.filelist;
					this.setState({filelist:filelist})
					console.log(filelist)
				}
			})
		}
		this.goAdView=this.goAdView.bind(this)
	}
	
	componentWillMount(){	
		//-----
		
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//-----
		
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
			let only_friend=window.localStorage.getItem("only_friend");
			//console.log(only_friend)
			this.setState({only_friend:only_friend,offset:0},()=>{
				this.gethomebetlistNew()
			})
			window.addEventListener('click', this.checksuspend);
			this.getSocketForNum();
		}else{
			this.gethomebetlist()
		}
		this.getrandomad(language)
		this.getaddlogos();
		
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			this.getrandomad(nextProps.languageKey)
		}
		if(nextProps.dashboardData){
			this.setState({homebetList:nextProps.dashboardData});
		}
		if(nextProps.only_friend && nextProps.only_friend!='null' && nextProps.only_friend!=null){
			this.setState({only_friend:nextProps.only_friend},()=>{
				
			});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	componentDidMount() {
		//window.addEventListener('scroll', this.onScrollTip, true);
		const tab = this.props.tab
		if(tab=='home'){
			//window.addEventListener('scroll', this.onScrollTip);
		}
	}

	componentWillUnmount() {
		//window.removeEventListener('scroll', this.onScrollTip);
		window.removeEventListener('click', this.checksuspend);
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		this.props.onTabClick("ad-list")
	}
	getSocketForNum(){
		var thiss=this
		var channelcode = 'coopbet-pick';
		var event = 'pickcoming-changed';
		var channel = pusher.subscribe(channelcode);
		channel.bind(event, function(data) {
			//alert(JSON.stringify(data));
			//console.log(data)
			var homebetList=thiss.state.homebetList
			var PickCodeComingNumber=data.PickCodeComingNumber
			var PickCodeComingUsers=data.PickCodeComingUsers
			if(homebetList!=null && homebetList!='' && homebetList!=true){
				let newhomeList=homebetList.map(dt=>{
					if(dt.PickCode==data.PickCode){
						dt.PickCodeComingNumber=PickCodeComingNumber
						dt.PickCodeComingUsers=PickCodeComingUsers
					}
					return dt;
				})
				//console.log(newhomeList)
				thiss.setState({homebetList:newhomeList})
			}
		});
	}
	render(){   
		//console.log(this.state.lguages);
		//console.log(languages);
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
		}			
		
		//---homebetList
		const homebetList=this.state.homebetList;
		//---
		let login=window.localStorage.getItem(TOKEN_NAME);
		let member_name='';
		if(login!='' && login!='null' && login!=null){
			let log=JSON.parse(login)
			member_name=log.UserInfo.member_name
		}
		let linkcado=''
		if(this.state.textlink!==undefined && this.state.textlink!==''){
			let linkT=this.state.textlink.split(" ");
			linkcado=linkT[linkT.length-1]
		}
		let filelist=this.state.filelist;
		console.log(filelist)
		//---
        return (
			<div id="body">   
				<div className="list-user" style={{'paddingTop':''}}>
				<div className="ct-ln-cado">
					<a href={linkcado} target="_blank" className="lnk-cado">
						 {this.state.textlink}
					</a>
				</div>
				<div className="ct-ln-kito">
					<a href="https://ktoviet.com/vn83736c" target="_blank" className="lnk-cado">
						 KTO Châu Á – Đối Tác Thương Hiệu độc quyền của Cá Độ VN và CoopBet
					</a>
				</div>
				
					<div className="container" >
						<div className="row" style={{'marginTop':'10px'}}>
							<div className="col-md-12 linklogo" style={{'textAlign':'right'}}>
								<i style={{'fontWeight':'600','color':'#999','marginRight':'3px'}}>Powered by: </i>
								{
									(filelist!=undefined && filelist!=null)?filelist.map((dt,i)=>{
										let link =(i==0)?'https://ktoviet.com/vn83736c':dt.linkto;
										return(
											<a href={link} target="_blank" key={i}>
												<img src={dt.fileurl} style={{'margin':'0 2px'}}/>
											</a>
										)
									}):''
								}
								
							</div>
						</div>
						<div className="items" style={{'marginTop':'10px'}}>
							{(qcinfo!='' && qcinfo!=null)?
							<div className="row">
								<div className="col-md-3">
									<div className="img-adv">
										<a href={url} target="_blank" className="rand-logo">
											<img alt="m88" src={imageurl} width="100" height="50"/>
										</a>
									</div>
								</div>
								<div className="col-md-9">
									<div className="desc-adv">
										<p style={{'height':'43px'}}>
											{
											ReactHtmlParser(description)
											}
										</p>
										<div>
										{
											(qcinfo!='' && qcinfo!=null && qcinfo.ad_long_description!='' && qcinfo.ad_long_description!=null)?
											<a onClick={this.goNewInfo.bind(this,qcinfo.ad_long_description,qcinfo.ad_title,qcinfo.id)} dataid={qcinfo.ad_user_id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
										}
										</div>
									</div>
								</div>
							</div>:''					
							}
						</div>
						<HomeList homebetList={homebetList} loadlist={this.gethomebetlistNew} loginInfo={login} onScrollTip={this.onScrollTip} only_friend={this.state.only_friend}/>
						
						{
							//(this.state.homebetList!='' && this.state.homebetList!=null &&  this.state.homebetList.length>29)?
							(this.state.homebetList!='' && this.state.homebetList!=null &&  this.state.homebetList.length>20)?
							<Row className="mg0">
								<Col md={12} className="div-load-more">
									<Button
										id="load-more"
										onClick={!this.state.isLoading ? this.loadMore : null}>
										{this.state.isLoading ? <span style={{'fontSize':'10px'}}><i className='fa fa-spinner fa-spin '></i> LOAD MORE ...</span> :<span>LOAD MORE </span>}
									 </Button>
								</Col>
							</Row>:''
						}
						
					</div>
				</div>

			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)