import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY,USERID_NF,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE} from '../../constants/actionTypes'
import cahome from '../common/captionhome.json';
import ModalBlock from './ModalBlock'
import ModalErorr from '../common/ModalErorr'
import ModalConfirmUnBlock from './ModalConfirmUnBlock'
import { localize, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import Lqhome from '../common/home.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class Block extends React.Component {
	constructor(){
		super()
		this.state = {
			lguages:'1005', isOpenModal:false,user_block:'',isOpenModalEror:false,msgerror:'',blockList:[],
			isOpenModalConfirmUnblock:false,user_id:''
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,isOpenModalEror:false,isOpenModalConfirmUnblock:false})
		}
		this.callModalError=(msgerror)=>{
			this.setState({isOpenModal:false,isOpenModalConfirmUnblock:false,isOpenModalEror:true,msgerror:msgerror})
		}
		//----
		this.showModalBolock=()=>{
			this.setState({isOpenModal:true,isOpenModalEror:false,isOpenModalConfirmUnblock:false})
		}
		this.showModalUnblock=(user_id)=>{
			this.setState({isOpenModal:false,isOpenModalEror:false,isOpenModalConfirmUnblock:true,user_id:user_id})
		}
		this.blockedusers=()=>{
			agent.running.blockedusers({}).then(data => {
				//console.log(data)
				if(data.result=="good"){
					this.setState({blockList:data.list})
				}
			})
		}
		//----
		this.handleChangeFiled=this.handleChangeFiled.bind(this)
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		
	}	

	componentWillMount() {
		//---
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		 if(login!='' && login!='null' && login!=null){
			// this.friendlist()
			this.blockedusers(); 
		 }
		 //console.log(login)
		
	}	
	componentDidMount() {
		
	}
	componentWillUnmount() {
	  window.removeEventListener('onChange', this.handleChangeFiled);
	}
	handleChangeFiled(ev){
		//console.log(ev.target.value)
		this.setState({user_block:ev.target.value})
	}
	goProfile(url,e){
		window.location.href='./'+url;
	}
	render() {
		
		//console.log(list)
		let blockList=this.state.blockList
		//---------
			return (
				
					
						<Row className="mg0">
							<h4>{localizeKey(Lqhome)('blockuser', this.state.lguages)}</h4>
							<Col md={12} className="block-body">
								<Row className="">
									{localizeKey(Lqhome)('txtasybl', this.state.lguages)}
								</Row>
								<Row className="mgt20">
									<Col md={3} className="txr-r">{localizeKey(Lqhome)('blockuser', this.state.lguages)}</Col>
									<Col md={6}>
										<input id="user_block" name="user_block" type="text" placeholder={localizeKey(Lqhome)('usname', this.state.lguages)} className="form-control" value={this.state.user_block} onChange={this.handleChangeFiled}/>
										{
											(blockList.length<1)?
											<span className="note">{localizeKey(Lqhome)('txtnoaddlist', this.state.lguages)}</span>:''
										}
									</Col>
									<Col md={3}>
										<Button className="btn-sigin bt-block" onClick={this.showModalBolock}>{localizeKey(cahome)('block', this.state.lguages)}</Button>
									</Col>
								</Row>
								<Row className="mg0" style={{'marginTop':'20px'}}>
									<Col md={2}></Col>
									<Col md={8}>
										<Row className="mg0">
										{
											(blockList!='' && blockList!=null)?blockList.map((dt,idx)=>{
												var url_username=dt.url_username
												let prName='';
												let url_profile='';
												if(url_username!='' && url_username!='null' && url_username!=null){
													prName=url_username.split("/");
													url_profile=prName[prName.length-1];
												}
												return(
													<Col md={6} key={idx}>												
														<label className="lb-name" onClick={this.goProfile.bind(this,url_profile)}><i className="fa fa-square-o icon-bl"></i> {dt.tipster_name}</label>
														<a className="a-block" onClick={this.showModalUnblock.bind(this,dt.user_id)}>{localizeKey(Lqhome)('unblocking', this.state.lguages)}</a>
													</Col>
												)
											}):''
										}
											
										</Row>
									</Col>
									<Col md={2}></Col>
								</Row>
							</Col>
							<ModalBlock isOpen={this.state.isOpenModal} close={this.close} search={this.state.user_block} blockedusers={this.blockedusers}/>
							<ModalErorr isOpen={this.state.isOpenModalEror} close={this.close} text={this.state.msgerror} callModalError={this.callModalError}/>
							<ModalConfirmUnBlock isOpen={this.state.isOpenModalConfirmUnblock} close={this.close} user_id={this.state.user_id}
			                    callModalError={this.callModalError} blockedusers={this.blockedusers}/>
						</Row>
						
						
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Block)
